import { ValidatorFn, AbstractControl } from '@angular/forms';

export function wholeNumberValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if(!control.value) {
      return null;
    }
    // Rules are as follows:
    // 1.) the "1*" = option country code for america
    // 2.) [2-9] = the first digit in the phone number (cannot start with '1' and must be a number)
    // 3.) [A-Z1-9]{9} = the remaining 9 digits in a phone number. Can either be a number or an uppercase character
    const wholeNumberPattern =  /^\d+$/g;
    const isAccepted = wholeNumberPattern.test(control.value);
    return isAccepted ? null : {'wholeNumber': true};
  };
}
