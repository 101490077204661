import { ValidatorFn, AbstractControl, Validators } from '@angular/forms';

export function titleValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if(!control.value) {
      return null;
    }

    // Dont allow Url, Phonenumber, Email for title
    const urlPattern = /^(https?:\/\/)?(www\.)?(([a-zA-Z0-9]{1,}[-a-zA-Z0-9]{0,}[a-zA-Z0-9]+\.){1,})+[a-zA-Z0-9]{2,}(\/([a-zA-Z0-9][-a-zA-Z0-9]+[a-zA-Z0-9\/]){0,})?(.[a-zA-Z0-9]{2,})?$/i;
    const isUrl = urlPattern.test(control.value);

    const phoneNumberPattern =  /1*[2-9][A-Z0-9]{9}/g;
    const isPhoneNumber = phoneNumberPattern.test(control.value);

    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;
    const isEmail = emailPattern.test(control.value);

    return isPhoneNumber || isUrl || isEmail  ? {'title': true} : null;
  };
}
