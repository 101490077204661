// tslint:disable: rxjs-no-unsafe-scope
import { HttpClient, HttpEventType, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ThumbnailSpec } from '../models/photo-thumbnail.model';
import { PhotoType} from '../models/photo-type.enum';
import { Observable, forkJoin, Subject, throwError, of, BehaviorSubject } from 'rxjs';
import { map, tap, filter, catchError, switchMap } from 'rxjs/operators';
import { UploadPhoto } from '../models/upload-photo.model';
import { AppService } from './app.service';
import { LoaderService } from './loader.service';
import { Filename } from '../models/filename.model';
import { ImageLoaderService } from './image-loader.service';
import { FileTypes } from '../constants/file-type.constants';
export type ResponseHandler = (responseBody: any) => void;

/**
 * Upload status is a model that is used to display an image while it is being uploaded.
 * While the image is being uploaded, this model maintains the progress of the upload.
 */
export class UploadStatus {
  thumbnailUrl?: string;
  error?: string;
  photos: UploadPhoto[];
  presignURL: string;
  /** Percent done (0-100) */
  // tslint:disable-next-line: rxjs-no-exposed-subjects
  public readonly progress: Subject<number> = new Subject();

  constructor(public readonly file: File) {
  }
}


@Injectable()
export class PhotoService {
  readonly fileNameEndpoint: string = `${AppService.get('photosBaseURL')}extractFilename`;

  readonly corsProxyEndPoint: string = `${AppService.get('photosBaseURL')}cors/download`;

  private isPhotoGalleryCollapsedSubject = new BehaviorSubject<boolean>(true);
  public isPhotoGalleryCollapsed = this.isPhotoGalleryCollapsedSubject.asObservable();

  constructor(private http: HttpClient,
              public loaderService: LoaderService,
              private imageLoader: ImageLoaderService) {
  }

  public setIsPhotoGalleryCollapsed(isCollapsed: boolean) {
    this.isPhotoGalleryCollapsedSubject.next(isCollapsed);
  }


  /**
   * @param file File to upload
   * @param progress Will emit progress values (0-100) as upload happens
   * @param thumbnailSpec Hints to photo storage service about thumbnail sizes that will be needed.  If provided,
   * @param quiet, if true, no progress is reported or loaderService informed;
   * any thumbnail URLs provided by the photo storage service will be saved in the listing.
   */
  public upload(file: File, progress?: Subject<number>, thumbnailSpec?: ThumbnailSpec[], photoType?: PhotoType): Observable<UploadPhoto[]> {

    // Emit zero progress for start
    if(progress) {
      progress.next(0);
    }

    const formData: FormData = new FormData();
    if (thumbnailSpec) {
      formData.append('thumbnails', JSON.stringify(thumbnailSpec));
    }
    formData.append('photoType', photoType || PhotoType.LISTING_PHOTO);

    formData.append('images', file, file.name);
    // for some reason, busboy in lcms-image is sometimes getting the thumbnails field
    // after the images, which means that it misses the thumbnails specification.
    // by including this next field as a marker, lcms-image will wait until it has
    // all fields to process the images, ensuring that the thumbnails field is available.
    // yes, this is an ugly hack
    formData.append('finalField', 'finalField');

    const url = AppService.get('photosBaseURL') + "upload";
    const req = new HttpRequest('POST', url, formData, { reportProgress: true });

    return this.http.request(req).pipe(
      tap(event => {
        // On the UploadProgress event, update the progress status
        this.loaderService.show();
        if(progress && event.type === HttpEventType.UploadProgress ) {
          const percentDone = Math.round((100 * event.loaded) / event.total);
          progress.next(percentDone);
        }
      }),
      // Filter to only the HttpResponse
      filter(event => event instanceof HttpResponse),
      map((event: HttpResponse<any>) => {
        this.loaderService.hide();
        if(progress) {
          progress.complete();
        }

        if (event.status === 200) {
          // Use provided response handler
          return event.body[0];
        } else {
          console.error("Upload failed with response:" + event.status);
          throwError(new Error("Upload failed with response:" + event.status));
        }
      }),catchError(err => {
        this.loaderService.hide();
        throw err
      })
    );
  }

  public preSignUploadWithStatus(status: UploadStatus, minFileSizeInMB?: number): Observable<UploadStatus> {
    // Load the file into a dataURL for the UI to display
    return this.imageLoader.loadFile(status.file, FileTypes.allExtensions, minFileSizeInMB).pipe(
      tap(dataUrl => status.thumbnailUrl = dataUrl),

      // Then upload the file to the photoService and store the photos that return
      switchMap(() => this.presignURLUpload(status, status.progress)),
      tap(event => event.status === 200 ? status : status),

      //Catch any errors from the previous steps and store them into the statis. Fail gracefully
      catchError(error => {
        status.error = error.statusText ? error.statusText : error;
        status.progress.complete();
        return of(status);
      }),

      //Return the status
      map(() => status),
    );
  }

  public presignURLUpload(presignStatus: UploadStatus, progress?: Subject<number>) {
    if(progress) {
      progress.next(0);
    }
      const req = new HttpRequest('PUT', presignStatus.presignURL, presignStatus.file, { reportProgress: true });
      return this.http.request(req).pipe(
          tap(event => {
            if(progress && event.type === HttpEventType.UploadProgress ) {
              const percentDone = Math.round((100 * event.loaded) / event.total);
              progress.next(percentDone);
            }
          }),
          // Filter to only the HttpResponse
          filter(event => event instanceof HttpResponse),
          map((event: HttpResponse<any>) => {
            if(progress) {
              progress.complete();
            }

            if (event.status === 200) {
              // Use provided response handler
              return event;
            } else {
              console.error("Upload failed with response:" + event.status);
              throwError(new Error("Upload failed with response:" + event.status));
            }
          }), catchError(err => {
            throw err
          })
        );
  }

  /**
   * Fetches the image from the s3 along with the file information and returns a File
   * @param imageUrl The imageUrl to fetch the file for
   */
  getImage(imageUrl: string): Observable<File> {
    // TODO: change api call to be initiated via apiservice once we have only one image source for downloading
    return forkJoin([this.getImageFile(imageUrl), this.getFilename(imageUrl)]).pipe(
      map(([blob, filename]) => {
        // Combine the blob information and the filename into a file object
        const file = <any>blob;
        file.name = filename.filename;
        return <File>file;
      })
    );
  }

  getFileUsingCorsProxy(imageUrl: string): Observable<File> {
    const params = new HttpParams().set('url', imageUrl);
    const setHeaders = <any>{};
    setHeaders['Access-Control-Allow-Headers'] =  'x-lc-filename';
    console.log('endpoint: ' + this.corsProxyEndPoint);
    console.log('url: ', imageUrl);
    return this.http.get(this.corsProxyEndPoint, { params, observe: 'response', responseType: 'blob'})
      .pipe(
        map( response => {
            const file =  <any>response.body;

            // Lets make the Block look like a file which is what the calliong code expects;
            file.name = response.headers.get('x-lc-filename');
            if (!file.name) {
              // Chrome will not expose this custom header to the Javascript (Firefox does)
              // so we use the last path element in the url.
              file.name = this.extractFilename(imageUrl);
            }
            console.log('file.name = ' + file.name)
            return file;
        }),
        catchError( err => {
        throw err;
      }));
  }

  extractFilename(imageUrl: string) {
    let name = imageUrl.substring(imageUrl.lastIndexOf('/')+1);
    const index = name.indexOf('_-_');
    if (index !== -1) {
      name = name.substring(index+3);
    }
    return name;
  }
  /**
   * Retrieves the image data for the given url and returns a blob
   * @param imageUrl The image to download as a blob
   */
  private getImageFile(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { observe: 'response', responseType: 'blob' })
      .pipe(map(response => response.body))
  }

  /**
   * Retrieves the file name related to the given imageUrl
   * @param imageUrl The imageUrl to retrieve the file name from
   */
  private getFilename(imageUrl: string): Observable<Filename> {
    const params = new HttpParams().set('imgurl', imageUrl);
    return this.http.get<Filename>(this.fileNameEndpoint, { params, observe: 'response', responseType: 'json' })
      .pipe(map(response => new Filename(response.body)));
  }

}
