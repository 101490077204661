import { ActionReducer } from '@ngrx/store';

// console.log all actions
export function debugReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    console.log('state', state);
    console.log('action', action);

    const newState = reducer(state, action);
    console.log('new state', newState);
    return newState;
  };
}
