import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { PhotoAgency, ProductInstance, ServiceActions, MarketingOrder, MarketingOrderContacts } from '../models';
import { UpdateCurrentOrderState } from '../state-mgmt/order/order.actions';
import { Store } from '@ngrx/store';
import { ProductInstanceForm } from '../forms';

@Injectable({
  providedIn: 'root'
})
export class MarketingOrderProductService {

  constructor(private apiService: ApiService, private store: Store<any>) { }

  /**
   * Returns the scoped agencies for the given marketing order and productCode
   * @param marketingOrderId The marketingOrder to scope agencies from
   * @param productCode The productCode used for agencyOverrides
   */
  getAgencies$(marketingOrderId: string, productCode: string): Observable<PhotoAgency[]> {
    return this.apiService.get<PhotoAgency[]>(`marketing-orders/${marketingOrderId}/products/${productCode}/vendors`).pipe(
      map(agents => agents.map(agent => new PhotoAgency(agent)))
    );
  }

  /**
   * Take an action on a product that may change state
   * @param id the marketing order id
   * @param action the action
   * @param product the product to act upon
   */
  async productAction(id: string, action: ServiceActions, product: ProductInstance): Promise<MarketingOrder> {
    const route = `marketing-orders/${id}/action/${action}?productCode=${product.code}`;
    return await this.apiService.put<MarketingOrder>(route, {approval: product.approval, targetState: product.status}).pipe(
      map(updatedOrder => new MarketingOrder(updatedOrder)),
      tap(updatedOrder => this.store.dispatch(new UpdateCurrentOrderState(updatedOrder))),
      catchError(errors => { throw Error(this.formatErrors(errors, 'An error occurred updating the product status.')); })
    ).toPromise();
  }

  getContactBlock$(marketingOrderId: string, product: ProductInstance): Observable<string> {
    const route = `marketing-orders/${marketingOrderId}/products/${product.code}/contact-block`;
    return this.apiService.get<string>(route).pipe(
      catchError(errors => { throw Error(this.formatErrors(errors, 'An error occurred while retrieving the contact block')); })
    );
  }

  async patchProduct(marketingOrderId: string, productCode: string, productForm: ProductInstanceForm) {
    const patchValue = productForm.getDirty(); // These are only the form values that have changed;
    const route = `marketing-orders/${marketingOrderId}/products/${productCode}`;
    return await this.apiService.patch<MarketingOrder>(route, patchValue).pipe(
      map(updatedOrder => new MarketingOrder(updatedOrder)),
      tap(updatedOrder => this.store.dispatch(new UpdateCurrentOrderState(updatedOrder))),
      catchError(errors => { throw Error(this.formatErrors(errors, 'An error occured while patching the product')); })
    ).toPromise();
  }

  async replaceProduct(marketingOrder: MarketingOrder, pkgCode: string, fromProductCode: string, toProductCode: string) {
    const route = `marketing-orders/${marketingOrder._id}/packages/${pkgCode}/products/replace/${fromProductCode}/${toProductCode}`;
    return await this.apiService.post<MarketingOrder>(route, marketingOrder).pipe(
      map(updatedOrder => new MarketingOrder(updatedOrder)),
      tap(updatedOrder => this.store.dispatch(new UpdateCurrentOrderState(updatedOrder)))
    ).toPromise();
  }

  private formatErrors(errors: any, defaultError?: string) {
    const message = errors?.error?.error?.raw?.message || errors?.error?.error?.message || defaultError;
    return message.toString();
  }
}
