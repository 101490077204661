import { NotificationEvent } from '../notifications';

/**
 * Structured data class for errors.
 */
export class ErrorData {
  type: string;
  payload: any;
  message: string;

  /**
   * Create the ErrorData object
   *
   * @param type the string constant describing error
   * @param payload the payload of the error. May be an Error object or something else
   * @param message optional message for error
   */
  constructor(type: string, payload: any, message?: string) {
    this.type = type;
    this.payload = payload;
    this.message = message;
  }

  getNotificationEvent() {
    return new NotificationEvent(this.type, this.message, this.payload);
  }
}
