import { Action } from '@ngrx/store';


export enum SessionActionTypes {
  UpdateUserSession = '[User] Session Updated'
}


export class UpdateUserSession implements Action {
  readonly type = SessionActionTypes.UpdateUserSession;
  constructor(public payload: any) {}

}

export type SessionActions = UpdateUserSession;

export const USERSESSION = 'userSession';

