import { Injectable } from '@angular/core';
import { NotificationEventService } from '../notifications/notification-event.service';
import { NotificationEvent, NotificationEventTypes } from '../notifications/notification-event';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private notificationService: NotificationEventService) { }

  showInfo(info: string) {
    const event = new NotificationEvent(NotificationEventTypes.INFO, info);
    this.notificationService.getEventEmitter().emit(event);
  }

  showError(error: string) {
    const event = new NotificationEvent(NotificationEventTypes.ERROR, error);
    this.notificationService.getEventEmitter().emit(event);
  }
}
