<mat-form-field [id]="idPrefix + '-form-field'" appearance="outline" floatLabel="always" [class.ng-valid]="errors === ''" [class.mat-form-field-readonly]="readonly">

  <!-- Label -->
  <mat-label [id]="idPrefix + '-label'">
    <template *ngIf="labelTemplate; else defaultLabel" [ngTemplateOutlet]="labelTemplate"></template>
    <ng-template #defaultLabel>
      {{label}}
    </ng-template>
  </mat-label>

  <!-- Input -->
  <input matInput [id]="idPrefix + '-input'" [placeholder]="placeholder" type="text" [mask]="inputMask?.mask" [patterns]="inputMask?.pattern" [formControl]="formControl" (blur)="onBlur()" [readonly]="readonly" [dropSpecialCharacters]="maskDropSpecialCharacters" [errorStateMatcher]="matcher">

  <!-- Hint-->
  <mat-hint [id]="idPrefix + '-hint'" class="w-100">
    <template *ngIf="hintTemplate; else defaultHint" [ngTemplateOutlet]="hintTemplate"></template>
    <ng-template #defaultHint>
      {{hint}}
    </ng-template>
  </mat-hint>

  <!-- Error -->
  <mat-error [id]="idPrefix + '-error'" *ngIf="formControl && formControl.invalid" [innerHTML]="errors"></mat-error>

  <!-- Suffix -->
  <div matSuffix>
    <template *ngIf="suffixTemplate; else defaultSuffix" [ngTemplateOutlet]="suffixTemplate" ></template>
    <ng-template #defaultSuffix>
      <span *ngIf="suffix">{{suffix}}</span>
    </ng-template>
  </div>
</mat-form-field>

