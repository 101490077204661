import { Actions, Effect, ofType } from '@ngrx/effects';
import { Profile } from '../../models/profile.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, filter, switchMap, tap, mergeMap } from 'rxjs/operators';
import { ProfileService } from '../../services/profile.service';
import { Store } from '@ngrx/store';
import {
  UpdateProfile,
  UpdateProfileComplete,
  ProfileActionTypes,
  GetProfileComplete,
  GetProfile
} from './profile.actions';
import { ErrorData, GlobalErrorHandler } from '../../errors';
import { NotificationEvent, NotificationEventService } from '../../notifications';
import { UserProfileUpdated } from '../user/user.actions';
import { BaseEffects } from '../base.effects';
import { GetOrder } from '../order/order.actions';
import { MarketingOrder } from '../../models/marketing-order.model';

@Injectable()
export class ProfileEffects extends BaseEffects {

  constructor(private actions$: Actions,
              private profileService: ProfileService,
              private store: Store<any>,
              eventService: NotificationEventService,
              errorHandler: GlobalErrorHandler) {
    super(errorHandler, eventService);
  }

  @Effect( { dispatch: false})
  getProfile: Observable<Profile> = this.actions$.pipe(
    ofType<GetProfile>(ProfileActionTypes.GetProfile),
    switchMap ((action) => {
      return this.profileService.getProfile(action.payload).pipe(
        catchError(err => this.processCatchError(ProfileActionTypes.GetProfile, {payload: action.payload}, err)),
      );
    }),
    filter( profile => !(profile instanceof ErrorData) ),
    tap((profile: Profile) => {
      this.store.dispatch(new GetProfileComplete(profile));
      const event = new NotificationEvent(ProfileActionTypes.GetProfileComplete, 'Profile loaded');
      this.eventService.getEventEmitter().emit(event);
    })
  );

  @Effect( { dispatch: false})
  updateProfile: Observable<Profile> = this.actions$.pipe(
    ofType<UpdateProfile>(ProfileActionTypes.UpdateProfile),
    mergeMap  (action => {
      return this.profileService.updateProfilePartial(action.payload, action.fields).pipe(
        tap(profile => {
          if(action.orderId) {
            // If the orderID is passed through as an action, we need to call to dispatch an
            // action to GetOrder so that the UI will update with the proper team information.
            // NOTE: This dispatch is using the cached version of the MarketingOrder.
            // Pass false as the last parameter if you want it to get the latest from the API
            this.store.dispatch(new GetOrder(new MarketingOrder({_id: action.orderId})));
          }
          if(action.isCurrentProfile){
            this.store.dispatch( new UserProfileUpdated(profile));
          }
        }),
        catchError(err => this.processCatchError(ProfileActionTypes.UpdateProfile, {payload: action.payload, fields: action.fields}, err)),
      );
    }),
    filter( profile => !(profile instanceof ErrorData) ),
    tap((profile: Profile) => {
      this.updateLoggedInUserProfile(profile);
      this.updateComplete(profile);
    })
  );

  updateLoggedInUserProfile(profile: Profile): void {
    this.store.dispatch( new UserProfileUpdated(profile));
  }

  updateComplete(profile: Profile) {
    this.store.dispatch(new UpdateProfileComplete(profile));
    const event = new NotificationEvent(ProfileActionTypes.UpdateProfileComplete, 'Profile updated');
    this.eventService.getEventEmitter().emit(event);
  }
}
