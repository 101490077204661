import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { InputField } from '../../inputs/input-field';
import { DomSanitizer } from '@angular/platform-browser';
import { FileTypes, UploadStatus, PhotoService, PromptDialogService, UploadService, LoaderService, PhotoDownloadService } from '@lc/core';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'lc-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ImageInputComponent),
      multi: true,
    }
  ]
})
export class ImageInputComponent extends InputField {
  @Input()
  hint: string;

  @Input()
  canEdit: boolean = true;

  @Input()
  label: string = 'Photo';

  @Input()
  placeholder: string = 'Drag your photo in';

  @Input()
  photoStyle: null | 'round';

  @Input()
  placeholderImage: string;

  @Input()
  downloadUrl: string;

  @Output()
  readonly imageUpdated = new EventEmitter<string>();

  @Output()
  readonly editImage = new EventEmitter<string>();

  @Input()
  allowedExtensions = [];

  uploadStatus: UploadStatus;
  readonly fileTypes = FileTypes;

  constructor(
    sanitizer: DomSanitizer,
    private photoService: PhotoService,
    private photoDownloadService: PhotoDownloadService,
    private promptDialogService: PromptDialogService,
    private uploadService: UploadService,
    private loaderService: LoaderService
  ) {
    super(sanitizer);
  }

  async onDelete() {
    this.updateValue(null)
  }

  async onEdit() {
    this.editImage.emit();
  }

  async onDownload(){
    this.photoDownloadService.downloadProfilePhoto(this.downloadUrl);
  }

  /**
   * Fired when the user uploads a file through lc-file-upload InputField
   * @param files the array of files uploaded through lc-file-upload
   */
  async uploadPhoto(files: File[]) {

    // lc-file-upload allows for an array of files but we only want one
    this.loaderService.show();
    this.uploadStatus = new UploadStatus(files[0]);
    
    const actualFiles = Array.from(files);
    const filesToPresign = {
      files: [{
        name: actualFiles[0].name,
        mimetype: actualFiles[0].type
      }]
    };

    this.uploadService.getPresignedUrlForProfile(filesToPresign)
    .pipe(
      switchMap((val, idx) => {
        const loadArray = [];
        for (const fileIter in val) {
          if (fileIter) {
            loadArray.push({
              file: Array.from(files).find(f => f.name === fileIter),
              url: val[fileIter],
              name: fileIter
            });
          }
        }

        // this.uploadStatus = new UploadStatus(loadArray[0].file);
        this.uploadStatus.presignURL = loadArray[0].url;

        return this.photoService.preSignUploadWithStatus(this.uploadStatus, 0);
      })
    )
    .subscribe(uploadResult => {
        if (uploadResult.error) {
          console.log(uploadResult.error);
          this.promptDialogService.openPrompt('Upload Status', `Failed to upload image: ${uploadResult.error}`, 'Ok');
        }
        else {
          const url = uploadResult.presignURL.substring(0, uploadResult.presignURL.indexOf('?'));
          this.updateValue(url);
        }
        this.uploadStatus = null;
      }
      , (error) => {
        console.log(error);
        this.promptDialogService.openPrompt('Upload Status', `Failed to upload image: ${error.message}`, 'Ok');
      }
      , ( ) => {
        this.loaderService.hide();
      }
    );

  }

  private updateValue(url: string){
    // Like the profile photo, the upload is not submitted so update the form and mark as dirty but do not change profile
    this.formControl.setValue(url);
    this.formControl.markAsDirty();
    this.imageUpdated.emit(url);
  }
}
