import { Profile, PhoneNumber, ContactType } from '../models';
import { ContactInfoFactory } from '../models/contactinfo.model';
import { AddressFactory } from './address.factory';

const johnDoe = new Profile({
  _id: '123',
  userId: '123',
  preferredFirstName: 'John',
  preferredLastName: 'Doe',
  preferredEmail: 'john@test.com',
  preferredPhoneNumber: new PhoneNumber({id: '1', number: '55512311111'}),
  photoUrl: 'https://photos.com/john-doe-profile.jpg',
  originalPhotoUrl: 'https://photos.com/john-doe-profile.jpg',
  firstLogin: new Date(2018, 1, 1),
  lastProfileSync: new Date(2019, 1, 1),
  website: 'www.johndoe.com',
  contactInfo: [
    ContactInfoFactory.createContactInfo({
      type: ContactType.card,
      address: AddressFactory.googleShippingAddress
    }),
    ContactInfoFactory.createContactInfo({
      type: ContactType.card,
      address: AddressFactory.googleBillingAddress
    }),
    ContactInfoFactory.createContactInfo({
      type: ContactType.card,
      address: AddressFactory.googleMailingAddress
    })
  ],
});

export class ProfileFactory {
  public static get johnDoe() {return new Profile(johnDoe); }
}
