import { Directive, OnInit, OnChanges, Input, SimpleChanges, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CropDimensions, Dimensions, CroppedImage } from '@lc/core';

declare const Cropper: any;

export enum AspectRatios {
  '1:1' = 1,
  '2:1' = 2,
  '4:3' = 4/3,
  '2:3' = 2/3,
  AgentVideoPhoto = 590/720, //.81944...
  TeamVideoPhoto = 912/720, //1.2666...
}

export class PhotoCropSettings {
  lockAspectRatio = true;
  allowCustomAspect = false;
  backgroundColor = '#fff';
  initialMode: 'crop' | 'move' | 'none' = 'move';
  customAspectRatios: AspectRatios[];
  dimensions: Dimensions;
  allowZoomOut: boolean;

  constructor(readonly aspectRatio: number, readonly existingCrops?: CroppedImage[], readonly isRound?: boolean){
  }

  findExistingCrop() {
    const existingCrop = this.existingCrops?.find(crop => crop.aspectRatio === CroppedImage.convertToFixed(this.aspectRatio));
    return existingCrop;
  }

  findSelectedDimensions() : Dimensions {
    if(this.dimensions) {
      // If dimensions has been explicitly set, use it
      return this.dimensions;
    }
    // Otherwise try to locate in the existing crops
    const existingCrop = this.findExistingCrop();
    return existingCrop?.dimensions;
  }
}

@Directive({
  selector: '[lcPhotoCropper]'
})
export class PhotoCropperDirective implements OnInit, OnChanges, OnDestroy {

  @Input('lcPhotoCropper') photo: string;

  @Input() settings: PhotoCropSettings;

  @Input() zoom: number;

  @Input() dimensions: Dimensions;

  @Output() zoomChange = new EventEmitter<number>();

  @Output() readonly loaded = new EventEmitter<boolean>();

  private cropper: any;
  public isLoaded: boolean;
  public initialZoom: number;

  private readonly zoomListener: (event: any) => any;

  constructor(private image: ElementRef) {

    this.zoomListener = (event) => {
      this.zoomTo(event.detail.ratio);
    }
  }

  ngOnInit() {
    this.image.nativeElement.addEventListener('zoom', this.zoomListener);
  }

  ngOnDestroy() {
    if (this.image?.nativeElement) {
      this.image.nativeElement.removeEventListener('zoom', this.zoomListener);
    }
    else {
      console.log('photo-cropper cannot remove eventListener, image.nativeElement is no longer defined (see LC-6181)')
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.photo || changes.settings) {
      this.setImageSource(this.photo);
    }
  }

  reload() {
    this.cropper?.destroy();
    this.cropper = null;
    this.setImageSource(this.photo);
  }

  getCroppedDimensions(): Dimensions {
    // Retrieve variables for quick-access
    const cropData = this.cropper.cropBoxData;
    const canvasData = this.cropper.canvasData;

    // Calculate the height/width ratios in regard to the zoom-in/zoom-out and scaling
    const canvasWidthRatio = canvasData.naturalWidth / canvasData.width;
    const canvasHeightRatio = canvasData.naturalHeight / canvasData.height;

    // Total height/width of cropped area
    const croppedWidthPixels = canvasWidthRatio * cropData.width;
    const croppedHeightPixels = canvasHeightRatio * cropData.height;

    // Calculate the top-left point in the cropped bounds.
    // Used to calculate data for the rendering pipelines
    const fromTopPixels = canvasHeightRatio * ((canvasData.top * -1) + cropData.top);
    const fromLeftPixels = canvasWidthRatio * ((canvasData.left * -1) + cropData.left);

    // Convert points and dimensions to percentages for the cropDimensions
    // Percentages are agnostic to the unit of measure (i.e. - inches, pixels, etc..)
    const cropDimensions: CropDimensions = {
      fromTopPercentage: fromTopPixels /  canvasData.naturalHeight,
      fromLeftPercentage: fromLeftPixels / canvasData.naturalWidth,
      widthPercentage: croppedWidthPixels / canvasData.naturalWidth,
      heightPercentage: croppedHeightPixels / canvasData.naturalHeight,
    };

    // Instantiate a new Dimensions object instead of using the existing photo[].dimensions
    // We don't want to accidently persist unsaved data
    const newDimensions = new Dimensions();
    newDimensions.cropDimensions = cropDimensions;
    newDimensions.cropData =  {
      canvasData: this.cropper.canvasData,
      cropBoxData: this.cropper.cropBoxData,
      zoomRatio: this.zoom
    };

    return newDimensions;
  }

  /** Returns the cropped canvas as a file */
  async getCroppedFile(fullFileName: string) {
    const canvasData = this.cropper.canvasData;

    let fileName = fullFileName.indexOf('?') !== -1 ? fullFileName.split('?')[0] : fullFileName;
    const fileParts = fileName.split('.');
    const fileExtension = fileParts.length > 1 ? fileParts[1] : 'jpg';
    fileName = `${fileParts[0]}-${Math.round(canvasData.width)}x${Math.round(canvasData.height)}`;
    const isPNG = fileExtension.toLowerCase() === 'png';
    const type = isPNG ? 'image/png' : 'image/jpeg';

    const options: any = { };
    if(!options.isPNG) {
      // If we are not using a png, fill the background with white if we have zoomed out further than the image
      options.fillColor = '#fff';
    }

    // Wrap the toBlob method in a Promise
    return new Promise<File>((resolve, reject) => {
      this.cropper.getCroppedCanvas(options).toBlob((blob: any) => {
        // Build the filename by retrieving the original fileName and appending the '-WxH' to the name
        this.blobToFile(blob, `${fileName}.${fileExtension}`);
        resolve(blob);
      }, type, 1);
    });
  }

  /**
   * Zooms the image in the visible canvas by a given zoomRatio.
   * @param zoomRatio Ratio to zoom in to (> 0)
   */
  zoomTo(zoomRatio: number): any {
    // If zoom is already set, return early
    if(this.zoom === zoomRatio) { return; }

    // Take the max of 0 and the ratio provided
    this.zoom = Math.max(zoomRatio, 0);
    this.zoomChange.emit(this.zoom);

    // If the canvas has not been zoomed in yet, perform zoom
    if(this.cropper.canvasData.aspectRatio !== this.zoom) {
      this.cropper.zoomTo(this.zoom);
    }
  }

  private setImageSource(url: string) {
    this.isLoaded = false;
    const image = this.image.nativeElement;
    image.src = url;
    if (this.cropper) {
      this.cropper.replace(image.src);
    } else {
      const dimensions: Dimensions = this.dimensions;
      this.cropper = new Cropper(image, {
          aspectRatio: this.settings.aspectRatio,
          background: true, // true to show checkered background, false to hide
          dragMode: this.settings.initialMode,
          checkCrossOrigin: false,
          viewMode: this.settings.allowZoomOut ? 0 : 3, //https://github.com/fengyuanchen/cropperjs/blob/master/README.md#viewmode,
          ready: () => {
            const canvas = this.cropper.canvasData;
            this.initialZoom = canvas.width / canvas.naturalWidth;

            // Change the background color to a lighter background
            this.cropper.dragBox.style.background = '#585858';

            if(dimensions?.cropData) {
              this.cropper.setCanvasData(dimensions.cropData.canvasData);
              this.cropper.setCropBoxData(dimensions.cropData.cropBoxData);
              this.zoomTo(dimensions.cropData.zoomRatio);
            } else {
              this.zoomTo(this.initialZoom);
            }
            this.isLoaded = true;
            this.loaded.emit(true);
          }
        });
      }
  }

  /** Converts the blob to a File typed object, with a fileName and modified date */
  private blobToFile(blob: Blob, fileName:string): File {
    const file: any = blob as any;
    file.lastModifiedDate = new Date();
    file.name = fileName;
    return <File>file;
  }
}
