import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Profile } from '../models/profile.model';
import { Observable, of } from 'rxjs';
import { CommonProfile, SocialMedia, ContactInfoFactory } from '../models';
import { ProfileForm } from '../forms';
import { Store } from '@ngrx/store';
import { UserProfileUpdated } from '../state-mgmt/user/user.actions';
import { UpdateProfileComplete } from '../state-mgmt/profile/profile.actions';
import { NotificationEventTypes, NotificationEvent } from '../notifications/notification-event';
import { NotificationEventService } from '../notifications/notification-event.service';

/**
 * Client side service for interacting with the server to retrieve and update profiles.
 */
@Injectable()
export class ProfileService {
  resource = 'profiles';
  addDelegateEndpoint = 'add-delegate';
  removeDelegateEndpoint = 'remove-delegate';

  constructor(private store: Store<any>, private apiService: ApiService, private notificationService: NotificationEventService) {
  }

  /**
   * Update an existing profile with
   *
   * @param id
   * @param profile
   * @return an Observable that results in a Profile
   */
  updateProfile(id: string, profile: Profile): Observable<Profile> {
    const clonedProfile = Object.assign({}, profile);
    delete clonedProfile.audit;
    return this.apiService.put(`profiles/${id}`, clonedProfile).pipe(map(body => new Profile(body)));
  }

  addDelegate(fromUser: string, toUser: string): Observable<Profile> {
    const payload = {
      fromUser: fromUser,
      toUser: toUser
    }
    return this.apiService.post(`${this.resource}/${this.addDelegateEndpoint}`, payload).pipe(
      map(body => new Profile(body))
    );
  }

  removeDelegate(fromUser: string, toUser: string): Observable<Profile> {
    const payload = {
      fromUser: fromUser,
      toUser: toUser
    }
    return this.apiService.post(`${this.resource}/${this.removeDelegateEndpoint}`, payload).pipe(
      map(body => new Profile(body))
    );
  }

  updateProfilePartial(profile: Profile, fields?: string[]): Observable<Profile> {
    let payload: any;

    payload = profile;
    if (fields && fields.length) {

      payload = {};
      //order.orderState = order.listing.orderState;
      fields.forEach(field => {
        payload[field] = profile[field];
      });
    }
    delete payload.audit;
    return this.apiService.put(`${this.resource}/${profile._id}`, payload).pipe(
      map(body => new Profile(body))
    );
  }

  async patch(profileForm: ProfileForm, isCurrentUser?: boolean): Promise<Profile> {
    // Gets all the dirty fields that have changed
    const dirty = profileForm.getDirty();
    if(dirty.contactInfo) {
      // HACK: These values should be formatted in the ProfileForm correctly so we do no need to build them
      // for now, leaving this method the same as it was before
      dirty.contactInfo = this.getSocialMedia(profileForm.originalValue, profileForm.value);
    }
    if(profileForm.contactInfo.length === 0) {
      dirty.contactInfo = [];
    }

    const updatedProfile = await this.apiService.put(`${this.resource}/${profileForm.id}`, dirty).pipe(
      map(body => new Profile(body)),
      catchError(error => {
        // Display toaster error
        console.error('There was an error patching the profile values', error, dirty)
        const event = new NotificationEvent(NotificationEventTypes.ERROR, 'An error occured updating your profile');
        this.notificationService.getEventEmitter().emit(event);
        return of(null);
      })).toPromise();

    if(updatedProfile) {
      this.store.dispatch(new UpdateProfileComplete(updatedProfile));
      if(isCurrentUser) {
        // If we are updating the current user, emit an event to update the profile
        // display information
        this.store.dispatch(new UserProfileUpdated(updatedProfile));
      }
    }
    return updatedProfile;
  }
  
  getSocialMedia(profile: Profile, socialMedia: SocialMedia){
    const getSocialMediaType = ContactInfoFactory.getContactInfoSocialMediaFactory(socialMedia);
    const getProfileAddress = profile.contactInfo.filter(item=>item.type==="card");
    return getProfileAddress.length ? [...getProfileAddress,...getSocialMediaType]:getSocialMediaType;
  }


  /**
   * Get a profile by id
   *
   * @param id the profile id
   */
  getProfile(id: string): Observable<Profile> {
    console.log('Get profile for id = %s', id);
    return this.apiService.get(`${this.resource}/${id}`).pipe(map(body => new Profile(body)));
  }

  /** Determines if a profile has the fields required to start the order process. */
  static isProfileComplete(profile: Profile): boolean {
    let profileComplete = false;
    if (profile) {
      const hasPrimaryPhone: boolean = profile && !!profile.phoneNumbers.find(pn => pn.primary);
      profileComplete =
        profile?.photoUrl?.length > 0 &&
        profile?.preferredFirstName?.length > 0 &&
        profile?.preferredLastName?.length > 0 &&
        profile?.preferredEmail?.length > 0 &&
        !(profile.isHawaiianAgent && profile.realtorType == null) &&
        hasPrimaryPhone;
    }
    return profileComplete;
  }

  static unsatisfiedRules(profile: Profile): string[] {
    // Check any validation rules specific to the profile
    const unsatisfiedRules = [];
    // Only one rule for now
    if (profile?.rules.find(r => (r === 'requireOfficePhone')) && !profile?.office?.phoneNumber?.number) {
      unsatisfiedRules.push("Your profile must have a phone number of type 'Office'.");
    }
    if(profile.isHawaiianAgent && profile.realtorType == null) {
      unsatisfiedRules.push("Your profile requires a realtor type to be set for Hawaii regulations.");
    }
    return unsatisfiedRules;
  }

  static isProfileHasOfficeDetails(profile: Profile): boolean {
    let hasOfficeData = false;
    const commonProfile = profile.commonProfile;

    if (commonProfile) {
      hasOfficeData =
        !!commonProfile.office && commonProfile.office.name && (commonProfile.office.name.length > 0) &&
        !!commonProfile.office && commonProfile.office.streetAddress1 && (commonProfile.office.streetAddress1.length > 0) &&
        !!commonProfile.office && commonProfile.office.city && (commonProfile.office.city.length > 0) &&
        !!commonProfile.office && commonProfile.office.postalCode && (commonProfile.office.postalCode.length > 0) &&
        !!commonProfile.office && commonProfile.office.state && (commonProfile.office.state.length > 0) &&
        !!commonProfile.office && commonProfile.office.phoneNumber && commonProfile.office.phoneNumber.number &&
        !!commonProfile.primaryOfficeCode && (commonProfile.primaryOfficeCode.length > 0) &&
        !!commonProfile.licenseNumber && (commonProfile.licenseNumber.length > 0) &&
        profile?.office?.marketingArea  && profile.office.marketingArea !== 'No Marketing Area (unmapped office code)';
    }
    return hasOfficeData;
  }
}
