<mat-card class="p-0 h-100 lc-card" fxLayout="column">
  <!-- Card Image-->
  <div mat-card-image class="w-100 m-0" *ngIf="imageTemplate">
    <template [ngTemplateOutlet]="imageTemplate"></template>
  </div>

  <mat-card-content class="m-0" [ngClass]="contentClass" fxFlex>
    <!-- Card Header-->
    <div *ngIf="headerTemplate" class="header-container" [ngClass]="{'pt-3 px-3': size == 'lg', 'pt-2 px-2': size === 'sm'}">
      <template [ngTemplateOutlet]="headerTemplate"></template>
    </div>

    <!-- Divider (conditional upon header and content being available)-->
    <ng-container *ngIf="headerTemplate && contentTemplate">
      <hr class="border-light my-0" [ngClass]="size == 'lg' ? 'mt-3 mx-3' : 'mt-2 mx-2'">
    </ng-container>

    <!-- Card Content-->
    <div *ngIf="contentTemplate" [ngClass]="size == 'lg' ? 'pt-3 px-3 ' + contentClass : 'pt-2 px-2 ' + contentClass" [class.pb-3]="!actionsTemplate && size=='lg'" [class.pb-2]="!actionsTemplate && size=='sm'">
      <template [ngTemplateOutlet]="contentTemplate"></template>
    </div>
  </mat-card-content>

  <!-- Divider (conditional upon content and actions)-->
  <ng-container *ngIf="(headerTemplate || contentTemplate) && actionsTemplate">
    <hr class="border-light my-0" [ngClass]="size == 'lg' ? 'mt-3 mx-3' : 'mt-2 mx-2'">
  </ng-container>

  <!-- Card Content-->
  <mat-card-actions class="mx-0" [ngClass]="{'p-3': size == 'lg', 'p-2': size === 'sm'}" *ngIf="actionsTemplate">
    <template [ngTemplateOutlet]="actionsTemplate"></template>
  </mat-card-actions>
</mat-card>
