import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

@Injectable()
export class VideoOrderService {

  resource = 'video-orders';

  constructor(private apiService: ApiService) { }

  get(marketingOrderId: string, productCode: string) {
    const queryParams = { marketingOrderId: marketingOrderId };
    if(productCode) {
      queryParams['productCode'] = productCode;
    }
    return this.apiService.get(this.resource, queryParams);
  }

  /**
   * This service is NOT To be used widely in the app. It is a tactical approach to re-submitting
   * video order requests under very specific conditions. Currently it is hidden behind a feature flag
   * that will only be enabled for a single user. The server resource is also hidden behind the same feature flag.
   */
  async resubmit(marketingOrderId: string, productCode: string) {
    const path = `${this.resource}/send-request/${marketingOrderId}`;
    return this.apiService.get(path, {productCode}).toPromise();
  }

}
