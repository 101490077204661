import { PhotoAgency } from '../../models/photography-agency.model';
import {PhotographyAgencyActions, PhotographyAgencyActionTypes} from './photography-agency.actions';

export const photographyAgencyInitialState: PhotoAgency[] = null;

export function photographyAgencyReducer(state = photographyAgencyInitialState, action: PhotographyAgencyActions): PhotoAgency[] {

  switch (action.type) {
    case PhotographyAgencyActionTypes.GetAllPhotographyAgenciesComplete: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
