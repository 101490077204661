<lc-base-dialog [title]="data.title" [message]="data.message" [primaryAction]="data.acceptAction" [otherActions]="data.readonly ? [] : [data.cancelAction]" (actionClicked)="onActionClicked($event)">

  <ng-template #content>
    <form [formGroup]="reasonForm" id="reason-form">
      <!-- CSS class is for test selector only.  No UI implications. -->
      <p class="reason-message mat-body-1">{{data.message}}</p>

      <!-- if the modal contains editable fields -->
      <ng-container *ngIf="!data.readonly">
        <div>
          <lc-dropdown [formControl]="reasonForm.get('reason')" emptyOptionDisplay="Please select a reason from the list" emptyOptionValue="" idPrefix="reason" class="reason" [options]="data.reasons" label="REASON" (selectionChanged)="changeReason()"></lc-dropdown>
        </div>
        <div [hidden]="hideNotes">
          <lc-textarea [formControl]="reasonForm.get('notes')" idPrefix="reason-note" label="DETAILS" placeholder="Please provide more details..." ></lc-textarea>
        </div>
        <div [hidden]="data.acceptAction.text !== 'Refund'">
          <lc-textbox [formControl]="reasonForm.get('amount')" idPrefix="reason-amount" label="REFUND" placeholder="$0" [inputMask]="refundMask" [maskDropSpecialCharacters]="false"></lc-textbox>
          <div>The maximum refund can be {{data.totalAmount | currency}}</div>
        </div>
      </ng-container>

      <!-- if the modal is readonly -->
      <ng-container *ngIf="data.readonly">
        <div class="mb-3">
          <div class="font-weight-bolder">REASON</div>
          <!-- CSS class is for test selector only.  No UI implications. -->
          <div class="reject-reason-text">{{reasonForm.getControl('reason').value}}</div>
        </div>
        <div>
          <div class="font-weight-bolder">DETAILS</div>
          <!-- CSS class is for test selector only.  No UI implications. -->
          <div class="reject-notes-text">{{reasonForm.getControl('notes').value}}</div>
        </div>
      </ng-container>

    </form>
  </ng-template>
</lc-base-dialog>


