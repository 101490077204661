import { Component, OnInit, Inject } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { NotesService, NoteForm, Note, DialogAction } from '@lc/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export class NotesDialogData {
  constructor(readonly ownerId: string, readonly title: string, readonly subtitle: string) {}
}


@Component({
  selector: 'lc-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss']
})
export class NotesDialogComponent {
  readonly notes$: Observable<Note[]>;
  readonly form: NoteForm = new NoteForm()
  readonly primaryAction = new DialogAction('Save', () => this.onSave());
  readonly cancelAction = new DialogAction('Cancel',  () => this.closeDialog());

  private readonly _close = new Subject();
  readonly close = this._close.asObservable();

  constructor(private notesService: NotesService,  @Inject(MAT_DIALOG_DATA) public data: NotesDialogData) {
    this.form.getControl('ownerId').setValue(data?.ownerId);
    this.notes$ =  this.notesService.getNotesFor(data?.ownerId);
  }

  /**
   * Marks all fields as dirty, validates the form and then submits the form to the API
   */
  onSave() {
    this.form.markAllAsDirty();
    if(!this.form.valid) {
      return;
    }

    this.notesService.create(this.form).toPromise()
      .then(_note => this.closeDialog(this.primaryAction));
  }


  /** Closes the dialog by emitting the close event */
  closeDialog(action: DialogAction = this.cancelAction) {
    this._close.next(action);
  }
}
