import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { SELECTEDPROFILE, GetProfile } from './profile.actions';
import { Profile } from '../../models/profile.model';

@Injectable()
export class ProfileResolve implements Resolve<Profile> {

  readonly profile$: Observable<Profile>;

  constructor(private store: Store<any>) {
    this.profile$ = this.store.select(SELECTEDPROFILE);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const profileId = route.params['profileId'];
    // TODO - throw error to be handled by global error handler
    if (!profileId) {
      return of(null);
    }

    this.store.dispatch(new GetProfile(profileId));
    return of(null);
  }
}
