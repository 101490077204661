import { User } from '../../models/user.model';
import { UserActions, UserActionTypes } from './user.actions';

export const initialState: User = null;

export function userReducer(state = initialState, action: UserActions): User {

  switch (action.type) {
    // set state to loaded user
    case UserActionTypes.LoadLoggedInUser: {
      return action.payload;
    }
    case UserActionTypes.UserProfileUpdated: {
      if(action.payload._id === state.profile._id) {
        state.profile = action.payload;
      }
      return state;
    }
    default: {
      return state;
    }
  }
}
