import { FormControl } from '@angular/forms';
import { BaseForm } from './base.form';
import { AddressForm } from './address.form';
import { PhoneNumber } from '../models/phone-number.model';
import { Address } from '../models/address.model';
import { PhoneNumberForm } from './phone-number.form';
import { AddressInformation } from '../models/address-information.model';
import { emailValidator } from '../validators/email.validator';
import { noWhitespaceValidator } from '../validators/no-whitespace.validator';
import { StringFormControl } from './form-controls/string-form-control';


export class AddressInformationForm extends BaseForm<AddressInformation> {
   public get fullName(): string { return this.get('fullName').value; };
   public get address(): Address { return this.get('address').value; };
   public get company(): string { return this.get('company').value; };
   public get phoneNumber(): PhoneNumber { return this.get('phoneNumber').value; };
   public get email(): string { return this.get('email').value; };
   public get type(): string { return this.get('type').value; };

  constructor(value?: any, options?: any) {
    super({
      fullName: new StringFormControl(null, [noWhitespaceValidator()]),
      company: new StringFormControl(),
      email: new FormControl(null, [emailValidator()]),
      address: new AddressForm(value ? value.address : null),
      phoneNumber: new PhoneNumberForm(value ? value.phoneNumber : null, options),
      type: new FormControl()
    });

    if(value) {
      this.patchValue(value);
    }
  }
}
