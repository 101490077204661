/**
 * Specification for photo thumbnail creation defined by the image server API.
 */
export interface ThumbnailSpec {
  width: number;
  height: number;
}

/**
 * Constants specific to property photos
 */
export namespace ThumbnailSizes {
  export const MEDIUM_THUMBNAIL_WIDTH = 540;
  export const LARGE_THUMBNAIL_WIDTH = 1080;

  /** Standard thumbnail photo sizes. */
  export const STANDARD_THUMBNAIL_SPECS: ThumbnailSpec[] = [
    // There is no standard height but the API requires a height value,
    { width: MEDIUM_THUMBNAIL_WIDTH, height: 9999},
    { width: LARGE_THUMBNAIL_WIDTH, height: 9999 }];
}

export class PhotoThumbnails {
  [size: number]: PhotoThumbnail;
}

export class PhotoThumbnail {
  uri: string;

  constructor(photoThumbnail?: any) {
    if(photoThumbnail) {
      this.uri = photoThumbnail.uri;
    }
  }
}
