import { KeyActions, KeyActionTypes } from './key.actions';


export const initialState: any = {};

export function keysReducer(state = initialState, action: KeyActions): any {

  switch (action.type) {
    // set state to loaded user
    case KeyActionTypes.Clear: {
      return {};
    }
    case KeyActionTypes.Add: {
      return Object.assign({}, state, action.payload);
    }
    case KeyActionTypes.Remove: {
      action.payload.forEach(member => delete state[member]);
      return Object.assign({}, state);
    }
    default: {
      return state;
    }
  }
}
