import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, filter, map, switchMap, withLatestFrom, tap, mergeMap } from 'rxjs/operators';
import { MarketingOrderService } from '../../services/marketing-order.service';

import {
  OrderActionTypes,
  UpdateOrder,
  UpdateOrderPhotos,
  UpdateOrderPhotosComplete,
  UploadOrderPhotos,
  UpdateOrderProductDescription,
  AddOrderComplete,
  GetOrderComplete,
  GetOrder,
  UpdateOrderComplete,
  SubmitOrder,
  SubmitOrderComplete,
  SELECTEDORDER,
  UpdateOrderState,
  UpdateOrderPhoto,
  DeleteOrder,
  DeleteOrderComplete,
  UpdateFulfillment,
  UpdateProductMedia,
  UpdateFulfillmentComplete,
  SelectProductTemplate,
  UpdateOrderFailed,
  BulkAssignOrders,
  BulkAssignOrdersComplete,
  UpdateCurrentOrderState,
  SelectProductTemplateComplete
} from './order.actions';
import { ErrorData } from '../../errors/error-data';
import { NotificationEvent, NotificationEventService } from '../../notifications';
import { ListingPhoto, MarketingOrder } from '../../models';
import { Store } from '@ngrx/store';
import { GlobalErrorHandler } from '../../errors/global-error-handler';
import { BaseEffects } from '../base.effects';
import { FulfillmentService } from '../../services/fulfillment.service';
import { ActionTypes, WorkflowAction } from '../workflow/workflow.actions';
import { TemplateService } from '../../services/template.service';
/**
 * The order effects are post state change actions that occur
 */
@Injectable()
export class OrderEffects extends BaseEffects {

  constructor(private actions$: Actions,
              private marketingOrderService: MarketingOrderService,
              private fulfillmentService: FulfillmentService,
              private store: Store<any>,
              private templateService: TemplateService,
              eventService: NotificationEventService,
              errorHandler: GlobalErrorHandler) {
    super(errorHandler, eventService);
  }

  /**
   * Intercept errors so if it's a simple card decline from Stripe
   * we don't log the error to DataDog
   */
  processCatchError(type, payload, err): Observable<ErrorData> {
    const noDatadogCodes = [ 'expired_card', 'card_declined', 'incorrect_cvc', 'processing_error' ];
    let logErrorToDatadog = true;
    if (type === OrderActionTypes.SubmitOrderFailed) {
      if (err.error && err.error.error && err.error.error.code && noDatadogCodes.indexOf(err.error.error.code) >= 0) {
        logErrorToDatadog = false;
      }
    }
    return super.processCatchError(type, payload, err, logErrorToDatadog);
  }

  /**
   * Take action on AddOrder actions
   *  1. Update the order
   *  2. If error, dispatch to error handler
   *  3. filter out chained error observables
   *  4. Send out success notification and return observable of order
   *
   */
  @Effect({ dispatch: false })
  addOrder: Observable<MarketingOrder> = this.actions$.pipe(
    ofType<UpdateOrder>(OrderActionTypes.AddOrder),
    mergeMap((action) => {
      return this.marketingOrderService.saveOrder(action.payload).pipe(
        catchError(err => this.processCatchError(OrderActionTypes.AddOrder, action.payload, err))
      )
    }),
    filter(order => !(order instanceof ErrorData)),
    tap (async(order:MarketingOrder) => await this.templateService.setTemplateInfoToProduct(order)),
    map((order:MarketingOrder) => {
      const event = new NotificationEvent(OrderActionTypes.AddOrderComplete, OrderActionTypes.AddOrderComplete, order);
      this.eventService.getEventEmitter().emit(event);
      this.store.dispatch(new AddOrderComplete(order));
      return order;
    })
  );

  /**
   * Take action on GetOrder actions
   *  1. Get the order
   *  2. If error, dispatch to error handler
   *  3. filter out chained error observables
   *  4. Send out success notification and return observable of order
   *
   */
  @Effect({ dispatch: false })
  getOrder: Observable<MarketingOrder> = this.actions$.pipe(
    ofType<GetOrder>(OrderActionTypes.GetOrder),
    withLatestFrom(this.store.select(SELECTEDORDER)),
    switchMap(([action, order]) => {
      if (order && order._id === action.payload._id && action.useCached) {
        return of(order);
      } else {
        return this.marketingOrderService.getOrder(action.payload._id).pipe(
          catchError(err => this.processCatchError(OrderActionTypes.GetOrder, action.payload, err))
        )
      }
    }),
    filter(order => !(order instanceof ErrorData)),
    tap (async(order:MarketingOrder) => await this.templateService.setTemplateInfoToProduct(order)),
    map((order:MarketingOrder) => {
      const event = new NotificationEvent(OrderActionTypes.GetOrderComplete, OrderActionTypes.GetOrderComplete, order);
      this.eventService.getEventEmitter().emit(event);
      this.store.dispatch(new GetOrderComplete(order));
      return order;
    })
  );

  /**
   * Take action on UpdateOrder actions
   *  1. Update the order
   *  2. If error, dispatch to error handler
   *  3. filter out chained error observables
   *  4. Send out success notification and return observable of order
   *
   */
  @Effect({ dispatch: false })
  updateOrder: Observable<MarketingOrder> = this.actions$.pipe(
    ofType<UpdateOrder>(OrderActionTypes.UpdateOrder),
    mergeMap((action) => {
      return this.marketingOrderService.saveOrder(action.payload).pipe(
        catchError(err => {
          this.store.dispatch(new UpdateOrderFailed(action.payload));
          return this.processCatchError(OrderActionTypes.UpdateOrderFailed, {payload: action.payload}, err)
        })
      )
    }),
    filter(order => !(order instanceof ErrorData)),
    tap (async(order:MarketingOrder) => await this.templateService.setTemplateInfoToProduct(order)),
    map((order:MarketingOrder) => {
      const event = new NotificationEvent(OrderActionTypes.UpdateOrderComplete, OrderActionTypes.UpdateOrderComplete);
      this.eventService.getEventEmitter().emit(event);
      this.store.dispatch(new UpdateOrderComplete(order));
      return order;
    })
  );

  /**
   * This effect will update the current order in state if the order that was updated
   * matches the current order
   */
  @Effect({ dispatch: false })
  updateCurrentOrderState: Observable<MarketingOrder> = this.actions$.pipe(
    ofType<UpdateCurrentOrderState>(OrderActionTypes.UpdateCurrentOrderState),
    withLatestFrom(this.store.select(SELECTEDORDER).pipe(
      map((order: MarketingOrder) => order))),
    map(([action, order]) => {
      if(action?.payload._id === order?._id) {
        let updatedOrder = action.payload;
        if(action.patch) {
          // If we are doing a patch, update the existing order in memory
          order.deserialize(action.payload);
          updatedOrder = order;
        }

        this.store.dispatch(new UpdateOrderComplete(updatedOrder));
        return action.payload;
      }
      return order;
    })
  );

  /**
   * Take action on SubmitOrder actions
   *  1. Update the order
   *  2. If error, dispatch to error handler
   *  3. filter out chained error observables
   *  4. Send out success notification and return observable of order
   *
   */
  @Effect({ dispatch: false })
  submitOrder: Observable<MarketingOrder> = this.actions$.pipe(
    ofType<SubmitOrder>(OrderActionTypes.SubmitOrder),
    switchMap((action) => {
      return this.marketingOrderService.submitOrder(action.payload).pipe(
        catchError(err => this.processCatchError(OrderActionTypes.SubmitOrderFailed, action.payload, err))
      )
    }),
    filter(order => !(order instanceof ErrorData)),
    tap (async(order:MarketingOrder) => await this.templateService.setTemplateInfoToProduct(order)),
    map((order:MarketingOrder) => {
      const event = new NotificationEvent(OrderActionTypes.SubmitOrderComplete, OrderActionTypes.SubmitOrderComplete);
      this.eventService.getEventEmitter().emit(event);
      this.store.dispatch(new WorkflowAction(ActionTypes.WorkflowActionTerminate));
      this.store.dispatch(new SubmitOrderComplete(order));
      return order;
    })
  );

  /**
   * Take action on DeleteOrder actions
   *
   *  1. Delete the MarketingOrder
   *  2. If error, dispatch to error handler
   *  3. filter out chained error observables
   *  4. Dispatch delete complete state
   *
   */
  @Effect({ dispatch: false })
  deleteOrder: Observable<any> = this.actions$.pipe(
    ofType<DeleteOrder>(OrderActionTypes.DeleteOrder),
    mergeMap((action) => {
      return this.marketingOrderService.deleteOrder(action.payload).pipe(
        catchError(err => this.processCatchError(OrderActionTypes.DeleteOrder, action.payload, err))
      )
    }),
    filter(order => !(order instanceof ErrorData)),
    map(() => { this.store.dispatch(new DeleteOrderComplete()); })
  );

    /**
   * Take action on UpdateOrderPhoto actions
   *  1. Updates a single listing photos call to API
   *  2. If error, dispatch to error handler
   *  3. Map to data structure to preserve action data passed
   *  4. filter out chained error observables
   *  5. Send out success notification and return observable of order
   */
  @Effect({ dispatch: false })
  updateOrderPhoto: Observable<any> = this.actions$.pipe(
    ofType<UpdateOrderPhoto>(OrderActionTypes.UpdateOrderPhoto),
    mergeMap((action) => {
      return this.marketingOrderService.updatePhoto(action.payload._id, action.photo._id, action.photo).pipe(
        catchError(err => this.processCatchError(OrderActionTypes.UpdateOrderPhoto, action.payload, err)),
        map((order: MarketingOrder) => {
          // this.store.dispatch(new UpdateOrderComplete(order) );
          return { order: order, photos: order.photos, action: action}
        })
      )
    }),
    filter(result => !(result instanceof ErrorData)),
    map(result => this.emitUpdateOrderPhotosComplete(result.order, result.photos, result.action.productCode))
  );


  /**
   * Take action on UpdateOrderPhotos actions
   *  1. Update the listing photos call to API
   *  2. If error, dispatch to error handler
   *  3. Map to data structure to preserve action data passed
   *  4. filter out chained error observables
   *  5. Send out success notification and return observable of order
   */
  @Effect({ dispatch: false })
  updateOrderPhotos: Observable<any> = this.actions$.pipe(
    ofType<UpdateOrderPhotos>(OrderActionTypes.UpdateOrderPhotos),
    mergeMap((action) => {
      action.payload.setPhotos(action.photos, action.productCode);
      return this.marketingOrderService.setPhotos(action.payload, action.productCode).pipe(
        catchError(err => this.processCatchError(OrderActionTypes.UpdateOrderPhotos, action.payload, err)),
        map((order: MarketingOrder) => {
          // this.store.dispatch(new UpdateOrderComplete(order));
          return { order: order, photos: order.photos, action: action}
        })
      )
    }),
    filter(result => !(result instanceof ErrorData)),
    map(result => this.emitUpdateOrderPhotosComplete(result.order, result.photos, result.action.productCode))
  );

  // TODO - look at consolidating this with updateOrderPhotos above
  /**
   * Take action on UploadOrderPhotos actions
   *  1. Update the listing photos call to API
   *  2. If error, dispatch to error handler
   *  3. Map to data structure to preserve action data passed
   *  4. filter out chained error observables
   *  5. Send out success notification and return observable of order
   */
  @Effect({ dispatch: false })
  uploadOrderPhotos: Observable<ListingPhoto[]> = this.actions$.pipe(
    ofType<UploadOrderPhotos>(OrderActionTypes.UploadOrderPhotos),
    switchMap((action) => {
      return this.marketingOrderService.addPhotoFromUploadAndUpdatePhotos(action.payload, action.photos,
        action.photographerId, action.productCode).pipe(
          catchError(err => this.processCatchError(OrderActionTypes.UploadOrderPhotos, action.payload, err)),
          map(result => ({order: new MarketingOrder(result), action: action}))
      )
    }),
    filter(result => !(result instanceof ErrorData)),
    map(result => this.emitUpdateOrderPhotosComplete(result.order, result.order.photos, result.action.productCode))
  );

  @Effect({ dispatch: false })
  updateOrderProductDescription: Observable<MarketingOrder> = this.actions$.pipe(
    ofType<UpdateOrderProductDescription>(OrderActionTypes.UpdateOrderProductDescription),
    mergeMap((action) => this.marketingOrderService.saveOrderProductDescription(action.payload).pipe(
        catchError(err => this.processCatchError(OrderActionTypes.UpdateOrderProductDescription, action.payload, err)),
      )
    ),
    filter(order => !(order instanceof ErrorData)),
    map((order:MarketingOrder) => {
      this.store.dispatch(new UpdateOrderComplete(order));

      const event = new NotificationEvent(OrderActionTypes.UpdateOrder, OrderActionTypes.UpdateOrder);
      this.eventService.getEventEmitter().emit(event);
      return order;
    })
  );

  @Effect({dispatch: false})
  updateFulfillment: Observable<MarketingOrder> = this.actions$.pipe(
    ofType<UpdateFulfillment>(OrderActionTypes.UpdateFulfillment),
    withLatestFrom(this.store.select(SELECTEDORDER)),
    filter(([action, order]) => order != null),
    mergeMap(([action, order]) => {
      return this.fulfillmentService.updateFulfillment(order, action.productCode, action.payload).pipe(
        catchError(err => this.processCatchError(OrderActionTypes.UpdateFulfillmentFailed, {payload: action.payload}, err)),
      )
    }),
    filter(response => !(response instanceof ErrorData)),
    tap (async(order:MarketingOrder) => await this.templateService.setTemplateInfoToProduct(order)),
    map((order:MarketingOrder) => {
      const event = new NotificationEvent(OrderActionTypes.UpdateFulfillmentComplete, OrderActionTypes.UpdateFulfillmentComplete);
      this.eventService.getEventEmitter().emit(event);
      this.store.dispatch(new UpdateFulfillmentComplete(order));
      return order;
    })
  );

  /**
   * Select the template effect will call service to udpate selection and if successful
   * dispatch UpdateOrderComplete to update state
   */
  @Effect({dispatch: false})
  selectTemplate: Observable<Promise<MarketingOrder>> = this.actions$.pipe(
    ofType<SelectProductTemplate>(OrderActionTypes.SelectProductTemplate),
    withLatestFrom(this.store.select(SELECTEDORDER)),
    filter(([action, order]) => order != null),
    switchMap(([action, order]) => {
      return this.marketingOrderService.selectTemplate(order, action.productCode, action.template).pipe(
        catchError(err => this.processCatchError(OrderActionTypes.SelectProductTemplate,
          {payload: {productCode: action.productCode, template: action.template}}, err)),
      )
    }),
    filter(response => !(response instanceof ErrorData)),
    map( async (order:MarketingOrder) => {
      await this.templateService.setTemplateInfoToProduct(order);
      const event = new NotificationEvent(OrderActionTypes.SelectProductTemplateComplete, OrderActionTypes.SelectProductTemplateComplete);
      this.eventService.getEventEmitter().emit(event);
      this.store.dispatch(new SelectProductTemplateComplete(order));
      return order;
    })
  );

  /**
   * This will update the media selected on a product instance.
   */
  @Effect({ dispatch: false })
  updateProductMedia: Observable<any> = this.actions$.pipe(
    ofType<UpdateProductMedia>(OrderActionTypes.UpdateProductMedia),
    withLatestFrom(this.store.select(SELECTEDORDER)),
    mergeMap(([action, order]) => {
      return this.marketingOrderService.updateMedia(order._id, action.productCode, action.payload).pipe(
        catchError(err => this.processCatchError(OrderActionTypes.UpdateProductMedia, action.payload, err)),
      )
    }),
    filter(orderState => !(orderState instanceof ErrorData)),
    map((order:MarketingOrder) => {
      const event = new NotificationEvent(OrderActionTypes.UpdateProductMediaComplete, OrderActionTypes.UpdateProductMediaComplete);
      this.eventService.getEventEmitter().emit(event);
      this.store.dispatch(new UpdateOrderComplete(order));
      return order;
    })
  );

      /**
   * Take action on BulkAssignOrders actions
   *  1. Update the orders assignment
   *  2. If error, dispatch to error handler
   *  3. filter out chained error observables
   *  4. Send out success notification and return observable of order
   *
   */
  @Effect({ dispatch: false })
  bulkAssignOrders: Observable<any> = this.actions$.pipe(
    ofType<BulkAssignOrders>(OrderActionTypes.BulkAssignOrders),
    switchMap(({orderIds, coordinatorId}) => {
      return this.marketingOrderService.bulkAssignOrders(orderIds, coordinatorId).pipe(
        catchError(err => this.processCatchError(OrderActionTypes.BulkAssignOrders, { orderIds, coordinatorId }, err))
      )
    }),
    filter(order => !(order instanceof ErrorData)),
    map((res:any) => {
      this.store.dispatch(new BulkAssignOrdersComplete());

      const event = new NotificationEvent(OrderActionTypes.BulkAssignOrdersComplete);
      this.eventService.getEventEmitter().emit(event);
      return res;
    })
  );

  /**
   * Emits an event when the marketing order photos have been updated/loaded
   * @param order The marketing order the photos have been added to
   * @param photos The photos that have been updated/loaded
   * @param productCode Optional: The productCode the photos are attached to
   */
  private emitUpdateOrderPhotosComplete(order: MarketingOrder, photos: ListingPhoto[], productCode?: string): ListingPhoto[]{
    const event = new NotificationEvent(OrderActionTypes.UpdateOrderPhotosComplete, OrderActionTypes.UpdateOrderPhotosComplete);
    this.eventService.getEventEmitter().emit(event);
    this.store.dispatch(new UpdateOrderPhotosComplete(order, photos, productCode));
    return photos;
  }
}

/**
 * The order state effects are post state change actions that occur.
 */
@Injectable()
export class OrderStateEffects extends BaseEffects {

  constructor(private actions$: Actions,
              private marketingOrderService: MarketingOrderService,
              eventService: NotificationEventService,
              errorHandler: GlobalErrorHandler) {
    super(errorHandler, eventService);
  }

  /**
   * Take action on UpdateOrderState actions
   *
   *  1. Update the listing
   *  2. If error, dispatch to error handler
   *  3. filter out chained error observables
   *  4. Send out success notification and return observable of listing
   *
   */
  @Effect({ dispatch: false })
  updateOrderState: Observable<any> = this.actions$.pipe(
    ofType<UpdateOrderState>(OrderActionTypes.UpdateOrderState),
    mergeMap((action) => {
      return this.marketingOrderService.updateOrderState(action.marketingOrder._id, action.payload).pipe(
        catchError(err => this.processCatchError(OrderActionTypes.UpdateOrderState, action.payload, err)),
      )
    }),
    filter(orderState => !(orderState instanceof ErrorData)),
    map((result:any) => {
      return result;
    })
  );

}
