import { AbstractControl, FormArray } from '@angular/forms';
import { BaseForm } from './base.form';

export class LCFormArray<TModel, TForm extends BaseForm<TModel> = BaseForm<TModel>> extends FormArray {
  readonly models: TModel[];
  readonly formCreator: (value: TModel, index?: number) => AbstractControl;
  readonly originalValues: TModel[];

  get forms(): TForm[] {
    return (this.controls as any) as TForm[];
  }

  /** Returns whether this control is dirty or not */
  get dirty(): boolean {
    return this.controls.some(child => child.dirty);
  }

  /**
   * Returns the dirty fields from this control.
   * For some reason .value was not retuning the properly modified fields
   */
  getDirty() {
    if(this.dirty)  {
      return this.controls.map(control => control.value);
    }
  }

  constructor(values: TModel[], formCreator: (value: TModel, index?: number) => AbstractControl) {
    super((values || []).map((value, index) => formCreator(value, index)));
    this.models = values || [];
    this.formCreator = formCreator;
  }

  elementAt(index: number): TForm  {
    return this.forms[index];
  }

  addControl() {
    const newForm = this.formCreator(null, this.forms.length);
    if (newForm instanceof BaseForm) {
      newForm.isNew = true;
      newForm.isEdit = true;
    }
    this.push(newForm);
    return newForm;
  }

  addForm(form: BaseForm<TModel>) {
    this.push(form);
  }

  deleteControl(form: BaseForm<TModel>) {
    const indexOfControl = this.controls.indexOf(form);
    if (indexOfControl >= 0) {
      this.removeAt(indexOfControl);
    }
  }

  setValue(values: any[], options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
    const controls = (values || []).map((value, index) => this.formCreator(value, index));
    this.controls.splice(0, this.controls.length, ...controls);
    super.setValue(values, options);
  }

  reset() {
    this.controls = (this.models || []).map((value, index) => this.formCreator(value, index))
  }
}
