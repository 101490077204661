import { Injectable } from '@angular/core';
import { MarketingOrder } from '../models/marketing-order.model';
import { ProductInstance } from '../models/product-instance.model';
import { ProductCategory } from '../models/product.category';
import { ProductInfoService } from './product-info.service';
import { ListingPhoto } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProductContentService {

  constructor(private productInfoService: ProductInfoService) { }

  public populateProductFromMarketingOrder(marketingOrder: MarketingOrder, product: ProductInstance) {
    if (marketingOrder && product) {
      // populate marketing copy headline and body
      this.populateMarketingCopyFromMarketingOrder(marketingOrder, product);

      // populate photos
      // video
      if (this.productInfoService.isVideoProduct(product)) {
        this.populatePhotoArrayFromMarketingOrder(marketingOrder, product, 'VIDEO');
        return;
      }

      // printed product
      if (product.category === ProductCategory.PRINT) {
        this.populatePhotoArrayFromMarketingOrder(marketingOrder, product, 'DEFAULT');
        return;
      }

      // website
      if (this.productInfoService.isWebsiteProduct(product) && product?.selectedTemplate?.templateInfo) {
        this.populatePhotoArrayFromMarketingOrder(marketingOrder, product, 'CAROUSEL');
        product.photos['GALLERY'] = marketingOrder.photos;

        return;
      }

    }
  }

  /**
   * Overwrites the marketing copy on the product with the content from the marketing order
   *
   * @param marketingOrder
   * @param product
   */
  public populateMarketingCopyFromMarketingOrder(marketingOrder: MarketingOrder, product: ProductInstance): void {
    product.marketingCopyHeadline = product?.marketingCopyHeadline?.length ? product.marketingCopyHeadline : marketingOrder.marketingCopyHeadline;
    product.marketingCopyBody = product?.marketingCopyBody?.length ?  product.marketingCopyBody : marketingOrder.marketingCopyBody;
  }

  /**
   * Overwrites the photos on the product with the content from the marketing order. This function assumes the
   * product has a selected template so it can tell how many photos from the order should be used.
   *
   * @param marketingOrder
   * @param product
   * @param field
   */
  public populatePhotoArrayFromMarketingOrder(marketingOrder: MarketingOrder, product: ProductInstance, field: string = 'DEFAULT'): any {
    const photoArray = (product.photos || {})[field];
    if(photoArray?.length) { return; }

    const photos = {};
    photos[field] = [];

    const marketingOrderPhotoCount = marketingOrder?.photos?.length;
    const productPhotoCount = product?.selectedTemplate?.templateInfo?.photoCount || product.details.maxPhotos || 0;
    let photoCounter = 0;

    // Add the number of photos listed on the temp
    const marketingOrderPhotos = this.defaultSort(marketingOrder.photos);
    while (photoCounter < Math.min(productPhotoCount, marketingOrderPhotoCount)) {
      const photo = marketingOrderPhotos[photoCounter];
      if (photo) {
        photos[field].push(marketingOrderPhotos[photoCounter]);
        photoCounter++;
      }
    }

    product.photos = photos;
  }


  private defaultSort(photos: ListingPhoto[]) {
    return photos.filter(photo => photo.favorite)
      .sort((photo1, photo2) => photo1.order - photo2.order)
      .concat(photos.filter (photo => !photo.favorite)
        .sort((photo1, photo2) =>photo1.order - photo2.order));
  }
}
