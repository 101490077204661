import { Injectable } from '@angular/core';
import { ReasonDialogAction, ReasonDialogComponent, ReasonDialogData } from './reason-dialog.component';
import { ProductRejectReasonType } from './product-reject-reason.type';
import { OrderRefundReasonType } from './order-refund-reason.type';
import { MatDialog } from '@angular/material/dialog';

export class ReasonDialogReasons {
  public static PRODUCT_REJECT_REASONS: string[] = [
    ProductRejectReasonType.COPY,
    ProductRejectReasonType.IMAGES,
    ProductRejectReasonType.DETAILS,
    ProductRejectReasonType.DESIGN,
    ProductRejectReasonType.NEEDS_REVIEW,
    ProductRejectReasonType.OTHER
  ];

  public static ORDER_REFUND_REASONS: string[] = [
    OrderRefundReasonType.NOT_HAPPY,
    OrderRefundReasonType.SLA,
    OrderRefundReasonType.SOLD,
    OrderRefundReasonType.PHOTOGRAPHY,
    OrderRefundReasonType.PACKAGE,
    OrderRefundReasonType.PROMO,
    OrderRefundReasonType.OTHER
  ]
}

@Injectable()
export class ReasonDialogService {

  constructor(private modal: MatDialog) { }

  openReason(readonly: boolean, title: string, message: string, reasons: string[], acceptAction: ReasonDialogAction | string, cancelAction?: ReasonDialogAction | string, reason?: string, notes?: string, totalCost?: number): Promise<ReasonDialogAction> {
    // Allow the actions to just be text, but convert them to dialogAction objects
    const acceptedAction = this.toAction(acceptAction);
    const cancelledAction = cancelAction ? this.toAction(cancelAction) : null;

    const dialogRef = this.modal.open<ReasonDialogComponent, any, ReasonDialogAction>(ReasonDialogComponent, {
      data: new ReasonDialogData(readonly, title, message, reasons, acceptedAction, cancelledAction, reason, notes, totalCost),
      width: '500px', maxWidth: '90vw', maxHeight: '90vh', disableClose: true
    });
    const component: ReasonDialogComponent = dialogRef.componentInstance;

    // Subscribe to the close event
    component.close.subscribe(action => dialogRef.close(action), (error) => { throw new Error(error); });
    return dialogRef.afterClosed().toPromise();
  }

  private toAction(action: ReasonDialogAction | string) {
    // Convert string actions to DialogAction if necessary
    return action instanceof ReasonDialogAction ? action : new ReasonDialogAction(action);
  }
}
