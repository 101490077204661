import { Deserializable } from './deserializable.model';

export class StateTransition implements Deserializable<StateTransition> {

  transitionedTo: string;
  transitionedAt: Date;

  constructor(input?: any) {

    this.deserialize(input || {});
  }

  deserialize(input: any): StateTransition {
    Object.assign(this, input);

    return this;
  }
}
