import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { NotificationEvent, NotificationEventService } from '@lc/core';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgbCustomDateFormatter } from '../date-picker/ngb-custom-date-formatter';

@Component({
  selector: 'lc-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    // Use the appropriate Date adapter to convert form Date object
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    // Use the CustomDateFormatter to display date in MM/DD/YYYY format
    {provide: NgbDateParserFormatter, useClass: NgbCustomDateFormatter}
  ]
})
export class DateRangeComponent implements OnChanges {

  hoveredDate: NgbDate;
  readonly navigation:string = "arrow";

  fromDate: NgbDate;
  toDate: NgbDate;

  @Input()
  to: Date;

  @Input()
  from: Date;

  @Output()
  readonly toChanged = new EventEmitter<Date>()

  @Output()
  readonly fromChanged = new EventEmitter<Date>()

  constructor(calendar: NgbCalendar, private adapter: NgbDateAdapter<Date>) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.to && changes.to.currentValue) {
      const struct = this.adapter.fromModel(this.to);
      this.toDate = new NgbDate(struct.year, struct.month, struct.day);
    }
    if(changes.from && changes.from.currentValue) {
      const struct = this.adapter.fromModel(this.from);
      this.fromDate = new NgbDate(struct.year, struct.month, struct.day);
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.fromChanged.emit(this.adapter.toModel(date));
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.toChanged.emit(this.adapter.toModel(date));
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.toChanged.emit(null);
      this.fromChanged.emit(this.adapter.toModel(date));
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }
}
