import { Deserializable } from './deserializable.model';
import { Address } from './address.model';
import { PhoneNumber } from './phone-number.model';
import { Metro } from './metro.model';


export class CommonProfile implements Deserializable<CommonProfile> {
  static readonly EMPLOYEE_MAK_OFFSET = 10000000;

  name: string;
  firstName: string;
  lastName: string;
  office: Address;
  metro: Metro;
  licenseNumber: string;
  licenseState: string;
  email: string;
  primaryOfficeCode: string;
  shippingAddress: Address;
  phoneNumbers: PhoneNumber[]=[];

  deserialize(input: any): CommonProfile {
    Object.assign(this, input);
    return this;
  }
}
