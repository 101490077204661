import { EventEmitter, Injectable } from '@angular/core';
import { HotKeyEvent } from './hotkey-event';


export enum hotKeys {
 NOTES = "alt+n"
}

@Injectable({
  providedIn: 'root'
})
export class HotkeyEventService {
  private eventEmitter: EventEmitter<HotKeyEvent> = new EventEmitter();

  constructor() {
  }

  public getEventEmitter(): EventEmitter<HotKeyEvent> {
    return this.eventEmitter;
  }
  
}
