import { Action } from '@ngrx/store';
import { Option } from '../../models/option.model';

export enum OptionActionTypes {
  LoadOptions = '[Options] Load',
  LoadOptionsComplete = '[Options] Load Complete'
}

export class LoadOptions implements Action {
  readonly type = OptionActionTypes.LoadOptions;
  constructor(public payload?: any) {}
}

export class LoadOptionsComplete implements Action {
  readonly type = OptionActionTypes.LoadOptionsComplete;
  constructor(public payload: Option[]) {}
}

export type OptionActions =
  LoadOptions |
  LoadOptionsComplete
  ;

export const APP_OPTIONS = 'appOptions';
