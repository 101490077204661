import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UploadPhoto } from '../models/upload-photo.model';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private readonly resource = 'presign';

  constructor(
    private http: HttpClient,
    private apiService: ApiService) { }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  public getPresignedUrl<T = any>(type: string, contentType?: string): Observable<T> {
    let url = this.resource + '/' + type;
    if (contentType) {
      url += '?contentType='+contentType;
    }
    return this.apiService.get(url).pipe(
      map(result => result)
    );
  }

  public upload(url, data) {
    return this.http.put(url, data).pipe(catchError(this.formatErrors));
  }

  public uploadCropData(cropData) {
    return this.apiService.postToPhotosUrl('upload/cropData', cropData);
  }

  public getPresignedUrlForAll(fileArray) {
    return this.apiService.postToPhotosUrl('presign/all', fileArray);
  }

  public getPresignedUrlForProfile(fileArray) {
    return this.apiService.postToPhotosUrl('presign/profile', fileArray);
  }

  public postImageUploadAndGetThumbnails(fileObject) {
    return this.apiService.postToPhotosUrl<UploadPhoto[]>('upload/thumbnails', fileObject).pipe(
      map(thumbnails => thumbnails.map(thumb => new UploadPhoto(thumb)))
    );
  }
}
