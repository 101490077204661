import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification } from '../models/notification.model';
import { NotificationForm } from '../forms/notification.form';
import { Store } from '@ngrx/store';
import { NOTIFICATIONS, UpdateNotification, NotificationsUpdated } from '../state-mgmt';
import { UserService } from './user.service';
import { switchMap, filter } from 'rxjs/operators';
import { NotificationsClient } from '../clients/notifications.client';

/**
 * The notification service is used by the UI components to perform specific actions on a notification.
 * UI components should interact with this instead of the store or clients directly so that these implementations can
 * be reused by other components.
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly notifications$: Observable<Notification[]>;

  constructor(private authService: UserService, private store: Store<any>, private notificationClient: NotificationsClient) {
    // this.store.dispatch(new LoadNotifications());

    // Only request notifications if user is logged in
    this.notifications$ = this.authService.currentUser.pipe(
      filter(user => user != null),
      switchMap(user => this.store.select(NOTIFICATIONS))
    );
  }

  /**
   * Returns an observable of the notifications that are stored in the current state management.
   */
  get(): Observable<Notification[]> {
    return this.notifications$;
  }

  /**
   * Marks the notifications as read and dispatches and update event.
   */
  markAsRead(notification: Notification) {
    if(notification.isRead) { return; }

    notification.isRead = true;
    this.store.dispatch(new UpdateNotification(new NotificationForm(notification)));
  }

    /**
   * Marks the notifications as read and dispatches and update event.
   */
  async markAllAsRead(notificationsIds: string[]) {
    // Call API to mark all notifications as read.
    await this.notificationClient.markAsRead(notificationsIds).toPromise();

    // Dispatch to store to now reload the notifications in the UI
    this.store.dispatch(new NotificationsUpdated([]));
  }
}
