import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../models/address.model';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(address: Address, args) {
    return address
      ? [address.streetAddress1, address.streetAddress2, address.city, address.state, address.postalCode].filter(str => str && str.trim() !== '').join(', ')
      : '';
  }
}
