import { FormControl, FormArray,FormGroup, Validators } from '@angular/forms';
import { BaseForm } from './base.form';
import { PhoneNumberForm } from './phone-number.form';
import { TeamContactPreferencesForm } from './team-contact-preferences.form';
import { TeamMemberForm } from './team-member.form';
import { urlValidator } from '../validators/url.validator';
import { TeamMember } from '../models';
import { PhotoInformationForm } from './photo-information.form';
import { emailValidator } from '../validators';
import { LCFormArray } from './lc-form-array';
import { SocialMedia } from '../models';
import { SocialMediaForm } from './social-media.form';

export class TeamProfileForm extends BaseForm {
  get teamLogoURL() { return this.get('teamLogoURL').value; } 
  public get contactInfo(): LCFormArray<SocialMedia> { return this.get('contactInfo') as LCFormArray<SocialMedia>; }

  constructor(readonly originalValue?: any, public options?: {validateType: boolean}){
    super({
      _id: new FormControl(),
      name: new FormControl(null, [Validators.required, Validators.maxLength(35)]),
      phone: new PhoneNumberForm(),
      email: new FormControl(null, [Validators.required, emailValidator()]),
      websiteURL: new FormControl(null, [Validators.required, urlValidator()]),
      ownerId: new FormControl(),
      teamImage: new FormControl(),
      teamPhoto: new PhotoInformationForm(originalValue ? originalValue.teamPhoto : null),
      teamLogoURL: new FormControl(),
      members: new FormArray([]),
      contactPreferences: new TeamContactPreferencesForm(),
      contactInfo: new LCFormArray<SocialMedia>(originalValue?.socialMedia || [],
        (socialMedia) => new SocialMediaForm(socialMedia, {typeRequired: true}))
    });    

    if(originalValue) {
      this.patchValue(originalValue);
      const members = originalValue.members as Array<TeamMember> || [];
      members.forEach(member => (this.get('members') as FormArray).push(new TeamMemberForm(member)));
    }
    if(originalValue && originalValue.contactInfo){
      const contactInfo = this.get('contactInfo') as FormArray;
      originalValue.contactInfo.forEach((element) => {
        if(element.type==='socialMedia'){
          contactInfo.push(new SocialMediaForm(element.socialMedia, {...this.options, typeRequired: true}));
        }
      });
    }

  }
}
