import { Deserializable } from './deserializable.model';
import { VendorServiceOrder } from './vendor-service-order.model'

export class PrintOrderTracking implements Deserializable<PrintOrderTracking> {

  carrier?: string;

  trackingNumber?: string;


  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input: any): PrintOrderTracking {
    Object.assign(this, input);

    return this;
  }
}

export class PrintOrder extends VendorServiceOrder {
  
  details: any;

  tracking: PrintOrderTracking;

  constructor(input?: any) {
    super(input)
    this.deserialize(input);
  }

  deserialize(input: any): PrintOrder {
    Object.assign(this, input);

    return this;
  }
}

export class PrintOrderCancelResult implements Deserializable<PrintOrderCancelResult> {
  error: number;
  message: string;
  vendorOrder: any;

  constructor() { }

  deserialize(input: any): PrintOrderCancelResult {
    Object.assign(this, input);

    return this;
  }
}