<ng-container *ngIf="viewModel$ | async as viewModel">
  <button id="notifications-icon-button" mat-icon-button [matMenuTriggerFor]="notificationMenu" [matBadge]="viewModel.badge" [matBadgeHidden]="!viewModel.count" matBadgeColor="primary" matBadgePosition="below after" matBadgeSize="small">
    <mat-icon class="material-icons-outlined">{{viewModel.count ? 'notifications_active' : 'notifications'}}</mat-icon>
  </button>

    <!-- Menu dropdown for the Notification menu -->
  <mat-menu #notificationMenu="matMenu" [class]="'menu-panel'">
    <div id="notifications-menu">
      <h3 class="mat-title m-0 px-3 py-2 border-bottom text-default">Notifications</h3>

      <!-- Clear All button -->
      <button *ngIf="viewModel.count" mat-menu-item id="clear-all-button" class="my-0"  (click)="viewModel.markAllAsRead()">
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
          <mat-icon class="material-icons-outlined">done_all</mat-icon>
          <span>Clear all</span>
        </div>
      </button>

      <!-- Display all notifications-->
      <ng-container *ngFor="let notificationViewModel of viewModel.notificationViewModels">
        <button mat-menu-item class="border-top py-2 my-0" (click)="onNotificationClicked(notificationViewModel, viewModel, $event)">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="25px">
            <mat-icon class="material-icons-outlined">{{notificationViewModel.icon}}</mat-icon>
            <div fxLayout="column">
              <span class="mat-body-2">{{notificationViewModel.title}}</span>
              <div class="mat-body-1 wrap-text" [innerHTML]="notificationViewModel.text"></div>
              <span class="mat-caption">{{notificationViewModel.when}}</span>
            </div>
          </div>
        </button>
      </ng-container>
    </div>
  </mat-menu>

</ng-container>
