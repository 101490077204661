import { Directive, Input, OnChanges, SimpleChanges, HostBinding, ElementRef } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { PresignedPhoto, ListingPhoto, PresignService, PresignedThumbnail } from '@lc/core';

abstract class PresignedDirective implements OnChanges {
  private _presignedPhoto: PresignedPhoto;
  public get presignedPhoto(): PresignedPhoto { return this._presignedPhoto; }
  abstract photo: ListingPhoto;

  @Input()
  size?: number;

  @Input()
  fallbackSize?: number;

  constructor(private service: PresignService, private element: ElementRef<HTMLImageElement>) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.photo || changes.size) {
      if(this._presignedPhoto) {
        this.setPresignedPhoto(this._presignedPhoto);
      } else {
        this.service.presignPhoto(this.photo).subscribe(presigned => this.setPresignedPhoto(presigned), (error) => { throw new Error(error); });
      }
    }
  }

  abstract setImageSource(source: string);

  private setPresignedPhoto(presignedPhoto: PresignedPhoto){
    // Cache the photo for future use
    this._presignedPhoto = presignedPhoto;

    const requestedThumbnail = this.findRequestedThumbnail(presignedPhoto);
    let source = requestedThumbnail
      ? requestedThumbnail.presignedUrl || requestedThumbnail.originalUrl
      : presignedPhoto.presignedUrl || presignedPhoto.originalUrl;

    // We need to detect errors loading the image on the <img/> element.
    let retryCount = 0;
    this.element.nativeElement.onerror = (error) => {
      if(retryCount < 10) {
        // Retry until it works...
        retryCount++;
        source = presignedPhoto.presignedUrl;
        this.setImageSource('');
        setTimeout(() => this.setImageSource(source), 100);
      }
    };

    this.setImageSource(source);
  }


  private findRequestedThumbnail(presigned: PresignedPhoto): PresignedThumbnail {
    if (!this.size) {
      return null; // If size is not specified, return null
    }

    // If a size is specified, lets find the requested thumbnail. If not found, find the fallback
    const requestedThumbnail = presigned.locateThumbnail(this.size, this.fallbackSize);
    return requestedThumbnail;
  }
}
@Directive({
  selector: '[lcPresignedPhoto]'
})
export class PresignedPhotoDirective extends PresignedDirective {
  @HostBinding('src')
  imageSource: string = '';

  @Input('lcPresignedPhoto')  // Keep the name of this input the same as the directive seleector name
  photo: ListingPhoto;

  constructor(service: PresignService, element: ElementRef<HTMLImageElement>) {
    super(service, element);
  }

  setImageSource(source: string) {
    this.imageSource = source;
  }
}

@Directive({
  selector: '[lcPresignedPhotoBackground]'
})
export class PresignedPhotoBackgroundDirective extends PresignedDirective {
  @Input('lcPresignedPhotoBackground')  // Keep the name of this input the same as the directive seleector name
  photo: ListingPhoto;

  @HostBinding('style.background-image')
  public backgroundImage: SafeStyle;

  constructor(service: PresignService, element: ElementRef<HTMLImageElement>, private sanitizer: DomSanitizer) {
    super(service, element);
  }

  setImageSource(source: string) {
    this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle(`url("${source}")`);
  }
}
