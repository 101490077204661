import { Action } from '@ngrx/store';
import { ParamMap } from '@angular/router';
import { FlowState } from '../../models/flow-state.model';

export enum ActionTypes {
  WorkflowAction = 'WFA',
  WorkflowActionNext = 'WFANext',
  WorkflowActionEdit = 'WFAEdit',
  WorkflowActionNextIncomplete = 'WFANextIncomplete',
  WorkflowActionPrev = 'WFAPrev',
  WorkflowActionInitialize = 'WFAInitialize',
  WorkflowActionTerminate = 'WFATerminate',
  WorkflowActionSetStep = 'WFASetStep',
  WFAUpdateStateData = '[WFA] Update State Data'
}

export interface ActionData {
  state?: FlowState;
  id?: string;
  stepCompleted?: boolean;
  parentCompleted?: boolean;
  stateData?: any;
}

export class WorkflowAction implements Action {

  constructor(public type: ActionTypes,
              public data?: ActionData,
              public params?: ParamMap) {
    this.type = type;
    this.data = data;
    this.params = params;
  }

}

// TODO - need to move to constants
// feature state names
export const ORDERSTATE = 'orderState';
