import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { MatTableExporterModule } from 'mat-table-exporter';
import { jsPDF } from 'jspdf';

import {MatPaginatorModule} from '@angular/material/paginator';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthGuard } from './guards/auth.guard';

import {
  AddressPipe,
  CssSafePipe,
  MinuteSecondsPipe,
  PhonePipe,
  SafeHtmlPipe,
  ServiceTypeDisplayPipe,
  StatusDisplayPipe
} from './pipes';

import { TokenInterceptor } from './interceptors/token.interceptor';

import {
  ApiService,
  AuthenticationService,
  FormattingService,
  LoaderService,
  MarketingOrderService,
  OptionsService,
  OrderListingFlowStateService,
  PackageService,
  PdfGenerationService,
  PhotographerService,
  PhotoOrderService,
  PhotoService,
  PollingService,
  ProductGenerationService,
  ProfileService,
  TemplateService,
  UserActivityService,
  UserAgentService,
  UserService,
  VideoGenerationService,
  VideoOrderService,
  WebsiteGenerationService
} from './services';
import { OktaAuthenticationProvider } from './services/auth-providers/okta-authentication.provider';
import { GlobalErrorHandler } from './errors';
import { NotificationEventService } from './notifications';
import { PhotoOrderStatusPipe } from './pipes/photo-order-status.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { RemoveSpacePipe } from './pipes/removespace.pipe';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { LoaderComponent } from './loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { SpacedPascalPipe } from './pipes/spaced-pascal.pipe';
import { TrackingHeaderGuard } from './guards';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MatProgressSpinnerModule,
    DeviceDetectorModule.forRoot(),
    MatTableExporterModule,
    MatPaginatorModule
  ],
  declarations: [
    AddressPipe,
    CssSafePipe,
    PhonePipe,
    MinuteSecondsPipe,
    StatusDisplayPipe,
    ServiceTypeDisplayPipe,
    SafeHtmlPipe,
    PhotoOrderStatusPipe,
    ReversePipe,
    RemoveSpacePipe,
    LoaderComponent,
    SpacedPascalPipe
  ],
  exports: [
    AddressPipe,
    CssSafePipe,
    PhonePipe,
    MinuteSecondsPipe,
    SafeHtmlPipe,
    StatusDisplayPipe,
    ServiceTypeDisplayPipe,
    PhotoOrderStatusPipe,
    ReversePipe,
    LoaderComponent,
    RemoveSpacePipe,
    SpacedPascalPipe,
    MatTableExporterModule,
    MatPaginatorModule
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AuthGuard,
        TrackingHeaderGuard,
        OktaAuthenticationProvider,
        AuthenticationService,
        LoaderService,
        PhotoService,
        UserService,
        PackageService,
        MarketingOrderService,
        ApiService,
        ProfileService,
        PhotographerService,
        NotificationEventService,
        GlobalErrorHandler,
        PhotoOrderService,
        OrderListingFlowStateService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HeaderInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorInterceptor,
          multi: true
        },
        {
          provide: ErrorHandler,
          useClass: GlobalErrorHandler
        },
        PdfGenerationService,
        WebsiteGenerationService,
        ProductGenerationService,
        TemplateService,
        OptionsService,
        UserAgentService,
        UserActivityService,
        VideoOrderService,
        VideoGenerationService,
        PollingService,
        FormattingService,
      ]};
  }
}
