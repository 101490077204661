import { Deserializable } from './deserializable.model';
import { PhoneNumber } from './phone-number.model';
import { CommonProfile } from './common-profile.model';
import { ContactInfo, ContactInfoFactory } from './contactinfo.model';
import { Audit } from './audit.model';
import { Office } from './office.model';
import { ImageModel } from './image.model';
import { RealtorType } from './realtor-type.enum';

export class DelegationProfileContact implements Deserializable<DelegationProfileContact> {
  
  profileId: string;
  userId: string;
  fullName: string;
  emailAddress: string;

  constructor(value?: DelegationProfileContact) {
    this.deserialize(value);
  }

  deserialize(value?: DelegationProfileContact) {
    Object.assign(this, value || {});
    return this;
  }
}

export class Profile implements Deserializable<Profile> {

  _id: string;
  userId: string;
  preferredFirstName: string;
  preferredLastName: string;
  preferredEmail: string;
  phoneNumbers: PhoneNumber[];
  canDelegate: DelegationProfileContact[];
  canBeDelegatedBy: DelegationProfileContact[];
  commonProfile: CommonProfile;
  office: Office;
  title: string;
  realtorType: RealtorType;
  areas: string[];
  profileImage: ImageModel;

  // TODO: [LC-4417] Deprecate the following 4 urls in favor of the profilePhoto object above
  originalPhotoUrl : string;
  photoUrl: string;
  profilePhotoUrl: string;
  videoProfilePhotoUrl: string;

  logoUrl: string;
  firstLogin: Date;
  website: string;
  contactInfo: ContactInfo[];
  preferredSources: any;
  socialMedia: any;
  rules: string[];
  audit: Audit;

  /** Returns if the agent is from Hawaii or not */
  get isHawaiianAgent(): boolean {
    const officeState = this?.commonProfile?.office?.state || this.office?.address?.state;
    return officeState === 'HI';
  }

  constructor(input?: Partial<any>) {
    this.deserialize(input);
  }

  deserialize(input?: Partial<any>): Profile {
    if(!input) { return this; }
    Object.assign(this, input);
    if(typeof input.user === "string") {
      this.userId = input.user;
    }
    if (this.contactInfo && this.contactInfo.length) {
      this.contactInfo = this.contactInfo.map( ci => ContactInfoFactory.createContactInfo(ci));
    }

    if(input.commonProfile) {
      this.preferredFirstName = input.preferredFirstName;
      this.preferredLastName = input.preferredLastName;
      this.preferredEmail = input.preferredEmail;
    }

    if(input.audit) {
      this.audit = new Audit(input.audit);
    }

    if(input.profilePhotoUrl || input.photoUrl) {
      input.profilePhotoUrl = input.profilePhotoUrl || input.photoUrl;
    }

    if(this.canBeDelegatedBy && this.canBeDelegatedBy.length) {
      this.canBeDelegatedBy = this.canBeDelegatedBy.map( ci => new DelegationProfileContact(ci));
    }

    if(this.canDelegate && this.canDelegate.length) {
      this.canDelegate = this.canDelegate.map( ci => new DelegationProfileContact(ci));
    }

    return this;
  }
}
