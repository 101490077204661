import { Component, OnInit, ContentChild, TemplateRef, Input } from '@angular/core';

@Component({
  selector: 'lc-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input()
  size: 'sm' | 'lg' = 'lg';

  @Input()
  contentClass: string;

  @ContentChild('image')
  imageTemplate: TemplateRef<any>;

  @ContentChild('header')
  headerTemplate: TemplateRef<any>;

  @ContentChild('content')
  contentTemplate: TemplateRef<any>;

  @ContentChild('actions')
  actionsTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
  }

}
