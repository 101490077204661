import { Deserializable } from './deserializable.model';
import { AddressType } from './address.type';
import { PhoneNumber } from './phone-number.model';

export class Address implements Deserializable<Address> {
  id: string;
  type: AddressType;
  name: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  formattedAddress: string;
  phoneNumber: PhoneNumber;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): Address {
    Object.assign(this, input);
    return this;
  }
}
