import { Component, OnInit, Input, ContentChild, TemplateRef, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { DialogAction, FeatureFlags, LaunchDarklyService } from '@lc/core';
import { Observable } from 'rxjs';

/**
 * The lc-base-dialog component is the base layout for all dialogs
 */
@Component({
  selector: 'lc-base-dialog',
  templateUrl: './base.dialog.html',
  styleUrls: ['./base.dialog.scss']
})
export class BaseDialog implements OnChanges, OnInit {
  swapPrimaryButtons$: Observable<Boolean>;
  /** The title to display on the dialog */
  @Input() title: string;

  /** The template used in place of the common 'title' */
  @ContentChild('title') titleTemplate: TemplateRef<any>;

  /** The message to display in the dialog */
  @Input() message: string;

  @Input() removeClose: boolean = false;

  /** The template to display in the place of the common 'message' */
  @ContentChild('content') contentTemplate: TemplateRef<any>;

  /** The primary action on the dialog (There can only be one) */
  @Input() primaryAction: DialogAction;

  /** The other actions that can be performed on the dialog */
  @Input() otherActions: DialogAction[];

  /** The template used in place of the common 'actions' area */
  @ContentChild('actions') actionsTemplate: TemplateRef<any>;

  /** The other actions that can be performed on the dialog */
  @Output() readonly actionClicked: EventEmitter<DialogAction> = new EventEmitter<DialogAction>();

  /**
   * The action that gets emitted when the close button is pressed.
   * NOTE: This component also emits actionClicked with null
   */
  @Output() readonly close: EventEmitter<void> = new EventEmitter<void>();

  constructor(private launchDarklyService: LaunchDarklyService) { }

  ngOnInit() {
    this.swapPrimaryButtons$ = this.launchDarklyService.getFeature$(FeatureFlags.PRIMARY_SECONDARY_BUTTONS_SWAP, false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.primaryAction) {
      this.primaryAction = this.toAction(this.primaryAction);
    }
    if(changes.otherActions && this.otherActions?.length) {
      this.otherActions = this.otherActions.map(action => this.toAction(action));
    }
  }

  onCloseClicked() {
    this.close.emit();
    this.actionClicked.emit(null);
  }

  onActionClicked(action: DialogAction) {
    if(action && action?.onClick) {
      // TODO: Wait async and display spinner!
      action.onClick();
    }
    this.actionClicked.emit(action);
  }

  private toAction(action: DialogAction | string) {
    // Convert string actions to DialogAction if necissary
    return action instanceof DialogAction ? action : new DialogAction(action);
  }

  toButtonId(text: string) {

    return `${text.toLowerCase().replace(/,/g, '').replace(/ /g, '-')}-button`;
  }
}
