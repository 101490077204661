import { Option } from  '../../models/option.model';
import { OptionActions, OptionActionTypes } from './options.actions';

const initialState: Option[] = null;

export function optionsReducer(state = initialState, action: OptionActions): Option[] {

  switch (action.type) {
    case OptionActionTypes.LoadOptionsComplete: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
