import { Injectable } from '@angular/core';
import { FlowState, MarketingOrder, ProductCode } from '../models';
import { FeatureFlags, LaunchDarklyService } from './launch-darkly.service';

export const orderListingFlowStatePrototype = {

  name: 'Order Listing',
  version: 1,
  steps: [
    {
      id: '/order-listing/details',
      displayName: 'Listing Details',
      description: 'Agent, property and seller info.',
      routerLinkUri: '/order-listing/:id/:pid/details',
      data: {
        header1: 'Good start! Let\'s add some additional listing details.',
        header2: ''
      },
      steps: [
        {
          id: '/order-listing/details/property',
          displayName: 'Property',
          routerLinkUri: '/order-listing/:id/:pid/property'
        },
        {
          id: '/order-listing/details/agent',
          displayName: 'Agent',
          routerLinkUri: '/order-listing/:id/:pid/agent'
        }
      ]
    },
    {
      id: '/order-listing/photography',
      displayName: 'Professional Photography',
      description: 'Select your photographer and your preferred date and time.',
      routerLinkUri: '/order-listing/:id/:pid/photography',
      steps: [
        {
          id: '/order-listing/photography/select-photographer',
          displayName: 'Photographer',
          routerLinkUri: '/order-listing/:id/:pid/select-photographer'
        },
        {
          id: '/order-listing/photography/photography-upgrades',
          displayName: 'Options',
          routerLinkUri: '/order-listing/:id/:pid/photography-upgrades'
        },
        {
          id: '/order-listing/photography/schedule-photographer',
          displayName: 'Preferred Appointment',
          routerLinkUri: '/order-listing/:id/:pid/schedule-photographer'
        },
        {
          id: '/order-listing/photography/photographer-comments',
          displayName: 'Comments',
          routerLinkUri: '/order-listing/:id/:pid/photographer-comments'
        }
      ],
      data: {
        header1: 'This is looking good',
        header2: 'Let\'s pick your photography preferences'
      }
    },
    {
      id: '/order-listing/package-options',
      displayName: 'Package Options',
      description: 'Customize your package.',
      routerLinkUri: '/order-listing/:id/:pid/package-options',
      steps: [
        {
          id: '/order-listing/brochure-options',
          displayName: 'Brochure',
          routerLinkUri: '/order-listing/:id/:pid/brochure-options'
        },
        {
          id: '/order-listing/advertising-options',
          displayName: 'Advertising',
          routerLinkUri: '/order-listing/:id/:pid/advertising-options'
        },
        {
          id: '/order-listing/additional-quantities',
          displayName: 'Additional Quantities',
          routerLinkUri: '/order-listing/:id/:pid/additional-quantities'
        }
      ],
      data: {
        header1: 'Almost Done!',
        header2: 'Let\'s pick your package options'
      }
    },
    {
      id: '/order-listing/checkout',
      displayName: 'Checkout',
      description: 'Complete your order.',
      routerLinkUri: '/order-listing/:id/:pid/checkout',
      data: {
        header1: 'Final step!',
        header2: 'Simply continue to finalize your order.'
      },
      steps: [
        {
          id: '/order-listing/checkout/shipping',
          displayName: 'Shipping',
          routerLinkUri: '/order-listing/:id/:pid/shipping'
        },
        {
          id: '/order-listing/checkout/payment',
          displayName: 'Payment',
          routerLinkUri: '/order-listing/:id/:pid/payment'
        }

      ]
    }
  ]
};

@Injectable()
export class OrderListingFlowStateService {

  resource = 'marketing-orders';

  constructor(private featureService: LaunchDarklyService) { }

  /**
   * Create a listing and optionally set step states based on a listing model
   *
   * @param marketingOrder option listing param to inspect to set state on steps
   */
  async createNewFlowState(marketingOrder?: MarketingOrder): Promise<FlowState> {
    // Deep copy the prototype as to not make any long-term changes in memory
    const orderListingFlows = JSON.parse(JSON.stringify(orderListingFlowStatePrototype));

    if(marketingOrder)  {
      //TBD How to handle flow state for different order types.
      // here we have only one way so we check ot see if the order has a listing address
      // to deciode to filter out details UI which deals only with a Listing Concierge order
      // should be separate flow-state.
      if (!marketingOrder.listing?.address) {
        orderListingFlows.steps = orderListingFlows.steps.filter(step => step.id !== '/order-listing/details');
      }
      const photographyProduct = marketingOrder.getPhotographyProduct();
      if(photographyProduct == null || photographyProduct.optedOut || !photographyProduct.isAvailable || photographyProduct.details?.isReplaced) {
        orderListingFlows.steps = orderListingFlows.steps.filter(step => step.id !== '/order-listing/photography');
      } else {
        if (photographyProduct?.details?.options?.length === 0) {
          this.deletePhotographyOptionsStep(orderListingFlows);
        }
      }


      const packageOptionsStep = orderListingFlows.steps.find(step => step.id === '/order-listing/package-options');
      if(packageOptionsStep != null) {

        if(!this.packageAllowsAdditionalPrintProducts(marketingOrder)) {
          // If we don't allow additional quantities, remove the order step
          packageOptionsStep.steps = packageOptionsStep.steps.filter((step: any) => step.id !== '/order-listing/additional-quantities');
        }

        const brochureProduct = marketingOrder.getBrochureProduct();
        if(brochureProduct == null || brochureProduct.optedOut || !brochureProduct.isAvailable || brochureProduct.details?.isReplaced) {
          // If brochure product does not exist, remove it
          packageOptionsStep.steps = packageOptionsStep.steps.filter((step: any) => step.id !== '/order-listing/brochure-options');
        }

        const customProduct = this.packageAllowCustomPrintProducts(marketingOrder);
        if(!customProduct) {
          // If brochure product does not exist, remove it
          packageOptionsStep.steps = packageOptionsStep.steps.filter((step: any) => step.id !== '/order-listing/custom-options');
        }

        const advertisingProduct = marketingOrder.getProduct(ProductCode.PRINT_ADVERTISING);
        if(advertisingProduct == null || advertisingProduct.optedOut || !advertisingProduct.isAvailable || advertisingProduct.details?.isReplaced) {
          // If advertising product does not exist, remove it
          packageOptionsStep.steps = packageOptionsStep.steps.filter((step: any) => step.id !== '/order-listing/advertising-options');
        }

        if(!marketingOrder.hasShippableProducts()){
          this.deleteShippingInformationStep(orderListingFlows);
        }

        if(!packageOptionsStep.steps?.length) {
          // No more package option steps. remove from flow entirely
          orderListingFlows.steps = orderListingFlows.steps.filter(step => step.id !== '/order-listing/package-options');
        }
      }
    }
    const flowState =  new FlowState(orderListingFlows);

    if (marketingOrder) {
      // If we have a listing, we should still deserialize state to initialize currentStep if it isn't already set
      flowState.deserializeState(marketingOrder.orderState ? marketingOrder.orderState.state : null);
    }
    return flowState;
  }

  private deletePhotographyOptionsStep(orderListingFlows: any) {
    const photographyStep = orderListingFlows.steps.find(step => step.id === '/order-listing/photography');
    const photographySubStepsWithoutOptions = photographyStep.steps.filter(step => step.displayName !== 'Options');
    photographyStep.steps = photographySubStepsWithoutOptions;
  }

  private deleteShippingInformationStep(orderListingFlows: any) {
      const checkoutOptionsStep = orderListingFlows.steps.find(step => step.id === '/order-listing/checkout');
      checkoutOptionsStep.steps = checkoutOptionsStep.steps.filter((step: any) => step.id !== '/order-listing/checkout/shipping');
  }

  /**
   * Checks the current selected package for any products that allow for additional print producs
   * @param marketingOrder The marketing order to check
   */
  private packageAllowsAdditionalPrintProducts(marketingOrder: MarketingOrder) {
    // Check to see if package has any products that can add additional quantities
    return marketingOrder.selectedPackage.products
      .some(product => product.details.isVendorPrinted && product.isAvailable && !product.details?.isReplaced && !product.optedOut && (product.details.unitPrice > 0 || product.details.additionalUnitPrice > 0));
  }


   /**
   * Checks the current selected package for any products that allow for additional print producs
   * @param marketingOrder The marketing order to check
   */
    private packageAllowCustomPrintProducts(marketingOrder: MarketingOrder) {
      // Check to see if package has any products that can add additional quantities

      return marketingOrder.selectedPackage.products
        .some(product => product?.details?.canBeReplacedBy && product.details.canBeReplacedBy[0]?.conversion);
    }
}
