import { BaseForm } from './base.form';
import { FormControl } from '@angular/forms';

export class PhotoInformationForm extends BaseForm {
  get photoUrl() { return this.get('photoURL').value; }

  constructor(value?: any) {
    super({
      photoUrl: new FormControl(),
      videoPhotoUrl: new FormControl(),
      originalPhotoUrl: new FormControl(),
      metaData: new FormControl()
    });

    if(value) {
      this.patchValue(value);
    }
  }
}
