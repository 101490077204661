import { Address, AddressType } from '../models';

export class AddressFactory {
  public static googleBillingAddress = new Address({
      id: '1',
      type: AddressType.BILLING,
      name: 'Google Billing',
      streetAddress1: '1600 Amphitheatre Parkway',
      city: 'Mountain View',
      state: 'CA',
      country: 'U.S',
      postalCode: '94043'
    } as Address
  );

  public static googleShippingAddress = new Address({
      id: '2',
      type: AddressType.SHIPPING,
      name: 'Google Shipping',
      streetAddress1: '1600 Amphitheatre Parkway',
      city: 'Mountain View',
      state: 'CA',
      country: 'U.S',
      postalCode: '94043'
    } as Address
  );

  public static googleMailingAddress = new Address({
    id: '3',
    type: AddressType.MAILING,
    name: 'Google Mailing',
    streetAddress1: '1600 Amphitheatre Parkway',
    city: 'Mountain View',
    state: 'CA',
    country: 'U.S',
    postalCode: '94043'
  } as Address
);
}
