<div fxLayout="row" fxLayoutGap="25px" *ngIf="(fieldVersions$|async) as fieldVersions">
    <div fxFlex="1 0 80%" class="version-table-container clean-scroll-bar" *ngIf="fieldVersions.length > 0">
        <table id="field-version-table" mat-table [dataSource]="fieldVersions" class="mat-elevation-z8 w-100 version-table">

            <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

            <!-- modifiedTime Column -->
            <ng-container matColumnDef="modifiedTime">
                <th mat-header-cell *matHeaderCellDef> Date/Time </th>
                <td id="field-version-date" style="width: 23%" mat-cell *matCellDef="let element">{{element.modifiedTime *1000
                    |date:'MM-dd-yyyy
                    h:mm a'}}
                </td>
            </ng-container>

            <!-- Key Column -->
            <ng-container matColumnDef="userFullName">
                <th mat-header-cell *matHeaderCellDef> Who </th>
                <td id="field-version-who" style="width: 23%" class="pr-2" mat-cell *matCellDef="let element">{{element.userFullName}}
                </td>
            </ng-container>
            <!-- Key Column -->
            <ng-container matColumnDef="key">
                <th mat-header-cell *matHeaderCellDef> Field </th>
                <td id="field-version-key" style="width: 23%" class="pr-2" mat-cell *matCellDef="let element">{{element.key}}
                </td>
            </ng-container>

            <!-- value Column -->
            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef> Value </th>
                <td id="field-version-value" style="width: 40%" class="pr-2" mat-cell *matCellDef="let element">{{element.value}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr id="field-version-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-icon class="clickable" (click)="onClose()">
        disabled_by_default
    </mat-icon>
</div>