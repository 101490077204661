import {Deserializable} from './deserializable.model';
import { ConciergeObligationTypes } from './concierge-obligation.types';
import { ProductDetails } from './product-details.model';
import { ProductCategory } from './product.category';

export class ProductType implements Deserializable<ProductType> {

  title: String;
  description: string;
  code: string;
  details: ProductDetails;
  //TODO extract or use enum type for kind
  kind: 'product' | 'service' | 'package';
  category: ProductCategory;
  active: boolean;
  activationDate: Date;
  retirementDate: Date;
  thumbnailUri: string;
  price: number;
  position: number;

  deserialize(input: any): ProductType {
    if(!input) { return this; }
    Object.assign(this, input);

    if(this.details) {
      this.details = new ProductDetails(this.details)
    }
    return this;
  }
}
