import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiClient } from './api.client';
import { NoteForm } from '../forms/note.form';
import { Note } from '../models/note.model';
import { AppService } from '../services/app.service';

/**
 *  Contains all of the methods required to communicate with the Notes API.
 *  Should not contain methods with business logic. This is only for API communication
 */
@Injectable({
  providedIn: 'root'
})
export class NotesClient {

  private readonly notesEndpoint = `${AppService.get('persistBaseURL')}notes`;

  constructor(private apiClient: ApiClient) {
  }

  /**
   * Queries the API and returns all notes for a provided ownerId parameter
   */
  get(params: {ownerId: string}): Observable<Note[]> {
    return this.apiClient.get<Note[]>(this.notesEndpoint, params)
      .pipe(map(notes => notes.map(note => new Note(note))));
  }

  /**
   * POSTs the notification form to the API. Ensuring proper form validation
   * @param form The notification form to post.
   */
  post(form: NoteForm): Observable<Note> {
    return this.apiClient.post<Notification>(this.notesEndpoint, form)
      .pipe(map(note => new Note(note)));
  }
}
