// tslint:disable: rxjs-no-sharereplay
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { UserSettingsForm } from '../forms';
import { User, UserSettings } from '../models';
import { LOGGEDINUSER } from '../state-mgmt/user/user.actions';
import { ApiService } from './api.service';

/**
 * This service handles the logic required to manage the user's settings
 */
@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  /** UserSettings cached in memory */
  private readonly userSettings: {[userId: string]: Subject<UserSettings> } = {};

  constructor(private readonly apiService: ApiService, private store: Store<any>,) { }

  getCurrentUserSettings$(): Observable<UserSettings> {
    return this.store.select(LOGGEDINUSER).pipe(
      filter((user: User) => user != null),
      switchMap(user => this.getUserSettings$(user._id))
    );
  }

  /** Retrieves the user's settings for the given userId. Will only fetch once per request  */
  getUserSettings$(userId: string): Observable<UserSettings> {
    if(!this.userSettings[userId]) {
      // Initialize the Subject that can be used to force the UI to update without re-calling the API
      this.userSettings[userId] = new BehaviorSubject<UserSettings>(null);
    }

    // Return the cached observable
    return this.userSettings[userId].pipe(
      switchMap(settings => settings
        // If settings have already been cached, return them
        ? of(settings)
        // Otherwise, make a new API Request
        : this.apiService.get<UserSettings>(`users/${userId}/settings`).pipe(
          map(userSettings => new UserSettings(userSettings)),
          tap(userSettings => this.userSettings[userId].next(userSettings))
        )
      ));

  }

  async GetandUpdateUserSetting(userId: string) {
    const val = await this.apiService.get<UserSettings>(`users/${userId}/settings`).pipe(
      map(userSettings => new UserSettings(userSettings)),
      tap(userSettings => this.userSettings[userId].next(userSettings)))
      await val.subscribe((settings: UserSettings) => {
        this.updateCache(userId, settings)
      }, (error) => { throw new Error(error); });
  }

  /** Updates the user's settings  */
  async updateSettings(settingsForm: UserSettingsForm): Promise<UserSettings> {
    const value: UserSettings = settingsForm.value;
    if(settingsForm.invalid) {
      // This shouldn't happen. If it does, log it so we see it in dataDog and can fix it
      console.warn('Attempting to update user settings with an invalid form.', value);
    }

    // Post the form to the API and update the in-memory cache
    return await this.apiService.post<UserSettings>(`users/${value.user}/settings`, value).pipe(
      map(settings => new UserSettings(settings)),
      tap(settings => this.updateCache(settings.userId, settings)),
    ).toPromise();
  }

  /** Triggers an update to the current observables if they are subscribed to */
  private updateCache(userId: string, settings: UserSettings) {
    if(this.userSettings[userId]) {
      this.userSettings[userId].next(settings);
    }
  }
}
