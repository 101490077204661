<h4 mat-dialog-title id="bulk-assign-title">{{data.title}}</h4>
<hr/>
<p id="bulk-assign-subtitle">{{data.subTitle}}</p>

<!--Modal Body-->
<div class="bulk-assign-modal-body">
  <lc-agent-search idPrefix="coordinator-search" [class.ng-valid]="error" [formControl]="coordinatorSearch" [searchBy]="roleTypeForReassign" (selectedChanged)="onCoordinatorSelected($event)" [max]="1" label="Search by Coordinator:" placeholder="Search by coordinator name"></lc-agent-search>
  <mat-error id="bulk-assign-error" *ngIf="error" [innerHTML]="error"></mat-error>
</div>

<!--Modal Footer -->
<div mat-dialog-actions *ngIf="swapPrimaryButtons$ | async; else swappedButtons">
  <button id="save-bulk-assign-button" type="button" mat-raised-button color="primary"
    (click)="onCommit()">Save</button>
    <button id="cancel-bulk-assign-button" type="button" mat-raised-button (click)="onCancel()">Cancel</button>
</div>

<ng-template #swappedButtons>
<div mat-dialog-actions>
  <button id="cancel-bulk-assign-button" type="button" mat-raised-button (click)="onCancel()">Cancel</button>
  <button id="save-bulk-assign-button" type="button" mat-raised-button color="primary"
    (click)="onCommit()">Save</button>
</div>
</ng-template>
