<ng-template #photoUpload>
  <label *ngIf="label" class="form-label" [for]="inputId || 'uploadPhotos'">{{label}}</label>
  <file-drop (onFileDrop)="handleDroppedFiles($event)" customstyle="mb-4 lc-drop-zone">
    <div *ngIf="!processing">
      <div class="row">
        <div class="col-12 align-content-center"><span class="text-faded">{{instructions}}</span></div>
      </div>
      <div class="row mt-2">
        <div class="col-12 align-content-center">
          <img [src]="imageUrl || '/assets/images/upload-photos.png'" alt="upload images" height="63px" [class.round]="photoStyle === 'round'">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 align-content-center">
          <input #fileInput type="file" multiple class="d-none lc-file-upload-input" [accept]="allowExt" [id]="inputId || 'uploadPhotos'" (click)="fileInput.value = null" (change)="handleSelectedFiles($event)" />
          <button type="button" mat-raised-button class="lc-file-upload-btn" [id]="btnId || 'chooseFileButton'" (click)="chooseFiles();">Upload</button>
        </div>
      </div>
    </div>
    <div *ngIf="processing">
      <div class="col align-content-center">
        <p class="text-faded">Please wait while uploading the file(s)...</p>
      </div>
    </div>
  </file-drop>
</ng-template>
<ng-container *ngTemplateOutlet="uploadTemplate ? uploadTemplate: photoUpload"></ng-container>
