import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { BaseForm } from './base.form';
import { STATES_BY_ABBREVIATION, Address } from '../models';
import { CustomValidator } from '../validators';
import { StringFormControl } from './form-controls/string-form-control';

export class AddressForm extends BaseForm {
  readonly states = STATES_BY_ABBREVIATION;
  readonly originalModel: Address;

  get isHawaii(): boolean { return this.getControl('state')?.value === 'HI'; }

  constructor(address?: any) {
    super({
      streetAddress1: new StringFormControl(null, [Validators.required]),
      streetAddress2: new StringFormControl(null),
      city: new StringFormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required,  Validators.pattern('^[A-Z]{2}$'), CustomValidator.validateState]),
      postalCode: new FormControl(null, [Validators.required, Validators.pattern('^\\d{5}$')])
    });

    if(address){
      this.patchValue(address);
      this.originalModel = address;
    }
  }
}
