import { Deserializable } from './deserializable.model';

export class PhotoInformation implements Deserializable<PhotoInformation> {

  photoUrl: string;
  originalPhotoUrl: string;
  videoPhotoUrl: string;
  metaData: any;

  constructor(input?: any) {

    this.deserialize(input || {});
  }

  deserialize(input: any): PhotoInformation {
    Object.assign(this, input);

    return this;
  }
}
