import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

/**
 * The RouterService should be initialized upon App Initialization and will keep track of router history.
 * This service can be used in the future to help with navigation-type events
 **/
@Injectable({
  providedIn: 'root'
})
export class RouterService {

  private history = ['/'];

  constructor(private router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(
        ({urlAfterRedirects}: NavigationEnd) => this.history.push(urlAfterRedirects),
        (error) => console.warn('Error in the RouterService subscription', error)
      );
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/';
  }
}
