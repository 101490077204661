import { Injectable } from '@angular/core';
import { MarketingOrder } from '../models/marketing-order.model';
import { AgentTypes } from '../models';
import { ApiService } from './api.service';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UpdateCurrentOrderState } from '../state-mgmt/order/order.actions';


@Injectable({
  providedIn: 'root'
})
export class MarketingOrderAgentService {

  constructor(private readonly apiService: ApiService, private readonly store: Store<any>) { }

  /**
   * Updates the Listing to singleAgent and dispatches an update to the store for the current marketing order
   **/
  async marketAsSingleAgent(marketingOrder: MarketingOrder): Promise<MarketingOrder> {
    this.setAgentType(marketingOrder, AgentTypes.singleAgent);
    return await this.updateListingAgent(marketingOrder);
  }

  /**
   * Updates the Listing to twoAgents and dispatches an update to the store for the current marketing order
   **/
  async marketAsCoList(marketingOrder: MarketingOrder, coListAgentId: string): Promise<MarketingOrder> {
    this.setAgentType(marketingOrder, AgentTypes.twoAgents);
    marketingOrder.coListAgentId = coListAgentId;
    return await this.updateListingAgent(marketingOrder);
  }

  /**
   * Updates the Listing to team and dispatches an update to the store for the current marketing order
   **/
  async marketAsTeam(marketingOrder: MarketingOrder, teamId: string): Promise<MarketingOrder> {
    this.setAgentType(marketingOrder, AgentTypes.team);
    marketingOrder.teamId = teamId;
    return await this.updateListingAgent(marketingOrder);
  }

  private async updateListingAgent(marketingOrder: MarketingOrder) {
    const payload: { agentType: AgentTypes, coListAgentId: string, teamId: string } = {
      agentType: marketingOrder.listing.agentType,
      coListAgentId: marketingOrder.coListAgentId,
      teamId: marketingOrder.teamId,
    }
    return await this.apiService.put(`marketing-orders/${marketingOrder._id}/listing-agent`, payload).pipe(
      map(response => new MarketingOrder(response)),
      tap(updatedOrder =>  this.store.dispatch(new UpdateCurrentOrderState(updatedOrder)))
    ).toPromise();
  }

  /**
   * Updates the listing with the appropriate agent type and clears out any values
   * that no longer pertain
   **/
  private setAgentType(marketingOrder: MarketingOrder, agentType: AgentTypes) {
    marketingOrder.listing.agentType = agentType;
    if(marketingOrder.listing.agentType === AgentTypes.singleAgent) {
      // If single agent, clear out coList and team ids
      marketingOrder.coListAgentId = null;
      marketingOrder.teamId = null;
    } else if(marketingOrder.listing.agentType === AgentTypes.twoAgents) {
      // If coList, clear out teamId
      marketingOrder.teamId = null;
    } else if(marketingOrder.listing.agentType === AgentTypes.team) {
      // If team, clear out coList id
      marketingOrder.coListAgentId = null;
    }
  }
}
