import { EventEmitter, Injectable } from '@angular/core';
import { NotificationEvent } from './notification-event';

/**
 * This is a injectable service that holds an event emitter that may be observed
 * by consumers to get notifications
 */
@Injectable()
export class NotificationEventService {

  private eventEmitter: EventEmitter<NotificationEvent> = new EventEmitter();

  constructor() {
  }

  public getEventEmitter(): EventEmitter<NotificationEvent> {
    return this.eventEmitter;
  }

}
