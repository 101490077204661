import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiService } from './api.service';
import { UpdateCurrentOrderState } from '../state-mgmt/order/order.actions';
import { MarketingOrder } from '../models/marketing-order.model';
import { PaymentRefund } from '../models/payment-refund.model';
import { tap, catchError, map } from 'rxjs/operators';

/**
 * The MarketingOrderRefundService is responsible for handline all refund requests.
 **/
@Injectable({
  providedIn: 'root'
})
export class MarketingOrderRefundService {

  constructor(private apiService: ApiService, private store: Store<any>) { }

  /**
   * Apply a refund to a marketing order
   * @param id the marketing order id
   * @param refund the refund data to apply
   */
  async refund(marketingOrder: MarketingOrder, refund: PaymentRefund): Promise<MarketingOrder> {
    return await this.apiService.put<MarketingOrder>(`marketing-orders/${marketingOrder._id}/refund`, {refund: refund}).pipe(
      map(updatedOrder => new MarketingOrder(updatedOrder)),
      tap(updatedOrder => this.store.dispatch(new UpdateCurrentOrderState(updatedOrder))),
      catchError(errors => { throw Error(this.formatRefundErrors(errors)); })
    ).toPromise();
  }

  private formatRefundErrors(errors: any) {
    // TODO: WTH...? We need similiar formatted error messages on the API
    const message = errors?.error?.error?.raw?.message || errors?.error?.error?.message || 'There was an error submitting your refund.';
    return message.toString();
  }

}
