import {Deserializable} from './deserializable.model';

export class ProductOption implements Deserializable<ProductOption> {
  title: string;
  selected: boolean;
  isBundled: boolean;
  description: string;
  assets: any;
  quantity: number;

  constructor(input?: Partial<ProductOption>) {
    this.deserialize(input);
  }

  deserialize(input: any): ProductOption {
    if (!input) { return this; }
    Object.assign(this, input);
    return this;
  }
}
