export class Mask {
  constructor(public mask: string, public pattern: { [character: string]: { pattern: RegExp, optional?: boolean} }) {
  }
}

export class Masks {
  public static phoneNumber: Mask = new Mask('#00.000.0000',  {'#': { pattern: new RegExp('\[2-9\]')}, '0': { pattern: new RegExp('\[0-9\]')}});
  public static wholeNumber: Mask = new Mask('0*',  {'0': { pattern: new RegExp('\[0-9\]')}});
  public static decimalWithWholeNumber: Mask = new Mask('0*.00', {'0':{pattern: new RegExp('^(0|[1-9]\d*)(\.\d+)?$')}});

}




