import { Notification } from '../../models/notification.model';
import { NotificationActionTypes, NotificationActions } from './notification.actions';

const initialState = [];

export function notificationReducer(state = initialState, action: NotificationActions): Notification[] {
    switch(action.type) {
      case NotificationActionTypes.NotificationsUpdated:
        return action.notifications;
    }
    return state;
}
