import { Fulfillment, FulfillmentTypes } from '../models/fulfillment.model';
import { FulfillmentOption, PrintOrderOptionTypes } from '../models/fulfillment-option.model';
import { AddressInformation } from '../models/address-information.model';


export class FulfillmentFactory {

  public static createFulfillment(type: FulfillmentTypes, options: FulfillmentOption[]) {
    const fulfillment = new Fulfillment({
      type: type,
      options: options
    });
    return fulfillment;
  }

  public static createContactFulfillmentOption(addressInfo: AddressInformation, contactType: string, quantity: number) {
    const fulfillmentOption = new FulfillmentOption({
      type: PrintOrderOptionTypes.CONTACTS,
      contactType: contactType,
      quantity: quantity,
      fullName: addressInfo.fullName,
      company: addressInfo.company,
      address: addressInfo.address
    });
    return fulfillmentOption;
  }

  public static createRadiusFulfillmentOption(addressInfo: AddressInformation, quantity: number) {
    const fulfillmentOption = new FulfillmentOption({
      type: PrintOrderOptionTypes.RADIUS,
      quantity: quantity,
      address: addressInfo.address
    });
    return fulfillmentOption;
  }

  public static createReturnAddressFulfillmentOption(addressInfo: AddressInformation) {
    const fulfillmentOption = new FulfillmentOption({
      type: PrintOrderOptionTypes.RETURN_ADDRESS,
      address: addressInfo.address
    });
    fulfillmentOption.address.name = addressInfo.fullName;
    fulfillmentOption.company = addressInfo.company;
    return fulfillmentOption;
  }

  public static createAttachmentFulfillmentOption(quantity: number, quantityOmitted: number, url: string) {
    const fulfillmentOption = new FulfillmentOption({
      type: PrintOrderOptionTypes.LIST,
      quantity: quantity,
      quantityOmitted: quantityOmitted,
      url: url
    });
    return fulfillmentOption;
  }
}
