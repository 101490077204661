import { FormControl, Validators } from '@angular/forms';
import { BaseForm } from './base.form';
import { wholeNumberValidator } from '../validators/whole-number.validator';
import { Listing } from '../models/listing.model';
import { LotSizeUnitOfMeasure } from '../models/lot-size-unit-of-measure.enum'
import { CustomValidator } from '../validators';
import { emptyStringValidator } from '../validators/empty-string.validator';


const ACRES_TO_SQUARE_FEET_FACTOR = 43560

export class PropertyInfoForm extends BaseForm {
  readonly originalModel: any;
  public get bedrooms(): number { return this.get('bedrooms').value; }
  public get bathrooms(): number { return this.get('bathrooms').value; }

  constructor(listing?: Listing) {

    super({
      propertyType: new FormControl(null, [Validators.required]),
      status: new FormControl(null, [Validators.required]),
      listPrice: new FormControl(null,[Validators.max(99000000)]),
      displayAsPriceOnRequest: new FormControl(false),
      bedrooms: new FormControl(null, [wholeNumberValidator(), Validators.max(99), emptyStringValidator('bedrooms')]),
      bedroomsLabel: new FormControl(),
      bathrooms: new FormControl(null, [Validators.max(99), emptyStringValidator('bathrooms')]),
      bathroomsLabel: new FormControl(),
      sqFt: new FormControl(null, [emptyStringValidator('sqFt')]),
      lotSize: new FormControl(null, [emptyStringValidator('lotSize')]),
      lotSizeUnitOfMeasure: new FormControl(listing?.lotSizeUnitOfMeasure || LotSizeUnitOfMeasure.sqft),
      listDate: new FormControl(null),
      closedDate: new FormControl(null),
      mlsId: new FormControl(null,[Validators.pattern('^[A-Za-z0-9 -]+$'),CustomValidator.forbiddenWordsValidator]),
      globalLuxuryListing: new FormControl(null),
      landTenure: new FormControl(listing?.landTenure),
      buyerBrokerageCommission: new FormControl(null),
      qrCodeStatus: new FormControl(null),
      priceLabel: new FormControl()
    });


    if (listing) {
      this.patchValue(listing);
      this.originalModel = listing;
    }
    if (!listing?.lotSizeUnitOfMeasure) {
      this.patchValue({ lotSizeUnitOfMeasure: LotSizeUnitOfMeasure.sqft })
    }
  }

  patchValue(value) {
    return super.patchValue(value);
  }

  setControlValue(control, value) {
    control.setValue(value);
    control.markAsDirty();
  }

}
