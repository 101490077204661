import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppService, AuthenticationService, FeatureFlags, LaunchDarklyService, LOGGEDINUSER, PromptDialogService, UIConstants, UpdateUserSession, User, UserRoles, UserService, ContentfulService, ContentKeys } from '@lc/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export class ViewModel {
  readonly firstName: string;
  readonly profilePhotoUrl: string;
  readonly showProfileLink: boolean = AppService.isCoordinatorApp || AppService.isAgentApp;
  readonly whatsNewLink$ : Observable<boolean>;
  readonly showFeedbackLink: boolean = AppService.isDCUserApp;
  readonly showWhatsNewLink: boolean = AppService.isCoordinatorApp;

  readonly showOnBehalfOf$: Observable<boolean>;


  constructor(readonly user: User, readonly assumedUser: User, featureFlagService: LaunchDarklyService) {
    this.firstName = user.getFirstName();
    this.profilePhotoUrl = user?.profile?.profilePhotoUrl || user?.profile?.photoUrl || 'assets/images/user.png';

    this.showOnBehalfOf$ = this.isOnBehalfOfEnabled$(user, assumedUser);


    this.whatsNewLink$ = featureFlagService.isFeatureEnabled$(FeatureFlags.WHATS_NEW); 
  }

  isOnBehalfOfEnabled$(user: User, assumedUser: User) {
    if(!AppService.isAgentApp) { return of(false); } // Only enabled for agent app
    if(assumedUser != null) { return of(false); } // We are already in OBO mode

    const onBehalfOfRoles = [UserRoles.BRANCH_MANAGER, UserRoles.MARKETING_DIRECTOR, UserRoles.NATIONAL_DIRECTOR, UserRoles.CBA_NATIONAL_DIRECTOR, UserRoles.CBR_NATIONAL_DIRECTOR, UserRoles.DELEGATE];
    const hasRole = onBehalfOfRoles.some(allowedRole => (user?.roles || []).includes(allowedRole));
    if(!hasRole) { return of(false); } // User does not have role, return false

    return of(true);
  }
}

@Component({
  selector: 'lc-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  readonly viewModel$: Observable<ViewModel>;
   productServicesTab = false;
   headerLogo$: Observable<any>;


  constructor(featureFlagService: LaunchDarklyService,
              iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer,
              public contentfulService: ContentfulService,
              private readonly router: Router,
              private readonly store: Store<any>,
              private readonly userService: UserService,
              private readonly authService: AuthenticationService,
              private readonly dialogService: PromptDialogService) {
    const user$ = this.store.select(LOGGEDINUSER).pipe(map((user: User) => user));
    const assumedUser$ = user$.pipe(map(() => this.userService.isAssumedUser() ? this.userService.getCurrentUser() : null));

    this.viewModel$ = combineLatest([user$, assumedUser$]).pipe(
      map(([user, assumedUser]) => new ViewModel(user, assumedUser, featureFlagService))
    );
  }

  ngOnInit(): void {
    this.headerLogo$ = this.contentfulService.getContent$(ContentKeys.APP_HEADER_IMAGE, 'assets/images/ListingConcierge.svg');
  }

  async endImpersonation() {
    await this.userService.endAssume();
    this.router.navigate(['/on-behalf-of']).then(() => { this.router.navigate(['/']); });
  }

  /** Prompts the user if they want to logout. If confirmed, stops the expiration countdown and redirects to logout. */
  async onLogout() {
    const response = await this.dialogService.openPrompt('Confirm', 'Are you sure you want to log out?', UIConstants.SIGN_OUT, [UIConstants.NO], {centered: true});
    if(response?.text !== UIConstants.SIGN_OUT) { return; }

    setTimeout(() => {
      this.closeSessionExpiryCountDown();
      this.authService.redirectToLogout()
    }, 300);
  }

  private closeSessionExpiryCountDown() {
    const sessionUpdate = { expiryAt: new Date().getTime(), sessionGoingToExpiry: false };
    this.store.dispatch(new UpdateUserSession(sessionUpdate));
  }
}
