import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationEvent, NotificationEventService, NotificationEventTypes } from '../notifications';
import { ErrorData } from './error-data';
import { OrderActionTypes } from '../state-mgmt/order/order.actions';

/**
 * GlobalErrorHandler to process errors
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private notificationService: NotificationEventService) {
  }

  handleError(error: any, logToDatadog: boolean = true) {
    if (error === null || typeof error === 'undefined') {
      return;
    }

    if(error?.payload?.error ==="EMPTYID")
    {

      this.handleUndefinedIdError(error);
    }

    // Log the error to the console (which will be written to datadog)
    if (logToDatadog) {
      console.error(`An unhandled exception has occured: ${error?.message}`, error);
    }

    if (error instanceof HttpErrorResponse) {
      this.handleHttpError(error);
    }
    else if (error.payload && error.payload.error && error.payload.error instanceof HttpErrorResponse) {
      this.handleServerError(error)
    } else if (error instanceof ErrorData) {
      this.handleErrorData(error);
    } else if (error && error.stack) {
      console.error(error.stack);
    } else {
      let errMsg = error;
      if (typeof error === 'object') {
        const cache = [];
        errMsg = JSON.stringify(error, function(key, value) {
          if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
              // Duplicate reference found, discard key
              return;
            }
            // Store value in our collection
            cache.push(value);
          }
          return value;
        });
      }
      console.error(`Error: ${errMsg}`);
      this.handleUnexpectedError(errMsg)
    }

  }


  handleHttpError(error: HttpErrorResponse) {
      // defaults to the snackbar for api error
      const name = NotificationEventTypes.APIERROR;
      const message = 'Error while processing the request  -  ' + error.statusText + '    ';
    const event = new NotificationEvent(name, message, { payload: error});
    this.notificationService.getEventEmitter().emit(event);
  }

  handleUndefinedIdError(error: any) {
    // defaults to the snackbar for api error
    const name = NotificationEventTypes.APIERROR;
    const message = 'Something went wrong. Please refresh the page' ;
  const event = new NotificationEvent(name, message, { payload: error});
  this.notificationService.getEventEmitter().emit(event);
}

  // we have different handlers based on different error objects. Final action to be determined
  handleServerError(error: any) {
      let name;
      let message;
      const paymentMessage = this.getPaymentMessage(error.payload.error);
      if (paymentMessage) {
        // special case for payment failure
        name = OrderActionTypes.SubmitOrderFailed;
        message = paymentMessage;
      } else {
        // defaults to the snackbar for api error
        name = NotificationEventTypes.APIERROR;
        message = 'Error while processing the request  -  ' + error.payload.error.statusText + '    ';
      }
      const event = new NotificationEvent(name, message, error);
      this.notificationService.getEventEmitter().emit(event);
  }

  getPaymentMessage(errorResponse: any) {
    if (errorResponse.error) {
      const paymentError = errorResponse.error.error;
      if (paymentError && paymentError.isPayment && paymentError.raw) {
        return paymentError.raw.message;
      }
      else if(paymentError && !paymentError.isPayment && paymentError.detail)
      {
        return paymentError.detail;

      }
    }
  }

  handleErrorData(error: ErrorData) {
    this.notificationService.getEventEmitter().emit(error.getNotificationEvent());
  }

  handleUnexpectedError(error: any) {
    const event = new NotificationEvent('error', 'unexpected', error);
    this.notificationService.getEventEmitter().emit(event);
  }
}
