import { FormArray, FormGroup,FormControl, Validators } from '@angular/forms';
import { BaseForm } from './base.form';
import { PhoneNumberForm } from './phone-number.form';
import { SocialMediaForm } from './social-media.form';
import { PhoneNumber, Profile, RealtorType, SocialMedia } from '../models';
import { urlValidator } from '../validators/url.validator';
import { titleValidator } from '../validators/title.validator';
import { emailValidator, noWhitespaceValidator } from '../validators';
import { LCFormArray } from './lc-form-array';
import { StringFormControl } from './form-controls/string-form-control';

export class ProfileForm extends BaseForm<Profile> {
  public get phoneNumbers(): LCFormArray<PhoneNumber> { return this.get('phoneNumbers') as LCFormArray<PhoneNumber>; }
  public get contactInfo(): LCFormArray<SocialMedia> { return this.get('contactInfo') as LCFormArray<SocialMedia>; }
  public get logoUrl(): string { return this.get('logoUrl').value; }

  constructor(public readonly originalValue?: Profile, public options?: {validateType: boolean, phoneNotRequired: boolean }) {
    super({
      _id: new FormControl(),
      preferredFirstName: new StringFormControl(null, [Validators.required, noWhitespaceValidator()]),
      preferredLastName: new StringFormControl(null, [Validators.required, noWhitespaceValidator()]),
      preferredEmail: new FormControl(null, [Validators.required, emailValidator()]),
      title: new FormControl(null, [Validators.maxLength(50), titleValidator()]),
      website: new FormControl(null, [urlValidator()]),
      areas: new FormControl(null, []),
      profileImage: new FormControl(null),
      // TODO: [LC-4417] Deprecate the following 4 urls in favor of the profilePhoto object above
      photoUrl: new FormControl(null, [Validators.required]),
      profilePhotoUrl: new FormControl(null, []),
      videoProfilePhotoUrl: new FormControl(null, []),
      originalPhotoUrl: new FormControl(),

      logoUrl: new FormControl(),
      phoneNumbers: new LCFormArray<PhoneNumber>(originalValue?.phoneNumbers || [],
        (phoneNumber, index) => new PhoneNumberForm(phoneNumber, {priority: index, typeRequired: true, isPrimary: index === 0})),
      contactInfo: new LCFormArray<SocialMedia>(originalValue?.socialMedia || [],
        (socialMedia) => new SocialMediaForm(socialMedia, {typeRequired: true})),
    });

    if(this.originalValue?.isHawaiianAgent) {
      this.addControl('realtorType', new FormControl(this.originalValue.realtorType, [Validators.required]));
    }

    if(this.originalValue) {
      this.patchValue(this.originalValue);
    }
  }

  deleteSocialMedia(form: BaseForm){
    this.contactInfo.deleteControl(form);
    this.markAllAsDirty();
  }

  deletePhoneNumber(form: BaseForm){
    this.phoneNumbers.deleteControl(form);
    this.markAllAsDirty();
  }

  patchValue(value: any, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.patchValue(value, options);
    if(value.contactInfo)
    {
      const contactInfo = this.get('contactInfo') as FormArray;
      value.contactInfo.forEach( (element) => {
        if(element.type==='socialMedia') {
          contactInfo.push(new SocialMediaForm(element.socialMedia, {...this.options, typeRequired: true}));
        }
      });
    }

    if(value.phoneNumbers) {
      const phoneNumbers = this.get('phoneNumbers') as FormArray;
      while (phoneNumbers.length !== 0) {
        phoneNumbers.removeAt(0);
      }
      value.phoneNumbers.forEach((phoneNumber, index) => phoneNumbers.push(new PhoneNumberForm(phoneNumber, {...this.options, priority: index, typeRequired: true})));
    }
  }
}
