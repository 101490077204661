import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { NxModule } from '@nrwl/nx';
import { PreloadAllModules, RouterModule } from '@angular/router';
import {SharedComponentsLayoutModule } from '@lc/shared-components-layout';
import { SharedComponentsAuthModule } from '@lc/shared-components-auth';
import {
  CoreModule,
  StateModule,
  AppService,
  initializeApp,
  StartupService,
  LaunchDarklyService,
  DataDogLogger
} from '@lc/core';
import { UserIdleModule, UserIdleConfig } from 'angular-user-idle';
import { SharedComponentsUiModule } from '@lc/shared-components-ui';
import { InputsModule } from '@lc/ui';

if (location.hostname !== 'localhost' && location.hostname !== 'remotedev') {
  DataDogLogger.initialize('pub19b56e84527e84bc9c0e28fa98d7137a')
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DragDropModule,
    CoreModule.forRoot(),
    StateModule,
    InputsModule,
    SharedComponentsAuthModule,
    SharedComponentsLayoutModule,
    SharedComponentsUiModule.forRoot(),
    NxModule.forRoot(),
    RouterModule.forRoot(
      [
        { path: '', loadChildren: () => import('@lc/suite-coord-app').then(m => m.SuiteCoordAppModule) },
      ], {
      enableTracing: false,
      paramsInheritanceStrategy: 'always', // This is required to allow nested modules to retrieve all query parameters (i.e.: 'id')
      preloadingStrategy: PreloadAllModules
    }),
    UserIdleModule
  ],
  providers: [
    // User activity timeouts
    // Ping value is not used
    // Session timeout is pulled from AppSettings after load.  If not found default is 5 minutes
    { provide: UserIdleConfig, useFactory: () => ({idle: AppService.get('sessionTimeout')  * 60, timeout: 30, ping: 10000}) },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [StartupService], multi: true},
    LaunchDarklyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}