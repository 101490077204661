import { BaseForm } from './base.form';
import { ProductInstance } from '../models/product-instance.model';
import { FormControl } from '@angular/forms';
import { LCFormArray } from './lc-form-array';

export class ProductInstanceForm extends BaseForm {
  get customContactBlock() { return this.getControl('customContactBlock'); }
  get marketingCopyHeadline() { return this.getControl('marketingCopyHeadline') as LCFormArray<string>; }
  get marketingCopyBody() { return this.getControl('marketingCopyBody') as LCFormArray<string>; }
  get customContactConsent() { return this.getControl('customContactConsent') }
  get hiddenTags() { return this.getControl('hiddenTags') }
  constructor(readonly originalModel?: ProductInstance) {

    // Declare ALL the fields that are allowed to be patched or updated from the UI
    super({
      customContactBlock: new FormControl(originalModel?.customContactBlock),
      hiddenTags: new FormControl(originalModel?.hiddenTags),
      marketingCopyHeadline: new LCFormArray<string>(
        originalModel?.marketingCopyHeadline,
        (headline) => new FormControl(headline), // TODO: Implement max chars here
      ),
      marketingCopyBody: new LCFormArray<string>(
        originalModel?.marketingCopyBody,
        (body) => new FormControl(body), // TODO: Implement max chars here
      ),
      // TODO: A strongly typed form is preferred, but this will suffice for the need now
      photos: new FormControl(originalModel?.photos),
      customContactConsent: new FormControl()
    });
  }
}
