import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { User } from '../../models/user.model';
import { UserActionTypes, LoadLoggedInUser} from './user.actions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UserEffects {

  constructor(private actions$: Actions) {
  }

  @Effect({ dispatch: false })
  doNothing: Observable<User> = this.actions$.pipe(
    ofType<LoadLoggedInUser>(UserActionTypes.LoadLoggedInUser),
    map(action => action.payload)
  );
}

