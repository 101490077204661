export enum ProductCode {
  FLYER= '100',
  PROFESSIONAL_PHOTOGRAPHY = '101',
  WEBSITE = '102',
  JUST_LISTED_POSTCARD = '103',
  BROCHURE = '104',
  JUST_SOLD_POSTCARD = '105',
  MAILER ='106',
  TELEVISION_AND_VIDEO = '108',
  PROFESSIONAL_VIDEO = '109',
  VIDEO_SLIDESHOW = '110',
  PRINT_ADVERTISING = '111',
  CLIENT_REPORT='112',
  EMAIL_FLYER = '201',
  SOCIAL_MEDIA_BANNER = '107',
  SOCIAL_BOOST = '204'
}
