import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Remove problematic characters from a string.
 *
 * Based upon: https://regular-expressions.mobi/unicode.html?wlr=1
 *
 */

export function printableTextValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {

    const value = control.value;
    if(!value) {
      return null; // If the value is null or empty, nothing to validate. Just return
    }

    // replace non standard line and paragraph separators with a newline, then split into lines
    // to remove non standard space characters and other characters.
    // finally joins the lines back together.
    const newValue = value.replace(/\p{Paragraph_Separator}/ug,  '\n')
      .replace(/\p{Line_Separator}/ug,  '\n')
      .split('\n').map( line =>
            line.replace(/\p{Space_Separator}/ug, ' ')
                .replace(/\p{Other}/ug, '')

  ).join('\n');


    if(newValue !== value) {
      // If we replaced some values, need to set the value again
      control.setValue(newValue, { emitEvent: false });
    }

    // Returning null leaves control marked as valid
    return null;
  };
}
