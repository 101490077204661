import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
const angularModules = [ CommonModule ];

import { CoreModule } from '@lc/core';
import { InputsModule } from '../inputs/inputs.module';
const lcModules = [ CoreModule, InputsModule ];

// Internal Components
import { AgentSearchComponent } from './components/agent-search/agent-search.component';
const exportComponents = [ AgentSearchComponent];

@NgModule({
  declarations: [...exportComponents],
  imports: [
    ...angularModules,
    ...lcModules
  ],
  exports: [ ...exportComponents ]
})
export class AgentSearchModule { }
