import { Component, Injectable, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { InputField } from '../input-field';
import { Mask } from '../input-masks';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomValidator } from '@lc/core';

const digitDateStringRegex = /^[0-9]{6}$/
@Injectable()
class MyDateAdapter extends NativeDateAdapter {

  /**
   * Look at the string entered by the user and if we find a 6-digit string we add in slash '/' characters to get a
   * date stirng in MM/DD/YY format and parse that.
   *
   * This lets a user enter a date quickly like '121120' and not end up with a bogus date.
   *
   * @param value
   */
  parse(value: any): Date {
    const valueToParse = CustomValidator.stringValueOf(value);
    if (valueToParse.match(digitDateStringRegex)) {
      // Attempt to add slashes in before testing if it is valid
      const dateStringWithSlashes = `${valueToParse.substring(0, 2)}/${valueToParse.substring(2, 4)}/${valueToParse.substring(4)}`;
      return super.parse(dateStringWithSlashes);
    }

    // Parse the date string using the native adapter
    return super.parse(valueToParse);
  }
}

@Component({
  selector: 'lc-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    // Use the appropriate Date adapter to convert form Date object
    // {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    // // Use the CustomDateFormatter to display date in MM/DD/YYYY format
    // {provide: NgbDateParserFormatter, useClass: NgbCustomDateFormatter},
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    // give our own data adapter to handle fast keyboard entry using only digits.
    {provide: DateAdapter, useClass: MyDateAdapter},

  ]
})
export class DatepickerComponent extends InputField {
  @Input()
  label: string;

  @Input()
  placeholder = '';

  @Input()
  popoverPlacement: 'default' | 'top' = 'default';

  @Input()
  inputMask: Mask;

  @Input()
  maxDate: Date;

  @Input()
  minDate: Date;

  @Input()
  hint: string;

  @Input()
  readonly: string;

  @Output()
  public readonly blur = new EventEmitter<any>();

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
    const now = new Date();
    now.setFullYear(now.getFullYear() + 1);
    this.maxDate = now;
  }
}
