import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { BaseEffects } from '../base.effects';
import { Store } from '@ngrx/store';
import { GlobalErrorHandler } from '../../errors';
import { PhotographerService } from '../../services/photographer.service';
import { PhotoAgency } from '../../models';
import { Observable, } from 'rxjs';
import { catchError , map, switchMap } from 'rxjs/operators';
import { GetAllPhotographyAgencies, PhotographyAgencyActionTypes, GetAllPhotographyAgenciesComplete } from './photography-agency.actions';
import { NotificationEventService } from '../../notifications';
@Injectable()
export class PhotographyAgencyEffects  extends BaseEffects {
  constructor(private actions$: Actions,
    private store: Store<any>,
    private photographerService: PhotographerService,
    eventService: NotificationEventService,
    errorHandler: GlobalErrorHandler) {
    super(errorHandler, eventService);
  }

  @Effect({ dispatch: false })
  getAllPhotographyAgencies: Observable<PhotoAgency[]> = this.actions$.pipe(
    ofType<GetAllPhotographyAgencies>(PhotographyAgencyActionTypes.GetAllPhotographyAgencies),
    switchMap((action) => {
      return this.photographerService.retrievePhotographers().pipe(
        catchError(err => this.processCatchError(PhotographyAgencyActionTypes.GetAllPhotographyAgencies, action.payload, err))
      )
    }),
    map((photoAgencies: PhotoAgency[]) => {
      this.store.dispatch(new GetAllPhotographyAgenciesComplete(photoAgencies));
      return photoAgencies;
    })
  );
}
