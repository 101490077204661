import { Deserializable } from './deserializable.model';
import { Listing } from './listing.model';
import { PackageInstance } from './package-instance.model';
import { Audit } from '../models/audit.model';

export class AllOrders implements Deserializable<AllOrders> {
  _id: string;
  agent: {
    firstName: string;
    lastName: string;
    photoUrl: string;
  };

  coordinator: {
    id: string;
    firstName: string;
    lastName: string;
    photoUrl: string;
  };

  listing: Listing;
  submittedDate: Date;
  package: PackageInstance;
  area: string;
  noteCount: number;
  isFlagged: boolean;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): AllOrders {
    Object.assign(this, input);
    if (input.listing) {
      this.listing = new Listing(input.listing);
    } if (input.package) {
      this.package = new PackageInstance(input.package);
    }

    return this;
  }
}
