import { ValidatorFn, AbstractControl } from '@angular/forms';

export function instagramUrlValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if(!control.value) {
      return null;
    }

    // Use Regex101 to test expressions: https://regex101.com/
    // This pattern not does require the http protocol, which goes against most uriValidators.
    // Need to maintain this regex across services if necissary
    const urlPattern = /^https?:\/\/(www\.)?instagram\.com\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/i;
    const isAcceptedUrl = urlPattern.test(control.value);

    return isAcceptedUrl ? null : {'url': true};
  };
}
