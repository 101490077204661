import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { FeatureFlags, LaunchDarklyService } from '@lc/core';
import { Subscription } from 'rxjs';

/**
 * This is a structural directive that displays/hides elements based on a given feature flag.
 * i.e. - <button *lcFeatureFlag="show-button-feature"></button>
 */
@Directive({
  selector: '[lcFeatureFlag]'
})
export class FeatureFlagDirective implements OnChanges, OnDestroy {
  private isShowing: boolean;
  private subscription: Subscription;

  /** The feature flag this control requires */
  @Input('lcFeatureFlag') flag: FeatureFlags;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly featureFlagService: LaunchDarklyService) { }

  /** Listens for Input changes */
  ngOnChanges(changes: SimpleChanges) {
    if(changes.flag && this.flag) {
      // If the flag value changes, update the subscription
      this.subscription = this.featureFlagService.isFeatureEnabled$(this.flag).subscribe(
        isEnabled => isEnabled ? this.show() : this.hide(),
        () => this.hide()
      )
    }
  }

  /** Executes when the directive is destroeid */
  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  /** Shows the element this directive is applied to */
  private show() {
    if(this.isShowing){ return; }
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.isShowing = true;
  }

  /** Hides the element this directive is applied to */
  private hide() {
    if(!this.isShowing){ return; }
    this.viewContainer.clear();
    this.isShowing = false;
  }
}
