import { Component, Input, ViewChild, AfterViewInit, TemplateRef, ContentChild, EventEmitter, Output, ViewChildren, QueryList,SimpleChanges,OnChanges, OnInit } from '@angular/core';
import { CdkDropList, CdkDragMove, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/overlay';

export interface DragItem {
  disabled?: boolean;
}
/**
 * This drag and drop component was written for the use of centralizing the functionality used for a grid-like
 * drag and drop component.
 * Note: The CDK's Drag and Drop does not yet support grids and is still an open bug with angular: https://github.com/angular/components/issues/13372
 * This drag and drop implements the work around as specified here: https://github.com/angular/components/issues/13372#issuecomment-483998378
 * (...and everyone celebrated)
 */
@Component({
  selector: 'lc-drag-n-drop-cdk',
  templateUrl: './drag-n-drop-cdk.component.html',
  styleUrls: ['./drag-n-drop-cdk.component.scss']
})
export class DragNDropCdkComponent implements AfterViewInit, OnChanges, OnInit {
  @Input()
  canDrag: boolean = true;

  @Input()
  canDrop: boolean = true;

  @Input()
  containerClass: string;

  @Input()
  itemClass: string;

  @ContentChild(TemplateRef)
  itemTemplate: TemplateRef<any>;

  @Input()
  items: any[];

  @Input()
  additionalDrops: CdkDropList[];

  @Input()
  verticalScrollbar;

  @Output()
  readonly reorder: EventEmitter<any[]> = new EventEmitter();

  @ViewChild(CdkDropList)
  placeholder: CdkDropList;

  @ViewChildren(CdkDropList)
  drops: QueryList<CdkDropList>;

  dropLists: CdkDropList<any>[];

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  public activeContainer;
  public gridPlaceholder: boolean = true;

  constructor(private viewportRuler: ViewportRuler) {
    this.target = null;
    this.source = null;
  }
  ngOnInit(): void {
    if(this.itemClass === 'manage-photo-grid'){
      this.gridPlaceholder = false;
    }
  }

  /*
  * Default ngOnChanges lifecycle hook for every simple chnage.
  */
  ngOnChanges(changes:SimpleChanges){
    if(changes?.itemClass?.currentValue){
        this.dropListDropped();
    }

  }

  ngAfterViewInit() {
    this.drops.changes.subscribe(drops => this.dropLists = this.drops.toArray(), (error) => { throw new Error(error); })
    const phElement = this.placeholder.element.nativeElement;

    phElement.style.display = 'none';
    phElement.parentElement.removeChild(phElement);
  }

  dragMoved(e: CdkDragMove) {
    const point = this.getPointerPositionOnPage(e.event);

    this.drops.forEach(dropList => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }

  hidePlaceholder() {
    const phElement = this.placeholder.element.nativeElement;
    phElement.style.display = 'none';
  }

  dropListDropped() {
    if (!this.target){
      return;
    }

    const phElement = this.placeholder.element.nativeElement;
    const parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

    this.target = null;
    this.source = null;

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.items, this.sourceIndex, this.targetIndex);
    }
    this.reorder.emit(this.items);
  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop === this.placeholder)
      return true;

    if (drop !== this.activeContainer)
      return false;

      if(!this.canDrop) {
        return false;
      }

    const phElement = this.placeholder.element.nativeElement;
    const sourceElement = drag.dropContainer.element.nativeElement;
    const dropElement = drop.element.nativeElement;

    const dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
    const dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
      ? dropElement.nextSibling : dropElement));

    //TODO: [LC-3586] Fix deprecated 'enter' before Angular v.10
    this.placeholder.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
    return false;
  }

  /** Determines the point of the page that was touched by the user. */
  getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
        const scrollPosition = this.viewportRuler.getViewportScrollPosition();

        return {
            x: point.pageX - scrollPosition.left,
            y: point.pageY - scrollPosition.top
        };
    }

}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
};

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
  const {top, bottom, left, right} = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}
