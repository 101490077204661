<div class="editor" id="editor-control-value"
     [class.disabled]="disabled"
     [ngClass]="!setBorder ? '' : isHTMLOverflow ? 'danger-border' : ''"
     (clickOutside)="onClickedOutside($event)"
     >
  <quill-editor
    id="quill-editor-value"
    #myEditor
    [formats]="quillFormats"
    [format]="quillFormat"
    [styles]="{'height': minimumHeight}"
    [(ngModel)]="value"
    [bounds]="'self'"
    (onSelectionChanged)="onBlur($event)"
    (onContentChanged)="onTextChange($event)"
    (onEditorCreated)="setFocus($event)"
    [class.invalid]="errors"
    [disabled]="disabled"
    [modules]="modules">
    <div quill-editor-toolbar>
      <div class="editable-toolbar">
        <div  class="editable-toolbar-inner" [ngClass]="{ 'hide-toolbar': !quillToolbarEnabled }">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex="noshrink" class="toolbar-buttons">
            <div class="ql-formats quill-editor-options">
              <div *ngIf="!isHeader">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>

                <button class="ql-list" value="ordered" type="button"></button>
                <button class="ql-list" value="bullet" type="button"></button>

                <select class="ql-align">
                  <option selected></option>
                  <option value="center"></option>
                  <option value="right"></option>
                </select>

                <button class="ql-link"></button>
                <button class="summarize-button" *ngIf="statistics && statistics.maximum && shouldHaveSummarizeButton"
                        (click)="onSummarizeClicked()"
                        [disabled]="hasSummarized">
                  <mat-icon class="summarize">auto_awesome</mat-icon>
                </button>
              </div>
            </div>

          </div>
          <div *ngIf="statistics && statistics.maximum">
            <div fxLayout="row">
              <div class="approx-text" [ngStyle]="{'color': statistics.percentage >= maxPercentage ? '#de342b' : '#000'}">approx {{statistics.maximum}} chars </div>
              <div fxFlex="none" class="mr-1">
                <mat-progress-spinner mode="determinate" [value]="statistics.percentage" class="spinner-right"
                                      [color]="statistics.percentage >= maxPercentage ? 'warn': 'primary'" [diameter]="spinnerDiameter"></mat-progress-spinner>
              </div>
            </div>

          </div>
        </div>
      </div>
      </div>
    </div>
  </quill-editor>
</div>
