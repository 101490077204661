
import { emailValidator, noWhitespaceValidator } from '../validators';
import { BaseForm } from './base.form';
import { FormControl, Validators } from '@angular/forms';
import { PhotograperDetails } from '../models/photographer-details.model';
import { PhoneNumberForm } from './phone-number.form';
import { PhoneNumber } from '../models/phone-number.model';
import { StringFormControl } from './form-controls/string-form-control';

export class PhotographerDetailsForm extends BaseForm {
  constructor(value?: PhotograperDetails) {
    super({
      firstName: new StringFormControl('', [Validators.required, noWhitespaceValidator()]),
      lastName: new StringFormControl('', [Validators.required, noWhitespaceValidator()]),
      email: new FormControl('', [Validators.required, emailValidator()]),
      username: new FormControl(),
      phoneNumber:  new PhoneNumberForm(null, {validateType: false, phoneNotRequired: true }),
      //mobilePhone: new FormControl('')
    });

    if(value) {
      if(!value.phoneNumber){
       value.phoneNumber =new PhoneNumber();
      }
      this.patchValue(value);
    }
    // if(value && value.phoneNumber){
    //
    //   const phoneNumbers = (value.phoneNumber as Array<PhoneNumber>) || [];
    //   if(phoneNumbers) {
    //     phoneNumbers.forEach(phoneNumber => {
    //       (this.get('phoneNumber') as FormArray).push(
    //         new PhoneNumberForm(phoneNumber)
    //       )
    //     });}
    //
    //   // const mobileTypePhone = value.phoneNumber.find(eachPhone => eachPhone.type === 'mobile')
    //   // if (mobileTypePhone)
    //   // this.controls.mobilePhone = new FormControl(mobileTypePhone.number);
    // }
  }
}
