import { Component, OnDestroy,OnInit } from '@angular/core';
import { NotificationEvent, NotificationEventService, NotificationEventTypes, USERSESSION, AppService, LaunchDarklyService, UserService, User, UserSettingsService, FeatureFlags } from '@lc/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { Router, Event, NavigationStart } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'lc-app-layout-without-sidenav',
  templateUrl: './layout-without-sidenav.component.html',
  styleUrls: ['./layout-without-sidenav.component.scss']
})
export class LayoutWithoutSidenavComponent implements OnDestroy, OnInit {
  //Notification subscription object
  private errorNotificationSubscription: Subscription;

  //Notification subscription object
  private snackbarSubscription: Subscription;


  // Snack bar Reference
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  //Error message ins snack bar
  private errorMessage: string = 'Error in processing the request.';

  public sessionTimeCounter: boolean = false;

  private timeLeft: number = 0;

  private setIntervalForExpiryTime:any;

  private sessionInfoSubscription: Subscription;

  public readonly maintenanceMode$: Observable<boolean>;
  public readonly messages$: Observable<any[]>;
  public readonly theme$ : Observable<boolean>;
  public isPluginDarkTheme: boolean = false;

  /**
   *
   * @param layoutService
   * @param _snackBar
   * @param notificationEventService
   * @param router
   */
  constructor(private overlayContainer: OverlayContainer,
              private _snackBar: MatSnackBar,
              private notificationEventService: NotificationEventService,
              private launchDarklyService: LaunchDarklyService,
              private userService: UserService,
              private userSettingsService: UserSettingsService,
              private router: Router,
              private store: Store<any>) {
    this.subscribeErrorNotifications();
    this.subscribeSnackBar();
    this.subscribeRouterEvents();
    this.messages$ = this.getBannerMessages$();
    this.maintenanceMode$ = this.getMaintenanceMode$();
    this.theme$ = this.getTheme();

  }

  /** Subscribes to the app maintenance and system maintenance modes */
  private getMaintenanceMode$() {
    const showFeature1 = this.launchDarklyService.getFeature$('perm-maintenance', false);
    const showFeature2 = this.launchDarklyService.getFeature$(`perm-${AppService.app}-maintenance`, false);
    return combineLatest([showFeature1, showFeature2]).pipe(map(features => features.some(feature => feature)));
  }

  /** Subscribes to the app banner and system banner messages */
  private getBannerMessages$() {
    const permBannerMessages = this.bannerMessages$('perm-banner');
    const permAppBannerMessages = this.bannerMessages$(`perm-${AppService.app}-banner`);
    return this.userService.currentUser.pipe(
      switchMap(() => combineLatest([permAppBannerMessages, permBannerMessages ])),
      map(([appBannerMessages, bannerMessages]) => appBannerMessages.concat(bannerMessages))
    );

  }

  private bannerMessages$(featureName: string): Observable<any[]> {
    return this.launchDarklyService.getFeature$(featureName, null).pipe(
      map(feature => {
        if(feature == null) {
          return []; // If feature is null, return no messages
        }

        const preferenceValue = this.userService.getPreferenceValue(featureName);
        let dismissedIds: any;
        if (preferenceValue) {
          let cleanup = false;
          Object.keys(preferenceValue).forEach(function(key) {
            if (key === feature.version.toString()) {
              dismissedIds = preferenceValue[key];
            } else {
              // delete old versions
              delete preferenceValue[key];
              cleanup = true;
            }
          });
          if (cleanup) {
            this.userService.setPreference(featureName, null, preferenceValue);
          }
        }
        dismissedIds = (dismissedIds || '').split(',');
        const messages: any[] = (feature.messages || []).filter((m: any) => !dismissedIds.includes(m.id.toString()));
        messages.forEach((m: any) => {
          m.featureName = featureName;
          m.version = feature.version;
        });
        messages.sort((a: any, b: any) => {
          // non-dismissible is always last
          if (!a.dismissible) return 1;
          else if (!b.dismissible) return -1;
          else {
            // sort by severity high to low
            return this.severity(b) - this.severity(a);
          }
        });
        return messages;
      })
    );
  }


  getTheme(){
    const user$ = this.userService.currentUser.pipe(filter((user: User) => user != null));
    return user$.pipe(
      switchMap((user) => this.userSettingsService.getUserSettings$(user._id)),
      map(theme => theme.isDarkTheme),
      tap(theme => {
        if(theme) {
          this.overlayContainer.getContainerElement().classList.add('dark-theme');
        } else {
          this.overlayContainer.getContainerElement().classList.remove('dark-theme')
        }
      })
    );
  }

  severity(message: any) {
    return message.severity === 'low' ? 0 : (message.severity === 'medium' ? 1 : 2);
  }

  dismiss(message: any) {
    message.dismissed = true;
    // add to user preferences
    let dismissedIds = this.userService.getPreferenceValue(message.featureName + '.' + message.version);
    if (dismissedIds) {
      dismissedIds += ',' + message.id;
    } else {
      dismissedIds = message.id.toString();
    }
    this.userService.setPreference(message.featureName, message.version.toString(), dismissedIds);
  }

  /**
   * Method for Error Notification subscription
   */
   subscribeErrorNotifications() {

    this.errorNotificationSubscription = this.notificationEventService.getEventEmitter()
      .subscribe(
        (event: NotificationEvent) => {
          // Display the snackbar with the corresponding toolbar color: https://stackoverflow.com/a/58934045
          if (event.name === NotificationEventTypes.APIERROR) {
            this.errorMessage = event.message
              ? event.message
              : 'Error in processing the request.';
            this.snackBarRef = this._snackBar.open(this.errorMessage, 'DISMISS' ,{
              duration: 10000,
              panelClass: ['mat-toolbar', 'mat-warn']
            });

          }
          else if (event.name === NotificationEventTypes.INFO) {
            this.snackBarRef = this._snackBar.open(event.message, 'DISMISS' ,{
              duration: 5000,
              panelClass: ['mat-toolbar', 'mat-default']
            });
          } else if (event.name === NotificationEventTypes.ERROR) {
            this.snackBarRef = this._snackBar.open(event.message, 'DISMISS' ,{
              duration: 5000,
              panelClass: ['mat-toolbar', 'mat-warn']
            });
          }
        }, (error) => { throw new Error(error); }
      )
  }

  startSessionExpiryTimer() {
    this.sessionInfoSubscription = this.store.select(USERSESSION).subscribe( userSession =>{
      if (userSession && userSession.sessionGoingToExpiry) {
        const currentTime = new Date()
        const expiryTime = new Date(userSession.expiryAt * 1000);
        const tokenLiveTime = expiryTime.valueOf() - currentTime.valueOf();
        this.timeLeft = Math.floor(tokenLiveTime/1000) - 30;
        if (this.timeLeft > 0) {
          this.sessionTimeCounter = true;
          this.setIntervalForExpiryTime = setInterval(() => {
            if (tokenLiveTime > 0) {
              this.timeLeft--;
            } else {
              this.timeLeft = tokenLiveTime / 1000;
            }
          }, 1000)
        }
      } else if(userSession && !userSession.sessionGoingToExpiry) {
          this.sessionTimeCounter = false;
          if(this.setIntervalForExpiryTime) clearInterval(this.setIntervalForExpiryTime);
          if (this.sessionInfoSubscription) this.sessionInfoSubscription.unsubscribe();
      }
    }, (error) => { throw new Error(error); })
  }
  /**
   * Method for snackbar subscription
   */
  subscribeSnackBar() {

    if(this.snackBarRef) {
      this.snackbarSubscription = this.snackBarRef.onAction()
        .subscribe(() => {
          this.snackBarRef.dismiss()
        }, (error) => { throw new Error(error); });
    }
    }

  /**
   * Method for router events subscription
   */
  subscribeRouterEvents() {

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (this.snackBarRef) this.snackBarRef.dismiss();
      }
    }, (error) => { throw new Error(error); });

  }

  ngOnInit(): void {
    if (window.location.pathname.includes('/indesign/intake')) {
      this.isPluginDarkTheme = true;
    }
    this.startSessionExpiryTimer();
  }

  /**
   * Method in Angular lifecycle
   */
  ngOnDestroy() {
    if (this.errorNotificationSubscription) {
      this.errorNotificationSubscription.unsubscribe();
    }

    if(this.snackbarSubscription) {
      this.snackbarSubscription.unsubscribe();
    }

    if (this.sessionInfoSubscription){
      this.sessionInfoSubscription.unsubscribe();
    }

  }


}
