
<!--
  Container for the photo-gallery-dialog:
    fxFlexFill:
      Takes up the entire space allocated to this dialog. Necissary for the dialog to maintain the same size regardless of photo's aspect ratio
    fxLayout="column"
      Necessary for making the modal-body take up a designated amount of space in order to maintain dialog size
    (keydown) events:
      Handles scrolling with the dialog
-->
<lc-base-dialog id="photo-gallery-dialog" fxFlexFill [title]="title" (actionClicked)="onClose()"  (keydown.arrowleft)="prevSlide()" (keydown.arrowright)="nextSlide()" >

  <!--Modal Body-->
  <ng-template #content>
    <div class="photo-gallery" *ngIf="photos" fxFlex="0 0 70%" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
      <!-- Left Scroll Button -->
      <button class="back" fxFlex="none" mat-icon-button (click)="nextSlide()">
        <mat-icon>chevron_left</mat-icon>
      </button>

      <!-- Carousel Container-->
      <div [@slideAnimation]="currentIndex">
        <ng-template ngFor [ngForOf]="photos" let-slide let-i="index">
          <img class="slider slide-animation nonDraggableImage"
              *ngIf="isCurrentSlideIndex(i)"
                (swiperight)="prevSlide()" (swipeleft)="nextSlide()"
                [src]="slide.uri">
        </ng-template>
      </div>

      <!-- Right Scroll Button -->
      <button class="forward" fxFlex="none" mat-icon-button (click)="prevSlide()">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </ng-template>

  <ng-template #actions>
    <div *ngIf="photos" fxLayout="row" fxLayoutAlign="center">
      <span class="font-weight-bold photo-index">Photo {{selectedIndex + 1}} of {{photos.length}}</span>
    </div>
  </ng-template>
</lc-base-dialog>
