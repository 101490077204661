import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService, AppService } from '@lc/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'lc-app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  @Input() title: string;
  readonly appName: string = AppService.get('applicationName');

  isForcedLogout: boolean = false;

  constructor(
    public authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle(this.titleService.getTitle() + ' - Logout');
    this.activatedRoute.queryParamMap
      .subscribe(params => {
        this.isForcedLogout = params.get('forcedLogout') ? true : false;
        this.authService.logout();
      }, (error) => { throw new Error(error); });
  }

  login() {
    this.authService.redirectToLogin();
  }
}
