import { Component, Inject } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { FieldVersionService, FieldVersionsData } from '@lc/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lc-field-version',
  templateUrl: './field-version.component.html',
  styleUrls: ['./field-version.component.scss']
})
export class FieldVersionComponent {
  private readonly _close = new Subject();
  readonly close = this._close.asObservable();
  displayedColumns: string[] = ['modifiedTime','userFullName', 'key', 'value'];
  readonly fieldVersions$: Observable<any>;

  constructor(private fieldVersionService: FieldVersionService,
    @Inject(MAT_DIALOG_DATA) public data: FieldVersionsData) {

    this.fieldVersions$ = this.fieldVersionService.create$(data).pipe(
      map(returnVersions => returnVersions))
  }

  onClose() {
    this._close.next();
  }
}
