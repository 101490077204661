import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export class PdfPreviewDialogData {
  constructor(public readonly pdfUrl: string){
  }
}

@Component({
  selector: 'lc-pdf-preview',
  templateUrl: './pdf-preview.dialog.component.html',
  styleUrls: ['./pdf-preview.dialog.component.scss']
})
export class PdfPreviewDialogComponent {
  readonly url: SafeUrl;

  constructor(
    sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<PdfPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: PdfPreviewDialogData) {
      this.url = sanitizer.bypassSecurityTrustResourceUrl(data?.pdfUrl);
  }
}
