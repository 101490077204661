// tslint:disable: rxjs-no-sharereplay
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { merge, Observable, Subject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { MarketingOrder, Pricing } from '../models';
import { Coupon } from '../models/coupon.model';
import { UpdateCurrentOrderState } from '../state-mgmt';
import { ApiService } from './api.service';

/**
 * This service handles the logic required to manage coupons on a marketing order.
 */
@Injectable({
  providedIn: 'root'
})
export class MarketingOrderCouponService {

  resource = 'marketing-orders';

  v2Resource = 'orders';

  /** Coupon Updates stores Subjects that can fire off an update to those subscribed to the couponCache */
  private readonly couponUpdates: {[marketingOrderId: string]: Subject<Coupon[]> } = {};

  constructor(private readonly apiService: ApiService, private readonly store: Store<any>) { }

  /** Retrieves the coupons for the given marketing order. Will only fetch once per request  */
  getCoupons$(marketingOrderId: string): Observable<Coupon[]> {
    if(!this.couponUpdates[marketingOrderId]) {
      // Initialize the Subject that can be used to force the UI to update without re-calling the API
      this.couponUpdates[marketingOrderId] = new Subject<Coupon[]>();
    }

    // Return the cached observable
    return merge(
      this.couponUpdates[marketingOrderId],
      this.apiService.getV2<Coupon[]>(`${this.v2Resource}/${marketingOrderId}/coupons`).pipe(
        map(coupons => coupons.map(coupon => new Coupon(coupon))),
        shareReplay(1)
      )
    );
  }

  /** Applies a coupon to a given marketing order  */
  async addCoupon(marketingOrderId: string, code: string): Promise<Pricing> {
    // Make the request, but suppress the errors in the log as we expect to get errors if the coupon is not found, etc...
    return await this.apiService.post<Coupon[]>(`marketing-orders/${marketingOrderId}/coupons`, {code}, {supressLogErrors: true}).pipe(
      map(pricing => new Pricing(pricing)),
      tap(pricing => this.updateCoupons(marketingOrderId, pricing)),
    ).toPromise();
  }

  /** Deletes a coupon from the given marketing order */
  async removeCoupon(marketingOrderId: string, couponCode: string): Promise<Pricing> {
    return await this.apiService.delete<Pricing>(`marketing-orders/${marketingOrderId}/coupons/${couponCode}`).pipe(
      map(pricing => new Pricing(pricing)),
      tap(pricing => this.updateCoupons(marketingOrderId, pricing)),
    ).toPromise();
  }

  /** Triggers an udpate to the current observables if they are subscribed to */
  private updateCoupons(marketingOrderId: string, pricing: Pricing) {
    if(this.couponUpdates[marketingOrderId]) {
      this.couponUpdates[marketingOrderId].next(<any>pricing.coupons);
    }
    const marketingOrderUpdates = { _id: marketingOrderId, pricing: pricing } as MarketingOrder;;
    this.store.dispatch(new UpdateCurrentOrderState(marketingOrderUpdates, true));
  }
}
