import { Component, OnInit, Input, EventEmitter, SimpleChanges, OnChanges, Output } from '@angular/core';
import { Subscription, Observable, isObservable, of } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MultiSelectDisplayOptions } from '../../../inputs/multiselect/multiselect.component';
import { DateOption } from './models/date-option';
import { OrderFiltersForm, OrderFilters } from './models/order-filters';
import { ProfileInfo } from '@lc/core';

@Component({
  selector: 'lc-order-filters',
  templateUrl: './order-filters.component.html',
  styleUrls: ['./order-filters.component.scss']
})
export class OrderFiltersComponent implements OnInit, OnChanges {
  readonly areaDisplayOptions = new MultiSelectDisplayOptions('Area', 'Areas', true);
  readonly statusDisplayOptions = new MultiSelectDisplayOptions('Status', 'Statuses', true);
  readonly dateDisplayOptions = new MultiSelectDisplayOptions('Dates', 'Dates', true);
  readonly assignmentDisplayOptions = new MultiSelectDisplayOptions('Coordinator', 'Coordinators', true);

  @Input()
  form: OrderFiltersForm;

  @Input()
  areas: string[];

  @Input()
  hasListing: Observable<Boolean>;

  @Output()
  readonly filter: EventEmitter<OrderFilters> = new EventEmitter<OrderFilters>();

  private orderChangesSub: Subscription
  private initialCount = 0;
  intitalFlag = false;

  constructor() { }

  ngOnInit(): void {
    this.initialCount = this.form.selectedAreas.length;
    if(this.initialCount === 0){
      this.intitalFlag = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.form) {
      this.subscribeToFormChanges();
    }
  }

  onAreaClosed(event) {
    this.initialCount = this.form.selectedAreas.length;
    if(this.initialCount > 0){
      this.intitalFlag = false;
    }
    if(!event && !this.intitalFlag){
      if(this.form.areas.dirty) {
        this.onFilter();
        this.form.getControl('areas').markAsPristine();
      }
    }
    
  }

  onStatusClosed() {
    if(this.form.getControl('status').dirty) {
      this.onFilter();
      this.form.getControl('status').markAsPristine();
    }
  }

  onAssignmentsClosed() {
    if(this.form.getControl('assignments').dirty) {
      this.onFilter();
      this.form.getControl('assignments').markAsPristine();
    }
  }

  onDateRangeClicked(event, option: DateOption) {
    const wasDateClicked = event.srcElement.getAttribute('class').indexOf('custom-day') > -1;
    if(wasDateClicked && option.startDate && option.endDate) {
      this.form.selectedDateOption = option;
      this.dateSelectionChanged();
    } else {
      event.stopPropagation()
    }
  }

  dateSelectionChanged() {
    this.onFilter();
  }


  public onAgentsSelected(agents: ProfileInfo[]) {
    const agentIds = agents.map(agent => agent.userId);
    this.form.coordinatorIds.setValue([]);
    this.form.agentIds.setValue(agentIds);
    this.onFilter();
  }

  public onCoordinatorsSelected(coordinators:ProfileInfo[]){
    const coordinatorIds = coordinators.map(coordinator => coordinator.userId);
    this.form.agentIds.setValue([]);
    this.form.coordinatorIds.setValue(coordinatorIds);
    this.onFilter();
  }

  private onFilter() {
    this.filter.emit(this.form.value);
  }

  private subscribeToFormChanges() {
    if(this.orderChangesSub && !this.orderChangesSub.closed) {
      this.orderChangesSub.unsubscribe();
    }
    // NOTE: We can also watch the entire form changes, but that doesn't work well with the multiselect closures
    this.orderChangesSub = this.form.search.valueChanges
      .pipe(debounceTime(500)) // 500 millisecond debounce time
      .subscribe(value$ => this.onFilter(), err => { })
  }

}
