import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from "./api.service";
import { PackageInstance } from '../models/package-instance.model';
import { map } from 'rxjs/operators';

@Injectable()
export class PackageService {

  resource = 'packages';

  constructor(private apiService: ApiService) {
  }

  getAllAvailablePackages(listingZipCode?: string): Observable<PackageInstance[]> {
    const params = this.apiService.buildParams({'zip': listingZipCode}); //HACK
    return this.apiService.get<PackageInstance[]>('package-instances/my-packages', params)
      .pipe(map(packages => packages.map(pkg => new PackageInstance(pkg))));
  }

  getPackages(): Observable<PackageInstance[]> {
    return this.apiService.get<PackageInstance[]>('package-instances/packages')
      .pipe(map(packages => packages.map(pkg => new PackageInstance(pkg))));
  }

}
