import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NotesClient } from '../clients/notes.client';
import { NoteForm } from '../forms/note.form';
import { Note } from '../models/note.model';

/**
 * The NotesService is a layer between the components and the API Clients.
 * The purpose of the service is to handle business logic while interacting with the
 * clients. This eliminates the components from needing to handle business logic.
 * All business logic should be done here
 */
@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(private readonly noteClient: NotesClient) { }

  /**
   * Loads the notes belonging to a specific owner. Owners can be marketingOrderIds,
   * userIds, etc.
   * @param ownerId The ownerId that we are looking up notes from
   */
  getNotesFor(ownerId: string): Observable<Note[]> {
    // Note: If we want to do caching or utilize the ngrx store, do it here
    return this.noteClient.get({ownerId: ownerId});
  }


  /**
   * Submits a notification to the API
   * @param form
   */
  create(form: NoteForm): Observable<Note>  {
    return this.noteClient.post(form);
  }
}
