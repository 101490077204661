import { ValidatorFn, AbstractControl } from '@angular/forms';
import { CustomValidator } from './custom.validator';

/**
 * Returns validation errors if the formControl consists of only spaces
 */
export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const isEmpty = (CustomValidator.controlValueAsString(control).length === 0);
    return isEmpty ? { 'whitespace': true } : null
  };
}
