import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public authService: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let cleanUrl;

    // Clean the Url (per https://stackoverflow.com/a/53839424)
    // Only clean the Url if its NOT the one we use for the CORS proxy we use.
    // https://cors-anywhere.herokuapp.com/
    if(req.url.indexOf('herokuapp.com') > -1) {
      cleanUrl = req.url;
    } else {
      cleanUrl = this.formatUrl(req.url);
    }

    let request = req.clone({ url: cleanUrl });

    const isAws = (request.url.indexOf('amazonaws.com') > 0);
    if (this.authService.isAuthenticated() && !isAws) {
      request = request.clone({
        setHeaders: this.authService.getHeaderOptions()
      });
    }

    return next.handle(request).pipe(
      tap(
        event => event instanceof HttpResponse ? 'succeeded' : '',
        async error => {
          if (error instanceof HttpErrorResponse) {
            if(error.status === 401) {
              console.log('Received a 401 response. Logging out', error);
              await this.authService.logout();
            }
          }
        })
    );
  }
  /** Ensures URL does not have any double or tripple slashes other than after http:|https: */
  private formatUrl(url: string) {
    // Regex taken from: https://stackoverflow.com/a/22173901
    const indexAfterProtocol = url.indexOf('//') + 1;
    const protocol = url.substring(0, indexAfterProtocol);
    const urlAfterProtocol = url.substring(indexAfterProtocol);
    return protocol + urlAfterProtocol.replace(/\/+/g, '/');
  }
}
