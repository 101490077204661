import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FlexLayoutModule } from '@angular/flex-layout'
const angularModules = [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, FlexLayoutModule, DragDropModule, MatSnackBarModule];

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgxLoadingModule } from 'ngx-loading';
const uiModules = [NgxLoadingModule, NgbModule, MatIconModule, MatButtonModule];

import { CoreModule } from "@lc/core";
import { UILayoutModule } from "@lc/ui";
import { LayoutWithoutSidenavComponent } from './layout/layout-without-sidenav/layout-without-sidenav.component';

import { ContactInfoComponent } from './layout/contact-info/contact-info.component';
import { BannerComponent } from './layout/banner/banner.component';
import { DragNDropComponent } from './layout/drag-n-drop/drag-n-drop.component';
import { DragNDropCdkComponent } from './layout/drag-n-drop-cdk/drag-n-drop-cdk.component';
const components = [ ContactInfoComponent, BannerComponent, DragNDropComponent, DragNDropCdkComponent ];

@NgModule({
  imports: [
    ...angularModules,
    ...uiModules,
    CoreModule,
    UILayoutModule
  ],
  declarations: [
    LayoutWithoutSidenavComponent,
    ...components,
  ],
  exports: [
    ...components
  ],
  providers: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class SharedComponentsLayoutModule {}
