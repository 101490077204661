import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from './app.service';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

export enum operation { // for field version api options
  RETRIEVE = 'retrieve'
}
export class FieldVersionsRes {
  modifiedTime: number;
  userFullName: string;
  key: string;
  value: any;

  constructor(model?: Partial<FieldVersionsRes>) {
    if (model) {
      Object.assign(this, model);
    }
  }
}

export class FieldVersionsData {
  constructor(readonly marketingOrderId: string, readonly model: any, readonly highlevelKey?: string) { }
}
/**
 * The FieldVersionService is a layer between the components and the API Clients.
 * The purpose of the service is to handle business logic while interacting with the
 * clients. This eliminates the components from needing to handle business logic.
 * All business logic should be done here
 */
@Injectable({
  providedIn: 'root'
})
export class FieldVersionService {
  batchProcessBaseURL: string = AppService.get('batchProcessBaseURL');
  highlevelKey: string = '';
  fieldData: any = [];
  fieldVersionsKeys: any

  constructor(private apiService: ApiService) { }
  /**
   * Submits a notification to the API
   * @param data
   */
  create$(data: FieldVersionsData): Observable<FieldVersionsRes[]> {
    this.highlevelKey = data.highlevelKey;
    const obj = this.createRequestObject(data);
    return this.apiService.post(`${this.batchProcessBaseURL}/jobs/version-fields`, obj).pipe(
      map(res => this.getFieldVersionKeys(res)));
  }

  /**
   * Builds Request payload for version-fields API
   * if we pass the Listing model and the key 'listing', we get a result like:
   * { listing: { key, value } } And if we don't pass a key we get a result like:
   * { key: value }
   */
  retrieveVersionFields(model: any, highlevelKey?: string) {
    const keys = Object.keys(model);
    let payload: any = {};
    keys.forEach(key => {
      if (model[key] && Array.isArray(model[key])) {
        payload[key] = { 0: 1 };
      } else if (model[key] && typeof model[key] === 'object' && !(model[key] instanceof Date)) {
        payload[key] = this.retrieveVersionFields(model[key]);
      } else {
        payload[key] = 1;
      }
    })
    if (highlevelKey) {
      const payloadl = payload;
      payload = {};
      payload[highlevelKey] = payloadl;
    }
    return payload;
  }


  /**
   * Updates Request payload for version-fields API
   */
  createRequestObject(data: FieldVersionsData) {
    const payload = this.retrieveVersionFields(data.model, data.highlevelKey)
    const requestObject = {
      options: {
        operation: operation.RETRIEVE,
        _id: data.marketingOrderId,
        change: {
          details: payload
        }
      }
    }
    return requestObject;
  }

  /**
   * Formats Version-field api response to display in table
   */
  getFieldVersionKeys(res) {
    this.fieldData = [];
    const fieldVersions = (this.highlevelKey) ? res[this.highlevelKey] : res;
    this.fieldVersionsKeys = Object.keys(fieldVersions);
    return this.getFieldVersion(fieldVersions);
  }

  /**
   * Sorts based on date, updates the formatted api response with key
   * and returns formatted res
   */
  getFieldVersion(res) {
    this.fieldVersionsKeys.forEach((key: any) => {
      if (key && Array.isArray(res[key])) {
        this.buildFieldHistory(res[key], key)
      } else if (key && typeof res[key] === 'object') {
        Object.keys(res[key]).forEach(keyField => {
          this.buildFieldHistory(res[key][keyField], keyField)
        })
      }
    })
    // Default sorting base on modifiedTime
    this.fieldData.sort((a, b) => {
      const i: any = new Date((a.modifiedTime) * 1000);
      const j: any = new Date((b.modifiedTime) * 1000);
      return j - i;
    });
    return this.fieldData;
  }

  /**
   * Updates key name in formatted Api response
   */
  buildFieldHistory(res, keyName) {
    const fieldRes = (this.highlevelKey) ? res : res[0];
    fieldRes?.forEach(r => r.key = keyName)
    if (fieldRes) {
      this.fieldData = [...this.fieldData, ...fieldRes];
    }
  }

}
