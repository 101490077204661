// tslint:disable: rxjs-no-sharereplay
import { Injectable } from '@angular/core';
import { PrintOrder, PrintOrderCancelResult, VendorOrderStatus, VendorServiceOrder, MarketingOrder, ProductInstance } from '../models';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { PresignService } from './presign.service';
import { shareReplay, map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as snakeCase from 'lodash.snakecase';

@Injectable({
  providedIn: 'root'
})
export class PrintOrderService {
  private marketingOrderPrintOrdersCache: {[marketingOrderId: string]: Observable<PrintOrder[]>} = {};
  private readonly marketingOrderPrintOrderDetailsCache: {[marketingOrderId: string]: Observable<PrintOrder[]>} = {};

  constructor(private apiService: ApiService,
    private presignService: PresignService) {
  }

  getPrintOrders(marketingOrderId: string): Observable<PrintOrder[]> {
     if(!this.marketingOrderPrintOrdersCache[marketingOrderId]) { 
      const url = `printer/jobs/${marketingOrderId}`;
      this.marketingOrderPrintOrdersCache[marketingOrderId] = this.apiService.getPrintDetails(url).pipe(
        shareReplay(1)
      );
    } 

    return this.marketingOrderPrintOrdersCache[marketingOrderId];
  }

  clearPrintOrderCache() {
    this.marketingOrderPrintOrdersCache = {};
  }

  getPrintOrderDetails(marketingOrderId: string): Observable<PrintOrder[]> {
    /* if(!this.marketingOrderPrintOrderDetailsCache[marketingOrderId]) { */
      const url = `printer/jobs/${marketingOrderId}?includeVendorDetails=true`;
      const vendorOrder = this.apiService.getPrintDetails(url).pipe(
        shareReplay(1)
      );
      /* } */
    return vendorOrder;
  }

  /**
   * Retrieves Print Job Details for a specific product of the marketing order
   * @param marketingOrderId The marketingOrderId to lookup printJobs for
   * @param productCode The specific product code we are interested in
   */
  getProductPrintOrderDetails$(marketingOrderId: string, productCode: string): Observable<PrintOrder> {
    return this.getPrintOrderDetails(marketingOrderId).pipe(
      map(printJobs => (printJobs || []).find(printJob => printJob.productCode === productCode && printJob.status !== VendorOrderStatus.CANCELLED))
    );
  }

  async cancelPrintJob(vendorOrderId: string): Promise<PrintOrderCancelResult> {
    const url = `printer/cancel/${vendorOrderId}`;
    return await this.apiService.cancelPrintJob(url).pipe(
      map(data => data)
    ).toPromise();
  }

  public async downloadContacts(contactListUrl: string, order: MarketingOrder, product: ProductInstance) {
    const presignedUrl = await this.presignService.contactListPresignedUrl([contactListUrl]).pipe(map(presigned => presigned[0]?.presignedUrl)).toPromise();
    this.apiService.getJsonFile(presignedUrl).toPromise()
      .then(file => this.initiateDownload(file, order, product));
  }

  private initiateDownload(jsonContent: Array<any>, order: MarketingOrder, product: ProductInstance) {

    const title = snakeCase(product.title.toLowerCase());
    const filename = `distribution_list_${title}_${snakeCase(order.listing.address.streetAddress1)}.xlsx`.toLowerCase();

    const ws = XLSX.utils.aoa_to_sheet(jsonContent);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, filename);
  }
}
