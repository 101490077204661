import { Deserializable } from './deserializable.model';

export class Audit implements Deserializable<Audit> {

  createdAt: Date;
  createdBy: string;
  createdByFullName: string;
  updatedAt: Date;
  updatedBy: string;
  updatedByFullName: string;

  constructor( input?: any) {
    this.deserialize( input || {});
  }

  deserialize(input: any): Audit {
    Object.assign(this, input);
    this.createdAt = new Date(input.createdAt);
    this.updatedAt = new Date(input.updatedAt);
    return this;
  }
}
