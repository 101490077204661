import { Component, EventEmitter, forwardRef, Input, OnInit, OnChanges, Output } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  FormService,
  ProductCode,
  FeatureFlags,
  StringFormControl,
  LaunchDarklyService,
  ProductInstanceForm,
} from '@lc/core';
import { Observable } from 'rxjs';
import { InputField } from '../input-field';

@Component({
  selector: 'lc-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RichTextComponent),
      multi: true,
    }
  ]
})
export class RichTextComponent extends InputField implements OnChanges, OnInit {

  @Input() productForm: ProductInstanceForm;
  @Input() productCode: string;
  @Input() label: string;
  @Input() minimumHeight: string;
  @Input() placeholder = '';
  @Input() formControl: FormControl;
  @Input() hint: string;
  @Input() suffix: string;
  @Input() readonly: boolean;
  @Input() richTextStats: any;
  @Input() isHeader: boolean;
  @Input() isContact: boolean;
  @Input() format = 'html';
  @Input() richTextEnabled = true;
  @Input() toolbarEnabled = true;

  htmlEnabled$: Observable<boolean>;
  isRichTextActions: boolean = false;
  textFits: boolean = true;

  @Output()
  public readonly change = new EventEmitter<void>();

  @Output()
  public readonly stepOut = new EventEmitter<void>();

  readonly flags = FeatureFlags;

  initialEditorValue: string = '';
  editorValue: string = '';

  formSerivce: FormService;
  disableSave: boolean;

  constructor(
    sanitizer: DomSanitizer,
    formService: FormService,
    private launchDarklyService: LaunchDarklyService) {
    super(sanitizer);
    this.formSerivce = formService;
    this.htmlEnabled$ = this.launchDarklyService.getFeature$(FeatureFlags.HTML_PREVIEW_ENABLED, false);
  }

  ngOnChanges(changes) {
    if(changes.richTextStats) {

      const { currentValue: { percentage, maximum, current} } = changes.richTextStats;
      // Tactical number for worst-case scenario. One line of all `i` is about 350 characters,
      // we've added for 2 lines of 'i'
      const buffer = 700;
      const maxAndBuffer = maximum + buffer;
      if(percentage === 100 && current > maxAndBuffer) {
        this.formSerivce.disableFormElements(this.label);
      } else {
        this.formSerivce.enableAllForms();
      }
      this.textFits = (percentage <= 99)
    }
  }

  ngOnInit(): void {
    this.initialEditorValue = this.formControl.value;
    this.isRichTextActionsEnabled();
    this.formSerivce.formErrorDetected.subscribe( subscription => {
      this.disableSave = subscription.isDisabled;
    }, error => {
      throw new Error(`Error from form service in rich text editor: ${error}`)
    })
  }

  public onBlur() {
    if(!this.disableSave) {
      if(this.formControl && this.formControl instanceof StringFormControl) {
        this.formControl.onLostFocus();
      }
      this.stepOut.emit(this.formControl.value);
    }
  }

  public onTextChange(value) {
   this.value = value;
   this.editorValue = value;
 }

 onSave(){
  this.onBlur();
  this.executeOnChanged();
 }

 onCancel(){
  this.formControl.setValue(this.initialEditorValue);
  this.stepOut.emit(this.formControl.value);
  this.executeOnChanged();
 }

 isRichTextActionsEnabled(){
  if(this.productCode && this.productCode !== ProductCode.WEBSITE){
    this.isRichTextActions = true;
  }
 }

}
