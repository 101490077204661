import { ValidatorFn, AbstractControl } from '@angular/forms';

export function facebookUrlValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if(!control.value) {
      return null;
    }

    // Use Regex101 to test expressions: https://regex101.com/
    // This pattern not does require the http protocol, which goes against most uriValidators.
    // Need to maintain this regex across services if necissary
    const urlPattern = /^http(s)?:\/\/(www\.)?(facebook|fb)\.com\/[A-z0-9_\-\.]+\/?/i;
    const isAcceptedUrl = urlPattern.test(control.value);

    return isAcceptedUrl ? null : {'url': true};
  };
}
