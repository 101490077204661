export interface Words {
    name: string;
}

export const rejWords: Words[] =
    [
        { "name": "tbd" },
        { "name": "na" },
        { "name": "n/a" },
        { "name": "coming soon" },
        { "name": "soon" },
    ];

export const REJECTEDWORDS =
    rejWords.slice().sort(function (x, y) {
        return x.name.localeCompare(y.name);
    });