import { ListingFactory } from './listing.factory';
import { Listing, ListingPhoto, MarketingOrder, PackageInstance, Pricing } from '../models';
import { ObjectID } from './objectid';
import { ThumbnailSizes } from '../models/photo-thumbnail.model';

export class MarketingOrderFactory {

  static listingFactory = new ListingFactory();

  static createMarketingOrder(orderId?: string, listing?: Listing, pkg?: PackageInstance, withPhotos?: boolean) : MarketingOrder {
    function createPhotos() {
      const photos : ListingPhoto[] = [];

      photos.push(createPhoto('id-1', 1, true, '/test/image.jpg'));
      photos.push(createPhoto('id-2', 2, false, '/test/image.jpg'));
      photos.push(createPhoto('id-3', 3, true, '/test/image.jpg'));
      photos.push(createPhoto('id-4', 4, false, '/test/image.jpg'));

      return photos;
    }

    function createPhoto(photoId, order, favorite, uri) {
      const photo = new ListingPhoto();
      photo._id = photoId;
      photo.order = order;
      photo.favorite = favorite;
      photo.uri = uri;
      photo.thumbnails = {};
      photo.thumbnails[ThumbnailSizes.MEDIUM_THUMBNAIL_WIDTH] = {uri: '/test/image-'+order+'.jpg'};
      return photo;
    }

    const id = orderId || new ObjectID().str;
    const l = listing || this.listingFactory.createDefaultListing();
    const p = pkg || new PackageInstance();

    const mo = new MarketingOrder();
    mo._id = id;
    mo.listing = l;
    mo.selectedPackage = p;
    mo.photos = withPhotos ? createPhotos() : null;
    mo.availablePackages = [p];
    mo.marketingCopyHeadline = ['Marketing Headline'];
    mo.marketingCopyBody = ['Marketing Body'];
    mo.pricing = new Pricing();
    return mo;
  }
}
