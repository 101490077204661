// TOOD - convert to enum
export enum PropertyType {

    SINGLE_FAMILY = "Single family",
    CONDO = "Condominiums/ Townhouse",
    MULTI_FAMILY = "Multi Family/ Rental Income",
    LOTS = "Lots/Land",
    RENTAL = "Rental",
    RESIDENTIAL = "Residential",
    BUSINESSOP = "Business Opportunity",
    MOBILE_HOME = "Mobile Home",
    TIC = "TIC",
    COOP = "CO-OP",
    VACANTLAND = "Vacant Land",
    FARMLAND = "Farm Land"
  }

  export abstract class PropertyTypes {
    public static all: string[] = [
      PropertyType.SINGLE_FAMILY,
      PropertyType.CONDO,
      PropertyType.MULTI_FAMILY,
      PropertyType.LOTS,
      PropertyType.RENTAL,
      PropertyType.RESIDENTIAL,
      PropertyType.BUSINESSOP,
      PropertyType.MOBILE_HOME,
      PropertyType.TIC,
      PropertyType.COOP,
      PropertyType.VACANTLAND,
      PropertyType.FARMLAND,
    ]
  }

  export enum PropertyStatus {
    ACTIVE  = "Active",
    PENDING = "Pending",
    SOLD    = "Sold"
  }

  export abstract class PropertyStatuses {
    public static all: string[] = [
      PropertyStatus.ACTIVE,
      PropertyStatus.PENDING,
      PropertyStatus.SOLD,
    ]
  }

  export enum PropertyBedroomsLabel {
    BEDROOMS = 'Bedroom(s)',
    BEDS     = 'Bed(s)'
  }

  export abstract class PropertyBedroomsLabels {
    public static all: string[] = [
      PropertyBedroomsLabel.BEDROOMS,
      PropertyBedroomsLabel.BEDS
    ]
  }

  export enum PropertyBathroomsLabel {
    BATHROOMS = 'Bathroom(s)',
    BATHS     = 'Bath(s)'
  }

  export abstract class PropertyBathroomsLabels {
    public static all: string[] = [
      PropertyBathroomsLabel.BATHROOMS,
      PropertyBathroomsLabel.BATHS
    ]
  }


  export enum PropertyPriceLabel {
    OFFERED_AT = 'Offered at',
    PRICED_AT = 'Priced at',
    LISTED_AT = 'Listed at', 
    STARTING_AT = 'Starting at',
    SOLD_FOR = 'Sold For',
    CLOSED_AT = 'Closed at',
    NONE = ''
  }

  export abstract class PropertyPriceLabels {
    public static all: string[] = [
      PropertyPriceLabel.OFFERED_AT,
      PropertyPriceLabel.PRICED_AT,
      PropertyPriceLabel.LISTED_AT,
      PropertyPriceLabel.STARTING_AT,
      PropertyPriceLabel.SOLD_FOR,
      PropertyPriceLabel.CLOSED_AT,
      PropertyPriceLabel.NONE
    ]
  }

