import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputField } from '../input-field';

/**
 * We expose the selected time as TimeValue of hours and minutes ( eg: 7:30PM = {hour: 19, minute: 30 }
 */
export interface TimeValue {
  hour: number;
  minute: number;
}

/**
 * Internally we work with an offset in minutes from midnight
 *
 * @param offset an offset in minutes from midnight.
 * @return a TimeValue representing the24 hour clock time (hour, minute) for the offset.
 */
function offsetToTimeValue(offset: number): TimeValue {
  return { hour: Math.floor(offset / 60), minute: offset % 60 };
}

/**
 * Convert a time value to an offset in minutes from midnight. We need this to
 * convert the input defaultTime to be marked as selected in the pull-down.
 *
 * @param timeValue
 */
function timeValueToOffset(timeValue: TimeValue): number {

  return timeValue.hour * 60 + timeValue.minute
}

/**
 * TimePicker provides a pull-down list of time-values that is unambiguous:
 *
 * 12:00 AM is 'Midnight'
 * 12:00 PM is Noon
 * all other times are in 12 hour clock format with AM or PM appended.
 *
 * options are create in 30 minute increments
 */
@Component({
  selector: 'lc-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent extends InputField implements OnInit {

  static readonly MINUTES_PER_DAY = 24 * 60;

  // Generate pull-down options in 30 minute increments.
  private readonly incrementInMinutes = 30;

  // a place to store the selected value.
  formControl = new FormControl();

  // Input parameter to initialize the selected time.
  @Input()
  defaultTime: TimeValue;

  // Notify the parent that the selection changed.
  @Output()
  public timeChanged: EventEmitter<TimeValue> = new EventEmitter<TimeValue>();

  // list of options in the pull-down.
  options: any[];

  ngOnInit(): void {
    this.options = this.initOptions();

    this.formControl.setValue(timeValueToOffset(this.defaultTime));
  }

  onSelectionChanged($event) {

    //notif the parent component that a new time value was chosen.
    const timeValue: TimeValue = offsetToTimeValue($event);
    this.timeChanged.emit(timeValue);
    this.formControl.setValue($event);

  }

  /**
   * construct the list of options for the pull-down each option has a
   * display value of displayTime and a selection value offsetInMinutes from
   * midnight.
   *
   * @private
   */
  private initOptions(): any[] {

    const options = [];

    for (let i = 0; i < TimePickerComponent.MINUTES_PER_DAY; i += this.incrementInMinutes) {
      options.push( { displayTime: TimePickerComponent.computeDisplayTime(i), offsetInMinutes: i});
    }
    return options;
  }

  private static computeDisplayTime(offsetInMinutes): string {
    const timeValue = offsetToTimeValue(offsetInMinutes);
    const hours = timeValue.hour;
    const minutes = timeValue.minute % 60;

    const pad = (mins) => {
      return (mins < 10) ? `0${mins}` : `${mins}`;
    }
    // midnight to 1am
    if (hours === 0) {
      if (minutes === 0) {
        return 'Midnight';
      }
      return `12:${pad(minutes)} AM`;
    }
    // 1am to Noon
    if (hours < 12) {
      return `${hours}:${pad(minutes)} AM`
    }
    // Noon to 1pm
    if (hours === 12) {
      if (minutes === 0) {
        return 'Noon';
      }
      return `12:${pad(minutes)} PM`;
    }
    // 1pm to Midnight
    return `${hours-12}:${pad(minutes)} PM`
  }
}
