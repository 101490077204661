import { Injectable } from '@angular/core';
import { ApiClient } from './api.client';
import { NotificationForm } from '../forms/notification.form';
import { Notification } from '../models/notification.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from '../services/app.service';
import { ApiService } from '../services/api.service';

/**
 *  Contains all of the methods required to communicate with the Notifications API.
 *  Should not contain methods with business logic. This is only for API communication
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationsClient {
  public readonly notificationEndpoint = `${AppService.get('notifyBaseURL')}notifications`;

  constructor(private apiClient: ApiClient, private apiService: ApiService) {
  }

  /**
   * Queries the API and returns all notifications
   */
  get(params?: {all?: boolean, userId?: string, otherIds?: string[], marketingOrderId?: string, deliveryType?: string}): Observable<Notification[]> {
    return this.apiClient.get<Notification[]>(this.notificationEndpoint, params)
      .pipe(map(notifications => notifications.map(notification => new Notification(notification))));
  }

  /**
   * Queries the API for a given notificationId
   * @param notificationId The notification to query
   */
  getById(notificationId: string): Observable<Notification> {
    return this.apiClient.get<Notification>(`${this.notificationEndpoint}/${notificationId}`)
      .pipe(map(notification => new Notification(notification)));
  }

  /**
   * POSTs the notification form to the API. Ensuring proper form validation
   * @param form The notification form to post.
   */
  post(form: NotificationForm): Observable<Notification> {
    // Note: Do not POST a model. Models are for displaying, forms are for posting and validating
    return this.apiClient.post<Notification>(this.notificationEndpoint, form)
      .pipe(map(notification => new Notification(notification)));
  }

  /**
   * PUTs the notification form to the API. Ensuring proper form validation
   * @param form
   */
  put(form: NotificationForm): Observable<Notification> {
    // Note: Do not PUT a model. Models are for displaying, forms are for posting and validating
    return this.apiClient.put<Notification>(`${this.notificationEndpoint}/${form._id}`, form)
      .pipe(map(notification => new Notification(notification)));
  }

  /**
   * Makes a POST request to mark all given notificationIds as read
   * @param notificationIds The notifications to update
   */
  markAsRead(notificationIds: string[]) {
    return this.apiService.post<Notification>(`${this.notificationEndpoint}/mark-as-read`, { notificationIds });
  }
}
