import {COMMA, ENTER, TAB} from '@angular/cdk/keycodes';
import { Component, forwardRef, Input, EventEmitter, Output, OnChanges, SimpleChanges, ContentChild, TemplateRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { InputField } from '../input-field';
import { Observable, isObservable, of, merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'lc-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ChipsComponent),
      multi: true,
    }
  ]
})
export class ChipsComponent extends InputField implements OnChanges, AfterViewInit {
  separatorKeysCodes: number[] = [];

  @ContentChild('option')
  optionTemplate: TemplateRef<any>;

  @ContentChild('tag')
  tagTemplate: TemplateRef<any>;

  @ContentChild('noResults')
  noResultsTemplate: TemplateRef<any>;

  @ContentChild('prefix')
  prefixTemplate: TemplateRef<any>;

  @Input()
  label: string;

  @Input()
  placeholder = '';

  @Input()
  hint: string;

  @Input()
  emptyPlaceholder = '';

  @Input()
  prefix: string;

  @Input()
  allowCustomInput = false;

  @Input()
  displayPath: string;

  @Input()
  noResultsDisplay: string;

  @Input()
  valuePath: string;

  @Input()
  options: any | Observable<any[]>;

  @Input()
  inputFormControl: FormControl = new FormControl();

  @Output()
  public readonly blur = new EventEmitter<void>();

  @Output()
  public readonly textChange = new EventEmitter<string>();

  @Output()
  public readonly selectedChanged = new EventEmitter<any[]>();

  @Input()
  selectedValues: any[] = [];

  @Input()
  max: number;



  @ViewChild('chipList') chipList;
  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;


  public options$: any | Observable<any[]>;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if(changes.options) {
      if(isObservable<any[]>(this.options)) {
        this.options$ = this.options.pipe(tap(options => console.log(`Options updated! ${options?.length}`)));
      } else {
        this.options$ = of(this.options);
      }
    }
    if(changes.selectedValues) {
      this.selectedValues = this.selectedValues || [];
    }

    if(changes.allowCustomInput) {
      this.separatorKeysCodes = this.allowCustomInput ? [COMMA, ENTER, TAB] : [];
    }

    if(changes.inputFormControl && this.inputFormControl.validator?.length) {


      this.inputFormControl.valueChanges.subscribe(() => {
        this.inputFormControl.markAsTouched();
        this.setErrors(this.formControl);
      }, (error) => { throw new Error(error); });
    }
  }

  ngAfterViewInit() {
    if(this.formControl) {
      this.formControl.valueChanges.subscribe(() => this.chipList.errorState = !this.formControl.valid, (error) => { throw new Error(error); });
      this.formControl.statusChanges.subscribe(() => this.chipList.errorState = !this.formControl.valid, (error) => { throw new Error(error); });
    }
  }

  public onBlur() {
    this.blur.emit();
    this.executeOnTouched();
  }

  canAdd() {
    return !this.max || (this.selectedValues || []).length < this.max;
  }

  /** Occurs when the user types in the input field */
  onUserInput($event: any){
    this.inputFormControl.setValue($event.target.value);
    this.inputFormControl.markAsDirty();
    this.inputFormControl.markAsTouched();
  }

  public onItemAdded(event: {display: any, value: any}) {
    this.updateSelection();
  }

  public onItemRemoved(option) {
    const index = this.selectedValues.indexOf(option);

    if (index >= 0) {
      this.selectedValues.splice(index, 1);
      this.updateSelection();
    }
  }

  public onSelected(event: MatAutocompleteSelectedEvent){
    const option = event.option.value;
    this.selectedValues.push(option);
    this.clearInput();
    this.updateSelection();
  }

  public onTextChange(text) {
    this.textChange.emit(text);
  }

  private updateSelection() {
    const values =  this.selectedValues.map(option => this.valuePath ? option[this.valuePath] : option);
    this.formControl.setValue(values);
    this.selectedChanged.emit(values);
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity();
  }

  onInputAdd(event: MatChipInputEvent) {
    // Do not allow custom add.
    // They must click on an option in the autocomplete
    if(!this.allowCustomInput) {
      return;
    }

    const value = `${(event.value || '')}`.trim();

    // No input has been set. Just tabbing out
    if(value.length === 0) {
      return;
    }

    if(this.inputFormControl.valid && value.length > 0) {
      this.selectedValues.push(event.value);
      this.updateSelection();

      this.inputFormControl.reset();
      event.input.value = '';
    } else {
      this.inputFormControl.markAsDirty();
      this.inputFormControl.markAsTouched();

    }
  }

  protected setErrors(formControl: FormControl) {
    this.errors = this.getDisplayErrors(formControl) || this.getDisplayErrors(this.inputFormControl);
    this.hasErrors = this.errors !== '';
    if(this.chipList) {
      this.chipList.errorState = this.hasErrors;
    }
  }

  private clearInput() {
    this.chipInput.nativeElement.value = '';
    this.inputFormControl.setValue('');
  }
}
