import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DialogAction, ContentfulService, ContentKeys, AppService } from '@lc/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'lc-terms-and-conditions-dialog',
  templateUrl: './terms-and-conditions-dialog.component.html',
  styleUrls: ['./terms-and-conditions-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermsAndConditionsDialogComponent implements OnInit {
  private readonly _close = new Subject<boolean>();
  public readonly close = this._close.asObservable();
  readonly accept = new DialogAction('Accept');
  readonly cancel = new DialogAction('Cancel');
  checkoutTandC$: Observable<any>;

  constructor(public sanitizer: DomSanitizer, public contentfulService: ContentfulService) {}

  onAction(action: DialogAction) {
    this._close.next(action === this.accept);
  }

  ngOnInit(): void {
    this.checkoutTandC$ = this.contentfulService.getContent$(ContentKeys.CHECKOUT_TERMS_CONDITIONS, '<p style="margin-bottom: 15px;">' +
      'Orders cannot be canceled after your proofs have been released. Once you have approved each of your items, they' +
      'will immediately go to print and the order cannot be stopped or canceled. Please be mindful of this as you review' +
      'and approve your materials.' +
      '</p>' +

      '<p style="margin-bottom: 15px;">' +
      'If you have ordered photography as part of your Listing Concierge package, please note that it’s only applicable' +
      'to one property only (i.e. if you are listing a property with multiple units, you can only use your Listing' +
      'Concierge photography for one of the units).' +
      '</p>' +

      '<p style="margin-bottom: 15px;">' +
      'If you did not enter all of your information (including listing information, content and marketing copy), please' +
      'return to the Listing Details section and complete the information. You may continue to make changes until your' +
      'Listing Concierge Coordinator has accepted the order.' +
      '</p>' +

      '<p style="margin-bottom: 15px;">' +
      'Once your Listing Concierge Coordinator has accepted the order, certain features of the Listing Concierge platform' +
      'will be locked until they release your proofs.' +
      '</p>' +

      '<p style="margin-bottom: 15px;">' +
      'When you receive your proofs, it is your responsibility to ensure that you thoroughly review the content,' +
      'photographs, layout, spelling, color, print marks, etc. Coldwell Banker, as well as your Listing Concierge' +
      'Coordinator, office staff and Branch Manager are not responsible for issues with your order that have been' +
      'pre-approved by you or your assistant, designer, team member, etc.' +
      '</p>' +

      '<p style="margin-bottom: 15px;">' +
      'Office staff and employees of Coldwell Banker are not permitted to approve your orders on your behalf.' +
      '</p>' +

      '<p style="margin-bottom: 15px;">' +
      'It is highly recommended to use a larger device, such as your desktop or laptop computer, to review and approve' +
      'your proofs. We do not recommend reviewing and approving your proofs on your phone or smaller mobile device (i.e.' +
      'tablet or iPad).' +
      '</p>');
  }

}
