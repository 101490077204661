<div 
  #mainContainer
  id="mainContainer"
  style="display:flex;flex-wrap:wrap;"
  [ngClass]="{containerClass:true}">
  <!-- If images are processing display the overlay -->
  <div
    *ngIf="imagesProcessing"
    class="images-processing-overlay">
  </div>
  <div 
    [class]="itemClass"
    *ngFor="let item of items; let i = index">
    <template 
      [ngTemplateOutlet]="itemTemplate" 
      [ngTemplateOutletContext]="{item: item, index: i}">
    </template>
  </div>
</div>