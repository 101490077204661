import { Injectable } from '@angular/core';

/**
 * Provides methods for converting display text to HTML friendly formats for use
 * inside HTML tags.
 */
@Injectable()
export class FormattingService {

  /**
   * Takes a string of mixed case text and converts it to a string that
   * can be uses in CSS class definitions. Example:
   *
   *  Social & Web Boost => social-n-web-boost
   *
   * @param text
   */
  formatDisplayTextAsCssClass(text: string) {

    if (!text) {
      return text;
    }
    else return text.toLowerCase()
      .replace('&', 'n', )
      // add more here as discovered
      .replace(/ /g, '-');
  }

}
