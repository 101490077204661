export enum LandTenure {
  none = '',
  fs = 'FS',
  feeSimple = 'Fee Simple',
  leasehold = 'Leasehold',
  LEASEHOLD = 'LEASEHOLD',
}

export const LandTenureDisplays: {display: string, value: LandTenure }[] = [
  { display: 'None', value: LandTenure.none },
  { display: LandTenure.fs, value: LandTenure.fs},
  { display: LandTenure.feeSimple, value: LandTenure.feeSimple},
  { display: LandTenure.leasehold, value: LandTenure.leasehold},
  { display: LandTenure.LEASEHOLD, value: LandTenure.LEASEHOLD}
]
