import { PackageInstance, ServiceStatusType, ProductKind, ProductCategory } from '../models';
import { ProductCode } from '../models/product.code';

const silverPkg = {
  title: 'Silver',
  code: '001',
  description: 'Silver',
  position: 1,
  price: 99,
  products: [
    {
      title: 'Flyer',
      code: ProductCode.FLYER,
      status: ServiceStatusType.PENDING,
      kind: ProductKind.PRODUCT,
      category: ProductCategory.PRINT,
      thumbnailUri: '123456',
      isAvailable: true,
      details: {
        optional: true,
        unitQuantity: 2.0,
        unitPrice: 200.0,
        additionalUnitPrice: 200.0,
        price: 34,
        bundledPrice: 21,
        conciergeObligation: 'full',
        isVendorPrinted: true,
        mailDeliverable: false,
        deliveredWithinEnvelope: false,
        options: [ ]
      }
    },
    {
      title: 'Just Listed Postcard',
      code: ProductCode.JUST_LISTED_POSTCARD,
      status: ServiceStatusType.PENDING,
      kind: ProductKind.PRODUCT,
      category: ProductCategory.PRINT,
      thumbnailUri: '123456',
      isAvailable: true,
      details: {
        optional: true,
        unitQuantity: 2.0,
        unitPrice: 200.0,
        additionalUnitPrice: 200.0,
        price: 34,
        bundledPrice: 21,
        conciergeObligation: 'full',
        isVendorPrinted: true,
        mailDeliverable: false,
        deliveredWithinEnvelope: false,
        options: [ ]
      }
    },
    {
      title: 'Brochure',
      code: ProductCode.BROCHURE,
      status: ServiceStatusType.PENDING,
      kind: ProductKind.PRODUCT,
      category: ProductCategory.PRINT,
      thumbnailUri: '123456',
      isAvailable: true,
      details: {
        optional: true,
        unitQuantity: 2.0,
        unitPrice: 200.0,
        additionalUnitPrice: 200.0,
        price: 34,
        bundledPrice: 21,
        conciergeObligation: 'full',
        isVendorPrinted: true,
        mailDeliverable: false,
        deliveredWithinEnvelope: false,
        options: [ ]
      }
    }
  ]
};

const goldPkg = {
  title: 'Gold',
  code: '002',
  description: 'Gold',
  position: 1,
  price: 299,
  products: [
    {
      title: 'Flyer',
      code: ProductCode.FLYER,
      status: ServiceStatusType.PENDING,
      kind: ProductKind.PRODUCT,
      category: ProductCategory.PRINT,
      thumbnailUri: '123456',
      isAvailable: true,
      details: {
        optional: true,
        unitQuantity: 2.0,
        unitPrice: 200.0,
        additionalUnitPrice: 200.0,
        price: 34,
        bundledPrice: 21,
        conciergeObligation: 'full',
        isVendorPrinted: true,
        mailDeliverable: false,
        deliveredWithinEnvelope: false,
        options: [ ]
      }
    },
    {
      title: 'Professional Photography',
      code: ProductCode.PROFESSIONAL_PHOTOGRAPHY,
      status: ServiceStatusType.PENDING,
      kind: 'service',
      category: 'multimedia',
      thumbnailUri: '123456',
      isAvailable: true,
      details: {
        optional: true,
        unitQuantity: 2.0,
        unitPrice: 200.0,
        additionalUnitPrice: 200.0,
        maxOptionsSelectable: 2,
        price: 34,
        bundledPrice: 21,
        conciergeObligation: 'full',
        isVendorPrinted: true,
        mailDeliverable: false,
        deliveredWithinEnvelope: false,
        options: [
          {
            title: 'Flyover',
            description: 'Flyover Video',
            selected: false,
            assets: {selected: 's1', unselected: 's2'}
          },
          {
            title: 'Upgraded',
            description: 'Upgraded Photography',
            selected: false,
            assets: {selected: 's1', unselected: 's2'}
          },
          {
            title: 'Zillow',
            description: 'Showcase Video with Zillow Boost',
            selected: false,
            assets: {selected: 's1', unselected: 's2'}
          }
        ]
      }
    },
    {
      title: 'Just Listed Postcard',
      code: ProductCode.JUST_LISTED_POSTCARD,
      status: ServiceStatusType.PENDING,
      kind: ProductKind.PRODUCT,
      category: ProductCategory.PRINT,
      thumbnailUri: '123456',
      isAvailable: true,
      details: {
        optional: true,
        unitQuantity: 2.0,
        unitPrice: 200.0,
        additionalUnitPrice: 200.0,
        price: 34,
        bundledPrice: 21,
        conciergeObligation: 'full',
        isVendorPrinted: true,
        mailDeliverable: false,
        deliveredWithinEnvelope: false,
        options: [ ]
      }
    },
    {
      title: 'Brochure',
      code: ProductCode.BROCHURE,
      status: ServiceStatusType.PENDING,
      kind: ProductKind.PRODUCT,
      category: ProductCategory.PRINT,
      thumbnailUri: '123456',
      isAvailable: true,
      details: {
        optional: true,
        unitQuantity: 2.0,
        unitPrice: 200.0,
        additionalUnitPrice: 200.0,
        price: 34,
        bundledPrice: 21,
        conciergeObligation: 'full',
        isVendorPrinted: true,
        mailDeliverable: false,
        deliveredWithinEnvelope: false,
        options: [ ]
      }
    }
  ]
};


const platinumPkg = {
  title: 'Platinum',
  code: '003',
  description: 'Platinum',
  position: 1,
  price: 499,
  products: [
    {
      title: 'Flyer',
      code: ProductCode.FLYER,
      status: ServiceStatusType.PENDING,
      kind: ProductKind.PRODUCT,
      category: ProductCategory.PRINT,
      thumbnailUri: '123456',
      isAvailable: true,
      details: {
        optional: true,
        unitQuantity: 2.0,
        unitPrice: 200.0,
        additionalUnitPrice: 200.0,
        price: 34,
        bundledPrice: 21,
        conciergeObligation: 'full',
        isVendorPrinted: true,
        mailDeliverable: false,
        deliveredWithinEnvelope: false,
        options: [ ]
      }
    },
    {
      title: 'Professional Photography',
      code: ProductCode.PROFESSIONAL_PHOTOGRAPHY,
      status: ServiceStatusType.PENDING,
      kind: ProductKind.SERVICE,
      category: ProductCategory.MULTIMEDIA,
      thumbnailUri: '123456',
      isAvailable: true,
      details: {
        optional: true,
        unitQuantity: 2.0,
        unitPrice: 200.0,
        additionalUnitPrice: 200.0,
        price: 34,
        bundledPrice: 21,
        conciergeObligation: 'full',
        isVendorPrinted: true,
        mailDeliverable: false,
        deliveredWithinEnvelope: false,
        options: [
          { title: "Brochure Option 1", selected: true }
        ]
      }
    },
    {
      title: 'Just Listed Postcard',
      code: ProductCode.JUST_LISTED_POSTCARD,
      status: ServiceStatusType.PENDING,
      kind: ProductKind.PRODUCT,
      category: ProductCategory.PRINT,
      thumbnailUri: '123456',
      isAvailable: true,
      details: {
        optional: true,
        unitQuantity: 2.0,
        unitPrice: 200.0,
        additionalUnitPrice: 200.0,
        price: 34,
        bundledPrice: 21,
        conciergeObligation: 'full',
        isVendorPrinted: true,
        mailDeliverable: false,
        deliveredWithinEnvelope: false,
        options: [
          { title: "Just Listed Option 1", selected: true }
        ]
      }
    },
    {
      title: 'Brochure',
      code: ProductCode.BROCHURE,
      status: ServiceStatusType.PENDING,
      kind: ProductKind.PRODUCT,
      category: ProductCategory.PRINT,
      thumbnailUri: '123456',
      isAvailable: true,
      details: {
        optional: true,
        unitQuantity: 2.0,
        unitPrice: 200.0,
        additionalUnitPrice: 200.0,
        price: 34,
        bundledPrice: 21,
        conciergeObligation: 'full',
        isVendorPrinted: true,
        mailDeliverable: false,
        deliveredWithinEnvelope: false,
        options: [
          { title: "Brochure Option 1", selected: true }
        ]
      }
    }
  ]
};

export class PackageInstanceFactory {
  public static get silver() { return new PackageInstance(silverPkg); }
  public static get gold() { return new PackageInstance(goldPkg) };
  public static get platinum() { return new PackageInstance(platinumPkg); }

  public static createAll(): PackageInstance[] {

    const packages = [];
    packages.push(new PackageInstance(silverPkg));
    packages.push(new PackageInstance(goldPkg));
    packages.push(new PackageInstance(platinumPkg));

    return packages;
  }
}
