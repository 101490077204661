import { Deserializable } from './deserializable.model';
import { Audit } from './audit.model'

export enum VendorOrderStatus {
    OPEN = 'OPEN',
    SERVICING = 'SERVICING',
    SUBMITTING = 'SUBMITTING',
    CLOSED = 'CLOSED',
    CANCELLED = 'CANCELLED'
}

export enum VendorOrderTypes {
    VIDEO = 'video',
    ADVERTISING = 'advertising',
    PRINT = 'print'
}

export enum VendorEventTypes {
    INITIAL_SEND = 'initial-send',
    ORDER_CONFIRMATION = 'order-confirmation',
    ORDER_COMPLETE = 'order-complete',
    ORDER_UPDATE = 'order-update'
}

export interface HttpRequest {
    url?: string;
    body?: any;
    query?: any;
    method?: string;
    headers?: any;
}

export interface HttpResponse {
    code?: string;
    body?: any;
}

export class VendorEvent implements Deserializable<VendorEvent>  {
    type: VendorEventTypes;
    description?: string;
    direction: 'inbound' | 'outbound';
    request: HttpRequest;
    response: HttpResponse;
    date: Date

    constructor(input?: any) {
        this.deserialize(input);
    }

    deserialize(input: any): VendorEvent {
        Object.assign(this, input);

        return this;
    }
}

export class VendorServiceOrder implements Deserializable<VendorServiceOrder> {

    type: VendorOrderTypes;
    status: VendorOrderStatus = VendorOrderStatus.OPEN;

    /** User this printJob pertains to */
    userId?: string;

    /** Marketing order this printJob pertains to */
    marketingOrderId?: string;

    /** Product code this printJob pertains to */
    productCode?: string;

    /** vendorOrderId of the printJob */
    vendorOrderId: string;

    events: VendorEvent[];

    orderedAt: Date;

    audit: Audit;

    details: any;

    constructor(input?: any) {
        this.deserialize(input);
    }

    deserialize(input: any): VendorServiceOrder {
        Object.assign(this, input);

        return this;
    }
}