import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
const angularModules = [
  CommonModule, RouterModule, FlexLayoutModule, MatBadgeModule,
  MatDividerModule, MatToolbarModule, MatMenuModule, MatButtonModule, MatIconModule
];

// Layout Components
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NotificationsMenuComponent } from './toolbar/notifications-menu/notifications-menu.component';
import { FooterComponent } from './footer/footer.component';
const internalComponents = [ NotificationsMenuComponent ];
const externalComponents = [ ToolbarComponent, FooterComponent ];

// Layout Dialogs
import { PrivacyPolicyDialogComponent } from './dialogs/privacy-policy-dialog/privacy-policy-dialog.component';
import { TermsOfUseDialogComponent } from './dialogs/terms-of-use-dialog/terms-of-use-dialog.component';
const layoutDialogs = [ PrivacyPolicyDialogComponent, TermsOfUseDialogComponent ]
@NgModule({
  declarations: [...internalComponents, ...externalComponents, ...layoutDialogs],
  exports: [...externalComponents],
  imports: [
    ...angularModules
  ],
  entryComponents:[
    ...layoutDialogs
  ],
})
export class UILayoutModule { }
