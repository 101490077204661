import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Route, UrlSegment, CanLoad, CanActivateChild } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {

  constructor(private authService: AuthenticationService, private store: Store<any>) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthenticated = await this.authService.isAuthenticated$();
    if (isAuthenticated) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page
    const postLoginRedirectTo = state.url;
    localStorage.removeItem('post-login-redirect');
    if (postLoginRedirectTo) {
      localStorage.setItem('post-login-redirect', state.url);
    }

    this.authService.redirectToLogin();
    return false;
  }

  async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    return await this.isAuthenticated$();
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return await this.isAuthenticated$();
  }

  private async isAuthenticated$(): Promise<boolean> {
    const isAuthenticated = await this.authService.isAuthenticated$();
    return isAuthenticated;

  }
}
