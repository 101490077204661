import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PhotoAgency } from '../models/photography-agency.model';
import { GlobalErrorHandler } from '../errors/global-error-handler';
import { ErrorData } from '../errors/error-data';
import { PhoneType } from '../models';

@Injectable()
export class PhotographerService {

  resource = 'photographers';

  constructor(private apiService: ApiService, protected readonly errorHandler: GlobalErrorHandler) { }

  /* istanbul ignore next */
  // This method retrieves photography agencies
  retrievePhotographers(listingZipCode?: string): Observable<PhotoAgency[]> {
    let params = {}
    if (listingZipCode) params = { 'zip': listingZipCode };
    return this.apiService.get<PhotoAgency[]>('photographers', params).pipe(
      map(body => body.map(photographer => new PhotoAgency(photographer)))
    );
  }

  getPhotographyAgency(id: string): Observable<PhotoAgency> {
    return this.apiService.get(`${this.resource}/${id}`).pipe(map(body => new PhotoAgency(body)));
  }

  getTemporaryPassword(email: string): Observable<{tempPassword: string}> {
    return this.apiService.put(`${this.resource}/temp-password/${email}`);
  }

  savePhotographyAgency(photographerAgency: PhotoAgency) {
    this.updatePhotographerDetails(photographerAgency);
    if (photographerAgency._id) {
      return this.updatePhotographyAgency(photographerAgency);
    } else {
      return this.createPhotographyAgency(photographerAgency);
    }
  }

  private updatePhotographerDetails(photographerAgency: PhotoAgency) {
    if (photographerAgency) {
      const photographerList = photographerAgency.photographerDetails;
      if (photographerList) {
        for (const photographer of photographerList) {
          if (!photographer.username) {
            photographer.username = photographer.email;
          }
          if (photographer.phoneNumber) {
            if (photographer.phoneNumber.number) {
              photographer.phoneNumber.type = PhoneType.mobile;
            } else {
              delete photographer.phoneNumber;
            }
          }

        }
      }
    }
  }

  deletePhotographyAgency(photographerAgency: PhotoAgency) {
    return this.apiService.delete(this.resource + '/' + photographerAgency._id).pipe(
      catchError(err => this.processCatchError('AGENCY_DELETE', 'palyload', err)
      ));
  }

  protected createPhotographyAgency(photoAgency: PhotoAgency) {
    return this.apiService.post(this.resource, photoAgency).pipe(
      catchError(err => {
        this.processCatchError('AGENCY_CREATE', 'palyload', err);
        return throwError(err);
      }));
  }

  protected updatePhotographyAgency(photoAgency: PhotoAgency) {
    return this.apiService.put(this.resource + '/' + photoAgency._id, photoAgency).pipe(catchError(err => {
      this.processCatchError('AGENCY_UPDATE', 'palyload', err);
      return throwError(err);
    }
    ));
  }

  processCatchError(type, payload, err): Observable<ErrorData> {
    const errorPayload = new ErrorData(type,
      {
        original: payload,
        error: err
      }
    );
    this.errorHandler.handleError(errorPayload);
    return of(errorPayload);
  }

}
