import { Component, Inject, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FeatureFlags, LaunchDarklyService, ProfileInfo, UserRoles } from '@lc/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export class BulkAssignDialogData {
  constructor(readonly title: string, readonly subTitle: string) {}
}

@Component({
  selector: 'lc-bulk-assign-dialog',
  templateUrl: './bulk-assign-dialog.component.html',
  styleUrls: ['./bulk-assign-dialog.component.scss']
})
export class BulkAssignDialogComponent implements OnInit {
  swapPrimaryButtons$: Observable<Boolean>;
  public error: string;

  public selectedCoordinator: ProfileInfo;
  private readonly _close = new Subject<ProfileInfo>();
  public readonly close = this._close.asObservable();
  readonly roleTypeForReassign:string = UserRoles.LCC;

  readonly coordinatorSearch = new FormControl();

  constructor(@Inject(MAT_DIALOG_DATA) public data: BulkAssignDialogData,  private launchDarklyService: LaunchDarklyService) { }

  /** Cancels any form edits and closes the dialog */
  onCancel() {
    this.closeDialog(null);
  }

  ngOnInit(){
    this.swapPrimaryButtons$ = this.launchDarklyService.getFeature$(FeatureFlags.PRIMARY_SECONDARY_BUTTONS_SWAP, false);
  }

  onCommit() {
    if (!this.selectedCoordinator) {
      this.error = 'Please select a coordinator before proceeding.'
    } else {
      this.closeDialog(this.selectedCoordinator);
    }
  }

  onCoordinatorSelected(coordinators) {
    if (coordinators.length > 0) {
      this.selectedCoordinator = coordinators[0];
    } else {
      this.selectedCoordinator = null;
    }
  }

  /** Closes the dialog by emitting the close event */
  private closeDialog(coordinator: ProfileInfo) {
    this._close.next(coordinator);
  }
}
