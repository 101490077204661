import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { MarketingOrder } from '../models/marketing-order.model';
import * as Mustache from 'mustache';
import { ProductDescription } from '../models/product-description.model';
import { Observable, throwError } from 'rxjs';
import { Listing, OrderState } from '../models/listing.model';
import { ListingPhoto } from '../models/listing-photo.model';
import { UploadPhoto } from '../models/upload-photo.model';
import { MarketingOrderTransitions } from '../models/marketing-order-status.type';
import { Media } from '../models/media.model';
import { TemplateInstance } from '../models/template-instance.model';
import { PrintOrder } from '../models/print-order.model';
import { UpdateCurrentOrderState } from '../state-mgmt/order/order.actions';
import { Store } from '@ngrx/store';
import { PackageInstance } from '../models/package-instance.model';

@Injectable()
export class MarketingOrderService {

  resource = 'marketing-orders';

  v2Resource = 'orders';

  constructor(private apiService: ApiService,
              private userService: UserService,
              private store: Store<any>) { }

  /**
   * Get all marketing orders owned by the currently logged in user. This will
   * also set the summary flag on the MarketingOrder object
   *
   * @return an Observable that results in an array of MarketingOrders
   */
  getAllMyOrders(params?:{ search?: string}): Observable<MarketingOrder[]> {
    const allParams: any = params || {};
    return this.apiService.getV2(`${this.v2Resource}/summary`,allParams).pipe(
        map((orders: [MarketingOrder]) => {
          const orderObjs = [];
          orders.forEach((o: MarketingOrder) => {
            o.summary = true;
            orderObjs.push(new MarketingOrder(o));
          });
          return orderObjs;
        })
    );
  }

  /**
   * Get all marketing orders owned by the currently logged in user. This will
   * also set the history flag on the MarketingOrder object
   *
   * @return an Observable that results in an array of MarketingOrders
   */
  getAllMyOrdersHistory(params?:{ search?: string}): Observable<MarketingOrder[]> {
    const allParams: any = params || {};
    return this.apiService.getV2(`${this.v2Resource}/history`,allParams).pipe(
        map((orders: [MarketingOrder]) => {
          const orderObjs = [];
          orders.forEach((o: MarketingOrder) => {
            orderObjs.push(new MarketingOrder(o));
          });
          return orderObjs;
        })
    );
  }

  getOrder(id): Observable<MarketingOrder> {
    return this.apiService.getV2(`${this.v2Resource}/${id}`).pipe(map( body => new MarketingOrder(body) ));
  }

  getOrdersForListing(listingId): Observable<MarketingOrder[]> {
    return this.apiService.get(this.resource, {'listingId': listingId});
  }

  /**
   * Queries for the marketingOrder photos. Includes the photographerId in the route parameters
   * if provided.
   */
  getOrderPhotos(marketingOrderId: string, photographerId?: string, productCode?: string): Observable<ListingPhoto []> {
    const route = `${this.resource}/${marketingOrderId}/photos`;
    const params: any = {};
    if(photographerId) {
      params.photographerId = photographerId;
    }
    if(productCode) {
      params.productCode = productCode;
    }
    return this.apiService.get(route, params).pipe(
      map(photos => photos.map(photo => new ListingPhoto(photo)))
    );
  }

  /**
   * Queries lcms-printer for the status of all print jobs for this marketingOrder
   */
  getPrintOrders(marketingOrderId: string): Observable<PrintOrder []> {
    const route = `${this.resource}/${marketingOrderId}`;
    const params: any = {};
    return this.apiService.get(route, params).pipe(
      map(printOrders => printOrders.map(printOrder => new PrintOrder(printOrder)))
    );
  }

  async updateListing(marketingOrder: MarketingOrder): Promise<MarketingOrder> {
    return this.updateListing$(marketingOrder._id, marketingOrder.listing).pipe(
      map(response => new MarketingOrder(response)),
      tap(updatedOrder =>  this.store.dispatch(new UpdateCurrentOrderState(updatedOrder)))
    ).toPromise();
  }

  updateListing$(id: string, listing: Listing): Observable<MarketingOrder> {
    return this.apiService.put(this.resource + '/' + id + '/listing', listing);
  }

  updateOrderPartial$(order: MarketingOrder, fields: string[]) {
    const clonedOrder = this.cleanOrder(order);

    fields = fields || [];
    const partial = {};
    //order.orderState = order.listing.orderState;
    fields.forEach( field => {
      partial[field] = clonedOrder[field];
    });
    return this.apiService.put(this.resource + '/' + order._id, partial);
  }


  // Removes "read-only" properties that cannot be included in put or post operations
  private cleanOrder(order: MarketingOrder): MarketingOrder {
    // HACK: This is a temporary solution and should be removed.
    // We should be handling photos in a separate service instead of the model
    const clonedOrder = Object.assign({}, order);
    if (order.listing) {
      const clonedListing = Object.assign({}, order.listing);
      clonedOrder.listing = clonedListing;
    }
    else {
      delete clonedOrder.listing;
    }
    delete clonedOrder.summary;
    delete clonedOrder.audit;
    delete clonedOrder.statusHistory;
    delete clonedOrder.pricing; // Do not allow the UI to update the pricing


    this.cleanPackage(clonedOrder?.selectedPackage);
    if (clonedOrder.availablePackages) {
      clonedOrder.availablePackages.forEach(availablePackage => this.cleanPackage(availablePackage));
    }
    return clonedOrder;
  }

  private cleanPackage(pkg: PackageInstance) {
    pkg?.products?.forEach(product => {
      delete product.statusHistory;
      delete product.publishConsents;
      delete product.selectedTemplate;
    });
  }
  saveOrder(order: MarketingOrder) {
    const clonedOrder = this.cleanOrder(order);

    if (order._id) {
      return this.updateOrder$(clonedOrder);
    } else {
      return this.createOrder(clonedOrder);
    }
  }

  saveOrderProductDescription(description: ProductDescription) {

    let url = `${this.resource}/${description.orderId}/marketingCopy`;
    if (description.productCode) {
      url += `?productCode=${description.productCode}`;
    }

    return this.apiService.put(url, {
      marketingCopyHeadline: description.marketingCopyHeadline,
      marketingCopyBody: description.marketingCopyBody
    });
  }

  protected createOrder(order: MarketingOrder) {
    return this.apiService.postV2(this.v2Resource, this.cleanOrder(order));
  }

  protected updateOrder$(order: MarketingOrder) {
    return this.apiService.putV2(this.v2Resource + '/' + order._id, this.cleanOrder(order))
      .pipe(map(response => new MarketingOrder(response)));
  }

  updateOrder(order: MarketingOrder) {
    return this.apiService.putV2(this.v2Resource + '/' + order._id, this.cleanOrder(order)).pipe(
      map(response => new MarketingOrder(response)),
      tap(updatedOrder =>  this.store.dispatch(new UpdateCurrentOrderState(updatedOrder)))
    ).toPromise();
  }

  /** Updates the specific marketingOrder fields and then updates the store with the current marketingOrder */
  updateOrderPartial(order: MarketingOrder, fields: string[]) {
    return this.updateOrderPartial$(order, fields).pipe(
      map(response => new MarketingOrder(response)),
      tap(updatedOrder =>  this.store.dispatch(new UpdateCurrentOrderState(updatedOrder)))
    ).toPromise();
  }

  deleteOrder(order: MarketingOrder) {
    return this.apiService.delete(this.resource + '/' + order._id);
  }

  submitOrder(order: MarketingOrder) {
    return this.apiService.putV2(this.v2Resource + '/' + order._id + '/submit', this.cleanOrder(order))
      .pipe(map(response => new MarketingOrder(response)));
  }

  fulfill(id, productId, template) {
    return this.apiService.get(`${this.resource}/${id}/dictionary/${productId}/1`).pipe(map(data => {
      return Mustache.render(template, data)
    }));
  }

  async performAction(marketingOrder: MarketingOrder, transition: MarketingOrderTransitions, reason?: string): Promise<MarketingOrder> {
    return await this.apiService.post<MarketingOrder>(`${this.resource}/${marketingOrder._id}/transition/${transition}`, {reason: reason}).pipe(
      tap(updatedOrder =>  this.store.dispatch(new UpdateCurrentOrderState(updatedOrder)))
    ).toPromise();
  }

  /**
   * Sends an update of the MarketingOrder for photos only.
   *
   * @param marketingOrder
   * @param productCode
   */
  setPhotos(marketingOrder: MarketingOrder, productCode?: string): Observable<MarketingOrder> {
    let url = `marketing-orders/${marketingOrder._id}/photos`;

    let photos: any;
    if(productCode) {
      url += `?productCode=${productCode}`;
      photos = marketingOrder.getPhotosForProduct(productCode);
    } else {
      photos = marketingOrder.getPhotos();
    }

    return this.apiService.put(url, photos).pipe(
      tap(order => order)
    );
  }

  /**
   * Gets update of the MarketingOrder for impediments.
   *
   * @param marketingOrder
   */
  getImpediments(marketingOrderId: string): Observable<MarketingOrder> {
    const url = `marketing-orders/${marketingOrderId}/impediments`;
    return this.apiService.get(url).pipe(
      tap(impediments => impediments)
    );
  }

  /**
   * Adds a photo to a MarketingOrder.
   *
   * @param marketingOrder The MarketingOrder
   * @param listingPhotos: New photos to add
   * @return Observable<any> of the response
   * @param productCode is optional. When sent we are modifying the product instance photos
   */
  addPhoto(marketingOrder: MarketingOrder, listingPhotos: ListingPhoto[], productCode: string): Observable<any> {
    if (typeof marketingOrder?._id === "undefined") {
      // fix for LC-4275
      console.log(`Cannot upload photos due to marketing order ID is ${marketingOrder._id}`);
      // tslint:disable-next-line: rxjs-throw-error
      return throwError("EMPTYID");
    }

    if (productCode) {
      return this.apiService.put(`marketing-orders/${marketingOrder._id}/photos?push=true&productCode=${productCode}`, listingPhotos);
    }
    return this.apiService.put(`marketing-orders/${marketingOrder._id}/photos?push=true`, listingPhotos);
  }

  /**
   * Update a single photo associated with a MarketingOrder or a product instance
   *
   * @param id
   * @param photoId
   * @param values the photo array
   */
  updatePhoto(id: string, photoId: string, values: any): Observable<MarketingOrder> {
    return this.apiService.put(`marketing-orders/${id}/photos/${photoId}`, values);
  }

  /**
   * Parses upload response into photo, adds to MarketingOrder, and updates the MarketingOrder on server
   */
  addPhotoFromUploadAndUpdatePhotos(marketingOrder: MarketingOrder, photos: UploadPhoto[], photographerId?: string, productCode?: string) : Observable<any> {
    const userId = this.userService.getUserId();
    const photoCount = marketingOrder.getPhotos().length; // TODO: This will not handle concurrency very well
    const listingPhotos: ListingPhoto[] = ListingPhoto.createFromRawPhotos(photos, userId, photoCount, photographerId);

    return this.addPhoto(marketingOrder, listingPhotos, productCode);
  }

  /**
   * Update the orderstate. This is currently implemented on the marketing order collection
   *
   * @param id
   * @param state
   */
  updateOrderState(id: string, state: OrderState): Observable<any> {
    return this.apiService.putV2(this.v2Resource + '/' + id + '/orderstate', state);
  }

  /**
   * Update media on a product in an order
   * @param id the marketing order id
   * @param productCode the product code to update
   * @param media the media data
   */
  updateMedia(id: string, productCode: string, media: Media) {
    return this.apiService.put(this.resource + '/' + id + '/media?productCode=' + productCode, media);
  }


  /**
   * Publishes the website
   * @param id Id of the marketing order t publish the website for
   * @param consent Consent is required
   */
  publishWebsite(id: string, consent: boolean) {
    return this.apiService.post(`${this.resource}/${id}/website?consent=${consent}`, {});
  }

    /**
   * Returns an object containing both "Opt In" and "Opt Out" prices of available packages within a marketing order.
   *
   * @param marketingOrderId
   * @param products
   */
  getPackagePricing$(marketingOrderId: string): Observable<{[packageCode: string]: number}> {
    return this.apiService.getV2(this.v2Resource + '/' + marketingOrderId + '/package-prices');
  }

  getPaymentRequired(marketingOrderId: string, qtyDetails): Observable<{isPaymentRequired, addonTaxTotal, addonTotal}>{
    return this.apiService.post(this.resource + '/' + marketingOrderId + '/addon-payment-info', qtyDetails);
  }

  /**
   * Save selected template
   *
   * @param order the order act on
   * @param productCode the product code
   * @param template the selected template
   */
  selectTemplate(order: MarketingOrder, productCode: string, template: TemplateInstance): Observable<MarketingOrder> {
    return this.apiService.put(this.resource + '/' + order._id + '/products/'+productCode+'/selectTemplate', {templateCode: template.code});
  }

    /**
   * Assign many orders to a single coordinator
   * @param orderIds an array of order ids to assign to the coordinator
   * @param coordinatorId the user id of the coordinator to assign to
   */
  bulkAssignOrders(orderIds: string[], coordinatorId: string) {
    const body = {
      orders: orderIds,
      coordinator: coordinatorId
    }
    return this.apiService.put(this.resource + '/bulk-assign', body);
  }
}
