// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
const angularModules = [ CommonModule, RouterModule, MatTabsModule ];

// Tab Components
import { NavigationTabsComponent } from './components/navigation-tabs/navigation-tabs.component';
const externalComponents = [ NavigationTabsComponent ];

@NgModule({
  declarations: [...externalComponents ],
  exports: [...externalComponents ],
  imports: [
    ...angularModules
  ]
})
export class UITabsModule { }
