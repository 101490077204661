import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OptionsService } from '../../services/options.service';
import { Option } from '../../models/option.model';
import { LoadOptions, LoadOptionsComplete, OptionActionTypes } from './options.actions';
import { GlobalErrorHandler } from '../../errors/global-error-handler';
import { BaseEffects } from '../base.effects';
import { NotificationEventService } from '../../notifications';

/**
 * The order effects are post state change actions that occur
 */
@Injectable()
export class OptionsEffects extends BaseEffects {

  constructor(private actions$: Actions,
              private optionsService: OptionsService,
              private store: Store<any>,
              eventService: NotificationEventService,
              errorHandler: GlobalErrorHandler) {
    super(errorHandler, eventService);
  }

  @Effect({ dispatch: false })
  getOptions: Observable<Option[]> = this.actions$.pipe(
    ofType<LoadOptions>(OptionActionTypes.LoadOptions),
    switchMap((action) => {
      return this.optionsService.getOptions().pipe(
        catchError(err => this.processCatchError(OptionActionTypes.LoadOptions, action.payload, err))
      )
    }),
    map((options: Option[]) => {
      this.store.dispatch(new LoadOptionsComplete(options));
      return options;
    })
  );
}

