import { Profile } from '../models/profile.model';
import { AddressType } from '../models/address.type';
import { ContactType } from '../models/contact.type';
import { AddressInformation } from '../models/address-information.model'
/**
 * This is an adapter class that take a profile and organizes information from the profile and the embedded
 * common profile that comes from Trident or Dash
 */
export class ProfileAdapter {

  profile: Profile;

  constructor(profile: Profile) {
    this.profile = profile;
  }

  getFullName() {
    const firstName = this.profile.preferredFirstName;
    const lastName = this.profile.preferredLastName;
    return (firstName && lastName) ? (firstName + ' ' + lastName) : null;
  }

  getAddressInformation(type: AddressType): AddressInformation {
    if(!this.profile || !this.profile.contactInfo) { return null; }
    const getAddressType = this.profile.contactInfo.filter(item=>item.type===ContactType.card);
    const contact = getAddressType.find((c) => c.address.type === type);
    return (contact) ? contact.address : null;
  }

  getCommonShippingAddress() {
    return this.profile.commonProfile.shippingAddress;
  }

  getOfficeAddress() {
    return this.profile.commonProfile.office;
  }
}
