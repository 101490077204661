import { Deserializable } from './deserializable.model';

export class ProfileInfo implements Deserializable<ProfileInfo> {

  /** The Profile Id */
  _id: string;

  /** The User's Id */
  userId: string;

  /** The  User's EmailAddress */
  email: string;

  /** The User's First Name */
  firstName: string;

  /** The User's Last Name */
  lastName: string;

  /** The User's Phone Number */
  phoneNumber: string;

  /** The User's primary office */
  officeName: string;

  /** The User's Photo Url */
  photoUrl: string;

  /** The User's Profile Photo Url */
  profilePhotoUrl: string;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input: any): ProfileInfo {
    Object.assign(this, input);

    if (input.agent && input.agent.length > 0) {
      const agent = input.agent[0];
      this.firstName = (agent.firstName || [])[0] || this.firstName;
      this.lastName = (agent.lastName || [])[0] || this.lastName;
      this.email = (agent.email || [])[0] || this.email;
      this.officeName = (agent.officeName || [])[0] || this.officeName;
      this.phoneNumber = (agent.PhoneNumber || [])[0] || this.phoneNumber;
      this.photoUrl = (agent.photoUrl || [])[0] || this.photoUrl;
      this.profilePhotoUrl = (agent.profilePhotoUrl || [])[0] || this.profilePhotoUrl;
    }
    return this;
  }
}
