import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MarketingOrderContactsClient } from '../clients/marketing-order-contacts.client';
import { IContactRuleSpecs, MarketingOrderContacts } from '../models/marketing-order-contacts.model';
/**
 * The Service is a layer between the components and the API Clients.
 * The purpose of the service is to handle business logic while interacting with the
 * clients. This eliminates the components from needing to handle business logic.
 * All business logic should be done here
 */
@Injectable({
  providedIn: 'root'
})
export class MarketingOrderContactService {

  constructor(private readonly contactInfoClient: MarketingOrderContactsClient) { }

  resource = 'marketing-orders';
  /**
   * Gets the contact information for a given marketing order
   * @param marketingOrderId The marketingOrderId to retrieve contact info for
   */
  getContactInfo(marketingOrderId: string): Observable<MarketingOrderContacts> {
    // Note: If we want to do caching or utilize the ngrx store, do it here
    if (!marketingOrderId) {
      // fix for LC-4137
      console.log(`Cannot retrieve marketing-order contact info because marketing order ID is ${marketingOrderId}`);
      return of(null);
    }

    return this.contactInfoClient.get(marketingOrderId);
  }

  // check the rule spec for the contact block
  checkContactBlockRuleSpec(marketingOrderId: string): Observable<IContactRuleSpecs>{
    return this.contactInfoClient.getRuleCheck(marketingOrderId);
  }
}
