import { Deserializable } from './deserializable.model';
import { Address } from './address.model';
import { ListingPhoto } from './listing-photo.model';

export class SharedProduct implements Deserializable<SharedProduct> {

  title: string;
  code: string;
  mimeType: string;
  finalPdfUrl: string;

  constructor(model?: Partial<SharedProduct>) {
    this.deserialize(model);
  }

  deserialize(model?: Partial<SharedProduct>) {
    if (!model) { return this; }

    Object.assign(this, model);
    return this;
  }
}

export class SharedMarketingOrder implements Deserializable<SharedMarketingOrder> {
  _id: string;
  address: Address;
  mainPhoto: ListingPhoto;
  sharedProducts: SharedProduct[];
  agent: { firstName: string, lastName: string };

  constructor(model?: Partial<SharedMarketingOrder>) {
    this.deserialize(model);
  }

  deserialize(model?: Partial<SharedMarketingOrder>) {
    if (!model) { return this; }

    Object.assign(this, model);
    if(this.sharedProducts) {
      this.sharedProducts = this.sharedProducts.map(shared => new SharedProduct(shared));
    }
    return this;
  }
}
