import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { InputField } from '../input-field';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'lc-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    }
  ]
})
export class DropdownComponent extends InputField {

  @Input() label: string;

  @Input() placeholder = '';

  @Input() required = false;

  @Input()
  emptyOptionDisplay: string;

  @Input() emptyOptionDisabled = false;

  @Input()
  emptyOptionValue: string = '';

  @Input()
  hint: string;

  @Input()
  displayPath: string;

  @Input()
  valuePath: string;

  @Input()
  options: any[] = [];

  @Output()
  public readonly blur = new EventEmitter<void>();

  @Output()
  public selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public onBlur() {
    this.blur.emit();
    this.executeOnTouched();
  }
  public onSelectionChanged(event: MatSelectChange) {
    this.selectionChanged.emit(event.value);
  }

  public getDisplay(option) {
    return (this.displayPath ? option[this.displayPath] : option) || '';
  }

  public getOptionId(option) {
    const optionText = this.getDisplay(option);

    // Get the unique option text if there are multiple with the same name and different casing (i.e. Leasehold vs. LEASEHOLD)
    const sameTextOptions = this.options.filter(opt =>  this.getDisplay(opt).toLowerCase() === optionText.toLowerCase());
    const optionIndex = sameTextOptions.indexOf(option);
    const uniqueOptionText = `option-${optionText}${sameTextOptions.length > 1 ? '-' + (optionIndex + 1) : ''}`;

    return uniqueOptionText
      .replace(/\./g, '')
      .replace(/ /g, '-')
      .replace(/\\/g, '-')
      .replace(/\//g, '-')
      .replace(/[\(\)]/g, '-')
      .replace(/:/g, '-')
      .toLowerCase();
  }

  /**
   * Method that gets executed when the fake input used to handle autoFill
   * changes value
   */
  onAutoFill(event: any) {
    if(this.formControl && event.target && event.target.value) {
      this.formControl.setValue(event.target.value);
    }
  }
}
