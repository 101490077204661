import { FormControl, Validators } from '@angular/forms';
import { BaseForm } from './base.form';
import { Notification } from '../models/notification.model';

export class NotificationForm extends BaseForm {
  /** ID is a read-only field and cannot be included in payload. Necissary in the form to determin proper route. */
  _id: string;

  constructor(notification?: Notification) {
    super({
      userId: new FormControl(),
      marketingOrderId: new FormControl(),
      subject: new FormControl(null, [Validators.required]),
      text: new FormControl(null, [Validators.required]),
      notificationType: new FormControl(null, [Validators.required]),
      status: new FormControl(null, [Validators.required]),
      isRead: new FormControl(false),
      nextNotificationAt: new FormControl(null),
      otherData: new FormControl(null),
    });

    if(notification){
    this._id = notification._id;
      this.patchValue(notification);
    }
  }
}
