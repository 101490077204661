import { Deserializable } from './deserializable.model';

/**
 * The Filename model represents the Data Transport Object retrieved
 * from the lcms-image's /extractFilename route.
 */
export class Filename implements Deserializable<Filename> {

  url: string;
  filename: string;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input: any): Filename {
    Object.assign(this, input);
    return this;
  }
}
