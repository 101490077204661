import { Deserializable } from './deserializable.model';
import { ProfileInfo } from './profile-info.model';

export interface IMarketingOrderTeam {
  id?: string;
  name?: string;
  teamPhoto?: string;
  members?: ProfileInfo[];
  phoneNumber?: string;
  email?: string;
}

export interface IContactRuleSpecs {
  id: string;
  area: string;
  rules: [];
}

export class MarketingOrderContacts implements Deserializable<MarketingOrderContacts> {
  agent: ProfileInfo;
  coListingAgent: ProfileInfo;
  coordinator: ProfileInfo;
  team: IMarketingOrderTeam;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input?: any): MarketingOrderContacts {
    if(!input) { return this; }
    Object.assign(this, input);
    return this;
  }
}
