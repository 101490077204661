import { ContentGenerator } from './content-generator';
import { MarketingOrder, OutputMimeType, ProductInstance, Address } from '../models';
import { MarketingOrderService } from '../services/marketing-order.service';
import { Observable, of} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const websiteTemplateUrl = '/assets/listing-site/index.html';

@Injectable()
export class WebsiteGenerationService implements ContentGenerator {

  mimeType = OutputMimeType.html;

  downloadable = false;

  constructor(private http: HttpClient,
    private marketingOrderService: MarketingOrderService) {

  }

  generate(order: MarketingOrder, product: ProductInstance, otherData?: {consent?: boolean}): Observable<any> {
    const hasConsent = otherData && otherData.consent === true;
    return this.marketingOrderService.publishWebsite(order._id, hasConsent);
  }

  download(order: MarketingOrder, product: ProductInstance, imageResolution?: string, isDownload?: boolean): Observable<any> {
    return of(null);
  }
  downloadById(marketingOrderId: string, address: Address, product: ProductInstance | {title: string, code: string}): void {
    return;
  }
}
