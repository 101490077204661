import { Listing } from '../models/listing.model';
import { Address } from '../models';

export class ListingFactory {

  createDefaultListing() {
    return this.createListing();
  }

  createListing() : Listing {
    const listing = new Listing();

    listing._id = ''+new Date().getTime();
    listing.address = {
      streetAddress1: '123 Somewhere St.',
      streetAddress2: '122',
      city: 'Irvine',
      state: 'CA',
      postalCode: '92634'
    } as Address;
    listing.listPrice = 333333;
    listing.bedrooms = 3;
    listing.bathrooms = 2.75;

    return listing;
  }
}
