import { Deserializable } from './deserializable.model';
import { Address } from './address.model';
import { PhotographerAppointment } from './photographer-appointment.model';
import { Media } from './media.model';
import { ListingPhoto } from './listing-photo.model';
import { ProductOption } from './product-option.model';
import { PhotoOrderStatus } from './photo-order-types';

export class PhotoOrder implements Deserializable<PhotoOrder> {
  _id: string;
  photoAgencyId: string;
  propertyAddress: Address;
  agentName: string;
  orderedAt: Date;
  contactDetails: string;
  appointment: PhotographerAppointment;
  marketingOrderId: string;
  photoOrderId: string;
  status: PhotoOrderStatus;
  media: Media;
  photos: ListingPhoto[] = [];
  options: ProductOption[] = [];
  marketingOrderStatus: string;

  constructor(input?: any) {
    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): PhotoOrder {
    Object.assign(this, input);
    this.media = new Media(this.media);
    return this;
  }
}
