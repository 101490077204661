import { Action } from '@ngrx/store';
import { PhotoAgency } from '../../models/photography-agency.model';

export enum PhotographyAgencyActionTypes {
  GetAllPhotographyAgencies= '[Photographer] Get All',
  GetAllPhotographyAgenciesComplete= '[Photographer] Get All Complete',
}



export class GetAllPhotographyAgencies implements Action {
  readonly type = PhotographyAgencyActionTypes.GetAllPhotographyAgencies;
  constructor(public payload?: any) {}
}


export class GetAllPhotographyAgenciesComplete implements Action {
  readonly type = PhotographyAgencyActionTypes.GetAllPhotographyAgenciesComplete;
  constructor(public payload: PhotoAgency[]) {}
}

export type PhotographyAgencyActions = GetAllPhotographyAgencies | GetAllPhotographyAgenciesComplete ;

export const PHOTOGRAPHY_AGENCIES = 'photographyAgencies';
