import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { KEYS } from '../state-mgmt/keys/key.actions';
import { switchMap, map, take } from 'rxjs/operators';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private store: Store<any>) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(KEYS).pipe(
      take(1),
      map(keys => request.clone({ setHeaders: keys})),
      switchMap((req: HttpRequest<any>) => {
        return next.handle(req)
      })
    )
  }
}


