import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { Subscription } from 'rxjs';

export interface ActivityTimeoutConfirmation {
  confirmContinue(continueConfirmationHandler:ContinueConfirmationHandler): Promise<void>,
  sessionEndWarning(continueConfirmationHandler:ContinueConfirmationHandler): Promise<void>,
  clearRequest():void
}

export interface ContinueConfirmationHandler {
  confirmInactivityContinue():void,
  confirmInactivityLogout():void,
  confirmInSameSession():void,
  confirmRelogin():void
}

/**
 * Responsible for monitoring user activity and timeout events such as inactivity logout, token refresh, etc.
 */
@Injectable()
export class UserActivityService implements ContinueConfirmationHandler {

  private activityTimeoutConfirmation: ActivityTimeoutConfirmation;

  // Indicates confirmation request is active (dialog is showing)
  private  confirming:boolean;
  private timerSub: Subscription;
  private timeoutSub: Subscription;

  constructor(
    private router: Router,
    private userIdle: UserIdleService) {
  }

  startWatching() {
    // Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.timerSub = this.userIdle.onTimerStart().subscribe(count => {
      if (count && this.activityTimeoutConfirmation && !this.confirming) {
        this.confirming = true;
        this.activityTimeoutConfirmation.confirmContinue(this);
      }
    }, (error) => { throw new Error(error); });

    // Start watch when time is up.
    this.timeoutSub = this.userIdle.onTimeout().subscribe(() => {
      this.confirmInactivityLogout();
    }, (error) => { throw new Error(error); });
  }

  setActivityTimeoutConfirmation(activityTimeoutConfirmation: ActivityTimeoutConfirmation) {
    this.activityTimeoutConfirmation = activityTimeoutConfirmation;
  }

  stopWatching() {
    this.userIdle.stopWatching();
    this.timerSub?.unsubscribe();
    this.timeoutSub?.unsubscribe();
  }

  sessionEndWarning() {
    this.activityTimeoutConfirmation?.sessionEndWarning(this);
  }


  confirmInactivityContinue(): void {
    this.confirming = false;
    if (this.activityTimeoutConfirmation) {
      this.activityTimeoutConfirmation.clearRequest();
    }
    if (this.userIdle) {
      this.userIdle.resetTimer();
    }
  }

  confirmInSameSession(): void {
    this.confirming = false;
    if (this.activityTimeoutConfirmation) {
      this.activityTimeoutConfirmation.clearRequest();
    }
  }

  confirmRelogin(): void {
    this.confirming = true;
    if (this.activityTimeoutConfirmation) {
      this.activityTimeoutConfirmation.clearRequest();
    }
    this.router.navigate(['/okta/login']);
  }

  confirmInactivityLogout(): void {
    this.confirming = true;
    if (this.activityTimeoutConfirmation) {
      this.activityTimeoutConfirmation.clearRequest();
    }
    if (this.userIdle) {
      this.userIdle.stopWatching();
    }
    this.router.navigate(['logout']);
  }
}
