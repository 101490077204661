import { Injectable } from '@angular/core';
import { TermsAndConditionsDialogComponent } from './terms-and-conditions-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsDialogService {
  constructor(private modal: MatDialog) { }

  /**
   * Opens the notes dialog component with the required parameters
   * @param ownerId The ownerId we are requesting notes for
   * @param title The title of the dialog
   * @param subheader The subtitle of the dialog
   */
  openDialog() {
    const dialogRef = this.modal.open(TermsAndConditionsDialogComponent, {width: '750px', maxWidth: '90vw', maxHeight: '90vh' });
    const component: TermsAndConditionsDialogComponent = dialogRef.componentInstance;

    component.close.subscribe(accepted => dialogRef.close(accepted), (error) => { throw new Error(error); });
    return dialogRef.afterClosed().toPromise();
  }
}
