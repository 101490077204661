import { FormControl, ValidatorFn } from '@angular/forms';

/**
 * TextSanitationOptions is used to parse the user's input and sanitze
 * any unsupported characters
 */
export class TextSanitizeOptions {
  /**
   * Specific allowed characters. All characters not specified will be parsed.
   * NOTE: Set this to null or empty string to enable all user input
   **/
  allowedCharacters?: string = ` a-zA-Z0-9\u0080-\uFFFF/.#'&®-`;

  /** Removes emojis from the users input */
  removeEmojis?: boolean = false;

  /** Removes leading white space (i.e. ' Test' --> 'Test') */
  removeLeadingWhitespace?: boolean = true;

  /** Removes leading white space (i.e. 'Test ' --> 'Test') */
  removeTrailingWhitespace?: boolean = true;

   /** Removes multiple white space (i.e. 'Rose  Morgan' --> 'Rose Morgan') */
  removeMultipleWhitespace?: boolean = true;
}

const defaultOptions: TextSanitizeOptions = new TextSanitizeOptions();

/**
 * The StringFormControl is an extension on Angular's common FormControl with the ability to
 * sanitize user input.
 */
export class StringFormControl extends FormControl {
  constructor(value?: string, validators?: ValidatorFn | ValidatorFn[], readonly options?: { sanitizeOptions?: TextSanitizeOptions }) {
    super(TextSanitizer.sanitize(value, options?.sanitizeOptions, false), validators);
  }

  /** Executes when the value changes */
  setValue(value: any) {
    // When the user is typing, sanitize with isLostFocus = false
    this.onSanitize(value, true);
  }

  /** Should be executed when user leaves the input field */
  onLostFocus() {
    // When the user leaves the field, perform sanitation with isLostFocus = true
    this.onSanitize(this.value, false);
  }

  /**
   * Performs sanitation of this form controls value
   */
  private onSanitize(value: string, isUserInput: boolean) {
    const sanitized = TextSanitizer.sanitize(value, this.options?.sanitizeOptions, isUserInput);
    if(isUserInput || this.value !== sanitized) {
      // Set the formControl value to the sanitized value.
      // Make sure to notify the UI
      super.setValue(sanitized, { emitModelToViewChange: true});
    }
  }
}

/**
 * The text sanitizer is a class that will sanitize the text values based on specific
 * sanitization options
 */
export class TextSanitizer {

  static sanitize(value: string, overrides: TextSanitizeOptions, isUserInput: boolean = true) {
    if(!value) { return value; }

    let sanitized: string = value.toString();
    const options = Object.assign({}, defaultOptions, overrides);

    if(options?.allowedCharacters) {
      // The options requires us to only allow specific characters. Build a regex to include only the allowed characters
      const regex = new RegExp(`[^${options.allowedCharacters}]*`, 'g')
      sanitized = sanitized.replace(regex, '');
    } else {
      // No specific allowed characters were entered.
      // Use options to filter out additional types of characters

      if(options?.removeEmojis) {
        // Replace common emojis: https://davidwalsh.name/emoji-regex AND https://www.unicode.org/Public/UCD/latest/ucd/emoji/emoji-data.txt
        sanitized = sanitized.replace(/(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu, '');

        // Along with some unicode characters used in Emojis
        sanitized = sanitized.replace(/[\ufe0f\u200d]/g,'');
      }
    }
    if(options?.removeMultipleWhitespace) {
      // This should be at the end, after all the other characters have been parsed out
      // Found regex here: https://stackoverflow.com/a/1981837
      sanitized = sanitized.replace(/  +/g, ' ');
    }

    if(options?.removeLeadingWhitespace) {
      // Added support for trimLeft by including es2019
      sanitized = sanitized.trimLeft();
    }

    // Only run these sanitations after user has left the field
    if(!isUserInput) {
      if(options?.removeTrailingWhitespace) {
        sanitized = sanitized.trimEnd();
      }
    }

    return sanitized;
  }
}
