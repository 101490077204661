import {
  Component,
  forwardRef,
  Input,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
  TemplateRef, ContentChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { InputField } from '../input-field';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, isObservable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

export class MultiSelectDisplayOptions {

  /**
   *
   * @param display Display text for options i.e. - Paper
   * @param multiDisplay Used for pluralization of selected options i.e. - Papers (2)
   * @param displayMultiCount Used to distinguish if should display the count for multi selections i.e. - (2)
   */
  constructor(public display: string, public multiDisplay?: string, public displayMultiCount: boolean = true){
  }
}

@Component({
  selector: 'lc-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MultiselectComponent),
      multi: true,
    }
  ]
})
export class MultiselectComponent extends InputField implements OnChanges {
  @Input()
  label: string;

  @Input()
  hint: string;

  @Input()
  displayOptions: MultiSelectDisplayOptions;

  @Input()
  options: any | Observable<any[]>;

  @Input()
  displayPath: string = '';

  @Input()
  valuePath: string;

  @Input()
  multiple: boolean = true;

  @Input()
  dropdownClass: string;

  @Input()
  idPath: string;

  @ContentChild('optionTpl')
  optionTemplate: TemplateRef<any>;


  @Output()
  readonly selectionChanged = new EventEmitter<any[]>();

  @Output()
  readonly openedChange = new EventEmitter<boolean>();

  public display: string;
  public isSelected: boolean;
  private allOptions: any[] = [];
  public options$: any | Observable<any[]>;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if(changes.options) {
      if(isObservable<any[]>(this.options)) {
        this.options$ = this.options.pipe(
          tap(options => this.allOptions = options),
          tap(() => this.updateDisplay())
        );
      } else {
        this.options$ = of(this.options);
        this.allOptions = this.options;
      }
    }
    if(changes.multiple) {
      this.multiple = changes.multiple.currentValue === true || changes.multiple.currentValue === 'true';
    }
    this.updateDisplay();
  }

  clear(event) {
    event.stopPropagation();

    const value = this.multiple ? [] : null;
    this.value = value;
    if(this.formControl) {
      this.formControl.setValue(value);
    }
    this.onSelectionChanged([]);
    this.openedChange.emit(false);
  }

  onSelectionChanged(selections: any[]) {
    const value = this.getSelection();
    this.selectionChanged.emit(value);
  }

  onOpenedChange(opened: boolean) {
    this.openedChange.emit(opened);
  }

  updateDisplay() {
    this.display = this.getDisplay();
    const selection = this.getSelection();
    this.isSelected = (selection || []).length > 0
  }

  protected executeOnChanged() {
    // Intercept when the value changes to update the display
    this.updateDisplay();
    super.executeOnChanged();
  }

  private getSelection(): any[] {
    if(!this.value) {
      return [];
    }
    const selection = this.multiple ? this.value : [this.value];
    return selection;
  }

  private getDisplay() {
    if(!this.displayOptions.displayMultiCount) {
      // If not displaying multicounts, just return the display
      return this.displayOptions.display;
    }

    const selected = this.getSelection() || [];
    const selectedCount = selected.length;

    if(selectedCount === 0) {
      return this.displayOptions.display;
    } else if(selectedCount === 1) {
      return this.displayPath ? selected[0][this.displayPath] : selected[0];
    } else if(selectedCount === this.allOptions.length) {
      return `All ${this.displayOptions.multiDisplay}`
    } else {
      return this.displayOptions.multiDisplay
      ? `${this.displayOptions.multiDisplay} (${selectedCount})`
      : this.displayOptions.display;
    }

  }

  public getOptionId(option) {
    const path = this.idPath || this.displayPath;
    return 'option-' + ((path ? option[path] : option) || '')
      .replace(/\./g, '')
      .replace(/ /g, '-')
      .toLowerCase();
  }
}
