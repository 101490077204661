import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { InputField } from '../input-field';
import { DomSanitizer } from '@angular/platform-browser';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'lc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    }
  ]
})
export class CheckboxComponent extends InputField {

  @Input()
  checked: boolean;

  @Input()
  label: string;

  @Input()
  hint: string;

  @Input()
  readonly: boolean;

  @Output()
  public readonly blur = new EventEmitter<void>();

  @Output()
  public readonly change = new EventEmitter<boolean>();

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public onBlur() {
    this.blur.emit();
    this.executeOnTouched();
  }

  public onChanged(event: MatCheckboxChange) {
    this.change.emit(event.checked);
  }
}
