import { Action } from '@ngrx/store';
import { User } from '../../models/user.model';
import { Profile } from '../../models/profile.model';

export enum UserActionTypes {

  LoadLoggedInUser = '[User] Load Logged In',
  LoadUser = '[User] Load User',
  UserProfileUpdated = '[User] Profile Updated'
}

/**
 * Create ease of consumption wrapper actions
 * Eg.
 *
 * new LoadLoggedInUser();
 */
export class LoadLoggedInUser implements Action {
  readonly type = UserActionTypes.LoadLoggedInUser;
  constructor(public payload: User) {}
}

export class LoadUser implements Action {
  readonly type = UserActionTypes.LoadUser;
  constructor(public payload: User) {}
}

export class UserProfileUpdated implements Action {
  readonly type = UserActionTypes.UserProfileUpdated;
  constructor(public payload: Profile) {}
}


export type UserActions = LoadLoggedInUser
   | LoadUser
   | UserProfileUpdated
   ;

export const LOGGEDINUSER = 'loggedInUser';
export const SELECTEDUSER = 'selectedUser';
