import { Profile } from '../../models/profile.model';
import { ProfileActions, ProfileActionTypes } from './profile.actions';

export const initialState: Profile = null;

export function profileReducer(state = initialState, action: ProfileActions): Profile {

  switch (action.type) {
    case ProfileActionTypes.ClearProfile: {
      return null;
    }
    case ProfileActionTypes.LoadProfile: {
      return action.payload;
    }
    case ProfileActionTypes.UpdateProfile: {
      return action.payload;
    }
    case ProfileActionTypes.GetProfileComplete:
    case ProfileActionTypes.UpdateProfileComplete: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
