import { Injectable } from '@angular/core';

const SUPPORTED_ATTRIBUTES = [
  'bold', 'italic', 'underline', 'strike', 'align', 'link', 'list'];

function isSupportedInsertOp(op) {
  return op.insert && typeof op.insert === 'string';
}

@Injectable()
export class RichTextSanitizerService {

  /**
   * <p>
   * The Quill editor saves its text as HTML and we can copy/paste HTML from a
   * web page or word processor. It does not use HTML to represent the
   * content in the editor, instead it uses a Delta representation.
   * </p>
   * <p>
   * A Delta is sequence of operations (insert, delete, etc.) with attributes
   * that specify formatting. See:
   * <a href="https://quilljs.com/docs/delta/">Quill Delta</a>
   * </p>
   * <p>
   * Traverse the Quill Delta representation removing anything we don't
   * support:
   * </p>
   * <ul>
   *   <li>Images
   *   <p>These are represented as an insert op, with an object as the insert
   *      value</p>
   *   </li>
   *   <li>Unsupported styling
   *   <p>Quill represents these as a Delta op with specific attributes.
   *      Unsuppported attributes are removed.
   *   </p>
   * </ul>
   *
   * @param $event
   */
  sanitize(content: { ops: any[] }): { sanitized: boolean, content: { ops: any[] }} {

    if (!content) {
      return { sanitized: false, content: content };
    }
    // keep track of the number of ops that are sanitized.
    let numSanitized = 0;
    const sanitizedOps = [];
    content.ops.forEach( op => {

      if (!op.insert) {
        // pass it through, no change
        sanitizedOps.push(op);
      }
      else {
        if (!isSupportedInsertOp(op)) {
          //not supported, skip it
          numSanitized++;
        }
        else {
          if (!op.attributes) {
            // supported but no attributes, pass it through
            sanitizedOps.push(op);
          }
          else {
            const numOriginalAttributes = Object.keys(op.attributes).length;
            const attributes = this.sanitizeAttributes(op.attributes);
            const numSanitizedAttributes = Object.keys(attributes).length;
            const sanitizedOp = { attributes: attributes, insert: op.insert, };
            if (!Object.keys(attributes).length) {
              // no more attributes, remove from the sanitized op
              delete sanitizedOp.attributes;
            }
            sanitizedOps.push(sanitizedOp);
            //increment our counter only if the attributes have been changed
            numSanitized += (numOriginalAttributes === numSanitizedAttributes) ? 0 : 1;
          }
        }
      }
    });

    // If we did not do any sanitizing return the original Delta, otherwise return a Delta containing the
    // sanitized ops, include a boolean flag so the caller can see that the content was sanitized.
    return { sanitized: (numSanitized > 0), content: (numSanitized > 0) ? {  ops: sanitizedOps } : content } ;
  }

  /**
   * Edit the attributes make a copy and remove all unsupported attributes
   *
   * @param originalAttributes
   * @private
   */
  private sanitizeAttributes(originalAttributes): object {
    const attributes = {};
    Object.keys(originalAttributes).forEach(key => {
      if (SUPPORTED_ATTRIBUTES.includes(key)) {
        attributes[key] = originalAttributes[key];
      }
    });

    return attributes;
  }
}
