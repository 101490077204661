import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActionTypes, ORDERSTATE } from './workflow/workflow.actions';
import { tap, withLatestFrom } from 'rxjs/operators';
import { FlowState } from '../models/flow-state.model';

@Injectable()
export class PersistenceEffects {

  constructor(private actions$: Actions,
              private store: Store<FlowState>) {}

  @Effect({ dispatch: false })
  persist: Observable<any> = this.actions$.pipe(
    ofType(ActionTypes.WorkflowActionNext, ActionTypes.WorkflowActionPrev, ActionTypes.WorkflowActionNextIncomplete),
    withLatestFrom(this.store, (action, store) => {
      return {
        action: action,
        orderState: store[ORDERSTATE]
      };
    }),
    tap(payload => {
      console.log('persistence effects');
    })
  );
}
