import { Action } from '@ngrx/store';


export enum KeyActionTypes {

  Clear = '[Keys] Clear',
  Add = '[Keys] Add',
  Remove = '[Keys] Remove'
}

export class ClearKeys implements Action {
  readonly type = KeyActionTypes.Clear;
  constructor(public payload: any) {}
}

export class AddKeys implements Action {
  readonly type = KeyActionTypes.Add;
  constructor(public payload: any) {}
}

export class RemoveKeys implements Action {
  readonly type = KeyActionTypes.Remove;
  constructor(public payload: any) {}
}

export type KeyActions =
  ClearKeys
  | AddKeys
  | RemoveKeys
  ;

export const KEYS = 'keys';
