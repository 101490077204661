<lc-base-dialog id="upload-status-dialog" title="Upload Status" (actionClicked)="onClose()" primaryAction="OK">
  <ng-template #content>
    <div fxLayout="column" fxLayoutGap="10px">
      <ul *ngIf="data.isMultiple">
        <li class="border border-round p-3 d-flex justify-content-between align-items-center"><b>Successful uploads</b>
          <span class="badge badge-success">{{ data.successfulUploads?.length }}</span>
        </li>
        <li class="border border-round p-3 d-flex justify-content-between align-items-center"><b>Failed Uploads</b>
          <span class="badge badge-danger">{{ data.failedUploads?.length }}</span>
        </li>
      </ul>
      <h6 class="text-danger">Failed upload(s):</h6>
      <ul class="scrollable-menu">
        <li class="border border-round p-3" *ngFor="let msg of data.failedUploads">
          <div [innerHTML]="msg"></div>
        </li>
      </ul>
    </div>
  </ng-template>
</lc-base-dialog>

