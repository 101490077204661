import { Injectable } from '@angular/core';
import { PhotoOrder } from '../models/photo-order.model';
import { UploadPhoto } from '../models/upload-photo.model';
import { ListingPhoto } from '../models/listing-photo.model';
import { MultiMediaItemRequest } from '../models/multi-media-item.model';

import { ApiService } from './api.service';
import { UserService } from '../services/user.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { PhotoOrderTransitions } from '../models/photo-order-types';
import { MarketingOrder, Media } from '../models';
import { PhotoOrderForm } from '../forms';

@Injectable()
export class PhotoOrderService {
  /** Cached Photo Orders (Instead of using ngrx states) */
  private readonly photoOrders: {[photoOrderId: string]: BehaviorSubject<PhotoOrder> } = {};

  private resource = 'photo-orders';

  constructor(private apiService: ApiService,
              private userService: UserService) { }

  /** Retrieves all photoOrders for a given marketingOrder */
  getPhotoOrders$(marketingOrderId: string): Observable<PhotoOrder[]> {
    return this.apiService.get<PhotoOrder[]>(`marketing-orders/${marketingOrderId}/photo-orders`).pipe(
      map(orders => orders.map(order => new PhotoOrder(order)))
    );
  }

  /** Retrieves the photo order and loads it into the current photo order state */
  getOrder$(photoOrderId: string): Observable<PhotoOrder> {
    if(!this.photoOrders[photoOrderId]) {
      // Initialize the Subject that can be used to force the UI to update without re-calling the API
      this.photoOrders[photoOrderId] = new BehaviorSubject<PhotoOrder>(null);
    }

    // Return the cached observable, otherwise from the api
    return this.photoOrders[photoOrderId].pipe(
      switchMap(photoOrder => photoOrder
        // If order have already been cached, return it
        ? of(photoOrder)

        // Otherwise, make a new API Request
        : this.apiService.get(`photo-orders/${photoOrderId}`).pipe(
            map(order => new PhotoOrder(order)),
            tap(order => this.photoOrders[photoOrderId].next(order))
        )
    ));
  }

  async updatePhotoOrder(photoOrder: PhotoOrder): Promise<PhotoOrder> {
    const id = photoOrder._id;
    // do not pass in _.id in the body structure
    delete photoOrder._id;
    delete photoOrder.media;
    return await this.apiService.put<PhotoOrder>(`${this.resource}/${id}`, photoOrder).pipe(
      map(order => new PhotoOrder(order)),
      tap(() => this.updateCache(photoOrder)),
    ).toPromise();
  }


  /** Queries the API for a list of PhotoOrders */
  retrievePhotoOrders(photoAgencyId: string, params?: any, pageRequest?:any): Observable<{orders: PhotoOrder[], totalCount: number}> {
    let allFilters: any = Object.assign({}, params, pageRequest);
    allFilters = Object.entries(allFilters)
      .reduce((combined, [name, value]) => {
        if(value){
          combined[name] = value;
        }
        return combined;
      }, {});

    return this.apiService.getResponse<PhotoOrder[]>(this.resource + '?photoAgencyId=' + photoAgencyId, allFilters).pipe(
      map(response => ({ 
        orders : response.body.map(order => new PhotoOrder(order)),
        totalCount: +(response.headers.get('total-count') || response?.body?.length)
      }))
    );
  }

  /** Adds a photo to a PhotoOrder and updates the photo order */
  async addPhotos(photoOrder: PhotoOrder, listingPhotos: ListingPhoto[]): Promise<PhotoOrder> {
    return await this.apiService.put<PhotoOrder>(`${this.resource}/${photoOrder._id}/photos?push=true`, listingPhotos).pipe(
      map(order => this.updateCache(new PhotoOrder(order)))
    ).toPromise();
  }

  /**
   * Sends an update of the PhotoOrder for photos only.
   */
  async setPhotos(photoOrder: PhotoOrder, photos: ListingPhoto[]): Promise<PhotoOrder> {
    const url = `${this.resource}/${photoOrder._id}/photos`;
    return await this.apiService.put<ListingPhoto[]>(url, photos).pipe(
      map(order => this.updateCache(new PhotoOrder(order)))
    ).toPromise();
  }

  async addPhotoFromUploadAndUpdatePhotos(photoOrder: PhotoOrder, photos: UploadPhoto[]) : Promise<PhotoOrder> {
    const userId = this.userService.getUserId();
    const listingPhotos: ListingPhoto[] = ListingPhoto.createFromRawPhotos(photos, userId, photoOrder.photos.length, photoOrder.photoAgencyId);
    return await this.addPhotos(photoOrder, listingPhotos);
  }

  /** Submits the photos to the marketing order */
  async submitPhotos(photoOrder: PhotoOrder, photoUrls: string[]) : Promise<PhotoOrder> {
    // TODO: This route does not appear to be RESTFUL.
    return this.apiService.post<PhotoOrder>(`photo-submit`, {photoOrderId: photoOrder._id, photoUrls }).pipe(
      map(order => this.updateCache(new PhotoOrder(order)))
    ).toPromise();
  }

  /** Adds a multimedia link to the given order and updates the order after it has been returned */
  async addMultimediaLink(photoOrder: PhotoOrder, linkRequest: MultiMediaItemRequest): Promise<PhotoOrder> {
    return await this.apiService.put<PhotoOrder>(`${this.resource}/${photoOrder._id}/addlink/${photoOrder.marketingOrderId}` , linkRequest).pipe(
      map(order => this.updateCache(new PhotoOrder(order)))
    ).toPromise();
  }

  /**
   * This will fetch a order with media only.
   *
   * @param photoOrderId the photo order id for this order
   * @param orderId the marketing order id
   */
  getMedia$(photoOrder: PhotoOrder): Observable<Media> {
    return this.apiService.get(`${this.resource}/${photoOrder._id}/links/${photoOrder.marketingOrderId}/${photoOrder.photoAgencyId}`).pipe(
      map((media: {_id: string, media: Media}) => new Media(media.media)),
      tap(media => photoOrder.media = media)
    );
  }

  async rescheduleOrder(marketingOrderId: string, photoOrderForm: PhotoOrderForm): Promise<PhotoOrder> {
    return await this.apiService.post<PhotoOrder>(`marketing-orders/${marketingOrderId}/photo-orders`, photoOrderForm.getCleanValue()).pipe(
      map(order => new PhotoOrder(order))
    ).toPromise();;
  }

  /** Method that will trigger a state transition to start the order */
  async startOrder(photoOrder: PhotoOrder): Promise<PhotoOrder> {
    return await this.transitionOrder(photoOrder._id, PhotoOrderTransitions.start).toPromise()
  }

  /** Method that will trigger a state transition to complete the order */
  async completeOrder(photoOrder: PhotoOrder): Promise<PhotoOrder> {
    return await this.transitionOrder(photoOrder._id, PhotoOrderTransitions.complete).toPromise()
  }

  /** Method that will trigger a state transition to cancel the order */
  async cancelOrder(photoOrder: PhotoOrder): Promise<PhotoOrder> {
    return await this.transitionOrder(photoOrder._id, PhotoOrderTransitions.cancel).toPromise()
  }

  /** Executes a request to the API to perform a specific transition */
  private transitionOrder(photoOrderId: string, transition: PhotoOrderTransitions) {
    return this.apiService.post(`photo-orders/${photoOrderId}/transition/${transition}`).pipe(
      map(order => this.updateCache(new PhotoOrder(order)))
    );
  }

  /** Triggers an update to the current observables if they are subscribed to */
  private updateCache(photoOrder: PhotoOrder) {
    if(this.photoOrders[photoOrder._id]) {
      this.photoOrders[photoOrder._id].next(photoOrder);
    }
    return photoOrder;
  }
}
