import { Deserializable } from './deserializable.model';

export class Payment implements Deserializable<Payment> {
  paymentId: string;
  payment_method: string;

  constructor(input?: any) {
    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): Payment {
    return Object.assign(this, input);
  }
}
