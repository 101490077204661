import { AbstractControl, ControlContainer, FormControl, ValidatorFn } from '@angular/forms';
import { STATES_BY_ABBREVIATION } from '../models';
import { REJECTEDWORDS } from '../models/rejected-words.model'
export class CustomValidator {
  static httpsRegex = new RegExp('^https://');
  static secureUrlRegex = /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
  static urlRegex = /^http:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

  static validateSecureUri(control: AbstractControl) {

    const candidate = control.value;
    if (candidate === null) {
      return null;
    }

    if (!CustomValidator.secureUrlRegex.test(candidate)) {
      const result: any = { url: true };
      if (!CustomValidator.httpsRegex.test(candidate)) {
        result.requireHttps = true;
      }
      return result;
    }
    return null;
  }


  static validateUri(control: AbstractControl) {

    const candidate = control.value;
    if (candidate === null || candidate.length === 0) {
      return null;
    }

    if (!CustomValidator.urlRegex.test(candidate) && !CustomValidator.secureUrlRegex.test(candidate)) {
      return { url: true };
    }
    return null;
  }


  static validateState(control: AbstractControl) {
    const state = control.value;

    if (state === null || state.length === 0) {
      return null;
    }
    if (!STATES_BY_ABBREVIATION.find(s => s.abbreviation === state)) {
      return { state: true };
    }
    return null;
  }

  static forbiddenWordsValidator(control: AbstractControl){
    const word = control.value;

    if(word === null || word.length === 0) {
      return null;
    }

    if(!REJECTEDWORDS.find(s => s.name.toUpperCase() === word?.trim().toUpperCase())){
      return null;
    }
    return {word: true};
  }

  static controlValueAsString(control: AbstractControl): string {

    // get the control value or a default empty string
    // coerce the result to a string
    return CustomValidator.stringValueOf(control?.value);

  }


  static stringValueOf(value: any): string {

    // log a warning if value is not a string, we do appear to expect it to be
    // one.
    if (value) {
      if ( typeof value !== 'string' ) {
        console.error(`Expecting a string but got ${typeof value} with value of ${value}`);
      }
    }
    // Treat a zero number value as a special case, since 0 is 'falsy'
    const v = (value === 0) ? value.toString() : value;

    // get the control value or a default empty string
    // coerce the result to a string
    return `${v || ''}`.trim();

  }
  static emptyString(control: FormControl) {
    const isEmpty = (CustomValidator.controlValueAsString(control).length === 0);
    return isEmpty ? { 'whitespace': true } : null;
  }

  /**
   * If control is defined and value in the control is not an empty string
   * then thats ok, otherwise flag an error.
   *
   * @param control
   */
  static required(control: AbstractControl) {

    // get the control value or a default empty string.
    const value = CustomValidator.controlValueAsString(control);

    return value.length === 0 ? { required: true } : null;
  }

}
