
export enum UserRoles {
  AGENT = 'agent',
  NONE_SALES_AGENT = 'non-sales-agent',
  BRANCH_MANAGER = 'branch-manager',
  MARKETING_DIRECTOR = 'marketing-director',
  NATIONAL_DIRECTOR = 'national-director',
  LCC = 'lcc',
  LCC_MANAGER = 'lcc-manager',
  LCC_NATIONAL_DIRECTOR = 'lcc-national-director',

  CBR_NATIONAL_DIRECTOR = 'cbr-national-director',
  CBA_NATIONAL_DIRECTOR = 'cba-national-director',
  DELEGATE = 'delegate'
}

export const UserRolesDisplays: {key: UserRoles, value: string}[] = [
  { key: UserRoles.AGENT, value: 'Agent'},
  { key: UserRoles.NONE_SALES_AGENT, value: 'Non-sales Agent'},
  { key: UserRoles.BRANCH_MANAGER, value: 'Branch Manager'},
  { key: UserRoles.MARKETING_DIRECTOR, value: 'Marketing Director'},
  { key: UserRoles.NATIONAL_DIRECTOR, value: 'National Director'},
  { key: UserRoles.LCC, value: 'LCC'},
  { key: UserRoles.DELEGATE, value: 'Delegate'},
  { key: UserRoles.LCC_MANAGER, value: 'LCC Manager'},
  { key: UserRoles.LCC_NATIONAL_DIRECTOR, value: 'LCC National Director'},
  { key: UserRoles.CBR_NATIONAL_DIRECTOR, value: 'National CBR Director'},
  { key: UserRoles.CBA_NATIONAL_DIRECTOR, value: 'National CBA Director'},
];
