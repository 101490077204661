import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

/**
 * The ImageLoaderService is responsible for taking in processing an image file,
 * performing validation and converting it to data64.
 */
@Injectable({
  providedIn: 'root'
})
export class ImageLoaderService {
  /*
 *  A map of  file 'magic' numbers to  mime-type and standard file suffix for that type.
 *  To support more types, just add to this map.
 */
  TYPES = Object.freeze({
    '89504E47': {
      mimeType: 'image/png',
      suffix: 'png'
    },
    'FFD8FFDB': {
      mimeType: 'image/jpeg',
      suffix: 'jpg'
    },
    'FFD8FFE0': {
      mimeType: 'image/jpeg',
      suffix: 'jpg'
    },
    'FFD8FFED': {
      mimeType: 'image/jpeg',
      suffix: 'jpg'
    },
    '25504446': {
      mimeType: 'application/pdf',
      suffix: 'pdf'
    },
    '504B0304': {
      mimeType: 'application/zip',
      suffix: 'zip'
    }
  });

  constructor() { }

  /**
   * Takes in an image file, and performs validation. If validation passes, it will load the file
   * into an HtmlImage and return the DataURL
   * @param file
   * @param allowedExtensions
   * @param minFileSizeInMB
   */
  loadFile(file: File, allowedExtensions: string[], minFileSizeInMB?: number): Observable<string> {
    return new Observable(observer => {

      if(!this.validateFileExtension(file, allowedExtensions)) {
        const failureMsg = `Invalid format (Should one of ${allowedExtensions.join(', ')})`;
        observer.error(failureMsg);
      }

      if(!this.validateFileSize(file, minFileSizeInMB)) {
        const failureMsg = `Image too small (Should be at least ${minFileSizeInMB}MB)`;
        observer.error(failureMsg);
      }

      this.loadImage(file, observer);
    })
  }

  /**
   * Validates the file extension against a list of allowed extensions
   * @param file The file to validate
   * @param allowedExtensions The allowed extensions
   */
  public validateFileExtension(file: File, allowedExtensions: string[]): boolean {
    let filenameSuffix = file.name ? file.name.split(".").slice(-1)[0] : undefined;
    filenameSuffix = filenameSuffix ? filenameSuffix.toLowerCase() : undefined;
    return allowedExtensions.some(extension => extension.replace('.', '') === filenameSuffix);
  }

  /**
   * Validates the file size
   * @param file The file to validate
   * @param minimumFileSizeInMB The file size in MB
   */
  public validateFileSize(file: File, minimumFileSizeInMB?: number) {
    // If there is no minimum file size, return true. It's valid
    if(minimumFileSizeInMB == null) { return true; }

    // Otherwise, convert the file size to MB and compare
    const fileSizeInMB = file.size / (1024 * 1024);
    return fileSizeInMB >= minimumFileSizeInMB;
  }

  private loadImage(file: File, observer: Subscriber<string>) {
    // Scale here in code so we know when scaling is complete
    // Otherwise scaling can often take longer than the upload.
    // If upload/progress completes before thumbnail is visible, the display looks very odd
    const img = new Image;
    img.onload = () => this.onImageLoaded(img, observer);
    img.onerror = (e: ErrorEvent) => this.onImageError(e, file, observer);
    img.src = URL.createObjectURL(file);
  }

  private onImageError(e: ErrorEvent, file: File, observer: Subscriber<string>) {
    // If the Image errors, we need to set an appropriate error message and have the observable error out
    const msg = "Error loading thumbnail for " + file.name + ":" + e.message;
    console.warn(msg);
    observer.error(msg);
  }

  /**
   * Load the HTML Image into a file and complete the observable with the DataURL
   * @param image
   * @param observer
   */
  private onImageLoaded(image: HTMLImageElement, observer: Subscriber<string>) {
    // Dump the image into a canvas to retrieve the DataURL
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const iw = image.width;
    const ih = image.height;
    const scale = Math.min((150 / iw), (150 / ih));
    const iwScaled = iw * scale;
    const ihScaled = ih * scale;
    canvas.width = iwScaled;
    canvas.height = ihScaled;
    ctx.drawImage(image, 0, 0, iwScaled, ihScaled);

    // NOT Sure what this is doing? Do we need this?
    const thumb = new Image();
    thumb.src = canvas.toDataURL();

    URL.revokeObjectURL(image.src);

    // Return the DataURL as the response to the observable
    observer.next(canvas.toDataURL());
    observer.complete();
  }

  /**
   * Determine a file type from the data and return an object with mimeType and suffix values for the detected type.
   *
   * Example, for a jpeg file returns: { mimeType: 'image/jpeg', suffix: 'jpg' }
   *
   * If the type cannot be determined, null is returned.
   *
   * @param data
   * @returns {Promise<{{string, string}}>}
   */
  getTypeForData(data) {
    return this.getType(this.getSignature(data));
  }

  /**
   * Extract a signature from the first 4 bytes of the data.
   * @param data
   * @returns {string}
   */
  getSignature(data) {
    const uint = new Uint8Array(data);

    const bytes = [];
    for (let i = 0; i < 4; i++) {
      let str = uint[i].toString(16);
      str = (str.length === 1) ? '0' + str : str;
      bytes.push(str);
    }
    return bytes.join('').toUpperCase();
  }

  /**
   * lookup up a type based upon a signature, return null if a type is not found
   *
   * @param signature
   */
  getType(signature) {
    const type = this.TYPES[signature]
    return type;
  }
}
