<mat-form-field appearance="outline" floatLabel="always">

  <mat-label [id]="idPrefix + '-label'">{{label}}</mat-label>
  <mat-select matInput [id]="idPrefix + '-input'" (blur)="onBlur()" [formControl]="formControl" [placeholder]="placeholder" (selectionChange)="onSelectionChanged($event)" [errorStateMatcher]="matcher">
    <mat-option *ngIf="emptyOptionDisplay" [value]="emptyOptionValue" [disabled]="emptyOptionDisabled" [class.disabled-option]="emptyOptionDisabled">
      {{ emptyOptionDisplay }}
    </mat-option>
    <mat-option *ngFor="let option of options" [value]="valuePath ? option[valuePath] : option" [id]="getOptionId(option)">
      <span class="option-title">{{ displayPath ? option[displayPath] : option }}</span>
    </mat-option>

  </mat-select>
  <mat-hint [id]="idPrefix + '-hint'">{{hint}}</mat-hint>
  <mat-error [id]="idPrefix + '-error'" *ngIf="formControl && formControl.invalid" [innerHTML]="errors"></mat-error>

  <!-- Fake input used to handle autofill changes. Needs to hide behind the screen. display: none does not work -->
  <input matInput class="hidden-autofill-input" tabindex="-1" (change)="onAutoFill($event)" [formControl]="formControl">

</mat-form-field>

