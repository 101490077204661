import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { FieldVersionComponent} from './field-version.component'
import { FieldVersionsData } from '@lc/core';


@Injectable()
export class FieldVersionDialogService {
  isDialogOpen = false;
  constructor(private modal: MatDialog) { }

  /**
   * Opens the field version  component with the required parameters
   * @param marketingOrderId The marketingOrderId we are requesting field versions for
   * @param requestedFields The requestedFields we are requesting field versions for
   * @param highlevelKey whether requestedFields have highlevel ley to format into common response
   */
  openVersions(marketingOrderId: string, model: any, highlevelKey?:string) {
    const dialogRef = this.modal.open<FieldVersionComponent, FieldVersionsData, any>(FieldVersionComponent, { data: new FieldVersionsData(marketingOrderId, model, highlevelKey), width: '900px', maxWidth: '90vw', maxHeight: '90vh' } );
    this.isDialogOpen = true;
    const component: FieldVersionComponent = dialogRef.componentInstance;

    component.close.subscribe(action => { this.isDialogOpen= false; return dialogRef.close(action)}, (error) => { throw new Error(error); });
    return dialogRef.afterClosed().pipe(finalize(() => this.isDialogOpen = false)).toPromise();
  }

  isFieldVersioDialogOpen() {
    return this.isDialogOpen
  };
}