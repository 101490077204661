import { Injectable } from '@angular/core';
import { ProductInstance } from '../models/product-instance.model';
import { ServiceStatusType } from '../models/service-status.type';
import { AppService } from './app.service';
import { ConciergeObligationTypes } from '../models/concierge-obligation.types';
import { ProductCode } from '../models/product.code';
import { OutputMimeType } from '../models/output-mime-type';
import { MarketingOrder, ProductCategory, ProductKind  } from '../models';
import { OutputFileFormatTypes } from '../models/output-file-format.types';
import { FeatureFlags, LaunchDarklyService } from './launch-darkly.service';
import { map } from 'rxjs/operators';
import { PromptDialogService } from './prompt-dialog.service';
import { UIConstants } from '../constants';
import { TemplateService } from './template.service';
import { of } from 'rxjs';

export type CustomNotificationType = 'COORDINATOR_WAITING' | 'REQUIRES_ASSETS' | 'REQUIRES_MODIFICATION' | 'CHANGE_DESIGN';
export type MessageLevelType = 'info' |'warn' | 'error';

export class ProductInfoNotification {
  constructor(public type: MessageLevelType, public htmlMessage: string, public customType?: CustomNotificationType) {
  }
}

export class ProductInfo {
  canEdit: boolean;
  notifications: ProductInfoNotification[];
}

@Injectable({
  providedIn: 'root'
})
export class ProductInfoService {
  // Static messages used to assert in the unit tests
  static agreeToStartProcessing = 'By editing this product you agree to start processing the order.';
  static moreInfoRequired = 'Before editing this product, please provide a property description and photos.';
  static coordinatorIsWaitingForMoreInfo = 'Your coordinator is waiting for the property description and photos to start working on this product.';
  static coordinatorIsAboutToStart = 'Your Coordinator is about to start working on this product. You can still Change Your Design';
  static agreeToStartCreating ='Please ensure that the product is accurate and ready to be rendered.';
  static contentIsBlockedByCoordinator = `The content is blocked because your coordinator is working on this product. We'll let you know when it's ready!`;
  static contentIsBlockedByAgentForReview = 'The content is blocked because the listing agent is reviewing the proof.';
  static readyForReview = `Your product is ready. Please review it and let us know if it's good to go!`;
  static readyToSendToPrint = `Ready to send your postcard to print? Approve and Continue below.`;
  static productWasApproved = `The product was approved!`;
  static productWasApprovedAndSentToPrint = `The product was approved and will be serviced`;
  static productWasCompleted = `The product was completed! You can keep editing if needed.`;
  static pleaseRejectProof = `We noticed you made changes to the video script proof. Please Request Revision and select the appropriate reason.`;

  constructor(private featureFlagService: LaunchDarklyService, private promptService: PromptDialogService, private templateService: TemplateService) { }

  private checkIfCoordinatorIsWaiting(product: ProductInstance): ProductInfoNotification {
    if(AppService.isAgentApp && product.status === ServiceStatusType.PENDING && product.details.conciergeObligation === ConciergeObligationTypes.FULL) {
      const message = ProductInfoService.coordinatorIsWaitingForMoreInfo;
      return new ProductInfoNotification('error', message, 'COORDINATOR_WAITING');
    }
  }

  private checkIfCoordinatorIsAboutToStart(product: ProductInstance): ProductInfoNotification {
    if(AppService.isAgentApp && product.status === ServiceStatusType.TODO && product.details.conciergeObligation === ConciergeObligationTypes.FULL) {
      return new ProductInfoNotification('warn', ProductInfoService.coordinatorIsAboutToStart, 'CHANGE_DESIGN');
    }
  }

  private checkIfUserAgreesToStart(product: ProductInstance): ProductInfoNotification {
    if(product.status === ServiceStatusType.TODO) {
      // Coordinator can start the order, but agent can only start if ConciergeObligation is not FULL
      if(AppService.isCoordinatorApp || (AppService.isAgentApp && product.details.conciergeObligation !== ConciergeObligationTypes.FULL)) {
        return new ProductInfoNotification('warn', ProductInfoService.agreeToStartProcessing);
      }
    }
  }

  private checkIfCoordinatorIsCreating(product: ProductInstance): ProductInfoNotification {
    if(AppService.isAgentApp && product.status === ServiceStatusType.CREATING && product.details.conciergeObligation === ConciergeObligationTypes.FULL) {
      return new ProductInfoNotification('warn', ProductInfoService.contentIsBlockedByCoordinator);
    }
  }

  private checkIfAgentIsProofing(product: ProductInstance): ProductInfoNotification {
    if(AppService.isAgentApp && product.status === ServiceStatusType.PROOFING) {
      if(product.isVideoService() && product.contentWasModified && !product.isInDeliveryLoop()){
        // The Video Product is not in the delivery loop yet, but the content was modified. Warn the user that they must reject
        // the proof in order to continue.
        return new ProductInfoNotification('warn', ProductInfoService.pleaseRejectProof);
      }
      return new ProductInfoNotification('warn', ProductInfoService.readyForReview);
    }
  }

  private checkIfUserCanStartCreation(product: ProductInstance): ProductInfoNotification {
    // Coordinators can create, but agents can as well as long as it is != FULL
    const userCanStartCreation = (AppService.isCoordinatorApp || product.details.conciergeObligation !== ConciergeObligationTypes.FULL);
    if(userCanStartCreation && product.status === ServiceStatusType.CREATING && !product.approval) {
      return new ProductInfoNotification('warn', ProductInfoService.agreeToStartCreating);
    }
  }

  private checkIfRequiredAssets(product: ProductInstance): ProductInfoNotification {
    if(AppService.isAgentApp && product.status === ServiceStatusType.PENDING && product.details.conciergeObligation !== ConciergeObligationTypes.FULL) {
      const message = ProductInfoService.moreInfoRequired;
      return new ProductInfoNotification('warn', message, 'REQUIRES_ASSETS');
    }
  }

  private checkIfAgentRejectedProof(product: ProductInstance): ProductInfoNotification {
    const isRejected = product.approval && !product.approval.approvedByAgent;
    if(AppService.isCoordinatorApp && product.status === ServiceStatusType.CREATING && isRejected) {
      return new ProductInfoNotification('error', 'The proof has been requested for revision. Please review details to know the reason why.', 'REQUIRES_MODIFICATION');
    }
  }

  private checkIfCoordinatorIsProofing(product: ProductInstance): ProductInfoNotification {
    if(AppService.isCoordinatorApp && product.status === ServiceStatusType.PROOFING) {
      return new ProductInfoNotification('info', ProductInfoService.contentIsBlockedByAgentForReview);
    }
  }

  private checkIfProductSentToPrint(product: ProductInstance): ProductInfoNotification {
    if(product.status === ServiceStatusType.DELIVERY) {
      return new ProductInfoNotification('info', ProductInfoService.productWasApprovedAndSentToPrint);
    }
  }

  private checkIfProductStateIsDone(product: ProductInstance): ProductInfoNotification {
    if(product.status === ServiceStatusType.DONE) {
      return new ProductInfoNotification('info', ProductInfoService.productWasCompleted);
    }
  }

  private checkIfProductStateIsReady(product: ProductInstance): ProductInfoNotification {
    if(AppService.isAgentApp && product.status === ServiceStatusType.READY && product.details.conciergeObligation === ConciergeObligationTypes.FULL) {
      return new ProductInfoNotification('info', ProductInfoService.readyToSendToPrint);
    }
    if(AppService.isCoordinatorApp && product.status === ServiceStatusType.READY && product.details.conciergeObligation === ConciergeObligationTypes.FULL) {
      return new ProductInfoNotification('info', ProductInfoService.productWasApproved);
    }
  }

  /**
   * Gets all notifications for the user (agent or coordinator) for a given product.
   * @param product The product instance we are requesting notifications for
   */
  getNotifications(product: ProductInstance): ProductInfoNotification[] {
    return [
      this.checkIfCoordinatorIsWaiting(product),
      this.checkIfCoordinatorIsAboutToStart(product),
      this.checkIfUserAgreesToStart(product),
      this.checkIfCoordinatorIsCreating(product),
      this.checkIfCoordinatorIsProofing(product),
      this.checkIfUserCanStartCreation(product),
      this.checkIfRequiredAssets(product),
      this.checkIfAgentRejectedProof(product),
      this.checkIfAgentIsProofing(product),
      this.checkIfProductSentToPrint(product),
      this.checkIfProductStateIsDone(product),
      this.checkIfProductStateIsReady(product)
    ].filter(notification => notification != null);
  }

  /**
   * Determines whether the current user (agent or coordinator) can edit the product instance or
   * if the overlay will display.
   * NOTE: This is different than whether they can view or navigate to the product
   * @param product The product instance we are confirming
   */
  canEdit(product: ProductInstance) {
    if(product == null) { return true; }

    if(!this.canView(product)) {
      // If we can't view the product, we also cannot edit the product
      return false;
    }

    if(AppService.isCoordinatorApp) {
      // Coordinators can only edit the products when they are in the following statuses
      const validCoordinatorStatuses = [ ServiceStatusType.TODO, ServiceStatusType.CREATING, ServiceStatusType.DONE, ServiceStatusType.REORDER ];
      return this.isInStatus(product, validCoordinatorStatuses);
    }

    // Agents can only edit the products based on the concierge obligation and the product status
    // The Agent can modify the following status, regardless of conciergeObligation
    const validStatuses: ServiceStatusType[] = [ ServiceStatusType.PROOFING, ServiceStatusType.READY, ServiceStatusType.DONE, ServiceStatusType.REORDER ];
    switch(product.details.conciergeObligation) {
      case ConciergeObligationTypes.PARTIAL:
        // Add additional statuses when conciergeObligation = 'PARTIAL'
        validStatuses.push(ServiceStatusType.TODO, ServiceStatusType.CREATING);
        break;
      case ConciergeObligationTypes.NONE:
         // Add additional statuses when conciergeObligation = 'NONE'
        validStatuses.push(ServiceStatusType.TODO, ServiceStatusType.CREATING);
        break;
    }
    return this.isInStatus(product, validStatuses);
  }

  /**
   * Determines whether the user (agent or coordinator) can view the product instance.
   * NOTE: This is different than canEdit(...)
   * @param product The product instance we are confirming
   */
  canView(product: ProductInstance) {
    if(product.category === ProductCategory.MANUAL) {
      return false;
    }

    if(product.title === 'Professional Photography') {
      // Allow edit of Professional Photography Product regardless of app or status
      return true;
    }
    if(AppService.isCoordinatorApp) {
      switch(product.details.conciergeObligation) {
        case ConciergeObligationTypes.NONE:
          // Coordinator cannot view any products with the obligation = NONE
          return false;
      }
      // Coordinator cannot view PENDING order statuses, regardless of concierge obligation
      return product.status !== ServiceStatusType.PENDING;
    }
    return true;
  }

  /**
   * Determines if the user (agent or coordinator) can see the "Send for Approval"
   */
  showSendForApproval(product: ProductInstance) {
    // Only Coordinators can send for approval when the status is CREATING, but can view it when it is TODO
    // The LCC shouldn't be able to view Concierge === NONE products
    // This should not be depended on product type or previous approvals (Confirmed)
    return AppService.isCoordinatorApp
      && product.details.conciergeObligation !== ConciergeObligationTypes.NONE
      && this.isInStatus(product, [ServiceStatusType.TODO, ServiceStatusType.CREATING]);
  }

  /**
   * Determines if the user(agent or coordinator) can send the product for approval
   * TODO: Can this be removed now? ShowSendForApproval is the same exact logic
   */
  canSendForApproval(product: ProductInstance) {
    // Only Coordinators can send for approval when the status is CREATING.
    // The LCC shouldn't be able to view Concierge === NONE products
    // This should not be depended on product type or previous approvals (Confirmed)
    return AppService.isCoordinatorApp
      && this.isInStatus(product, [ServiceStatusType.CREATING, ServiceStatusType.TODO])
      && product.details.conciergeObligation !== ConciergeObligationTypes.NONE;
  }

  /**
   * Determines if the Reject Proof button is disabled for the user (agent or coordinator)
   * @param product The product to verify
   */
  showRejectProof(product: ProductInstance) {
    // Allow agents to reject FULL and PARTIAL services
    const acceptableRejectionTypes = [ConciergeObligationTypes.FULL, ConciergeObligationTypes.PARTIAL];

    // Only Agents can reject proofs when they are in the PROOFING status, but it is still displayed in CREATING status
    // However, the only time it is visible is when ConciergeObligation matches FULL or PARTIAL
    return AppService.isAgentApp
      && (
        !product.isInDeliveryLoop()
        && !product.isVideoService()
        && acceptableRejectionTypes.includes(product.details.conciergeObligation)
        && this.isInStatus(product, [ServiceStatusType.PROOFING]))
      || (
        !product.isInDeliveryLoop()
        && product.isVideoService()
        && this.isInStatus(product, [ServiceStatusType.PROOFING])
        && product.statusHistory.every(history => history.transitionedTo !== ServiceStatusType.DELIVERY)
      );
  
  }

  /**
   * Determines if the user (agent or coordinator) can reject the proof
   * @param product
   */
  canRejectProof(product: ProductInstance) {
    // Allow agents to reject FULL and PARTIAL services
    const acceptableRejectionTypes = [ConciergeObligationTypes.FULL, ConciergeObligationTypes.PARTIAL];

    // Only Agents can reject proofs when the status is PROOFING.
    // However, the only time it is visible is when ConciergeObligation === FULL
    return AppService.isAgentApp
      && !product.isInDeliveryLoop()
      && this.isInStatus(product, [ServiceStatusType.PROOFING])
      && acceptableRejectionTypes.includes(product.details.conciergeObligation);
  }

  /**
   * A few UI interactions are based on where a product has traveled in its workflow. This function returns a
   * boolean based on whether the product is in the correct current state as well as the correct
   * previous state
   * @param product
   * @param currentState
   * @param previousState
   */
  hasLastTwoStates(product: ProductInstance, currentState: ServiceStatusType, previousState: ServiceStatusType): boolean {
    const length = product.statusHistory.length;
    const index = length-1;

    // if a previousState was passed in but the status history is less than 2 return false;
    if(previousState && length < 2) { return false; }

    return length > 0
      && product.statusHistory[index].transitionedTo === currentState
      && length > 1 ? product.statusHistory[index-1].transitionedTo === previousState : true;
  }

  /**
   * Checks the product before submitting any updates. If valid, returns true. Otherwise, returns false to prevent update
   * @param product The product to be checked
   * @param scriptChanged
   */
  async validateProductUpdates(product: ProductInstance, scriptChanged: boolean): Promise<boolean> {
    if(AppService.isAgentApp && product?.isVideoService() && product?.status === ServiceStatusType.PROOFING && scriptChanged) {
      // Retrieve if the product has already begun the PROOFING --> DELIVERY loop
      const isInDeliveryLoop = product.isInDeliveryLoop()
      if(!isInDeliveryLoop && !product.contentWasModified) {
        // If the product is in PROOFING for the first time, prompt the user before they make any adjustments
        const message = `Any modification to the script will require you to request for revision. The Listing Concierge Coordinator will review your changes and provide you with an updated script proof.`;
        const response = await this.promptService.openPrompt('Confirm', message, UIConstants.CONFIRM, [UIConstants.CANCEL]);
        if(response?.text !== UIConstants.CONFIRM) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Determines if the Approve Proof button is enabled for the user (agent or coordinator)
   * @param product The product to verify
   */
  showApproveProof(product: ProductInstance) {
    // Only Agents can reject proofs when they are in the PROOFING status, but it is still displayed in CREATING status
    // However, the only time it is visible is when ConciergeObligation !== NONE
    return AppService.isAgentApp
      && ((this.isInStatus(product, [ServiceStatusType.PROOFING])
      && product.details.conciergeObligation !== ConciergeObligationTypes.NONE) ||
        (this.isInStatus(product, [ServiceStatusType.CREATING])
          && product.details.conciergeObligation === ConciergeObligationTypes.FULL));
  }


  /**
   * Determines if the user (agent or coordinator) can reject the proof
   * @param product
   */
  canApproveProof(product: ProductInstance) {
    // Only Agents can reject proofs when the status is PROOFING.
    // However, the only time it is visible is when ConciergeObligation !== NONE
    const canApproveProduct = AppService.isAgentApp
      && this.isInStatus(product, [ServiceStatusType.PROOFING])
      && product.details.conciergeObligation !== ConciergeObligationTypes.NONE;

    if(this.isVideoProduct(product)) {
      // Video products can be APPROVED multiple times while in the PROOFING --> DELIVERY statuses,
      // until it reaches cutoff or is marked as DONE
      const isInDeliveryLoop = product.isInDeliveryLoop();
      return canApproveProduct && (
        // We can approve if we are in the delivery loop
        // Otherwise, content was modified must be false, signifying no changes has been made since PROOF request was made
        isInDeliveryLoop || (!isInDeliveryLoop && !product.contentWasModified)
      )
    }

    // Product is not a video product. Just return canApproveProduct
    return canApproveProduct;
  }

  /**
   * Determines if the "Continue to Print" button is enabled for the user (agent or coordinator)
   * @param product The product to verify
   */
  showContinueToPrint(product: ProductInstance) {
    // Only Agents can send products to print when they are in the READY status
    // However, the only time it is visible is when ConciergeObligation === FULL
    return AppService.isAgentApp
      && this.isInStatus(product, [ServiceStatusType.READY])
      && product.details.conciergeObligation === ConciergeObligationTypes.FULL;
  }

  /**
   * Determines if an "assisted" or a "self-service" product can be assent-transitioned
   * to a DONE state from the CREATING state on both assisted and non-assisted products
   * by an Agent and only an Agent.
   */
  showCompleteSelfServiceOrAssistedService(product: ProductInstance) {
    return AppService.isAgentApp
      && (product.details.conciergeObligation === ConciergeObligationTypes.NONE || product.details.conciergeObligation === ConciergeObligationTypes.PARTIAL)
      && (!product.isVideoService() && this.isInStatus(product, [ServiceStatusType.CREATING, ServiceStatusType.TODO])
        || product.isVideoService() && this.isInStatus(product, [ServiceStatusType.TODO, ServiceStatusType.CREATING, ServiceStatusType.PROOFING])
      );
  }

  /**
   * Disables Continue for a video "self-service" product.
   */
  disableContinueForVideoSelfService(product: ProductInstance) {
    return AppService.isAgentApp
      && product.isVideoService()
      && this.isInStatus(product, [ServiceStatusType.DELIVERY])
      && (product.details.conciergeObligation === ConciergeObligationTypes.NONE || product.details.conciergeObligation === ConciergeObligationTypes.PARTIAL);
  }

  /**
   * Determines if the "Change Design" button is enabled for the user (agent or coordinator)
   * @param product The product to verify
   */


  showChangeDesign$(marketingOrder: MarketingOrder, product: ProductInstance) {
    if(product.category === ProductCategory.VIDEO || !this.canEdit(product)) {
      return of(false); // Quick return if product category is video
    }
    return this.templateService.getProductTemplates(marketingOrder._id, product.code).pipe(
      // Only allow change deisng if templates if global luxary matches the flag
      map(templates => this.templateService.filterTemplatesForOrder(marketingOrder, templates || []).length > 1)
    );
  }

  /**
   * Determines if the "Share" button is enabled for the user (agent or coordinator)
   * @param product The product to verify
   */
  showShareProduct$(product: ProductInstance) {
    // Can only share the product if it is shareable and status is in DELIVERY || DONE
    return (product.details.isShareable
      && this.isInStatus(product, [ServiceStatusType.DELIVERY, ServiceStatusType.DONE]));
  }

   /**
   * Determines if the "Re-order" button is enabled for the user (agent or coordinator)
   * @param product The product to verify
   */
  showReorderProduct$(product: ProductInstance) {
    // Can only share the product if it is shareable and status is in DELIVERY || DONE
    return this.featureFlagService.getFeature$(FeatureFlags.PRODUCT_REORDER, false).pipe(
      map(isFeatureEnabled => isFeatureEnabled
        && (AppService.isAgentApp && product.kind === ProductKind.PRODUCT && product.category === ProductCategory.PRINT &&
          (product.status === ServiceStatusType.DONE || product.status === ServiceStatusType.REORDER) && product.isVendorPrinted))
    )
  }

  /**
   * Determines if the "Show/Hide tag Visibility" button is enabled for the user (agent or coordinator)
   * @param product The product to verify
   */
   showHideUnhideTag$(product: ProductInstance) {
    // Can only share the product if the product kind is Product and categoty is print   
    return this.featureFlagService.getFeature$(FeatureFlags.LCC_QUICK_ADMIN, false).pipe(
      map(isFeatureEnabled => isFeatureEnabled && product.kind === ProductKind.PRODUCT && product.category === ProductCategory.PRINT))
  }


  /**
   * Determines if the custom contact block should be displayed
   * @param product The product to verify
   */
  showCustomContactBlock$(product: ProductInstance) {
    // Only display contact block for coordinators if the feature is enabled and it is a print product
    return this.featureFlagService.getFeature$(FeatureFlags.CUSTOM_CONTACT_BLOCK, false).pipe(
      map(isFeatureEnabled => isFeatureEnabled && AppService.isCoordinatorApp && product.category === ProductCategory.PRINT && product.selectedTemplate?.templateInfo?.textTags?.includes('contactTextBlock'))
    )
  }


  /**
   * Determines if a product is a website or not
   * @param product The product to verify
   */
  isImageProduct(product: ProductInstance) {
    return product.details.outputFileFormat === OutputFileFormatTypes.jpg || (product.selectedTemplate && product.selectedTemplate.mimeType === OutputMimeType.jpg);
  }

  isVideoProduct(product: ProductInstance) {
    return product.code === ProductCode.TELEVISION_AND_VIDEO
     || product.code === ProductCode.PROFESSIONAL_VIDEO
     || product.code === ProductCode.VIDEO_SLIDESHOW;
  }

  isTelevisionProduct(product: ProductInstance) {
    return product.code === ProductCode.TELEVISION_AND_VIDEO;
  }


  isAdvertisingProduct(product: ProductInstance) {
    return product.code === ProductCode.PRINT_ADVERTISING;
  }

  /**
   * Determines if a product is a website or not
   * @param product The product to verify
   */
  isWebsiteProduct(product: ProductInstance) {
    return product.code === ProductCode.WEBSITE || (product.selectedTemplate && product.selectedTemplate.mimeType === OutputMimeType.html);
  }

  /**
   * Determines if the product has been approved
   * @param product The product to verify
   */
  isApproved(product: any): boolean {
    return this.isInStatus(product, [ServiceStatusType.CREATING, ServiceStatusType.PROOFING])
      && this.hasBeenApprovedByAgent(product);
  }

  /**
   * Checks to see if the product is in any one of the statuses passed in
   * @param product The product to verify
   * @param statuses Accepted statuses
   */
  private isInStatus(product: ProductInstance, statuses: ServiceStatusType[]) {
    return statuses.some(status => status === product.status);
  }

  /**
   * Determines if the product has already been approved (or rejected) by an agent
   * @param product The product to verify
   */
  private hasBeenApprovedByAgent(product: ProductInstance) {
    return product.approval && product.approval.approvedByAgent;
  }

  public hasDownloadLink(product: ProductInstance, branded: boolean): boolean {
    return this.hasCorrectLink(product, branded, 'amazon');
  }

  public hasCopyLink(product: ProductInstance, branded: boolean): boolean {
    const youtubeLink = this.hasCorrectLink(product, branded, 'youtube');
    const amazonLink = this.hasCorrectLink(product, branded, 'amazon');
    return branded ? youtubeLink : (youtubeLink || amazonLink);
  }

  private hasCorrectLink(product: ProductInstance, branded: boolean, source: string): boolean {
    const uriType = branded ? 'brandedvideo' : 'unbrandedvideo';
    return product.publishedUris.filter(video => video.uriType === uriType && video.uri.indexOf(source) >= 0).length > 0;
  }


}
