import { Pipe, PipeTransform } from '@angular/core';
import { ServiceStatusType } from '../models/service-status.type';

@Pipe({
  name: 'statusDisplay'
})
export class StatusDisplayPipe implements PipeTransform {

  /**
   * Transforms the status from the UI to a friendly display
   */
  transform(val: string, args?: any) {
    if(!val) { return val; }

    switch(val) {
      case ServiceStatusType.TODO:
        return 'To-Do';
      default:
        return capitalize(val.toLowerCase().replace(/_/g, ' '));

    }
  }
}


const capitalize = (str) => {
  if(typeof str === 'string') {
    return str.replace(/^\w/, c => c.toUpperCase());
  } else {
    return '';
  }
};
