
export enum UploadTypes {
  MAILING_LIST_PUT = 'MAILING_LIST_PUT'
}

export class UploadResponse {

  type: string;
  expiration: number;
  url: string;
  options: any;

  constructor(input?: any) {
    Object.assign(this, input || {});
  }
}
