import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiClient } from './api.client';
import { IContactRuleSpecs, MarketingOrderContacts } from '../models/marketing-order-contacts.model';
import { AppService } from '../services/app.service';

/**
 *  Contains all of the methods required to communicate with the marketing-orders/:id/contact-info API.
 *  Should not contain methods with business logic. This is only for API communication
 */
@Injectable({
  providedIn: 'root'
})
export class MarketingOrderContactsClient {

  private apiBaseUrlV1:string = AppService.get('persistBaseURL');
  private apiBaseUrlV2 = this.apiBaseUrlV1.replace('/v1', '/v2');

  constructor(private apiClient: ApiClient) {
  }

  private getEndpoint(orderId: string) {
    return `${this.apiBaseUrlV2}orders/${orderId}/contact-info`
  }

  private getRulesEndpoint(marketingOrderId) {
    return `${this.apiBaseUrlV1}marketing-orders/${marketingOrderId}/get-rules`
  }

  private getContactConsentEndpoint(marketingOrderId, productCode){
    return `${this.apiBaseUrlV1}marketing-orders/${marketingOrderId}/consent/${productCode}`
  }

  getRuleCheck(marketingOrderId: string): Observable<IContactRuleSpecs>{
    return this.apiClient.get(this.getRulesEndpoint(marketingOrderId));
  }

  /**
   * Queries the API and returns the contact info for a given marketingOrderId
   */
  get(marketingOrderId: string): Observable<MarketingOrderContacts> {
    return this.apiClient.get<MarketingOrderContacts>(this.getEndpoint(marketingOrderId))
      .pipe(map(info => new MarketingOrderContacts(info)));
  }
}
