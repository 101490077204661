import { Deserializable } from './deserializable.model';
import { PhoneNumber } from './phone-number.model';

export class PhotograperDetails implements Deserializable<PhotograperDetails> {

  _id: string;
  phoneNumber: PhoneNumber;
  firstName: string;
  lastName: string;
  username: string;
  email: string;

  constructor(input?: any) {
    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): PhotograperDetails {
    Object.assign(this, input);
    return this;
  }

}
