import { Injectable } from '@angular/core';
import { UIConstants } from '../constants/UI.constants';
import { ConciergeObligationTypes } from '../models/concierge-obligation.types';
import { MarketingOrder } from '../models/marketing-order.model';
import { ProductInstance, ProductInstanceApproval } from '../models/product-instance.model';
import { ServiceActions, ServiceStatusType } from '../models/service-status.type';
import { MarketingOrderProductService } from './marketing-order-product.service';
import { MarketingOrderService } from './marketing-order.service';
import { ProductContentService } from './product-content.service';
import { PromptDialogService } from './prompt-dialog.service';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root'
})
export class ProductStateService {

  constructor(private productContentService: ProductContentService,
              private marketingOrderService: MarketingOrderService,
              private productService: MarketingOrderProductService,
              private promptDialog: PromptDialogService,
              private toasterService: ToasterService,
              private dialogService: PromptDialogService) {
  }

  async completeProduct(marketingOrder: MarketingOrder, product: ProductInstance) : Promise<void | boolean>{

    if (product.status === ServiceStatusType.TODO) {
      // Transition the marketing order to CREATING first
      this.productContentService.populateProductFromMarketingOrder(marketingOrder, product);
      await this.marketingOrderService.updateOrder(marketingOrder)
        .catch((error: Error) => {this.toasterService.showError(`Failed to update order ${error.message}`)});

      if(product.details.conciergeObligation === ConciergeObligationTypes.PARTIAL && product.isVendorPrinted) {
        return await this.productService.productAction(marketingOrder._id, ServiceActions.START, product)
        .then(() => { this.toasterService.showInfo('Product has been updated!')
        return true; })
        .catch((error: Error) =>  this.toasterService.showError(error.message));
        }
    }

    if (product.status === ServiceStatusType.CREATING) {
      // Transition the marketing order to CREATING first
      this.productContentService.populateProductFromMarketingOrder(marketingOrder, product);
      await this.marketingOrderService.updateOrder(marketingOrder)
        .catch((error: Error) => this.toasterService.showError(`Failed to update order ${error.message}`));

      if(product.details.conciergeObligation === ConciergeObligationTypes.PARTIAL && product.isVendorPrinted) {
        return await this.productService.productAction(marketingOrder._id, ServiceActions.PARTIALREVIEW, product)
        .then(() => { this.toasterService.showInfo('Product has been updated!')
        return true; })
        .catch((error: Error) =>  this.toasterService.showError(error.message));
        }
    }

    if(product.details.conciergeObligation === ConciergeObligationTypes.NONE || !product.isVendorPrinted) {
      return await this.productService.productAction(marketingOrder._id, ServiceActions.DELIVER, product)
        .then(() => { this.toasterService.showInfo('Product has been completed!')
          return true; })
        .catch((error: Error) => this.toasterService.showError(error.message));
    }
  }

  async sendForApproval(marketingOrder: MarketingOrder, product: ProductInstance) {
    if(!(product && marketingOrder)) { return; }

    // Validate the product and then update if it is valid
    const isValid = await this.validateProduct(marketingOrder, product);
    if(!isValid) { return; }

      //Copying all photos & description to product level when it sent for approval.
      if (product.status === ServiceStatusType.TODO) {
        // this.productContentService.populateProductFromMarketingOrder(marketingOrder, product);
         await this.marketingOrderService.updateOrder(marketingOrder).then(
          ()=> this.productService.productAction(marketingOrder._id, ServiceActions.REVIEW, product)
         .then(() => this.toasterService.showInfo('Product has been sent for approval!'))
         .catch((error: Error) => this.toasterService.showError(error.message))

         );
       }
       else
       {

       await this.productService.productAction(marketingOrder._id, ServiceActions.REVIEW, product)
         .then(() => this.toasterService.showInfo('Product has been sent for approval!'))
         .catch((error: Error) => this.toasterService.showError(error.message));
       }

      }

  async approve(marketingOrder: MarketingOrder, product: ProductInstance): Promise<void | boolean> {
    if(!(product && marketingOrder)) { return; }

    // Validate the product and then update if it is valid
    const isValid = await this.validateProduct(marketingOrder, product);
    if(!isValid) { return; }

    if(product.isVideoService()) {
      const message = `
        You are about to request a video product to be created.
        You will have to wait till the video is finalized and sent back before making any edits.
        Are you sure you want to proceed?
      `;
      const response = await this.promptDialog.openPrompt('Confirm', message, UIConstants.YES, [UIConstants.NO]);
      if(response?.text !== UIConstants.YES) {
        return; // They did not confirm. Exit
      }
    }

    product.approval = new ProductInstanceApproval({approvedByAgent: true});
    return await this.productService.productAction(marketingOrder._id, ServiceActions.APPROVE, product)
      .then(() => {
        this.toasterService.showInfo('Product has been approved!');
        return true;
      })
      .catch((error: Error) => this.toasterService.showError(error.message))

  }

  /**
   * Validates the product before a state-transition.
   * NOTE: This is a UI only validation and will need to be implemented on the backend, but for the scope of LC-3966
   * we are only doing front-end validation
   **/
  async validateProduct(order: MarketingOrder, product: ProductInstance) {
    const productInfo: any = new Object();

    if (!product.isVideoService()) {
      productInfo.headline = product?.selectedTemplate?.templateInfo?.headlineInfo,
        productInfo.body = product?.selectedTemplate?.templateInfo.bodyInfo;
    } else {
      productInfo.body = product?.details?.bodyLayouts;
    }

    if (productInfo?.body || productInfo?.headline) {
      const isBodyTemplateValid = this.validateMaxChars(product, order, productInfo.body, 'marketingCopyBody');
      const isHeadlineTemplateValide = this.validateMaxChars(product, order, productInfo.headline, 'marketingCopyHeadline');

      if (!isBodyTemplateValid || !isHeadlineTemplateValide) {
        await this.dialogService.openPrompt('Invalid', 'The product is invalid and cannot be submitted', 'Ok');
      }

      return isBodyTemplateValid && isHeadlineTemplateValide;
    }
    return true;
  }

  /**
   * product - Selected product e.g. Video / Advertise / etc.
   * order - Specific marketing order
   * info - The values to select from product for comparison
   * array - The array containing the maxChar values for comparison
   * Purpose: Returns true or false if inputs do not exceed the max limit for a specified product.
   */
  validateMaxChars(product, order, info, array) {
    // Make sure the body layout is valid. If no body layouts exist, the function will .every(...) function will return true
    return (info || [])?.every((bodyInfo, index) => {
      // Get the body from the product. If not on the product, default it from the order
      const body = (product[array] || [])[index] || (order[array] || [])[index];
      // Body length exceeds the maxCharacters. Return false for invalid
      if(bodyInfo.maxChars && (body || '').length > bodyInfo.maxChars) {
        return false;
      }
      return true;
    })
  }
}
