import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MarketingOrdersAreasService {
  private cachedAreas$: Observable<string[]>;
  private cachedPackageTitles$: Observable<string[]>;
  private cachedPackages$: Observable<string[]>;

  constructor(private apiService: ApiService) { }

  /**
   * Get all options.
   */
  getAreas$(): Observable<string[]> {
    if(!this.cachedAreas$) {
      // tslint:disable-next-line: rxjs-no-sharereplay
      this.cachedAreas$ = this.apiService.get<string[]>('marketing-orders/areas');
    }
    return this.cachedAreas$;
  }

  getPackages$(): Observable<string[]> {
    if (!this.cachedPackages$) {
      this.cachedPackages$ = this.apiService.get<string[]>('package-instances/my-packages');
      if (this.cachedPackages$) {
        this.getPackagesArray(this.cachedPackages$)
      }
    }
    return this.cachedPackageTitles$;
  }

  getPackagesArray(data) {
    const packArray = [];
    data.forEach(element => {
      element.filter(dt => {
        if (dt?.title) {
          packArray.push(dt.title + '-' + dt.code);
        }
      })
    });
    this.cachedPackageTitles$ = of(packArray)
  }

}
