import { Injectable } from '@angular/core';
import { HotKeyEvent, HotkeyEventService } from '@lc/core';


export enum altKeys { // for alt key and its combo keys
  n = 'NOTESDIALOG'
}

export enum shiftKeys { // for shift key and its combo keys
}

export enum ctrlKeys { // for control key and its combo keys
}

@Injectable({
  providedIn: 'root'
})

export class HotKeyService {

  constructor(private hotkeyEventService: HotkeyEventService) { }

  initialaizeHotKeyEvent() {
    let action;
    let keysPressed = {};
    document.addEventListener("keydown", (e) => {
      keysPressed[e.key] = true;
      if (keysPressed['Alt']) {
        action = altKeys[e.key];
      } else if (keysPressed['Shift']) {
        action = shiftKeys[e.key];
      } else if (keysPressed['ctrl']) {
        action = ctrlKeys[e.key];
      }
      if (action) {
        console.log(`Opened ${action} using hotkey`);
        keysPressed = {};
        const event = new HotKeyEvent(e.key, action);
        this.hotkeyEventService.getEventEmitter().emit(event);
        action = null;
      }
    });
  }

}
