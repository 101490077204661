
import {
  Input,
  Output,
  OnInit,
  OnChanges,
  Component,
  ViewChild,
  TemplateRef,
  ContentChild,
  HostListener,
  EventEmitter,
  AfterViewInit
} from '@angular/core';
import Sortable from 'sortablejs';

export interface DragItem {
  disabled?: boolean;
}
/**
 * We use sortableJS to handle our photo drag and drop as it has the functionality we want out-of-the-box
 */
@Component({
  selector: 'lc-drag-n-drop',
  templateUrl: './drag-n-drop.component.html',
  styleUrls: ['./drag-n-drop.component.scss']
})
export class DragNDropComponent implements AfterViewInit, OnInit, OnChanges {

  @ViewChild('mainContainer')
  mainContainer;

  @ContentChild(TemplateRef)
  itemTemplate: TemplateRef<any>;

  @HostListener('end', ['$event']) onDrop(event) {
    const temp = this.items[event.oldIndex];
    this.items.splice(event.oldIndex, 1);
    this.items.splice(event.newIndex, 0, temp);
    this.reorder.emit(this.items);
    if(this.isFirefox) {
      event.item.style = 'pointer-events:all';
    }
  }

  // Remove pointer events from dragged item.
  @HostListener('start', ['$event']) onStart(event) {
    if(this.isFirefox){
      event.item.style = 'pointer-events:none';
    }
  }


  @Input()
  canDrag: boolean = true;

  @Input()
  containerClass: string;

  @Input()
  itemClass: string;

  @Input()
  isManagedPhotos: boolean;

  @Input()
  imagesProcessing: boolean;

  @Input()
  items: any[];

  @Output()
  readonly reorder: EventEmitter<any[]> = new EventEmitter();

  public gridPlaceholder: boolean = true;

  public sortable: Sortable;

  public isFirefox: boolean;

  constructor() {}

  ngOnChanges(changes: any) {
    // Ensure the itemClass is assigned to the
    // draggable option for sortable instance.
    if(changes?.itemClass && this.sortable) {
      this.sortable.options.draggable = `.${changes.itemClass.currentValue}`;
    }
    if (changes?.canDrag && this.sortable) {
      this.sortable.options.disabled = !this.canDrag
    }
  }

  ngOnInit(): void {
    if(this.itemClass === 'manage-photo-grid'){
      this.gridPlaceholder = false;
    }
    // If firefox, we use the forceFallback option in our sortable instance(s).
    // This will use a sortableJS instance that will work better with firefox.
    this.isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  }

  ngAfterViewInit() {
    const el = this.mainContainer.nativeElement;
    this.sortable = Sortable.create(el, {
      group: 'shared',
      animation: 250,
      disabled: !this.canDrag || this.isManagedPhotos,
      forceFallback: this.isFirefox,
      // Only allow draggable items that match itemClass
      draggable: `.${this.itemClass}`
    });
  }
}
