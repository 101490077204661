import { Deserializable } from './deserializable.model';

export class ComponentMetaData implements Deserializable<ComponentMetaData> {
  tagname: string;
  width: number;
  height: number;
  fontFamily: string;
  fontStyle: string;
  fontPointSize: number;
  pageNumber: number;
  maxChars: number;

  constructor(data?: any) {
    this.deserialize(data || {});
  }

  deserialize(input: any): ComponentMetaData {
    Object.assign(this, input);
    return this;
  }
}

export class TemplateInstanceMetaData implements Deserializable<TemplateInstanceMetaData> {

  templateURL: string;
  headlineCount: number;
  bodyCount: number;
  photoCount: number;
  headlineInfo: ComponentMetaData[];
  bodyInfo: ComponentMetaData[];
  photoInfo: ComponentMetaData[];
  pageCount: number;
  pageWidth: number;
  pageHeight: number;

  constructor(data?: any) {
    this.deserialize(data || {});
  }

  deserialize(input: any): TemplateInstanceMetaData {
    Object.assign(this, input);
    if(this.photoInfo?.length) {
      this.photoInfo = this.photoInfo.sort((photoA, photoB) => {
        // Get the photo index from the tag name (i.e. Photos[1] ==> 1, Photos[2] ==> 2)
        const indexA = +photoA.tagname.match(/\d/g);
        const indexB = +photoB.tagname.match(/\d/g);
        return indexA - indexB; // Sort Ascending Order (i.e. photos[0], photos[1], photos[2], etc...)
      })
    }
    return this;
  }

  /**
   * @return - string containing the page size e.g: 8.5 x 11
   */
  get pageSizeDescription() {
    return (this.pageWidth && this.pageHeight) ? `${this.pageWidth} x ${this.pageHeight}` : null;
  }
}
