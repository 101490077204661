import { Deserializable } from './deserializable.model';

export class ActivityAudit implements Deserializable<ActivityAudit> {
  objectId: string;
  userId: string;
  userFullName: string;
  changeDate: Date;
  change: string;

  constructor( input?: any) {
    this.deserialize( input || {});
  }

  deserialize(input: any): ActivityAudit {
    Object.assign(this, input);
    this.changeDate = new Date(input.changeDate);
    return this;
  }
}
