import { ActionTypes, WorkflowAction } from './workflow.actions';
import { FlowState } from '../../models/flow-state.model';

export let initialWorkflow = null;

function updateStepState(data, action) {
  if (action.data.stepCompleted) {
    data.getCurrentStep().setCompleted(true);
  }
  if (action.data.parentCompleted) {
    data.getCurrentStep().parent.setCompleted(action.data.parentCompleted);
  }
  data.getCurrentStep().setStarted(true);
  if (action.data.stateData) {
    data.getCurrentStep().stateData = action.data.stateData;
  }
}

/**
 * This function will create a new state object from an action. See ngrx.io for the architecture
 * of reducers.
 *
 * @param data the current state
 * @param action the action
 * @return FlowState the new flow state
 */
export function workflowReducer(data = initialWorkflow, action: WorkflowAction): FlowState {

  if (action.type !== ActionTypes.WorkflowActionInitialize && action.data && action.data.state) {
    throw new Error('Only WorkflowActionInitialize action may include data.state');
  }

  switch (action.type) {
    case ActionTypes.WorkflowActionInitialize:
      return action.data.state;
    case ActionTypes.WorkflowActionTerminate:
      return null;
    case ActionTypes.WorkflowActionNext:
      updateStepState(data, action);
      data.nextStep(data.getCurrentStep());
      return new FlowState({...data});
    case ActionTypes.WorkflowActionPrev:
      updateStepState(data, action);
      data.prevStep(data.getCurrentStep());
      return new FlowState({...data});
    case ActionTypes.WorkflowActionNextIncomplete:
      // if not started. Start the work
      if (!data.getCurrentStep()) {
        data.start();
        return new FlowState({...data});
      }
      updateStepState(data, action);
      data.nextIncompleteStep(data.getCurrentStep(), true);
      return new FlowState({...data});
    case ActionTypes.WorkflowActionEdit:
      data.setStep(action.data.id);
      updateStepState(data, action);
      if (data.getCurrentStep().hasSubsteps()){
        data.nextStep(data.getCurrentStep());
      }
      return new FlowState({...data});
    case ActionTypes.WorkflowActionSetStep:
      if (!data) {
        console.log('Workflow is not initialized');
        return data;
      }
      if (data.getCurrentStep() && data.getCurrentStep().id === action.data.id) {
        return data;
      } else {
        data.setStep(action.data.id);
        return new FlowState({...data});
      }
    case ActionTypes.WFAUpdateStateData:
      if (data.getCurrentStep()) {
        data.getCurrentStep().stateData = action.data.stateData;
        return new FlowState({...data});
      } else {
        return data;
      }
    default:
      return data;
  }
}
