import { FormControl, FormArray, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { BaseForm } from './base.form';
import { PhotoAgency } from '../models/photography-agency.model';
import { PhotograperDetails } from '../models/photographer-details.model';
import { PhoneNumberForm } from './phone-number.form';
import { PhotographerDetailsForm } from './photographer-details.form';
import { emailValidator, urlValidator, CustomValidator } from '../validators';
export class PhotographyAgencyForm extends BaseForm<PhotoAgency> {
  get photographerDetails(): FormArray { return this.getControl('photographerDetails') as FormArray; }

  constructor(value?: PhotoAgency) {
    super({
      _id: new FormControl(),
      code: new FormControl(Math.floor(Math.random() * 10000000 + 1)),
      title: new FormControl(null, [
        Validators.required,
        Validators.maxLength(30)
      ]),
      agencyPhoneNumber: new PhoneNumberForm(),
      agencyEmail: new FormControl(null, [
        Validators.required,
        emailValidator()
      ]),
      photographerDetails: new FormArray([]),
      thumbnailUri: new FormControl(null, [Validators.required]),
      coverageZipCodes: new FormControl(null, [Validators.required]),
      productCodeServiced: new FormControl(),
      vendorOverrides: new FormControl(),
      useCustomScheduling: new FormControl(value?.useCustomScheduling || false),
      customSchedulingUrl: new FormControl(value?.customSchedulingUrl),
      description: new FormControl(value?.description)
    });

    this.getControl('customSchedulingUrl').setValidators([urlValidator(), this.requiredIfUseCustomScheduling(this.getControl('useCustomScheduling'))])
    if (value) {
      this.patchValue(value);
      const photographers = (value.photographerDetails as Array<PhotograperDetails>) || [];
      photographers.forEach(photo => {

        (this.get('photographerDetails') as FormArray).push(
           new PhotographerDetailsForm(photo)
        )
      });
    }
  }

  private requiredIfUseCustomScheduling(useCustomSchedulingControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      // Pronunciation control is not required
      const isRequired =  useCustomSchedulingControl.value;
      return isRequired ? CustomValidator.required(control) : null;
    };
  }
}
