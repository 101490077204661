import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AppService } from './app.service';
import { HeadersEnum } from '../models/headers.enum';

/** Additional Options that can be used when making the request */
class ApiOptions {
  /** Signifies the ErrorInterceptor to not log errors if one is returned (i.e. - Coupon not found) */
  supressLogErrors?: boolean;
}

@Injectable()
export class ApiService {

  baseUrl: string = AppService.get('persistBaseURL');
  pdfRenderUrl: string = AppService.get('pdfRenderBaseURL');
  printerBaseUrl: string = AppService.get('printerBaseURL');
  batchProcessBaseURL: string = AppService.get('batchProcessBaseURL');
  photosBaseURL: string = AppService.get('photosBaseURL');
  notifyBaseURL: string = AppService.get('notifyBaseURL');

  private baseUrlV2 = this.baseUrl.replace('v1', 'v2');

  constructor(private http: HttpClient) {
  }

  private formatErrors(error: any) {
    return throwError(error);
  }

  get<TModel = any>(path: string, params: any = {}, options?: ApiOptions): Observable<TModel> {
    const headers = this.buildHeaders(options);
    const httpParams = new HttpParams({fromObject: this.buildParams(params || {})});
    return this.http.get<TModel>(`${this.baseUrl}${path}`, { params: httpParams, headers })
      .pipe(catchError(this.formatErrors));
  }

  getV2<TModel = any>(path: string, params: any = {}, options?: ApiOptions): Observable<TModel> {
    const headers = this.buildHeaders(options);
    const httpParams = new HttpParams({fromObject: this.buildParams(params || {})});
    return this.http.get<TModel>(`${this.baseUrlV2}${path}`, { params: httpParams, headers })
      .pipe(catchError(this.formatErrors));
  }

  put<TModel = any>(path: string, body: Object = {}, options?: ApiOptions): Observable<TModel> {
    const url = `${this.baseUrl}${path}`;
    const headers = this.buildHeaders(options);
    return this.http.put<TModel>(url, body, { headers })
      .pipe(catchError(this.formatErrors));
  }

  putV2<TModel = any>(path: string, body: Object = {}, options?: ApiOptions): Observable<TModel> {
    const url = `${this.baseUrlV2}${path}`;
    const headers = this.buildHeaders(options);
    return this.http.put<TModel>(url, body, { headers })
      .pipe(catchError(this.formatErrors));
  }
  post<TModel = any>(path: string, body: Object = {}, options?: ApiOptions): Observable<TModel> {
    const baseUrl = path.startsWith('http') ? '' : this.baseUrl;
    const headers = this.buildHeaders(options);
    return this.http.post<TModel>(`${baseUrl}${path}`, body, { headers })
      .pipe(catchError(this.formatErrors));
  }

  postV2<TModel = any>(path: string, body: Object = {}, options?: ApiOptions): Observable<TModel> {
    const baseUrl = path.startsWith('http') ? '' : this.baseUrlV2;
    const headers = this.buildHeaders(options);
    return this.http.post<TModel>(`${this.baseUrlV2}${path}`, body, { headers })
      .pipe(catchError(this.formatErrors));
  }

  postToPhotosUrl<TModel = any>(path: string, body: Object = {}, options?: ApiOptions): Observable<TModel> {
    const headers = this.buildHeaders(options);
    return this.http.post<TModel>(`${this.photosBaseURL}${path}`, body, { headers })
      .pipe(catchError(this.formatErrors));
  }

  patch<TModel = any>(path: string, body: Object = {}, options?: ApiOptions): Observable<TModel> {
    const headers = this.buildHeaders(options);
    return this.http.patch<TModel>(`${this.baseUrl}${path}`, body, { headers })
      .pipe(catchError(this.formatErrors));
  }

  delete<TModel = any>(path: string, parameters: any = {}, options?: ApiOptions): Observable<TModel> {
    const headers = this.buildHeaders(options);
    const params = new HttpParams({fromObject: parameters});
    return this.http.delete<TModel>(`${this.baseUrl}${path}`, { params: params, headers })
      .pipe(catchError(this.formatErrors));
  }

  getPrintDetails<TModel = any>(path: string, params: any = {}, options?: ApiOptions): Observable<TModel> {
    const headers = this.buildHeaders(options);
    const httpParams = new HttpParams({fromObject: params});
    return this.http.get<TModel>(`${this.printerBaseUrl}${path}`, { params: httpParams, headers })
      .pipe(catchError(this.formatErrors));
  }

  cancelPrintJob<TModel = any>(path: string, params: any = {}, options?: ApiOptions): Observable<TModel> {
    const headers = this.buildHeaders(options);
    const httpParams = new HttpParams({fromObject: params});
    return this.http.put<TModel>(`${this.printerBaseUrl}${path}`, { params: httpParams, headers })
      .pipe(catchError(this.formatErrors));
  }

  /**
   * Queries the API and returns api response (different than the other GET in that it returns the entire response, not just the body)
   * @param path The path to query
   * @param params The parameters to use
   */
  getResponse<TModel = any>(path: string, params: any = {}, options?: ApiOptions): Observable<HttpResponse<TModel>> {
    const headers = this.buildHeaders(options);
    const httpParams = new HttpParams({fromObject: params});
    return this.http.get<TModel>(`${this.baseUrl}${path}`, { params: httpParams, observe: 'response', headers })
      .pipe(catchError(this.formatErrors));
  }

  getBlob$(route: string, params: any = {}, options?: ApiOptions) {
    const headers = this.buildHeaders(options);
    const httpParams = new HttpParams({fromObject: this.buildParams(params)});
    return this.http.get<Blob>(`${this.pdfRenderUrl}${route}`, {responseType: 'blob' as 'json', params: httpParams, headers }).pipe(
      tap((blob: any) => console.log(blob.type))
    );
  }

  getBlobFromURL$(imageUrl: string, options?: ApiOptions, params: any = {}): Observable<Blob> {
    const headers = this.buildHeaders(options);
    const httpParams = new HttpParams({fromObject: this.buildParams(params)});
    return this.http.get(imageUrl, { observe: 'response', responseType: 'blob', params: httpParams, headers })
      .pipe(map(response => response.body))
  }

  getBlobFromS3URL$(imageUrl: string, options?: ApiOptions, params: any = {}): Observable<HttpResponse<Blob>> {
    const headers = this.buildHeaders(options);
    const httpParams = new HttpParams({fromObject: this.buildParams(params)});
    return this.http.get(imageUrl, { observe: 'response', responseType: 'blob', params: httpParams, headers })
      .pipe(map(response => response))
  }

  public getJsonFile(imageUrl: string): Observable<any> {
    return this.http.get(imageUrl, { observe: 'response', responseType: 'json' })
      .pipe(map(response => response.body))
  }

  downloadFile(file: Blob, fileName: string) {
    const element:any = document.createElement("a", {});
    document.body.appendChild(element);
    element.style = "display: none";

    const url = window.URL.createObjectURL(file);
    element.href = url;
    element.download = fileName;
    element.click();
    window.URL.revokeObjectURL(url);
  }

  postToEmailTempaltes<TModel = any>(path: string, body: Object = {}, options?: ApiOptions): Observable<TModel> {
    const headers = this.buildHeaders(options);
    return this.http.post<TModel>(`${this.notifyBaseURL}${path}`, body, { headers })
      .pipe(catchError(this.formatErrors));
  }

  getEmailTempaltes<TModel = any>(path: string, params: any = {}, options?: ApiOptions): Observable<TModel> {
    const headers = this.buildHeaders(options);
    const httpParams = new HttpParams({fromObject: this.buildParams(params || {})});
    return this.http.get<TModel>(`${this.notifyBaseURL}${path}`, { params: httpParams, headers })
      .pipe(catchError(this.formatErrors));
  }

  /**
   * Combines multiple param objects into a array of objects and returns the non-nullable properties
   */
  buildParams(paramObjects: any | any[]) {
    const paramArray = paramObjects instanceof Array ? paramObjects : [paramObjects];

    // Combine all parameters and only return the values that are defined (i.e. - not null)
    let allParams = Object.assign({}, ...paramArray);
    // @ts-ignore
    allParams = Object.entries(allParams)
      .reduce((combined, [name, value]) => {
        if (value) {
          if (typeof value !== 'string') {
            combined[name] = JSON.stringify(value);
          } else {
            combined[name] = value;
          }
        }
        return combined;
      }, {});
    return allParams;
  }


  private buildHeaders(options: ApiOptions): HttpHeaders | { [header: string]: string | string[]} {
    if(options?.supressLogErrors) {
      const headers: any = {};
      headers[HeadersEnum.SuppressLogErrors] = 'true';
      return headers
    }
  }
}
