import { Deserializable } from './deserializable.model';
import { FulfillmentOption } from './fulfillment-option.model';


export enum FulfillmentTypes {
  ADDRESS = 'ship',
  RADIUS = 'radius',
  LIST = 'list',
  WEBSITE = 'website'
}

export enum PrintOrderStatus {
  PENDING = 'pending',
  REJECTED = 'rejected',
  SUBMITTED = 'submitted',
  READY = 'ready',
  CANCELLED = 'cancelled',
  COMPLETE = 'complete',
  ERROR = 'error'
}

export class Fulfillment implements Deserializable<Fulfillment> {

  type: FulfillmentTypes;
  options: FulfillmentOption[];
  status: PrintOrderStatus;
  isProofReady?: boolean;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input: any): Fulfillment {
    Object.assign(this, input);
    return this;
  }

  addOption(aOption: FulfillmentOption) {
    if (!this.options) {
      this.options = [];
    }
    if(aOption)
    {
    this.options.push(aOption);
    }
  }

  find(selector): FulfillmentOption {
    return this.options.find(selector);
  }
}
