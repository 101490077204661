<mat-toolbar class="mat-elevation-z1" *ngIf="viewModel$ | async as viewModel">
  <ng-container *ngIf="viewModel.assumedUser as assumedUser">
    <mat-toolbar-row id="obo-row" fxLayout="row" fxLayoutAlign="space-between center" class="px-4 py-1 bg-warn">
      <div class="mat-body-1 m-0">

        <!-- Impersonating user: -->
        <span>
          ACTING ON BEHALF OF: {{assumedUser.profile?.preferredFirstName}} {{assumedUser.profile?.preferredLastName}}
        </span>

        <!-- Email Link -->
        <a *ngIf="assumedUser.profile?.preferredEmail" [href]="'mailto:' + assumedUser.profile.preferredEmail" [style.color]="'inherit'">
          (<u>{{assumedUser.profile.preferredEmail}}</u>)
        </a>

        <!-- Metro Name -->
        <span *ngIf="assumedUser.profile?.commonProfile?.metro?.metroName">
          - {{assumedUser.profile?.commonProfile?.metro?.metroName}}
        </span>

      </div>

      <!-- End Impersonation Button -->
      <button mat-button class="on-behalf-of" id="btn-exit-obo" aria-label="Close" (click)="endImpersonation()">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon class="material-icons-outlined">block</mat-icon>
          <span>Exit Acting Mode</span>
        </div>
      </button>

    </mat-toolbar-row>
  </ng-container>

  <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center" class="px-4">
    <span *ngIf="headerLogo$ | async as headerLogo">
      <a id="home-button" routerLink="/" >
        <img [src]="headerLogo" class="header-logo logo-img">
      </a>
    </span>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
      <!-- Notification Menu -->
      <lc-notifications-menu></lc-notifications-menu>

      <mat-divider [vertical]="true" style="height: 15px;"></mat-divider>

      <!-- Dropdown Menu -->
      <ng-container>
        <button id="account-menu-button" mat-button [matMenuTriggerFor]="accountMenu">
          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
            <img [src]="viewModel.profilePhotoUrl" id="header-profile-avatar" class="img-avatar rounded-circle">
            <span>{{ viewModel.firstName }}</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>

        <!-- Menu dropdown for the Account menu -->
        <mat-menu #accountMenu="matMenu" >
          <!-- Profile Link -->
          <a *ngIf="viewModel.showProfileLink" mat-menu-item id="profile-link" class="border-top border-bottom" [routerLink]="['/my-profile', viewModel.user?.profile?._id]">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-icon class="material-icons-outlined">account_circle</mat-icon>
              <span>My Profile</span>
            </div>
          </a>

          <!-- Profile Link -->
          <a *ngIf="viewModel.showWhatsNewLink && viewModel.whatsNewLink$ | async" mat-menu-item id="whats-new-link" class="border-top border-bottom" [routerLink]="['/whats-new']">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-icon class="material-icons-outlined">tungsten</mat-icon>
              <span>What's New</span>
            </div>
          </a>

          <!-- On Behalf of Link -->
          <a *ngIf="viewModel.showOnBehalfOf$ | async" mat-menu-item id="on-behalf-of-link" class="border-top border-bottom" [routerLink]="['/on-behalf-of']">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-icon class="material-icons-outlined">people</mat-icon>
              <span>On Behalf Of</span>
            </div>
          </a>
          <a *ngIf="viewModel.showFeedbackLink" mat-menu-item id="feedback-link" class="border-top border-bottom" [routerLink]="['/feedback']">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-icon class="material-icons-outlined">feedback</mat-icon>
              <span>Feedback</span>
            </div>
          </a>
          <!-- Logout -->
          <button mat-menu-item id="logou-link" class="border-top border-bottom" (click)="onLogout()">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-icon class="material-icons-outlined" color="warn">exit_to_app</mat-icon>
              <span>Logout</span>
            </div>
          </button>



        </mat-menu>
      </ng-container>

    </div>
  </mat-toolbar-row>
</mat-toolbar>
