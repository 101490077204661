import { Injectable } from '@angular/core';

export class DialogAction {
  constructor(public text: string, public onClick?: () => any) {
  }
}

export abstract class PromptDialogReference {
  public abstract readonly result: Promise<any>;

  abstract close(): void;
}

/**
 * This is the default PromptDialogService that will get registered, but must then be
 * overridden by the actual implementation. The reason is, we do not want @lc/core to
 * have to worry about the UI implementation details. Therefore, we are registering a
 * a default implementation that we can reference as an 'interface'
 */
@Injectable({providedIn: 'root'})
export class PromptDialogService {

  constructor() { }

  getPromptReference(title: string, message: string, primaryAction: DialogAction | string, otherActions?: DialogAction[] | string[], options?: {centered?: boolean, backdrop?: boolean}): PromptDialogReference {
    throw new Error('Not Implemented: Make sure to register the PromptDialogService by calling SharedComponentsUiModule.forRoot() in the app.module');
  }

  openPrompt(title: string, message: string, primaryAction: DialogAction | string, otherActions?: DialogAction[] | string[], options?: {centered?: boolean, backdrop?: boolean}): Promise<DialogAction> {
    throw new Error('Not Implemented: Make sure to register the PromptDialogService by calling SharedComponentsUiModule.forRoot() in the app.module');
  }
}
