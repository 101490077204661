<lc-chips [idPrefix]="idPrefix" class="agent-search" [label]="label" [options]="memberSearch$" [formControl]="formControl"
  [max]="max" [emptyPlaceholder]="emptyPlaceholder" [placeholder]="placeholder"
  [inputFormControl]="searchControl" (selectedChanged)="updateAgents($event)" [(selectedValues)]="selected"
  [class.has-selected]="selected?.length > 0"
  noResultsDisplay="No user found. Please ensure the user you are searching for has logged into Listing Concierge and completed their profile">

  <ng-template #prefix>
    <template *ngIf="prefixTemplate" [ngTemplateOutlet]="prefixTemplate"></template>
  </ng-template>

  <!--Tag Template -->
  <ng-template #tag let-member="item">
    {{member.firstName}} {{member.lastName}}
  </ng-template>

  <!-- Template to display the member -->
  <ng-template #option let-member="item">
    <div class="row align-items-center m-0">
      <img [src]="member?.profilePhotoUrl || member?.photoUrl || 'assets/images/user.png'" alt="agent avatar" class="agent-avatar img-avatar rounded-circle d-inline-block" placement="bottom">
      <div class="ml-2 d-inline-block" style="line-height: 1em">
        {{member.firstName}} {{member.lastName}}<br>
        {{member.officeName}}
      </div>
    </div>
  </ng-template>

</lc-chips>
