<footer>
  <mat-toolbar class="border-top">
    <mat-toolbar-row class="link-row px-4"
                     fxLayout="row" fxLayoutAlign="space-around center"
                     fxLayout.lt-md="column" fxLayoutAlign.lt-md="start start">

      <div fxLayout="column" fxFlexOrder.lt-md="1">
        <div fxLayout="row wrap" fxLayoutGap="25px">
          <div>
            <span class="mat-title m-0">
              Listing&nbsp;Concierge
            </span>
          </div>

          <div fxLayout="row" fxLayoutGap="5px">
            <a mat-button id="privacy-policy-link" (click)="onOpenPrivacyPolicy()">
              Privacy Policy
            </a>

            <a mat-button id="terms-of-use-link" (click)="onOpenTermsAndConditions()">
              Terms of Use
            </a>
          </div>
        </div>

        <span class="mat-caption">&copy; 2018 - {{copyrightDate}} NRT LLC</span>
      </div>


      <!-- Social Media Icons-->
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" fxFlexOrder.lt-md="0">

        <a class="text-facebook" href="https://www.facebook.com/coldwellbanker" target="_blank">
          <i class="ion ion-logo-facebook"></i>
        </a>

        <a class="text-twitter" href="https://twitter.com/coldwellbanker" target="_blank">
          <i class="ion ion-logo-twitter"></i>
        </a>

        <a class="text-instagram" href="https://www.instagram.com/coldwellbanker/" target="_blank">
          <i class="ion ion-logo-instagram"></i>
        </a>

      </div>

    </mat-toolbar-row>
  </mat-toolbar>
</footer>
