import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AllOrders } from '../models';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppService } from './app.service';

export enum OrderFilterTypes {
  mine = 'mine',
  notMine = 'notMine'
}

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  
  constructor(private httpClient: HttpClient) {
  }

  getOrders(requestType: OrderFilterTypes, params?: { search?: string, sortColumn?: string, sortDir?: string, page?: number, pageSize?: number}): Observable<{orders: AllOrders[], totalCount: number}> {
    const baseUrl = AppService.get('persistBaseURL')
    const allParams: any = params || {};
    allParams.requestType = requestType;
    const httpParams = new HttpParams({fromObject: allParams});
    httpParams.append('requestType', requestType.toString());
    return this.httpClient.get<AllOrders[]>(`${baseUrl.replace('v1', 'v2')}orders/all`, { params: httpParams, observe: 'response'})
      .pipe(map(response => {
        const orders = response.body.map(order => new AllOrders(order));
        const totalCount = response.headers.get('total-count');
        return { orders, totalCount: +totalCount}
      }));
  }

  getSchema() {
    const baseUrl = AppService.get('persistBaseURL')
    return this.httpClient.get(`${baseUrl.replace('v1', 'v2')}orders/schema`)
      .pipe(map(response => response ));
  }
  
}
