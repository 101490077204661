import { Deserializable } from './deserializable.model';

export class PaymentRefund implements Deserializable<PaymentRefund> {
  amount: number;
  refundDate: Date;
  notes: string;

  constructor(input?: any) {
    if (input) {
      this.deserialize(input);
    }
  }

  deserialize(input: any): PaymentRefund {
    return Object.assign(this, input);
  }
}
