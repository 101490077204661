import { Component, OnInit } from '@angular/core';
import { AuthenticationService, OktaAuthenticationProvider } from '@lc/core';


@Component({
  selector: 'lc-app-okta-callback',
  templateUrl: './okta-callback.component.html',
  styleUrls: ['./okta-callback.component.scss']
})
export class OktaCallbackComponent implements OnInit {
  public errorMsg: any;
  public hasError = false;
  public isRetryButton = true;
  public gatewayReturnLink: string;
  public contactMessage: string;

  constructor(
    private auth: AuthenticationService,
    private okta: OktaAuthenticationProvider
  ) {}

  ngOnInit() {
    // Handles the response from Okta and parses tokens
    const currentObject = this;

    this.okta.handleAuthentication(true).then(() => {
      currentObject.auth.redirectPostLogin();

    }).catch(function(error) {
      currentObject.hasError = true;
      currentObject.errorMsg = 'An error has occurred during Authentication.';
      currentObject.contactMessage = 'If this problem persists, please contact the NRT Help Desk: 877.NRT.HELP (877.678.4357)';
      if(error.message.indexOf('not assigned') > -1){
        currentObject.errorMsg = 'User is not assigned to this application.';
        currentObject.isRetryButton = false;
        currentObject.contactMessage = 'If this problem persists, please contact the Realogy Brokerage Group Help Desk at 877.678.4357.';

      }
      return;
    });

  }

  retry() {
    this.okta.logout();
    this.okta.login();
  }
}
