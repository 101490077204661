import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

/**
 * API Client is the core of our communication with the API endpoints. Sets some standards for how we should be handling
 * the request, the response and what are valid objects to create/update.
 * Must use forms to POST/PUT as it will enable to client to ensure proper validation before calling post/put.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiClient {

  constructor(private http: HttpClient) {
  }

  private formatErrors(error: any, form?: FormGroup) {
    if(form) {
      // If a form was passed in and an error was thrown, we need to enable the form before returning.
      form.enable();
    }
    return throwError(error.error);
  }

  /**
   * GETs the requested resources from the path
   * @param path The path to get the response from
   * @param params Any additional parameters to add to the request
   */
  get<TModel>(path: string, params: any = {}): Observable<TModel> {
    const httpParams = this.getCleanParams(params);
    return this.http.get<TModel>(path, { params: httpParams })
      .pipe(catchError(error => this.formatErrors(error)));
  }

  /**
   * PUTs the form to the path provided and returns the response body as the model
   * Also updates the form with the updated model values
   * @param path The path to PUT the value to
   * @param form The form to PUT
   */
  put<TModel>(path: string, form: FormGroup): Observable<TModel> {
    const value = this.getFormValue(form, 'PUT');
    return this.http.put<TModel>(path, value)
      .pipe(
        tap(model => this.updateFormValue(form, model)),
        catchError(error => this.formatErrors(error, form))
      );
  }

  /**
   * POSTs the form to the path provided and returns the response body as the model
   * Also updates the form with the created model values
   * @param path The path to POST the value to
   * @param form The form to POST
   */
  post<TModel>(path: string, form: FormGroup): Observable<TModel> {
    const value = this.getFormValue(form, 'POST');
    return this.http.post<TModel>(path, value)
      .pipe(
        tap(model => this.updateFormValue(form, model)),
        catchError(error => this.formatErrors(error, form))
      );
  }

  /**
   * Removes any null or undefined properties from the HttpParameters
   */
  private getCleanParams(params: any = {}) {
    if(params) {
      const cleanedParams = Object.entries(params)
        .reduce((cleanParams, [key, value]) => (value == null ? cleanParams : (cleanParams[key] = value, cleanParams)), {});
      return new HttpParams({fromObject: cleanedParams});
    }
    return new HttpParams();
  }

  /**
   * Validates the form and disables it until the response has returned.
   * @param form The form being submitted
   * @param action The action being performed on the form
   */
  private getFormValue(form: FormGroup, action: 'PUT' | 'POST') {
    if(form.invalid) {
      return throwError(new Error(`Cannot ${action} an invalid form`));
    }

    // Disable form so we cannot edit it on the UI until respons returns
    form.disable();
    return form.value;
  }

  /**
   * Updates the form with the model value from the response
   * @param form The form that was just submitted
   * @param model The response from the API
   */
  private updateFormValue(form: FormGroup, model: any) {
    form.patchValue(model);
    form.enable();
  }
}
