import { Action } from '@ngrx/store';
import { Profile } from '../../models/profile.model';

export enum ProfileActionTypes {
  ClearProfile = '[Profile] Clear',
  LoadProfile = '[Profile] Load',
  GetProfile = '[Profile] Get',
  GetProfileComplete = '[Profile] Get Complete',
  UpdateProfile = '[Profile] Update',
  UpdateProfileComplete = '[Profile] Update Complete',
}

/**
 * Create ease of consumption wrapper actions
 * Eg.
 *
 * new LoadLoggedInUser();
 */

export class ClearProfile implements Action {
  readonly type = ProfileActionTypes.ClearProfile;
  constructor() {}
}


export class LoadProfile implements Action {
  readonly type = ProfileActionTypes.LoadProfile;
  constructor(public payload: Profile) {}
}

export class GetProfile implements Action {
  readonly type = ProfileActionTypes.GetProfile;
  constructor(public payload: string) {}
}

export class GetProfileComplete implements Action {
  readonly type = ProfileActionTypes.GetProfileComplete;
  constructor(public payload: Profile) {}
}

export class UpdateProfile implements Action {
  readonly type = ProfileActionTypes.UpdateProfile;
  constructor(public payload: Profile,  public fields?: string[], public orderId?: string, public isCurrentProfile?: boolean) {}
}

export class UpdateProfileComplete implements Action {
  readonly type = ProfileActionTypes.UpdateProfileComplete;
  constructor(public payload: Profile) {}
}


export type ProfileActions = ClearProfile | LoadProfile | GetProfile | GetProfileComplete | UpdateProfile | UpdateProfileComplete;

export const SELECTEDPROFILE = 'selectedProfile';
