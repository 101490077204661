import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { map, tap } from 'rxjs/operators';
import { OktaAuthenticationProvider } from './auth-providers/okta-authentication.provider';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';


@Injectable({
    providedIn: 'root'
  })
export class CannyService {
  constructor(

    //Canny SSO currently is depending on LD service.
    private oktaService: OktaAuthenticationProvider,
    private httpClient: HttpClient) {

      //workaround functions within Launch-darkly Service.
  }

    
/**Retrieves the Canny SSO from localStorage or from persist */
getCannySSO$(): Observable<any> {
   const cachedCanny = localStorage.getItem('canny-sso')
     if (cachedCanny) {
      return of(cachedCanny)
        }
        // TODO: Doesn't the token.interceptor already do this?
    const accessToken = this.oktaService.getToken()
    const headers = { Authorization: `Bearer ${accessToken}` };
    const route = AppService.get('cannySSOURL');

       return this.httpClient.get(route, { headers: headers }).pipe(
          map(res => (<any>res)),
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          tap(keysRes => {localStorage.setItem('canny-sso', keysRes.cannySSOToken)
          })
        );
      }
    }