import { Deserializable } from './deserializable.model';
import { Audit } from './audit.model';


export class Note  implements Deserializable<Note> {

  _id?: string;

  /** Owner this note belongs to */
  ownerId: string;

  /** Full name of the user who wrote the note */
  author: string;

  /** The text of the note */
  text: string;

  /** Date the notification was generated. Used as the secondary sort order of the notifications in the UI */
  audit?: Audit;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input: any): Note {
    Object.assign(this, input);
    if(input && input.audit) {
      this.audit = new Audit(input.audit);
    }

    return this;
  }
}
