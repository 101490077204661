import { BaseForm } from './base.form';
import { FormControl } from '@angular/forms';

export class TeamMemberForm extends BaseForm {
  constructor(value?: any) {
    super({
      agentId: new FormControl(),
      teamRole: new FormControl(),
      teamAdmin: new FormControl(false),
      position: new FormControl(0),
    });

    if(value) {
      this.patchValue(value);
    }
  }
}
