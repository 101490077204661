import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
const angularModules = [CommonModule];

// Internal Directives
import { BadgeDirective } from './directives/badge.directive';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
// import { ClickOutsideDirective } from './directives/click-outside.directive';
import { PresignedPhotoDirective, PresignedPhotoBackgroundDirective } from './directives/presigned-photo.directive';
import { DebounceClickDirective } from './directives/debounce-click.directive';
const directives = [
  BadgeDirective, FeatureFlagDirective,
  PresignedPhotoDirective, PresignedPhotoBackgroundDirective, DebounceClickDirective
];

@NgModule({
  declarations: [...directives],
  imports: [
    ...angularModules
  ],
  exports: [
    ...directives
  ]
})
export class UICoreModule { }
