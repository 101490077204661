import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { TeamProfile } from '../../models/team-profile.model';
import { GetTeam } from './team.actions';

/**
 * The team resolve is used to simply request the store to get the pipe plumbed with the Team
 */
@Injectable({
  providedIn: 'root'
})
export class TeamResolve  implements Resolve<TeamProfile> {
  constructor(private store: Store<any>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const teamId = route.params['teamId'];
    let teamProfile: TeamProfile;
    if(teamId) {
      teamProfile = new TeamProfile();
      teamProfile._id = teamId;
    }
    this.store.dispatch(new GetTeam(teamProfile));
    // we are no using resolve injection of objects so just return null
    return of(null);
  }

}
