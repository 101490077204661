import { MarketingOrder } from '../../models';
import { OrderActionTypes, SelectProductTemplateComplete } from './order.actions';

export const initialState:MarketingOrder = null;

export function orderReducer(state = initialState, action): MarketingOrder {
  switch (action.type) {
    case OrderActionTypes.InitializeOrder: {
      return null;
    }
    // set state to loaded order
    case OrderActionTypes.LoadOrder:
    case OrderActionTypes.AddOrderComplete:
    case OrderActionTypes.GetOrderComplete:
    case OrderActionTypes.UpdateOrderComplete:
    case OrderActionTypes.UpdateOrderPhotosComplete:
    case OrderActionTypes.UpdateOrderListingComplete:
    case OrderActionTypes.UpdateFulfillmentComplete:
    case OrderActionTypes.SelectProductTemplateComplete:
    case OrderActionTypes.SubmitOrderComplete: {
      return new MarketingOrder(action.payload);
    }
    default:
      return state;
  }
}

export const cloneOrder = (order) => {
  return order ? new MarketingOrder(JSON.parse(JSON.stringify(order))) : null;
};
