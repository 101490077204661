<div class="okta-container text-center">
  <div class="wrapper" *ngIf="!hasError">
      <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      <br><br>
      <h1 class="lead">Signing in ....</h1>
  </div>
  <div class="wrapper retry" *ngIf="hasError">
      <div class="alert" role="alert">
        {{errorMsg}}
      </div>
<div class="contact-message">
  {{contactMessage}}
</div>
<div *ngIf="isRetryButton" >
    <br>
      <button  type="button" mat-raised-button color="primary" (click)="retry()">Try again</button>
  </div>
    </div>
</div>
