import { Directive, Input, HostBinding, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { ContentfulService, ContentKeys, PackageInstance } from '@lc/core';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[lcBadge]'
})
export class BadgeDirective implements  OnChanges {

  /** The package to locate the badge for */
  @Input('lcBadge') package: PackageInstance;

  /** The badge size */
  @Input() isSmall: boolean;

  /** Host binding that sets the image source */
  @HostBinding('src') source: string;


  /** Host binding that sets the alt the elemenet */
  @HostBinding('alt') alt: string;

  badgeImages : string

  constructor(public contentfulService: ContentfulService) {
    const badgeImages$ = this.contentfulService.getContent$(ContentKeys.PACKAGES_BADGE_IMAGES, '');
    const sub = badgeImages$.pipe(take(1)).subscribe((value) => { this.badgeImages = value }, (error) => { throw new Error(error); });
    sub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.package && this.package) {
      this.source = this.getBadge();
      this.alt = `${this.package.title.toLowerCase()} Badge`;
    }
  }

  private getBadge() {
    switch (this.package.code) {
      case '001':
        return this.getBadgePath('silver');
      case '006':
        return this.getBadgePath('bronze');
      case '008':
      case '007':
        return this.getBadgePath('silver');
      case '002':
        return this.getBadgePath('gold');
      case '003':
      case '009':
        return this.getBadgePath('platinum');
      case '004':
      case '010':
        return this.getBadgePath('blue');
      case '011':
        return this.getBadgePath('temp');
      case '900':
        return this.getBadgePath('essentials');
      case '901':
        return this.getBadgePath('brand_boost');
      case '902':
        return this.getBadgePath('disruptor');

      default:
        return `/assets/images/marketing-package/broken_badge${this.isSmall ? '-small' : ''}.svg`;
    }
  }

  private getBadgePath(color: string) {
    const fileNameKey = `${color}_badge${this.isSmall ? '-small' : ''}.svg`;
    if (this.badgeImages && Array.isArray(this.badgeImages)) {
      const entry = this.badgeImages?.find(obj => obj.key === fileNameKey);
      return entry ? entry.value : `/assets/images/marketing-package/broken_badge${this.isSmall ? '-small' : ''}.svg`;
    }
    return `/assets/images/marketing-package/broken_badge${this.isSmall ? '-small' : ''}.svg`;
  }
}
