import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
const angularModules = [ CommonModule, MatDialogModule, MatDividerModule, MatButtonModule, FlexLayoutModule, MatIconModule ]

// Internal Components
import { BaseDialog } from './base/base.dialog';
const externalComponents = [ BaseDialog ];


@NgModule({
  declarations: [...externalComponents],
  imports: [
    ...angularModules
  ],
  exports: [ ...externalComponents]
})
export class DialogsModule { }
