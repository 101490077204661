import { Deserializable } from './deserializable.model';
import { Address } from './address.model';

export enum PrintOrderOptionTypes {
  RADIUS = 'radius',
  CONTACTS = 'contact',
  LIST = 'list',
  RETURN_ADDRESS = 'return_address'
}

export enum OtherOptionTypes {
  WEBSITE = 'website'
}

export class FulfillmentOption implements Deserializable<FulfillmentOption> {

  type: PrintOrderOptionTypes | OtherOptionTypes;
  contactType?: string;
  quantity: number;
  quantityOmitted?: number;
  fullName?: string;
  company?: string;
  address?: Address;
  url?: string;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input: any): FulfillmentOption {
    Object.assign(this, input);
    return this;
  }

}
