<mat-form-field [id]="idPrefix + '-form-field'" appearance="outline" floatLabel="always" [class.ng-valid]="errors === ''" class="dashed-border">

  <!-- Label -->
  <mat-label [id]="idPrefix + '-label'">
    {{label}}
  </mat-label>

  <!-- Input -->
  <div fxLayout="column" fxLayoutAlign="center center" class="upload-container">
    <lc-file-upload [class.d-none]="formControl?.value" #fileUpload
      [imageUrl]="placeholderImage" [photoStyle]="photoStyle"
      [instructions]="placeholder" [allowedExtensions]="fileTypes.logoExtensions" [processing]="uploadStatus != null"
      (uploadResult)="uploadPhoto($event)" [allowedExtensions]="allowedExtensions"></lc-file-upload>

    <!-- Image Display-->
    <div *ngIf="formControl?.value" class="image-container">
      <img [src]="formControl.value" [class]="photoStyle" class="image-display">
    </div>

    <!-- Menu Button -->
    <button *ngIf="canEdit && formControl?.value" mat-mini-fab [matMenuTriggerFor]="menu" aria-label="Logo Options" class="float-bottom-right" color="default">
      <mat-icon>more_vert</mat-icon>
    </button>

    <!-- Dropdown Menu -->
    <mat-menu #menu="matMenu">
      <button mat-menu-item color="primary" (click)="onEdit()" *ngIf="editImage?.observers?.length">
        <mat-icon>edit</mat-icon>
        <span>Edit</span>
      </button>
      <button  mat-menu-item color="primary" (click)="fileUpload?.chooseFiles()">
        <mat-icon>import_export</mat-icon>
        <span>Replace</span>
      </button>
      <button  mat-menu-item color="primary" *ngIf="downloadUrl" (click)="onDownload()">
        <mat-icon>download</mat-icon>
        <span>Download</span>
      </button>
      <button mat-menu-item color="warn" (click)="onDelete()">
        <mat-icon>delete</mat-icon>
        <span>Delete</span>
      </button>
    </mat-menu>
  </div>

  <!-- Hidden input used to make this a valid mat-form-field-->
  <input matInput class="d-none" [formControl]="formControl">

  <!-- Hint-->
  <mat-hint [id]="idPrefix + '-hint'" class="w-100">
    {{hint}}
  </mat-hint>

  <!-- Error -->
  <mat-error [id]="idPrefix + '-error'" *ngIf="formControl && formControl.invalid" [innerHTML]="errors"></mat-error>
</mat-form-field>

