import { FormControl  } from '@angular/forms';
import { BaseForm } from './base.form';
import { PhotographerAppointmentForm } from './photographer-appointment.form';
import { PhotoOrder } from '../models/photo-order.model';

export class PhotoOrderForm extends BaseForm<PhotoOrder> {
  // accessors are not required, but reduce code and risk of not retrieving the proper control
  public get appointment(): PhotographerAppointmentForm { return this.getControl('appointment') as PhotographerAppointmentForm; }
  public get options(): FormControl { return this.getControl('options') as FormControl; } // TODO: We can make options strongly typed if needed

  constructor(value?: PhotoOrder, required?: boolean){
    super({
      _id: new FormControl(value?._id),
      options: new FormControl(value?.options), // TODO: Custom validator for options?
      appointment: new PhotographerAppointmentForm(value?.appointment, required),
    });
  }
}


