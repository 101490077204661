import { Deserializable } from './deserializable.model';

export class UploadPhoto implements Deserializable<UploadPhoto> {
  file: string;
  success:boolean;
  msg: string;
  url: string;
  original: boolean;
  width: number;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): UploadPhoto {
    Object.assign(this, input);
    return this;
  }
}
