import { FormGroup, FormArray, AbstractControl } from '@angular/forms';

export class BaseForm<TModel = any> extends FormGroup {
  get id(): string {
    const control =  this.get('_id');
    return control ? control.value : null;
  }

  isEdit: boolean;
  private _isNew: boolean;
  get isNew(): boolean { return this._isNew === true || (this._isNew === undefined && this.id == null)};
  set isNew(val) { this._isNew = val; }

  getDirty(): any {
    const dirty = {};
    Object.keys(this.controls).forEach(key => {
      const control = this.controls[key];
      if (!control.dirty) {
        return;
      }
      if(control instanceof BaseForm || (control as any).getDirty) {
        dirty[key] = (control as any).getDirty();
      } else {
        dirty[key] = control.value;
      }
    });
    return dirty;
  }

  /**
   * Returns the form value with all null fields removed
   */
  getCleanValue() {
    return JSON.parse(JSON.stringify(this.value, (key, value) => {
      return (value === null ? undefined : value);
    }));
  }

  setEnabled(isEnabled) {
    if(isEnabled) {
      this.enable();
    } else {
      this.disable();
    }
  }

  markAllAsDirty() {
    this.markAsDirty();
    Object.keys(this.controls).forEach(key => {
      const control = this.controls[key];
      if (control instanceof FormArray) {
        for(const form of control.controls){
          this.markAllControlsAsDirty(form);
        }
      } else {
        this.markAllControlsAsDirty(control);
      }
    })
  }

  private markAllControlsAsDirty(control: BaseForm | AbstractControl) {
    if(control instanceof BaseForm){
      control.markAllAsDirty();
    } else {
      control.markAsDirty();
      control.markAsTouched();
      control.updateValueAndValidity();
    }
  }

  markControlAsDirty(control: AbstractControl) {
    control.markAsDirty();
    control.markAsTouched();
    control.updateValueAndValidity();
  }

  getControl(propertyName: keyof TModel): AbstractControl {
    return this.get(propertyName.toString());
  }
}
