import { Injectable } from '@angular/core';
import { MarketingOrder } from '../models/marketing-order.model';
import { Fulfillment } from '../models/fulfillment.model';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FulfillmentService {

  resource = 'marketing-orders';

  constructor(private apiService: ApiService) { }
  
  updateFulfillment(marketingOrder: MarketingOrder, productCode: string, fulfillment: Fulfillment): Observable<MarketingOrder>  {
    const url = `${this.resource}/${marketingOrder._id}/products/${productCode}/fulfillment`;
    return this.apiService.put(url, fulfillment);
  }
}
