import { Injectable } from '@angular/core';
import { MarketingOrder } from '../models/marketing-order.model';
import { Fulfillment } from '../models/fulfillment.model';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ListingPhoto } from '../models';
import { PhotoDownloadService } from './photo-download.service';
import { ProductGenerationService } from './product-generation.service';
import { ClipboardService } from 'ngx-clipboard';
import { ToasterService } from './toaster.service';

interface SummaryResult {
  summary: {
    extractive?: string[]
    abstractive?: string[]
  }
}

@Injectable({
  providedIn: 'root'
})
export class TextSummaryService {

  constructor(private apiService: ApiService,
    private clipboardService: ClipboardService,
    private toasterService: ToasterService,
    private photoDownloadService: PhotoDownloadService,
    private productGenerationService: ProductGenerationService) { }
  
  summarizeText(text: string, maxChars: number): Observable<SummaryResult>  {
    const url = `text-summary`;
    return this.apiService.post(url, { text, maxChars });
  }

  onCopyLink(branded: boolean, viewModel: any) {
    if (!viewModel?.product) { return; }
    const product = viewModel.product;

    let lastPublishedUrl = '';
    if (viewModel.product.isWebsiteProduct()) {
      lastPublishedUrl = viewModel?.product?.publishedUris[0].uri;
      if (!branded && lastPublishedUrl) {
        // Unbranded. We need to change the url to mlsindex.html.
        lastPublishedUrl = lastPublishedUrl.replace('/index.html', '/mlsindex.html');
      }
    } else {
      const copyLinks = product?.publishedUris.filter(video => {
        const ytLink = video.uri.indexOf('youtube') >= 0;
        const awsLink = video.uri.indexOf('amazonaws') >= 0;
        return video.uriType === (branded ? 'brandedvideo' : 'unbrandedvideo') &&
          (branded ? ytLink : (ytLink || awsLink))
      });
      lastPublishedUrl = copyLinks[copyLinks?.length - 1]?.uri;
    }

    this.clipboardService.copyFromContent(lastPublishedUrl);
    this.toasterService.showInfo('Website Url saved to clipboard');
  }

  onDownload(imageResolution: string, viewModel: any): void {
    if (!(viewModel?.canDownload && viewModel?.marketingOrder)) { return; }
    this.productGenerationService.download(viewModel?.marketingOrder, viewModel?.product, imageResolution);
  }

  /** Downloads the branded or unbranded digital products */
  onDownloadDigital(branded: boolean, viewModel: any): void {
    if (!(viewModel?.canDownload && viewModel?.product)) { return; }

    const downloadableLinks = viewModel?.product.publishedUris.filter(video => {
      return video.uriType === (branded ? 'brandedvideo' : 'unbrandedvideo') &&
        video.uri.indexOf('amazonaws') >= 0
    });

    const lastPublishedUrl = downloadableLinks[downloadableLinks?.length - 1]?.uri;
    if (lastPublishedUrl) {
      this.downloadUsingCorsProxy(lastPublishedUrl);
    }
  }

  downloadUsingCorsProxy(url: string): void {
    if (url) {
      //const corsProxy = 'https://cors-anywhere.herokuapp.com/';
      this.photoDownloadService.downloadUsingCorsProxy(new ListingPhoto({ uri: url }),
        (err: Error) => {
          console.error(`Error downloading ${url}: ${err.message}`)
          this.toasterService.showInfo('There was a problem downloading the file. Please try again');
        });
    }
  }
}
