import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BaseForm } from './base.form';
import { phoneValidator } from '../validators/phone.validator';
import { PhoneNumber } from '../models';

export class PhoneNumberForm extends BaseForm {
  public get number(): string { return this.get('number').value; }

  constructor(value?: PhoneNumber, options?: { validateType?: boolean, phoneNotRequired?: boolean, priority?: number, typeRequired?: boolean, isPrimary?: boolean }){
    const init = value || new PhoneNumber();
    super({
      number: new FormControl(init.number, [Validators.required, phoneValidator()]),
      type: new FormControl(init.type, options?.typeRequired ? [Validators.required] : []),
      priority: new FormControl(init.priority || options?.priority),
      prefix: new FormControl(init.prefix),
      primary: new FormControl(options?.isPrimary || init.primary || false),
      extension: new FormControl(init.extension)
    });

    if(options && options.validateType === true){
      this.get('type').setValidators([this.validateType(this)]);
      this.get('type').updateValueAndValidity();
      this.get('number').valueChanges.subscribe(() => this.get('type').updateValueAndValidity({emitEvent: true}), (error) => { throw new Error(error); });
    }

    if(options && options.phoneNotRequired){
      // Set validators to only validate the phone regex
      this.get('number').setValidators([phoneValidator()]);
      this.get('type').setValidators([]);
      this.get('number').updateValueAndValidity();
      this.get('type').updateValueAndValidity();

    }

    if(value) {
      this.patchValue(value);
    }
  }

  validateType(group: FormGroup): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const phoneNumber = group.get('number').value;
      return phoneNumber && control.value == null ? {required: true} : null;
    };
  }
}
