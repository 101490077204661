import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lc-privacy-policy-dialog',
  templateUrl: './privacy-policy-dialog.component.html',
  styleUrls: ['./privacy-policy-dialog.component.scss']
})
export class PrivacyPolicyDialogComponent {

  constructor(public dialogRef: MatDialogRef<PrivacyPolicyDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

   onCancel(): void {
    this.dialogRef.close();
  }

}
