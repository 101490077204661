import { Injectable } from '@angular/core';
import { NotesDialogComponent, NotesDialogData } from './notes-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

/**
 * Handles the creation of the Notes Dialog component by requiring proper parameter construction
 * and eliminates the need to inject the dialog into the components directly. This allows
 * the ability to change implementation if we wanted to switch to a different dialog component (i.e. - Angular Material)
 */
@Injectable()
export class NotesDialogService {
  isDialaogOpen = false;
  constructor(private modal: MatDialog) { }

  /**
   * Opens the notes dialog component with the required parameters
   * @param ownerId The ownerId we are requesting notes for
   * @param title The title of the dialog
   * @param subheader The subtitle of the dialog
   */
  openNotes(ownerId: string, title: string, subtitle: string) {
    const dialogRef = this.modal.open<NotesDialogComponent, NotesDialogData, any>(NotesDialogComponent, { data: new NotesDialogData(ownerId, title, subtitle), width: '500px', maxWidth: '90vw', maxHeight: '90vh' } );
    this.isDialaogOpen = true;
    const component: NotesDialogComponent = dialogRef.componentInstance;

    component.close.subscribe(action => { this.isDialaogOpen= false; return dialogRef.close(action)}, (error) => { throw new Error(error); });
    dialogRef.afterClosed().pipe(finalize(() => this.isDialaogOpen = false)).toPromise();
    return dialogRef;
  }

  isNotesOpen() {
    return this.isDialaogOpen
  };
}
