import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperDialog, CropRequest, ImageCropperDialogResponse, ImageCropperDialogData } from './image-cropper.dialog';
import { PhotoType } from '@lc/core';
@Injectable({
  providedIn: 'root'
})
export class ImageCropperNewDialogService {

  constructor(private modal: MatDialog) { }

  openImageCropper(cropRequest: CropRequest | CropRequest[], saveInS3: boolean = true, photoType: PhotoType = PhotoType.LISTING_PHOTO): Promise<ImageCropperDialogResponse> {
    const dialogRef = this.modal.open<ImageCropperDialog, ImageCropperDialogData, ImageCropperDialogResponse>(ImageCropperDialog, {
      data: new ImageCropperDialogData(cropRequest, saveInS3, photoType),
      width:'90vw', maxWidth: '600px', maxHeight: '90vh'
    });

    // Subscribe to the closed event
    const component: ImageCropperDialog = dialogRef.componentInstance;
    component.close.subscribe(action => dialogRef.close(action), (error) => { throw new Error(error); });
    return dialogRef.afterClosed().toPromise();
  }
}
