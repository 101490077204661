export enum NotificationEventTypes {
  APIERROR = 'ApiError',
  INFO = 'Informational',
  ERROR = 'Error'
}

/**
 * Generic call to hold display notification information
 */
export class NotificationEvent {
  name: string;
  message: string;
  payload: any;

  /**
   * Create the display notification
   * @param name the name of the notification
   * @param message the message
   * @param payload the payload. For example an error object
   */
  public constructor(name: string, message?: string, payload?: any) {
    this.name = name;
    this.message = message;
    this.payload = payload;
  }
}
