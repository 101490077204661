import { Injectable } from '@angular/core';
import { createClient, ContentfulClientApi } from 'contentful';
import { AppService } from '../services/app.service';
import { Observable, BehaviorSubject} from 'rxjs';

export enum ContentKeys {
  WELCOME_MESSAGE = 'welcomeMessage',
  APP_HEADER_IMAGE = 'appHeaderImage',
  CONFIRMATION_IMAGE = 'confirmationImage',
  CONFIRMATION_MESSAGE = 'confirmationMsg',
  CONTACT_INFO = 'contactInfo',
  GENERIC_PHONE_NUMBER = 'genericPhoneNumber',
  CHECKOUT_TERMS_CONDITIONS = 'checkoutTandC',
  PACKAGES_BADGE_IMAGES = 'badgeImages'
}

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  private clientApi: ContentfulClientApi;
  /** BehaviorSubjects for each of the configured/requested feature flags. */
  private readonly ContentKeys$: { [keyName: string]: BehaviorSubject<any> } = {};

  constructor() {
    const space = AppService.get('contentFulSpace');
    const accessToken = AppService.get('contentFulAccessToken');
    const contentId = AppService.get('contentId');
    if (space && accessToken) {
      this.clientApi = createClient({
        space: space,
        accessToken: accessToken
      });
      this.getContentfulData(contentId);
    }
  }

  getContentfulData(id: string): any {
    this.clientApi.getEntry(id).then(response => {
      Object.keys(response.fields).forEach((keyFieldInContentFul) => {
        this.updateKeyValue(keyFieldInContentFul, response.fields[keyFieldInContentFul]);
      });
    });
  }

  /*
   * Returns the content value from the contnetFul
   * @param flag The content key value we are returning
   */
  getContent$<TExpected = any>(content: ContentKeys | string, defaultValue: TExpected = null): Observable<TExpected> {
    return this.getContentKeys$(content, defaultValue).asObservable();
  }

  private getContentKeys$<TExpected = any>(content: ContentKeys | string, defaultValue: TExpected = null): BehaviorSubject<TExpected> {
    if (!this.ContentKeys$[content]) {
      this.ContentKeys$[content] = new BehaviorSubject(defaultValue);
    }
    return this.ContentKeys$[content];
  }

  /**
   * Stores the value for the given content key in the BehaviorSubject to be emitted
   * @param ContentKeyName The Content Key name
   * @param value The value to emit
   */
  private updateKeyValue(ContentKeyName: string, value: any) {
    const retrievedValue = this.checkForDifferentTypeOfValues(value);
    if (this.ContentKeys$[ContentKeyName] == null) {
      this.ContentKeys$[ContentKeyName] = new BehaviorSubject<any>(retrievedValue);
    } else {
      this.ContentKeys$[ContentKeyName].next(retrievedValue);
    }
  }

  checkForDifferentTypeOfValues(objValue: any) {
    const isObjectType = objValue && typeof objValue === 'object' && objValue.constructor === Object;
    const isArray = objValue && Array.isArray(objValue);
    if (isArray) {
      const isFileArray = objValue.some(obj => obj.fields?.file?.url);
      const result = [];
      if (isFileArray) {
        objValue.forEach(obj => result.push({
          key: obj?.fields?.file?.fileName,
          value: `https:${obj.fields?.file?.url}`
        }));
        return result;
      }
    } else if (isObjectType && objValue?.nodeType === 'document') {
      return objValue.content[0].content[0].value;
    } else if (isObjectType && objValue?.fields?.file?.url) {
      return `https:${objValue.fields.file.url}`;
    } else {
      return objValue;
    }
  }
}
