// tslint:disable: rxjs-no-sharereplay
import { Injectable } from '@angular/core';
import { Option } from '../models/option.model';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';



@Injectable()
export class OptionsService {

  private  optionsCache: Observable<Option[]>;

  constructor(private apiService: ApiService) { }


  /**
   * Returns all available  options  Caches the options once per page-refresh
   * by utilizing shareReplay
   */
  getOptions(): Observable<Option[]> {
    if(this.optionsCache === undefined)
    {
      this.optionsCache = this.apiService.get('options').pipe(shareReplay(1));

    }
    return this.optionsCache;
  }

}
