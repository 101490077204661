import { TeamProfile } from '../../models/team-profile.model';
import { TeamActions, TeamActionTypes } from './team.actions';

export const teamInitialState: TeamProfile = null;

export function teamReducer(state = teamInitialState, action: TeamActions): TeamProfile {

  switch (action.type) {
    case TeamActionTypes.GetTeamComplete: {
      return action.team;
    }
    case TeamActionTypes.UpdateTeam: {
      return action.payload;
    }
    case TeamActionTypes.UpdateTeamComplete: {
      console.log('UpdateTeamComplete:', action);
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
