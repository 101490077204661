
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NotificationsClient } from '../../clients/notifications.client';
import { Observable, of } from 'rxjs';
import { Notification } from '../../models/notification.model';
import { NOTIFICATIONS, NotificationActionTypes, CreateNotification, NotificationsUpdated, LoadNotifications, UpdateNotification, UpdateNotificationComplete, CreateNotificationComplete } from './notification.actions';
import { withLatestFrom, switchMap, tap, filter, catchError, map, mergeMap } from 'rxjs/operators';
import { ErrorData } from '../../errors/error-data';
import { UserService } from '../../services/user.service';
import { BaseEffects } from '../base.effects';
import { GlobalErrorHandler } from '../../errors/global-error-handler';
import { NotificationEventService } from '../../notifications/notification-event.service';

@Injectable()
export class NotificationEffects extends BaseEffects {

  constructor(private actions$: Actions,
              private store: Store<any>,
              private userService: UserService,
              private client: NotificationsClient,
              eventService: NotificationEventService,
              errorHandler: GlobalErrorHandler) {
    super(errorHandler, eventService);
  }

  @Effect({ dispatch: false })
  loadNotifications: Observable<Notification[]> = this.actions$.pipe(
    ofType<LoadNotifications>(NotificationActionTypes.LoadNotifications),
    withLatestFrom(this.store.select(NOTIFICATIONS)),
    switchMap(([action, notifications]) => {
      // only call API if userId is defined
      if (this.userService.getUserId()) {
        return this.client.get({userId: this.userService.getUserId(), otherIds: action.otherIds, deliveryType: 'APPLICATION'}).pipe(
          catchError(err => this.processCatchError(NotificationActionTypes.LoadNotifications, {}, err,false)),
        )
      } else {
        return of([]);
      }
    }),
    map(notifications => (notifications instanceof ErrorData) ? [] : notifications),
    tap((notifications: Notification[]) => this.store.dispatch(new NotificationsUpdated(notifications)))
  );

  @Effect({ dispatch: false })
  createNotification: Observable<Notification[]> = this.actions$.pipe(
    ofType<CreateNotification>(NotificationActionTypes.CreateNotification),
    withLatestFrom(this.store.select(NOTIFICATIONS)),
    mergeMap(([action, notifications])=> this.client.post(action.form)
      .pipe(
        // Emit an event that the notification was created
        tap(created => this.store.dispatch(new CreateNotificationComplete(created))),
        // Merge the results with the existing list of notifications to prevent re-calling the API
        map(created => this.mergeResults(notifications, created)),
        // Handle any errors
        catchError(err => this.processCatchError(NotificationActionTypes.CreateNotificationFailed, {payload: action.form}, err))
      )
    ),
    filter(notification => !(notification instanceof ErrorData)),
    tap((notifications: Notification[]) => this.store.dispatch(new NotificationsUpdated(notifications)))
  );

  @Effect({ dispatch: false })
  updateNotification: Observable<Notification[]> = this.actions$.pipe(
    ofType<UpdateNotification>(NotificationActionTypes.UpdateNotification),
    withLatestFrom(this.store.select(NOTIFICATIONS)),
    mergeMap(([action, notifications])=> this.client.put(action.form)
      .pipe(
        // Emit an event that the notification was updated
        tap(updated => this.store.dispatch(new UpdateNotificationComplete(updated))),
        // Merge the results with the existing list of notifications to prevent re-calling the API
        map(updated => this.mergeResults(notifications, updated)),
        // Handle any errors
        catchError(err => this.processCatchError(NotificationActionTypes.UpdateNotificationFailed, {payload: action.form}, err))
      )
    ),
    filter(notification => !(notification instanceof ErrorData)),
    tap((notifications: Notification[]) => this.store.dispatch(new NotificationsUpdated(notifications)))
  );

  /**
   * Merge the notification with the existing set of notifications. This prevents an additional call to the API
   * to reload notifications.
   */
  private mergeResults(notifications: Notification[], updated: Notification){
    const existing = notifications.find(n => n._id === updated._id);
    if(existing){
      //Update the existing element in the array
      existing.deserialize(updated);
    } else {
      notifications.push(updated);
    }
    return notifications;
  }
}
