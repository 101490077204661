import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ListingPhoto } from '../models';
import { Observable, throwError, of } from 'rxjs';
import { PhotoThumbnail } from '../models/photo-thumbnail.model';
import { catchError, tap, map } from 'rxjs/operators';
import { AppService } from './app.service';

export class PresignedThumbnail {
  size: number;
  originalUrl: string;
  presignedUrl: string;

  constructor(size: string, thumbnail?: PhotoThumbnail) {
    if(thumbnail) {
      this.size = +size; // The '+' converts the string to a number
      this.originalUrl = thumbnail.uri;
    }
  }
}

export class PresignedPhoto {
  originalUrl: string;
  presignedUrl: string;
  thumbnails: PresignedThumbnail[];

  constructor(photo?: ListingPhoto) {
    if(photo) {
      this.originalUrl = photo.uri;
      this.thumbnails = Object.keys(photo.thumbnails).map(key => new PresignedThumbnail(key, photo.thumbnails[key]));
    }
  }

  locateThumbnail(size: number, fallbackSize?: number): PresignedThumbnail {
    return this.thumbnails.find(thumb => thumb.size === size)
      || this.thumbnails.find(thumb => fallbackSize && thumb.size === fallbackSize)
  }
}

@Injectable({
  providedIn: 'root'
})
export class PresignService {
  private static readonly presignedUrlCache: {[originalUrl: string]: string} = {};
  private readonly apiBase = AppService.get('photosBaseURL');
  private readonly printerApiBase = AppService.get('printerBaseURL');

  constructor(private http: HttpClient) {}

  presignPhoto(photo: ListingPhoto): Observable<PresignedPhoto> {
    const presignedPhoto = new PresignedPhoto(photo);

    // Create an array with all of the requested photos and their originalUrls. We need this to map back after the response returns
    const photoRequests: {photo: PresignedPhoto | PresignedThumbnail, originalUrl: string}[] = [];
    photoRequests.push({photo: presignedPhoto, originalUrl: presignedPhoto.originalUrl})
    presignedPhoto.thumbnails.forEach(thumbnail => photoRequests.push({photo: thumbnail, originalUrl: thumbnail.originalUrl}));

    const allPresignedRequests = photoRequests.map(request => request.originalUrl);

    return this.presignUrls(allPresignedRequests).pipe(
      tap(presignedUrlMap => {
        // We need to map all of the responses back to the original requests.
        photoRequests.forEach(request => {
          const presignedUrl = presignedUrlMap.find(urlMap => urlMap.originalUrl === request.originalUrl);
          if(presignedUrl) {
            request.photo.presignedUrl = presignedUrl.presignedUrl;
          }
        })
      }),
      map(() => presignedPhoto)
    );
  }

  // NOTE: We could avoid a lot of these issues if we
  private getPresignUrls(urls: string[], endpointBaseUrl: string): Observable<{originalUrl: string, presignedUrl: string }[]> {
    const presignedUrlMap = urls.map(url => ({originalUrl: url, presignedUrl: this.getCachedPresignUrl(url)}));

    // Only request the urls that have not been requested yet.
    const urlsToRequest = presignedUrlMap.filter(urlMap => urlMap.presignedUrl == null).map(urlMap => urlMap.originalUrl);
    if(urlsToRequest.length === 0) {
      // Return all the cached values
      return of(presignedUrlMap);
    }
    // Return a mix of the cached values and the requested values
    return this.http.post<string[]>(endpointBaseUrl + 'presign', urlsToRequest).pipe(
      map(presignedUrls => {
        // Update the presignedUrls that have not been asigned yet.
        presignedUrlMap.filter(urlMap => urlMap.presignedUrl == null).forEach(urlMap => {
          const presigned = presignedUrls.find(presignedUrl => presignedUrl?.indexOf(urlMap.originalUrl) > -1);
          if(presigned) {
            urlMap.presignedUrl = presigned;
            this.setCachedPresignUrl(urlMap.originalUrl, urlMap.presignedUrl);
          }
        });
        return presignedUrlMap;
      }),
      catchError(error => throwError(error.error))
    );
  }

  contactListPresignedUrl(urls: string[]): Observable<{originalUrl: string, presignedUrl: string }[]> {
    return this.getPresignUrls(urls, this.printerApiBase);
  }

  presignUrls(urls: string[]): Observable<{originalUrl: string, presignedUrl: string }[]> {
    return this.getPresignUrls(urls, this.apiBase);
  }
  private getCachedPresignUrl(originalUrl: string): string {
    return PresignService.presignedUrlCache[originalUrl];
  }

  private setCachedPresignUrl(originalUrl: string, presignedUrl) {
    PresignService.presignedUrlCache[originalUrl] = presignedUrl;
  }
}
