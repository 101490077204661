export const AdditionalQuantities: { value: number, text: string }[] = [
    { value: 25, text: "25" },
    { value: 50, text: "50" },
    { value: 75, text: "75" },
    { value: 100, text: "100" },
    { value: 200, text: "200" },
    { value: 300, text: "300" },
    { value: 400, text: "400" },
    { value: 500, text: "500" },
    { value: 600, text: "600" },
    { value: 700, text: "700" },
    { value: 800, text: "800" },
    { value: 900, text: "900" },
    { value: 1000, text: "1000" }
]