import { Directive, Input, EventEmitter, OnInit, HostListener, OnDestroy, Output, HostBinding, OnChanges, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[lcDebounceClick]'
})
export class DebounceClickDirective implements OnInit, OnDestroy, OnChanges {

  /** DebounceTime in milliseconds */
  @Input() debounceTime = 200;

  /** Time to disable to button after click in milliseconds */
  @Input() disabledTime = 1000;

  /** Original, default disabled property*/
  @Input() disabled: boolean;

  /** Displays a spinner if isLoading = true */
  @Input() isLoading: boolean;

  /** Adds the loading class to the button to display a spinner */
  @HostBinding('class.spinner') showSpinner: boolean = false;

  /** Host binding that disables the elemenet */
  @HostBinding('disabled') isDisabled: boolean;

  /** Event that executes on click event */
  @Output('lcDebounceClick') readonly debounceClick = new EventEmitter();

  /** Handles the click event on the host component and emits the click event.  */
  @HostListener('click', ['$event'])
  clickEvent(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks$.next(event);
  }


  /** Subject that will handle all clicks */
  private readonly clicks$ = new Subject();

  /** The subscription that manages the clicks and emits a debounceClick event based on debounce time. */
  private clickSubscription: Subscription;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.disabled) {
      this.updateDisabled();
    }
    if(changes.isLoading) {
      this.updateSpinner(this.isLoading);
    }
  }

  /**
   * Once the directive is initialized, setup the subscription to monitor the click events
   */
  ngOnInit() {
    // Subscribe to the click event, adding the debounce time to ensure we avoid double-tap
    this.clickSubscription = this.clicks$.pipe(debounceTime(this.debounceTime))
      .subscribe(event => {
        this.updateSpinner(true);
        this.debounceClick.emit(event);
        setTimeout(() => this.updateSpinner(false), this.disabledTime);
      }, error => {
        this.updateSpinner(false);
        console.error('Error handling debounce click!', error)
      });
  }

  /**
   * When the directive is destroyed. Cleanup any subscriptions
   */
  ngOnDestroy() {
    if(this.clickSubscription) {
      this.clickSubscription.unsubscribe();
    }
  }

  private updateSpinner(isLoading: boolean){
    this.showSpinner = isLoading || this.isLoading;
    this.updateDisabled();
  }

  private updateDisabled() {
    this.isDisabled = this.disabled || this.showSpinner;
  }
}
