import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserMatchClient } from '../clients/user-match.client';
import { ProfileInfo } from '../models/profile-info.model';
import { CustomValidator } from '../validators';

@Injectable({
  providedIn: 'root'
})
export class TeamMemberService {

  constructor(private userMatchClient: UserMatchClient) { }

  searchAgents(searchTerm?: string, excludeTeamMembers?: boolean, roles?: string, filterLicenseNumber?: boolean): Observable<ProfileInfo[]> {
    searchTerm = CustomValidator.stringValueOf(searchTerm).toLowerCase();
    if(searchTerm === '') {
      return of([]);
    }
    const searchAgents = {search: searchTerm, excludeTeamMembers: excludeTeamMembers,roles:roles, filterLicenseNumber: filterLicenseNumber};
    return this.userMatchClient.get(searchAgents);
  }

  searchAgentsById(agentIds: string[]) {
    // TODO: This needs to be hooked up to the endpoint or the information needs to be returned with the
    // existing queries (i.e. - TeamProfiles and marketing-order)
    return this.userMatchClient.get({userIds: agentIds})
  }
}
