// Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
const angularModules = [ CommonModule, MatButtonModule, MatCardModule, FlexLayoutModule];

// Export Components
import { CardComponent } from './components/card/card.component';
const exportComponents = [ CardComponent ];

@NgModule({
  declarations: [...exportComponents],
  exports: [...exportComponents],
  imports: [
    ...angularModules
  ]
})
export class CardsModule { }
