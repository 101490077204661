import { Deserializable } from './deserializable.model';
import { ConciergeObligationTypes } from './concierge-obligation.types';
import { ProductOption } from './product-option.model';
import { DurationUnits } from './duration-units.enum';
import { LayoutDetail } from './layout-detail.model';

interface CanBeReplacedBy {
  type: 'product',
  description: string,
  value: string,
  conversion: {
    from: {
      type: 'quantity' | 'duration',
      value: number
    },
    to: {
      type: 'quantity' | 'duration',
      value: number
    }
  }
}

export class ProductDetails implements Deserializable<ProductDetails> {

  conciergeObligation: ConciergeObligationTypes;
  optional: boolean;
  isBundled: boolean;
  durationOfSLA: number;
  unitQuantity: number;
  unitPrice: number;
  additionalUnitPrice: number;
  price: number;
  bundledPrice: number;
  maxOptionsSelectable: number;
  options: ProductOption[];
  isVendorPrinted: boolean;
  isShareable: boolean;
  deferredDelivery: boolean;
  mailDeliverable: boolean;
  mailReturnAddress: boolean;
  deliveredWithinEnvelope: boolean;
  outputFileFormat: string;
  shippingCarrier: string;
  shippingDeliveryOption: string;
  duration: number;
  durationUnits: DurationUnits;
  maxPhotos: number;
  bodyLayouts: LayoutDetail[];
  shippedBy: any;
  canBeReplacedBy: CanBeReplacedBy[];
  isReplaced: boolean;
  isReplacementOnly: boolean;

  constructor(input?: any) {
    this.deserialize(input);
  }

  deserialize(input?: any): ProductDetails {
    if(!input) { return this; }
    Object.assign(this, input);

    if(this.options) {
      this.options = this.options.map(option => new ProductOption(option));
    }
    if(this.bodyLayouts) {
      this.bodyLayouts = this.bodyLayouts.map(layout => new LayoutDetail(layout));
    }
    return this;
  }

  get durationDescription() {

    if (!this.duration) {
      return null;
    }
    let desc = `${this.duration}`;
    if (this.durationUnits) {
      desc = `${desc} ${this.durationUnits}`;
    }
    return desc;
  }
}
