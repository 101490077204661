import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';


/**
 * Smart Street api call for getting the address suggestions based on streetAddress1
 */
@Injectable({
  providedIn: 'root'
})
export class SmartyStreetService {
  private readonly smartStreetKey: string;
  private suggestions: any[];

  constructor() {
    this.smartStreetKey = AppService.get('smartStreetKey');
  }

  getAddressSuggestions(term: string): any {
    const SmartyStreetsCore = SmartyStreetsSDK.core;
    const Lookup = SmartyStreetsSDK.usAutocompletePro.Lookup;
    const websiteKey = this.smartStreetKey; //'86422360070416896'; // Website Key
    const credentials = new SmartyStreetsCore.SharedCredentials(websiteKey);
    const clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud']);
    const client = clientBuilder.buildUsAutocompleteProClient();
    const lookup = new Lookup(term);
    client.send(lookup).then(data => {
      this.suggestions = this.buildSuggestions(data.result);
    }).catch();
    return this.suggestions;
  }

  buildSuggestions(suggestions) {
    const results = [];
    if (suggestions?.length > 0) {
      suggestions.forEach(suggestion => {
        let whiteSpace = '';
        if (suggestion.secondary) {
          if (suggestion.entries > 1) {
            suggestion.secondary += ' (' + suggestion.entries + ' entries)';
          }
          whiteSpace = ' ';
        }
        const addressTest = suggestion.streetLine + whiteSpace + suggestion.secondary + ' ' + suggestion.city + ', ' + suggestion.state + ' ' + suggestion.zipcode;

        results.push({
          streetAddress1: suggestion.streetLine,
          streetAddress2: suggestion.secondary,
          city: suggestion.city,
          state: suggestion.state,
          postalCode: suggestion.zipcode,
          text: addressTest
        });
      });
    }
    return results;
  }
}
