import { Deserializable } from './deserializable.model';
import { User } from './user.model';

export class NotificationContact  implements Deserializable<NotificationContact> {
  emailAddress: string;
  isEnabled: boolean;

  constructor(value?: Partial<NotificationContact>) {
    this.deserialize(value);
  }

  deserialize(value?: Partial<NotificationContact>) {
    Object.assign(this, value || {});
    return this;
  }
}

export class NotificationSettings implements Deserializable<NotificationSettings>  {
  isEnabled: boolean;
  contacts: NotificationContact[];

  constructor(value?: Partial<NotificationSettings>) {
    this.deserialize(value);
  }

  deserialize(value?: Partial<NotificationSettings>) {
    Object.assign(this, value || {});
    this.contacts = (this.contacts || []).map(address => new NotificationContact(address));
    return this;
  }
}

export class UserSettings implements Deserializable<UserSettings> {
  _id: string;
  user: string | User;
  notifications: {[type: string]: NotificationSettings };
  isDarkTheme: boolean = false;

  get userId(): string {
    if(!this.user) { return null; }
    if(this.user instanceof User) {
      return this.user._id;
    };
    return this.user;
  }

  constructor(value?: Partial<UserSettings>) {
    this.deserialize(value);
  }

  deserialize(value: Partial<UserSettings>) {
    Object.assign(this, value || {});

    // Deserialize the notifications into proper objects
    this.notifications = this.notifications || {};
    for (const notificationType in this.notifications) {
      if (Object.prototype.hasOwnProperty.call(this.notifications, notificationType)) {
        this.notifications[notificationType] = new NotificationSettings(this.notifications[notificationType]);
      }
    }

    return this;
  }
}
