<div class="modal-header">
  <span class="mat-headline">Terms Of Use Statement</span>
  <button type="button" mat-icon-button (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="modal-body">
  <div mat-dialog-content>
    <p class="mat-headline"> WHAT YOU SHOULD KNOW</p>
    <p class="mat-body-1">
    Welcome to Desk - the NRT LLC Intranet/Extranet site (the "Desk"). Please read our Intranet Terms of Use Statement (the
    "Terms") carefully before continuing on with your use of Desk. These Terms shall govern the use of the Desk and apply to
    all Intranet traffic visiting Desk. By accessing or using Desk, you agree to the Terms. The Terms are meant to protect
    all of our Desk visitors and your use of Desk signifies your agreement with these Terms. <span class="mat-headline">IF YOU DO NOT AGREE WITH THESE
    TERMS, DO NOT USE THIS INTRANET SITE.</span> NRT reserves the right, in its sole discretion, to modify, alter or otherwise
    update these Terms at any time. Such modifications shall be effective immediately upon posting. By using Desk after we
    have posted notice of such modifications, alterations or updates you agree to be bound by such revised Terms.
    </p>
    <p class="mat-body-1">
    In accordance with our goals, Desk will permit you to link to many other Web sites, that may or may not be affiliated
    with Desk and NRT LLC ("NRT"), and that may have terms of use that differ from, or contain terms in addition to, the
    terms specified here. Your access to such Web sites through links provided on the Desk is governed by the terms of use
    and policies of those sites, not Desk.
    </p>
    <p  class="mat-headline"> PRIVACY</p>

    <p class="mat-body-1">

    Registration data and certain other information about you is subject to our Privacy Policy. For more information, please
    review our full Privacy Policy on the main menu that can be found on Desk.
    </p>

    <p class="mat-headline">TRADEMARKS, COPYRIGHTS AND RESTRICTIONS</p>
   <p class="mat-body-1">

    Desk is controlled and operated by NRT, 175 Park Avenue, Madison, NJ 07940. All content on Desk, including, but not
    limited to text, images, illustrations, audio clips, and video clips, is protected by copyrights, trademarks, service
    marks, and/or other intellectual property rights (which are governed by U.S. and worldwide copyright laws and treaty
    provisions, privacy and publicity laws, and communication regulations and statutes), and are owned and controlled by NRT
    or its affiliates, or by third party content providers, merchants, sponsors and licensors (collectively "Providers")
    that have licensed their content or the right to market their products and/or services to NRT.
   </p>
    <p class="mat-body-1">

    Notwithstanding, the content generally posted or made available on Desk or any Web site owned, operated, licensed or
    controlled by the Providers solely is for your personal, non-commercial use; except for the limited commercial purpose
    of complying with applicable Federal and State Do Not Call regulations. You may print a copy of such content and/or
    information for your personal, non-commercial use only, but you may not copy, reproduce, republish, upload, post,
    transmit, distribute, and exploit the content or information in any way (including by e-mail or other electronic means)
    for commercial use without the prior written consent of NRT or the Providers. You may request consent by faxing a
    request to NRT's Legal Department at 973-407-5059. Absent the prior written consent of NRT or the Providers, your
    modification of the content, use of the content on any other Web site or networked computer environment, or use of the
    content for any purpose other than personal, non-commercial use, violates the rights of the owners of Desk and/or the
    Provider copyrights, trademarks or service marks and other proprietary rights, and is prohibited. As a condition to your
    use of Desk, you warrant to NRT that you will not use Desk for any purpose that is unlawful or prohibited by these
    Terms, including without limitation the posting or transmitting any threatening, libelous, defamatory, obscene,
    scandalous, inflammatory, pornographic, or profane material. If you violate any of these Terms, your permission to use
    Desk immediately terminates without the necessity of any notice. NRT retains the right to deny access to anyone at its
    discretion for any reason, including for violation of these Terms.
    </p>
        <p class="mat-body-1">

    You may not use on your or a franchisee's Web site any trademarks, service marks, or copyrighted materials appearing on
    this Intranet Site, including but not limited to any logos or characters, without the express written consent of the
    owner of the mark or copyright. You may not frame or otherwise incorporate into another Web site any of the content or
    other materials on this Intranet Site without prior written consent of NRT.
        </p>
    <p class="mat-headline">LINKS</p>

            <p class="mat-body-1">

    Desk may contain hyperlinks to other Web sites ("Linked Sites"). The Linked Sites are provided for your convenience and
    information only and, as such, you access them at your own risk. The content of any Linked Sites is not under NRT's
    control, and NRT is not responsible for, and does not endorse, such content, whether or not NRT is affiliated with the
    owners of such Linked Sites. You may not establish a hyperlink to this Intranet Site or provide any links that state or
    imply any sponsorship or endorsement of your or a franchisee's Web site by NRT, or its affiliates, or Providers.
            </p>
        <p class="mat-headline">DISCLAIMER OF WARRANTIES AND LIABILITY</p>
    <p class="mat-headline">
    ALL CONTENT ON DESK IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. OTHER THAN THOSE
    WARRANTIES WHICH, UNDER THE U.S. LAWS APPLICABLE TO THESE TERMS, ARE IMPLIED BY LAW AND ARE INCAPABLE OF EXCLUSION,
    RESTRICTION, OR MODIFICATION, NRT DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
    IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. NEITHER NRT, ITS AFFILIATED OR RELATED
    ENTITIES, NOR THE PROVIDERS, NOR ANY PERSON INVOLVED IN THE CREATION, PRODUCTION, AND DISTRIBUTION OF THIS SITE WARRANT
    THAT THE FUNCTIONS CONTAINED IN THIS SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT
    THE SERVER THAT MAKES THE CONTENT OR ELEMENTS AVAILABLE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE CONTENT
    THAT YOU ACCESS ON THIS INTRANET SITE IS PROVIDED SOLELY FOR YOUR CONVENIENCE AND INFORMATION ONLY. NRT DOES NOT WARRANT
    OR MAKE ANY REPRESENTATIONS REGARDING THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THIS INTRANET SITE, OR AS TO THE
    RELIABILITY, ACCURACY OR CURRENCY OF ANY INFORMATION, CONTENT, ELEMENTS, SERVICE AND/OR MERCHANDISE ACQUIRED PURSUANT TO
    YOUR USE OF DESK.
    </p>
    <p class="mat-headline">
    YOU EXPRESSLY AGREE THAT USE OF DESK, AND ANY OF THE PRODUCTS CONTAINED THEREIN, IS AT YOUR OWN RISK. YOU (AND NOT NRT)
    ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION OF YOUR SYSTEM. YOU EXPRESSLY AGREE THAT NEITHER
    NRT, NOR ITS AFFILIATED OR RELATED ENTITIES (INCLUDING ITS PROVIDERS), NOR ANY OF THEIR RESPECTIVE EMPLOYEES, OR AGENTS,
    NOR ANY PERSON OR ENTITY INVOLVED IN THE CREATION, PRODUCTION AND DISTRIBUTION OF THIS INTRANET SITE, IS RESPONSIBLE OR
    LIABLE TO ANY PERSON OR ENTITY WHATSOEVER FOR ANY LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE OR OTHERWISE),
    INJURY, CLAIM, LIABILITY OR OTHER CAUSE OF ANY KIND OR CHARACTER WHATSOEVER BASED UPON OR RESULTING FROM THE USE OR
    ATTEMPTED USE OF DESK OR ANY OTHER LINKED SITE. BY WAY OF EXAMPLE, AND WITHOUT LIMITING THE GENERALITY OF THE FOREGOING,
    NRT AND RELATED PERSONS AND ENTITIES SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY CLAIM OR DAMAGE ARISING FROM FAILURE OF
    PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION, COMPUTER VIRUS, THEFT, DESTRUCTION,
    UNAUTHORIZED ACCESS TO OR ALTERATION OF PERSONAL RECORDS, OR THE RELIANCE UPON OR USE OF DATA, INFORMATION, OPINIONS OR
    OTHER MATERIALS APPEARING ON THIS INTRANET SITE. YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT NRT IS NOT LIABLE OR
    RESPONSIBLE FOR ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER SUBSCRIBERS OR THIRD PARTIES. SOME JURISDICTIONS
    MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES. IN SUCH JURISDICTIONS,
    NRTS LIABILITY IS LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
    </p>
    <p class="mat-headline">INDEMNIFICATION</p>
    <p class="mat-body-1">

    You agree to indemnify, defend, and hold harmless NRT and the Providers, its and their officers, directors, employees,
    affiliates, agents, licensors, and suppliers from and against all losses, expenses, damages and costs, including
    reasonable attorneys' fees, resulting from any violation by you of these Terms.
    </p>
  <p class="mat-headline"> THIRD PARTY RIGHTS</p>
      <p class="mat-body-1">

    These Terms are for the benefit of NRT and its Providers, its and their officers, directors, employees, affiliates,
    agents, licensors, and suppliers. Each of these individuals or entities shall have the right to assert and enforce these
    Terms directly against you on its or their own behalf.
      </p>
    <p class="mat-headline">JURISDICTIONAL ISSUES</p>
          <p class="mat-body-1">

    Unless otherwise specified, the content contained in this Intranet Site is presented solely for your convenience and/or
    information. The NRT Site is controlled and operated by NRT from its offices within Madison, New Jersey. NRT makes no
    representation that content in Desk is appropriate or available for use in other locations. Those who choose to access
    Desk from other locations do so on their own initiative and are responsible for compliance with local laws, if and to
    the extent local laws are applicable. You may not use or export the materials in this Intranet Site in violation of U.S.
    export laws and regulations. These Terms shall be governed by, construed and enforced in accordance with the laws of the
    State of New Jersey, as they are applied to agreements entered into and to be performed entirely within such State. Any
    action you, any third party or NRT brings to enforce these Terms, or in connection with any matters related to this
    Intranet Site, shall be brought only in either the state or Federal courts located in and for Morris County, New Jersey
    and you expressly consent to the jurisdiction of said courts. If any provision of these Terms shall be unlawful, void or
    for any reason unenforceable, then that provision shall be deemed sever able from these Terms and shall not affect the
    validity and enforceability of any remaining provisions.
          </p>
  <p class="mat-headline"> ENTIRE AGREEMENT</p>

  <p class="mat-body-1">

        The provisions and conditions of these Terms, and each obligation referenced herein, represent the entire Agreement
        between NRT, its affiliated or related entities, and you, and supersede any prior agreements or understandings not
        incorporated herein. In the event that any inconsistencies exist between these Terms and any future published terms of
        use or understanding, the last published Terms or terms of use or understanding shall prevail.
        ANY RIGHTS NOT EXPRESSLY GRANTED HEREIN ARE RESERVED BY NRT LLC.
    </p>

  <div class="modal-footer">

    <button id="terms-of-use-cancel-button" type="button" mat-raised-button (click)="onCancel()">OK</button>
  </div>
  </div>
</div>
