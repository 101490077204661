<label [id]="idPrefix + '-label'" class="form-label" [for]="idPrefix + '-input'">{{label}}</label>

<!--Card container-->
<div [id]="idPrefix + '-card-container'" *ngIf="options$ | async as options" fxLayout="row wrap" class="card-container">

  <!--Cards-->
  <div fxFlex="0 0 125px" class="option-card" *ngFor="let option of options; let i = index" (mouseenter)="onHover(option)" (mouseleave)="onHover()">
    <!-- Each card is 125 x 125 with a 5 px padding ==> 115 x 115 inside-->
    <a [id]="parseId('card-option-' + (option.id || option.text || i))"
      class="mat-elevation-z1 mat-card card-option clickable" fxLayout="column"
      [class.disabled]="!option.isEnabled" [ngClass]="{'shadow option-selected': option.isSelected}" (click)="selectOption(options, option)">

      <!--Card Image 95 of the 115 px-->
      <div fxFlex="0 0 95px" class="image-container" fxLayout="column" fxLayoutAlign="center center">

        <!-- Card Image -->
        <template *ngIf="imageTemplate; else defaultImage" [ngTemplateOutlet]="imageTemplate" [ngTemplateOutletContext]="{option: option}"></template>
        <ng-template #defaultImage>
          <img *ngIf="!option.isSelected && option.imageSource" class="logo-img" [src]="option.imageSource"/>
          <img *ngIf="option.isSelected && option.selectedImageSource" class="logo-img" [src]="option.selectedImageSource"/>
        </ng-template>

        <!-- Overlay Icon-->
        <span *ngIf="!option.model.isBundled"  class="far top-right" [ngClass]="{'fa-circle': !option.isSelected, 'fa-check-circle option-icon-selected': option.isSelected}"></span>
      </div>

      <!--Display Label: Remaining 20 of the 115px-->
      <span fxFlex="0 0 20px" fxLayout="column" fxLayoutAlign="end">
        <template *ngIf="footerTemplate; else defaultFooter" [ngTemplateOutlet]="footerTemplate" [ngTemplateOutletContext]="{option: option}"></template>
        <ng-template #defaultFooter>
          <span class="card-label text-wrap">{{ option.text }}</span>
        </ng-template>
      </span>
    </a>
  </div>
</div>

<!-- <input [id]="idPrefix + '-input'" class="form-control" [placeholder]="placeholder" type="text" [mask]="inputMask?.mask" [patterns]="inputMask?.pattern" [formControl]="formControl" > -->
<small [id]="idPrefix + '-error'" [innerHTML]="errors || '&nbsp;'" class="invalid-feedback d-block"></small>
