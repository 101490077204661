export class HotKeyEvent {
  key: string;
  actionName: string;

  /**
   * Create the display notification
   * @param key the key pressed
   */
  public constructor(key: string, actionName:string) {
    this.key = key;
    this.actionName = actionName
  }
}