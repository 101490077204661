import { Component, ViewChild, Inject, OnInit } from '@angular/core';
import { ListingPhoto, ThumbnailSizes } from '@lc/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { slideAnimation } from './slide.animation';
export class PhotoGalleryDialogData {
  constructor(readonly title: string, readonly photos: ListingPhoto[], readonly selectedPhotoIndex?: number, readonly isFavourite?: boolean) {}
}
@Component({
  selector: 'lc-photo-gallery-dialog',
  templateUrl: './photo-gallery-dialog.component.html',
  styleUrls: ['./photo-gallery-dialog.component.scss'],
  animations: [slideAnimation]
})
export class PhotoGalleryDialogComponent implements OnInit{
  readonly title: string;
  readonly photos: ListingPhoto[];
  selectedIndex = 0;
  isFavourite: boolean;
  thumbnailSize = ThumbnailSizes.LARGE_THUMBNAIL_WIDTH;
  private readonly _close = new Subject();
  readonly close = this._close.asObservable();

  @ViewChild(NgbCarousel)
  carousel: NgbCarousel;

  currentIndex = 0;
  constructor( @Inject(MAT_DIALOG_DATA) public data: PhotoGalleryDialogData) {
    this.title = data.title;
    this.photos = data.photos;
    this.selectedIndex = data.selectedPhotoIndex;
    this.isFavourite = data.isFavourite;

    this.preloadImages();
  }

  ngOnInit() {
    // Enable this code for auto slide change
    /*
    setInterval(() => {
      this.selectedIndex = ++this.selectedIndex % this.photos.length;
    }, 3000);
    */
  }

  /** Closes the dialog by emitting the close event */
  onClose() {
    this._close.next();
  }

  preloadImages() {
    this.photos.forEach(slide => {
      (new Image()).src = slide.uri;
    });
  }

  setCurrentSlideIndex(index) {
    this.selectedIndex = index;
  }

  isCurrentSlideIndex(index) {
    return this.selectedIndex === index;
  }

  prevSlide() {
    this.selectedIndex = (this.selectedIndex < this.photos.length - 1) ? ++this.selectedIndex : 0;
  }

  nextSlide() {
    this.selectedIndex = (this.selectedIndex > 0) ? --this.selectedIndex : this.photos.length - 1;
  }
}
