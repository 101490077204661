import { Component, OnInit } from '@angular/core';
import { Browser, UserAgentService } from '@lc/core';

@Component({
  selector: 'lc-browser-support',
  templateUrl: './browser-support.component.html',
  styleUrls: ['./browser-support.component.scss']
})
export class BrowserSupportComponent implements OnInit {

  public os: string;
  public osVersion: string;
  public browser: string;
  public browserVersion: string;
  public isCompliant: boolean;

  public supportedBrowsers: Browser[];

  constructor(private userAgentService: UserAgentService) { }

  ngOnInit() {
    // user's info
    this.os = this.userAgentService.getOs();
    this.osVersion = this.userAgentService.getOsVersion();
    this.browser = this.userAgentService.getBrowser();
    this.browserVersion = this.userAgentService.getBrowserVersion();
    this.isCompliant = this.userAgentService.isCompliant();

    // supported user agents
    this.supportedBrowsers = this.userAgentService.getSupportedBrowsers(this.os);
  }

}
