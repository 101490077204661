import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
const angularModules = [ CommonModule, FlexLayoutModule ];

// LC Modules
import { InputsModule } from '../inputs/inputs.module';
import { AgentSearchModule } from '../agent-search/agent-search.module';
const lcModules = [InputsModule, AgentSearchModule];

// Internal Components
import { OrderFiltersComponent } from './components/order-filters/order-filters.component';
const exportComponents = [ OrderFiltersComponent ];

@NgModule({
  declarations: [...exportComponents],
  imports: [
    ...angularModules,
    ...lcModules
  ], exports: [ ...exportComponents]
})
export class OrderFiltersModule { }
