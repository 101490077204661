import { Component, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseForm, DialogAction } from '@lc/core';
import { FormControl, Validators } from '@angular/forms';
import { Masks } from '@lc/ui';
import { OrderRefundReasonType } from './order-refund-reason.type';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ReasonDialogData {
  constructor(
    readonly readonly: boolean = false,
    readonly title: string,
    readonly message: string,
    readonly reasons: string[],
    readonly acceptAction: ReasonDialogAction,
    readonly cancelAction: ReasonDialogAction,
    readonly reason?: string,
    readonly notes?: string,
    readonly totalAmount?: number) {}
}

export class ReasonDialogAction extends DialogAction {
  constructor(
    public text: string,
    public reason?: string,
    public notes?: string,
    public amount?: number,
    public onClick?: () => any) {
      super(text, onClick)
    }
}

export class ReasonForm extends BaseForm {
  constructor(value?: any) {
    super({
      reason: new FormControl(null, [Validators.required]),
      notes: new FormControl(null, [Validators.required]),
      amount: new FormControl(null)
    });

    if (value) {
      this.patchValue(value);
    }
  }
}

@Component({
  selector: 'lc-reason-dialog',
  templateUrl: './reason-dialog.component.html',
  styleUrls: ['./reason-dialog.component.scss']
})
export class ReasonDialogComponent {
  readonly refundMask = Masks.decimalWithWholeNumber;

  public readonly reasonForm: ReasonForm = new ReasonForm();

  private readonly _close = new Subject<ReasonDialogAction>();
  public readonly close = this._close.asObservable();

  public hideNotes = false;

  constructor( @Inject(MAT_DIALOG_DATA) public data: ReasonDialogData) {
      // populate the form if values are passed in
      if(data.reason) {
        this.reasonForm.getControl('reason').setValue(data.reason);
      }
      if(data.notes) {
        this.reasonForm.getControl('notes').setValue(data.notes);
      }

      if(data.acceptAction?.text === 'Refund') {
        this.reasonForm.controls['amount'].setValidators([Validators.required, Validators.min(1), Validators.max(data.totalAmount)]);
        this.hideNotes = true;
      }

      this.reasonForm.markAsPristine();
  }

  /**
   * Sets the validator on the notes form control if the reason is 'other'
   */
  changeReason() {
    const reason = this.reasonForm.get('reason').value;

    if(this.data.acceptAction.text === 'Refund' && reason !== OrderRefundReasonType.OTHER) {
      this.hideNotes = true;
      this.reasonForm.controls['notes'].clearValidators();
      this.reasonForm.controls['notes'].setValue(null);
    } else {
      this.hideNotes = false;
      this.reasonForm.controls['notes'].setValidators([Validators.required]);
    }
  }

  onActionClicked(action: ReasonDialogAction) {
    if((action || this.data.cancelAction) === this.data.cancelAction) {
      this.onClose(this.data.cancelAction);
    } else {
      this.onAcceptClicked(this.data.acceptAction);
    }
  }


  private onAcceptClicked(action: ReasonDialogAction) {
    this.reasonForm.markAllAsDirty();
    if (!this.reasonForm.valid) {
      return;
    }

    action.reason = this.reasonForm.getControl('reason').value;
    action.notes = this.reasonForm.getControl('notes').value;
    action.amount = this.reasonForm.getControl('amount').value;
    this.onClose(action);
  }

  private onClose(action: ReasonDialogAction) {
    if (action?.onClick) {
      action.onClick();
    }

    this._close.next(action);
  }
}
