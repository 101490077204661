<mat-form-field appearance="outline" floatLabel="always">
  <mat-label [id]="idPrefix + '-label'" >{{label}}</mat-label>
  <input matInput [id]="idPrefix + '-input'" [matDatepicker]="picker" [placeholder]="placeholder" [formControl]="formControl"  [errorStateMatcher]="matcher"
         [readonly]="readonly"
         [max]="maxDate"
         [min]="minDate">
  <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="readonly || disabled"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-hint [id]="idPrefix + '-hint'">{{hint}}</mat-hint>
  <mat-error [id]="idPrefix + '-error'" *ngIf="formControl && formControl.invalid" [innerHTML]="errors"></mat-error>
</mat-form-field>
