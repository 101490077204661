import { Injectable } from '@angular/core';
import { PhotoGalleryDialogComponent, PhotoGalleryDialogData } from './photo-gallery-dialog.component';
import { ListingPhoto } from '@lc/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class PhotoGalleryDialogService {
  constructor(private modal: MatDialog) { }

  /**
   * Opens the dialog component with the required parameters
   */
  open(title: string, photos: ListingPhoto[], index?: number) {
    const dialogRef = this.modal.open(PhotoGalleryDialogComponent, {
      data: new PhotoGalleryDialogData(title, photos, index, photos[index].favorite),
      width: '90vw', maxWidth: '1200px', height: '90vh', maxHeight: '800px', panelClass: 'photo-gallery' });
    const component: PhotoGalleryDialogComponent = dialogRef.componentInstance;

    component.close.subscribe(action => dialogRef.close(action), (error) => { throw new Error(error); });
    return dialogRef.afterClosed().toPromise();
  }
}
