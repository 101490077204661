import { ComponentType } from '@angular/cdk/portal';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyPolicyDialogComponent } from '../dialogs/privacy-policy-dialog/privacy-policy-dialog.component';
import { TermsOfUseDialogComponent } from '../dialogs/terms-of-use-dialog/terms-of-use-dialog.component';

@Component({
  selector: 'lc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  readonly copyrightDate = new Date().getFullYear();

  constructor(private readonly dialog: MatDialog) { }

  async onOpenTermsAndConditions() {
    await this.openDialog(TermsOfUseDialogComponent, 'Terms of Use');
  }

  async onOpenPrivacyPolicy(){
    await this.openDialog(PrivacyPolicyDialogComponent, 'Privacy Policy');
  }

  private async openDialog(type: ComponentType<any>, name: string) {
    const dialogRef = this.dialog.open(type, {
      width: '80%',
      height: '90vh',
      data: { component: name}
    });
    await dialogRef.afterClosed().toPromise();
  }
}
