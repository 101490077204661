import { Action } from '@ngrx/store';
import { OrderState, Listing } from '../../models/listing.model';
import { MultiMediaItemRequest, MultiMediaItem } from '../../models/multi-media-item.model';
import { Fulfillment } from '../../models/fulfillment.model';
import { PaymentRefund } from '../../models/payment-refund.model';
import { MarketingOrder } from '../../models/marketing-order.model';
import { ServiceActions } from '../../models/service-status.type';
import { ProductInstance } from '../../models/product-instance.model';
import { ProductDescription } from '../../models/product-description.model';
import { ListingPhoto } from '../../models/listing-photo.model';
import { UploadPhoto } from '../../models/upload-photo.model';
import { TemplateInstance } from '../../models/template-instance.model';
import { Media } from '../../models/media.model';


export enum OrderActionTypes {
  LoadOrder = '[Order] Load',

  GetOrder = '[Order] Get',
  GetOrderComplete = '[Order] Get Complete',

  AddOrder = '[Order] Add',
  AddOrderComplete = '[Order] Add Order Complete',

  UpdateOrder = '[Order] Update',
  UpdateOrderComplete = '[Order] Update Order Complete',
  UpdateOrderFailed = '[Order] Update Order Failed',
  UpdateCurrentOrderState = '[Order] Update Current Order State',

  SubmitOrder = '[Order] Submit',
  SubmitOrderComplete = '[Order] Submit Order Complete',
  SubmitOrderFailed = '[Order] Submit Order Failed',

  UpdateOrderProductDescription = '[Order] Update Product Description',

  UpdateOrderListing = '[Order] Update Listing',
  UpdateOrderListingComplete = '[Order] Update Listing Complete',
  UpdateOrderListingFailed = '[Order] Update Listing Failed',

  UpdateOrderPhotos = '[Order] Update Order Photos',
  UpdateOrderPhotosComplete = '[Order] Update Order Photos Complete',

  UpdateOrderPhoto = '[Order] Update Order Photo',

  UploadOrderPhotos = '[Order] Upload Order Photos',

  DeleteOrder = '[Order] Delete',
  DeleteOrderComplete = '[Order] Delete Order Complete',

  InitializeOrder = '[Order] Initialize',

  UpdateOrderState = '[Order] Update State',

  UpdateFulfillment = '[Order] Update fulfillment',
  UpdateFulfillmentComplete = '[Order] Update fulfillment complete',
  UpdateFulfillmentFailed = '[Order] Update fulfillment failed',

  UpdateProductMedia = '[Order] Update product media',
  UpdateProductMediaComplete = '[Order] Update product media complete',

  SelectProductTemplate = '[Order] Select product template',
  SelectProductTemplateComplete = '[Order] Select product template complete',

  BulkAssignOrders = '[Order] Bulk Assignment',
  BulkAssignOrdersComplete = '[Order] Bulk Assignment Complete'
}

/**
 * Create ease of consumption wrapper actions
 * Eg.
 *
 * new LoadOrder(new MarketingOrder());
 */
export class LoadOrder implements Action {
  readonly type = OrderActionTypes.LoadOrder;
  constructor(public payload: MarketingOrder) {}
}

export class GetOrder implements Action {
  readonly type = OrderActionTypes.GetOrder;
  useCached;
  constructor(public payload: MarketingOrder, useCached?: boolean) {
    // only if passed false, disable cache. Otherwise use cached order if same id
    this.useCached = useCached === false ? false : true;
  }
}

export class GetOrderComplete implements Action {
  readonly type = OrderActionTypes.GetOrderComplete;
  constructor(public payload: MarketingOrder) {}
}

export class AddOrder implements Action {
  readonly type = OrderActionTypes.AddOrder;
  constructor(public payload: MarketingOrder) {}
}

export class AddOrderComplete implements Action {
  readonly type = OrderActionTypes.AddOrderComplete;
  constructor(public payload: MarketingOrder) {}
}

export class UpdateOrder implements Action {
  readonly type = OrderActionTypes.UpdateOrder;
  constructor(public payload: MarketingOrder) {}
}

export class UpdateCurrentOrderState implements Action {
  readonly type = OrderActionTypes.UpdateCurrentOrderState;
  constructor(public payload: MarketingOrder, public patch: boolean = false) {}
}

export class UpdateOrderComplete implements Action {
  readonly type = OrderActionTypes.UpdateOrderComplete;

  /**
   * Initialize the complete action
   * @param payload the payload of a marketing order
   * @param initiatingAction the original type of action causing this update
   */
  constructor(public payload: MarketingOrder, public initiatingAction?: OrderActionTypes) {}
}

export class SubmitOrder implements Action {
  readonly type = OrderActionTypes.SubmitOrder;
  constructor(public payload: MarketingOrder) {}
}

export class SubmitOrderComplete implements Action {
  readonly type = OrderActionTypes.SubmitOrderComplete;
  constructor(public payload: MarketingOrder) {}
}

export class UpdateOrderFailed implements Action {
  readonly type = OrderActionTypes.UpdateOrderFailed;
  constructor(public payload: any) {}
}

export class UpdateOrderPhoto implements Action {
  readonly type = OrderActionTypes.UpdateOrderPhoto;
  constructor(public payload: MarketingOrder, public photo: ListingPhoto, public productCode?: string) {}
}

export class UpdateOrderProductDescription implements Action {
  readonly type = OrderActionTypes.UpdateOrderProductDescription;
  constructor(public payload: ProductDescription ) {}
}

export class UpdateOrderPhotos implements Action {
  readonly type = OrderActionTypes.UpdateOrderPhotos;
  constructor(public payload: MarketingOrder, public photos: any, public photographerId?: string, public productCode?: string) {}
}

export class UploadOrderPhotos implements Action {
  readonly type = OrderActionTypes.UploadOrderPhotos;
  constructor(public payload: MarketingOrder, public photos: UploadPhoto[], public photographerId?: string, public productCode?: string) {}
}

export class UpdateOrderPhotosComplete implements Action {
  readonly type = OrderActionTypes.UpdateOrderPhotosComplete;
  constructor(public payload: MarketingOrder, public photos: ListingPhoto[], public productCode?: string) {}
}

export class DeleteOrder implements Action {
  readonly type = OrderActionTypes.DeleteOrder;
  constructor(public payload: MarketingOrder) {}
}

export class DeleteOrderComplete implements Action {
  readonly type = OrderActionTypes.DeleteOrderComplete;
  constructor() {}
}

export class InitializeOrder implements Action {
  readonly type = OrderActionTypes.InitializeOrder;
  constructor() {}
}

export class UpdateOrderState implements Action {
  readonly type = OrderActionTypes.UpdateOrderState;
  constructor(public marketingOrder: MarketingOrder, public payload: OrderState) {}
}

export class UpdateFulfillment implements Action {
  readonly type = OrderActionTypes.UpdateFulfillment;
  constructor(public payload: Fulfillment, public productCode: string) {}
}

export class UpdateFulfillmentComplete implements Action {
  readonly type = OrderActionTypes.UpdateFulfillmentComplete;
  constructor(public payload: MarketingOrder) {}
}

export class UpdateFulfillmentFailed implements Action {
  readonly type = OrderActionTypes.UpdateFulfillmentFailed;
  constructor(public payload: MarketingOrder) {}
}

export class UpdateProductMedia implements Action {
  readonly type = OrderActionTypes.UpdateProductMedia;
  constructor(public payload: Media, public productCode: string) {}
}

export class SelectProductTemplate implements Action {
  readonly type = OrderActionTypes.SelectProductTemplate;
  constructor(public productCode: string, public template: TemplateInstance) {}
}

export class SelectProductTemplateComplete implements Action {
  readonly type = OrderActionTypes.SelectProductTemplateComplete;
  constructor(public payload: MarketingOrder) {}
}
export class BulkAssignOrders implements Action {
  readonly type = OrderActionTypes.BulkAssignOrders;
  constructor(public orderIds: string[], public coordinatorId: string) {}
}

export class BulkAssignOrdersComplete implements Action {
  readonly type = OrderActionTypes.BulkAssignOrdersComplete;
  constructor() {}
}


export type OrderActions =
  LoadOrder
  | GetOrder
  | GetOrderComplete
  | AddOrder
  | AddOrderComplete
  | UpdateOrder
  | UpdateOrderComplete
  | SubmitOrder
  | SubmitOrderComplete
  | UpdateOrderPhoto
  | UpdateOrderPhotos
  | UploadOrderPhotos
  | UpdateOrderPhotosComplete
  | DeleteOrder
  | DeleteOrderComplete
  | UpdateOrderProductDescription
  | InitializeOrder
  | UpdateOrderState
  | UpdateFulfillment
  | UpdateFulfillmentComplete
  | UpdateFulfillmentFailed
  | UpdateProductMedia
  | SelectProductTemplate
  | SelectProductTemplateComplete
  | UpdateOrderFailed
  | BulkAssignOrders
  | BulkAssignOrdersComplete
  ;

export const SELECTEDORDER = 'selectedOrder';
