<lc-base-dialog id="image-cropper-dialog" [title]="title" (actionClicked)="onCancel()">

  <!--Modal Body-->
  <ng-template #content>
    <div fxLayout="column" fxLayoutGap="10px">

      <!-- Modal description-->
      <!-- TODO: Can we make this an info icon to save space? -->
      <div id="crop-description">
        To crop the photo, click and drag any of the blue dots within the blue photo box.
        To move the location of the blue photo box, click and drag the photo box to the desired location.
        The ratio of the photo cropping box will remain consistent. Use the slider to zoom in and out.
      </div>

      <!-- Photo Cropper Stepper -->
      <mat-horizontal-stepper [linear]="true" #stepper [class.hide-header]="viewModels.length === 1" (selectionChange)="onStepChanged($event)">
        <mat-step *ngFor="let viewModel of viewModels" [stepControl]="viewModel.hasViewed" >
          <ng-template matStepLabel>{{viewModel.request?.display}}</ng-template>

          <div fxLayout="row" fxLayoutAlign="center">
            <lc-image-cropper fxFlex="0 1 400px" (cropperLoaded)="imageLoaded(viewModel, $event)" class="round" [photo]="viewModel.url" [settings]="viewModel.settings"></lc-image-cropper>
          </div>
        </mat-step>
      </mat-horizontal-stepper>

    </div>
  </ng-template>

  <ng-template #actions>
    <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px" *ngIf="swapPrimaryButtons$ | async; else swappedButtons">
      <button id="cropper-next" class="mr-2" mat-raised-button color="primary" (click)="onNext()">{{ nextText$ | async }}</button>
      <button id="cropper-cancel" mat-stroked-button (click)="onCancel()">Cancel</button>
    </div>
  </ng-template>

  <ng-template #swappedButtons>
    <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
      <button id="cropper-cancel" class="mr-2" mat-stroked-button (click)="onCancel()">Cancel</button>
      <button id="cropper-next" mat-raised-button color="primary" (click)="onNext()" [disabled]="!imageReadyToCrop">{{ nextText$ | async }}</button>
    </div>
  </ng-template>
</lc-base-dialog>


