import { Deserializable } from './deserializable.model';

export enum TeamMemberRole {
  salesAgent = 'sales-agent',
  nonSalesSupport = 'non-sales-support'
}

export class TeamMember implements Deserializable<TeamMember> {
  _id: string;
  agentId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string[];
  teamRole: TeamMemberRole;
  teamAdmin: boolean;
  position: number;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): TeamMember {
    Object.assign(this, input);
    return this;
  }
}
