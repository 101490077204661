import { Injectable } from '@angular/core';
import { PromptDialogComponent, PromptDialogData } from './prompt-dialog.component';
import { DialogAction, PromptDialogService as CorePromptDialogService, PromptDialogReference } from '@lc/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

export class MatPromptDialogReference extends PromptDialogReference {
  public readonly result: Promise<any>;

  constructor(public dialogRef: MatDialogRef<PromptDialogComponent>) {
    super();
    this.result = dialogRef.afterClosed().toPromise();
  }

  close() {
    this.dialogRef.close();
  }
}

/**
 * Concrete implementation of the CorePromptDialogService.
 * Gets registered as the core implementation when calling SharedComponentsUiModule.forRoot()
 **/
@Injectable()
export class PromptDialogService implements CorePromptDialogService  {

  constructor(private modal: MatDialog) { }

  openPrompt(title: string, message: string, primaryAction: DialogAction | string, otherActions?: DialogAction[] | string[], options?: {centered?: boolean, backdrop?: boolean}): Promise<DialogAction> {
    const reference = this.getPromptReference(title, message, primaryAction, otherActions, options);
    return reference.result;
  }

  getPromptReference(title: string, message: string, primaryAction: DialogAction | string, otherActions?: DialogAction[] | string[], options?: {centered?: boolean, backdrop?: boolean}): PromptDialogReference {
    otherActions = otherActions ? otherActions : [];

    // Allow the actions to just be text, but convert them to dialogAction objects
    const primary = this.toAction(primaryAction);
    const others = (<any[]>otherActions).map((action: DialogAction | string) => this.toAction(action));

    const dialogRef = this.modal.open(PromptDialogComponent, {
      data: new PromptDialogData(title, message, primary, others),
      hasBackdrop: options?.backdrop || true,
      width: '500px', maxWidth: '90vw', maxHeight: '90vh' });
    const component: PromptDialogComponent = dialogRef.componentInstance;

    // Initialize the dialog with the properties
    component.close.subscribe(action => dialogRef.close(action), (error) => { throw new Error(error); });
    return new MatPromptDialogReference(dialogRef);
  }

  private toAction(action: DialogAction | string) {
    // Convert string actions to DialogAction if necissary
    return action instanceof DialogAction ? action : new DialogAction(action);
  }
}
