import { BaseForm } from './base.form';
import { Lockbox } from '../models/photographer-appointment.model';
import { StringFormControl } from './form-controls/string-form-control';

export class LockboxForm extends BaseForm {
  constructor(value?: Lockbox){
    super({
      code: new StringFormControl(),
      location: new StringFormControl(),
    });

    if (value) {
      this.patchValue(value);
    }
  }
}
