import { Component, Input, forwardRef, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { InputField } from '../input-field';
import { DomSanitizer } from '@angular/platform-browser';
import { Mask } from '../input-masks';
import { StringFormControl } from '@lc/core';

@Component({
  selector: 'lc-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextboxComponent),
      multi: true,
    }
  ]
})
export class TextboxComponent extends InputField {

  @Input()
  label: string;

  @Input()
  placeholder = '';

  @Input()
  readonly = false;

  @Input()
  required = false;

  @Input()
  inputMask: Mask;

  @Input()
  hint: string;

  @Input()
  suffix: string;

  @Input()
  maskDropSpecialCharacters = true //# choose if mask will drop special character in the model, or not, default value is true

  @Output()
  public readonly blur = new EventEmitter<void>();

  @ContentChild('label')
  labelTemplate: TemplateRef<any>;

  @ContentChild('hint')
  hintTemplate: TemplateRef<any>;

  @ContentChild('suffix')
  suffixTemplate: TemplateRef<any>;

  constructor(sanitizer: DomSanitizer) {
    super(sanitizer);
  }

  public onBlur() {
    if(this.formControl && this.formControl instanceof StringFormControl) {
      this.formControl.onLostFocus();
    }

    this.blur.emit();
    this.executeOnTouched();
  }
}
