
/**
 * The cropped photo contains the url of the uploaded cropped photo as well as its
 * dimensions and aspect ratios.
 */
export class CroppedImage {
  url: string;
  isPreferred: boolean;
  aspectRatio: number;
  dimensions: any;
  metadata: any;

  constructor(value?: Partial<CroppedImage>) {
    if(value) {
      Object.assign(this, value);
      if(this.aspectRatio) {
        this.aspectRatio = CroppedImage.convertToFixed(this.aspectRatio);
      }
    }
  }

  static convertToFixed(aspectRatio: number | string): number {
    if(!aspectRatio) { return null; }
    return +(+aspectRatio).toFixed(3)
  }
}

/**
 * The Photo object represents an image that has been uploaded the s3. It includes the url and any
 * of the cropped images that correspond with this original image.
 */
export class ImageModel {
  /** The url of the original photo */
  url: string;

  /** The cropped images of the original photo, including the url, aspect ratio and dimensions */
  cropped: CroppedImage[];

  constructor(value?: Partial<ImageModel>) {
    if(value) {
      Object.assign(this, value);
      this.cropped = (this.cropped || []).map(crop => new CroppedImage(crop))
    }
  }

  findCropped(aspectRatio: number) {
    const converted = +aspectRatio.toFixed(3);
    return (this.cropped || []).find(crop => crop.aspectRatio === converted);
  }

  findPreferredCrop() {
    return (this.cropped || []).find(crop => crop.isPreferred);
  }
}
