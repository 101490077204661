import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserMatchClient } from '../clients/user-match.client';
import { ProfileInfo } from '../models/profile-info.model';
import { UserService } from './user.service';
import { CustomValidator } from '../validators';

@Injectable({
  providedIn: 'root'
})
export class OfficeMemberService {

  constructor(private userMatchClient: UserMatchClient,
      private userService: UserService) { }

  searchAgents(searchTerm?: string): Observable<ProfileInfo[]> {
    searchTerm = CustomValidator.stringValueOf(searchTerm).toLowerCase();
    if(searchTerm.length === 0) {
      return of([]);
    }

    return this.userService.onBehalfOfUsers$({search: searchTerm});
  }

  searchAllAgents(searchTerm?: string): Observable<ProfileInfo[]> {
    searchTerm = CustomValidator.stringValueOf(searchTerm).toLowerCase();
    if(searchTerm.length === 0) {
      return of([]);
    }

    return this.userService.getAllUsers$({search: searchTerm});
  }

  searchAgentsById(agentIds: string[]) {
    // TODO: This needs to be hooked up to the endpoint or the information needs to be returned with the
    // existing queries (i.e. - TeamProfiles and marketing-order)
    return this.userMatchClient.get({userIds: agentIds})
  }
}
