import { SessionActions, SessionActionTypes } from './session.actions';


export const initialState: any = null;


export function sessionReducer(state = initialState, action: SessionActions) {

  switch (action.type) {

    case SessionActionTypes.UpdateUserSession: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
