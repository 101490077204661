import { Deserializable } from './deserializable.model';
import { Address } from './address.model';
import { PhoneNumber } from './phone-number.model';

export class Office implements Deserializable<Office> {
  id: string;
  officeCode: string;
  address: Address;
  phoneNumber: PhoneNumber;
  marketingArea: string;

  constructor(input?: any) {
    this.deserialize(input || {});
  }

  deserialize(input: any): Office {
    Object.assign(this, input);
    return this;
  }
}
